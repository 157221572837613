import { Box, Button } from "@mui/material";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from '@mui/icons-material/Check';
import { TextFieldRoot } from "Components/CmtProfileContainer/sc.ProfileContainer";


export default function EditionModeInfos ({companyData, setEditionMode, submit}) {
    const checkError = (values) => {
        const errors = {};

        if (!values.name) {
            errors.firstName = 'Veuillez renseigner un nom';
        }

        return errors;
    };

    return (
        <Box 
            display='flex'
            alignItems='center'
        >
            <Box >
                <Formik
                    initialValues={{
                        name: companyData?.name || '',
                    }}
                    validate={values => checkError(values)}
                    onSubmit={(values) => {
                        submit(values);
                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <Box display='flex' alignItems='flex-end' justifyContent='center'>
                                <Box mr={4}>
                                    <TextFieldRoot
                                        type="text"
                                        name="name"
                                        label="Nom"
                                        id='structureName'
                                        fullWidth={false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name} 
                                        error={touched.name && errors.name}
                                    />
                                </Box>
                                <Button type='submit' >
                                    <Box color='white'>
                                        <CheckIcon/>
                                    </Box>
                                </Button>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </Box>
            
        </Box>
    );
};