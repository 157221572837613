import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { REDIRECTION_TIME } from "Constant";
import { Formik } from "formik";
import { CirclePicker } from "react-color";
import { NotificationManager } from "react-notifications";

export const CreateCalendar = ({open, onClose, submit, colorList}) => {
    const checkError = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = 'Veuillez renseigner un nom pour votre calendrier';
        }

        if (!values.color) {
            errors.color = 'Veuillez choisir une couleur pour votre calendrier';
        }

        return (errors);
    };

    const handleSubmit = async (values) => {
        submit(values)
        .then(result => {
            if (result?.result) {
                NotificationManager.success('Votre calendrier à été crée correctement', 'Succès', REDIRECTION_TIME);

                onClose();
            } else {
                NotificationManager.error(result?.error?.message, 'Erreur', REDIRECTION_TIME);
            }
        })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle>
                <Typography component='p' variant='subtitle1'> Créer un calendrier</Typography>
            </DialogTitle>
            <Formik
                initialValues={{
                    name: '',
                    color: '',
                    isDefault: false,
                }}
                validate={values => checkError(values)}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                }) => (
                    <CmtForm onSubmit={handleSubmit}>
                        <DialogContent dividers>
                            <FormInput
                                name='name'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                label='Nom'
                                error={touched?.name && errors?.name}
                            />

                            <Box py={2} mt={5}>
                                <Box pb={2}>
                                    <Typography component='p' variant='h6'>Couleur</Typography>
                                </Box>
                                <CirclePicker
                                    width='100%'
                                    onChange={newValue => setFieldValue('color', newValue.hex.toUpperCase())}
                                    value={values.color}
                                    name='color'
                                    colors={colorList}
                                    onBlur={handleBlur}
                                />
                                <FormHelperText error>{touched.color && errors.color}</FormHelperText>
                            </Box>


                            <Box display='flex' alignItems='center' mt={5}>
                                <Checkbox sx={{p: 0}} checked={values.isDefault} onClick={(e) => setFieldValue('isDefault', e.target.checked)} />
                                <Typography component='p' ml={3} variant='h5'>Faire de ce calendrier mon calendrier principal</Typography>
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Box width='100%' display='flex' justifyContent='space-between'>
                                <Button variant='outlined' color='secondary' onClick={onClose}>Annuler</Button>
                                <Button variant='contained' type='submit' color='primary'>Créer</Button>
                            </Box>
                        </DialogActions>
                    </CmtForm>
                )}
            </Formik>
        </Dialog>
    );
};