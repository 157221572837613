import React from "react";
import { Box, ClickAwayListener } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import history from "services/History/history";
import { HOME_PATH } from "Constant";

const AuthWrapper = ({ children }) => {
    return (
        <Box width="100%" display="flex" height="100%" justifyContent="center" alignItems="center">
            <Box maxWidth="1050px" width="100%">
                <CmtCard>
                    <ClickAwayListener onClickAway={() => history.push(HOME_PATH)}>
                        <Box display="flex" flexDirection="row">
                            {children}
                        </Box>
                    </ClickAwayListener>
                </CmtCard>
            </Box>
        </Box>
    );
};

export default AuthWrapper;
