import { Hidden } from "@mui/material";
import { LIST_MODE } from "Constant";
import { useDispatch } from "react-redux";
import { favoriteAnnouncementAction } from "redux/actions/announcementsActions";
import { AnnouncementsGrid } from "./AnnouncementsGrid";
import { AnnouncementsTable } from "./AnnouncementsTable";

export const AnnouncementsList = ({
    list,
    profileId,
    basicPath,
    displayMode,
    filters,
    changeFilters,
    deleteAnnouncement,
    path,
    loading,
    profile,
}) => {
    const dispatch = useDispatch();

    const handleClickFavorite = (id) => {
        dispatch(favoriteAnnouncementAction(id));
    };

    if (displayMode === LIST_MODE) {
        return (
            <>
                <Hidden mdDown>
                    <AnnouncementsTable
                        handleClickFavorite={handleClickFavorite}
                        list={list}
                        profileId={profileId}
                        basicPath={basicPath}
                        filters={filters}
                        changeFilters={changeFilters}
                        deleteAnnouncement={deleteAnnouncement}
                        path={path}
                        loading={loading}
                        profile={profile}
                    />
                </Hidden>
                <Hidden lgUp>
                    <AnnouncementsGrid
                        list={list}
                        profileId={profileId}
                        basicPath={basicPath}
                        handleClickFavorite={handleClickFavorite}
                        deleteAnnouncement={deleteAnnouncement}
                        path={path}
                        loading={loading}
                        profile={profile}
                    />
                </Hidden>
            </>
        );
    } else {
        return (
            <AnnouncementsGrid
                handleClickFavorite={handleClickFavorite}
                list={list}
                profileId={profileId}
                basicPath={basicPath}
                deleteAnnouncement={deleteAnnouncement}
                path={path}
                loading={loading}
                profile={profile}
            />
        );
    }
};
