import { Button } from "@mui/material";
import { Box, styled } from "@mui/system";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CmtPopover } from "Components/CmtPopover/CmtPopover";

export const LocationButton = styled(Button)`
    width: 35px;
    height: 35px;
    min-width: 30px;
    padding-inline: 5px;
`;

export const RotatingIcons = styled(ArrowUpwardIcon)`
    transform: ${props => props.order === 'DESC' && 'rotate(180deg)'};
    transition: 1s;
`;

export const LocationLineContainer = styled(Box)`
    & .suggestions {
        max-height: 180px;
        overflow-y: auto;

    }
`;

export const LocationPopover = styled(CmtPopover)`
    & .MuiPopover-paper {
        height: 250px;
    }
`;