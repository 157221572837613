import { Box, Button, Alert, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { SideBar } from "Apps/ConnectedContainer/SideBar/SideBar";
import { HOME_PATH, PROFILE_PATH } from "Constant";
import { authSelector } from "redux/selectors/authSelector";
import history from "services/History/history";
import { useEffect, useState } from "react";
import { profileSelector } from "redux/selectors/profileSelector";
import { HeaderBar } from "./HeaderBar/HeaderBar";
import { connect } from "react-redux";
import { getProfileAction, markAsReadAction } from "redux/actions/profileActions";
import { notConnectedAction } from "redux/actions/authActions";
import { useCallback } from "react";
import search from "services/Api/search";
import auth from "services/Api/auth";
import { DisplaySearchResult } from "./Search/DisplaySearchResult";
import { MainBox } from "./sc.ConnectedContainer";
import CloseIcon from "@mui/icons-material/Close";
import { IncompleteProfile } from "Components/IncompletProfile/IncompleteProfile";
import { chatSelector } from "redux/selectors/chatSelector";

export default function ConnectedContainer({
    children,
    chatData,
    disconnect,
    profile,
    authData,
    alertDisplay = true,
    markAsRead,
}) {
    const [isCollapse, setCollapse] = useState(true);

    const [searchValue, setSearchValue] = useState("");
    const [searchResult, setSearchResult] = useState(null);
    const [searchOpen, setSearchOpen] = useState(false);

    const [openProfileInformation, setOpenProfileInformation] = useState(
        profile?.profile?.isCompleted === false && !sessionStorage.getItem("profileInformation")
    );

    useEffect(() => {
        if (
            profile?.profile?.isCompleted === false &&
            !sessionStorage.getItem("profileInformation")
        ) {
            setOpenProfileInformation(true);
        } else {
            setOpenProfileInformation(false);
        }
    }, [profile]);

    const handleCloseInformation = () => {
        setOpenProfileInformation(false);

        sessionStorage.setItem("profileInformation", "true");
    };

    const handleCloseSearch = () => {
        setSearchValue("");
        setSearchResult(null);
        setSearchOpen(false);
    };

    useEffect(() => {
        if (!authData.connected && !authData.loading) {
            history.push(HOME_PATH);
        }
    });

    useEffect(() => {
        const listen = history.listen(() => {
            handleCloseSearch();
        });

        return () => {
            listen();
        };
    }, []);

    const fetchSearch = useCallback(async () => {
        if (searchValue?.length > 0) {
            setSearchOpen(true);
        }

        if (searchValue?.length < 3) {
            setSearchResult(null);

            return;
        }

        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();
        }

        search.searching(searchValue).then((result) => {
            if (result?.result) {
                setSearchResult(result?.data);
            }
        });
    }, [searchValue, disconnect]);

    useEffect(() => {
        fetchSearch();
    }, [fetchSearch]);

    if (authData.connected === null) {
        return <></>;
    }

    return (
        <>
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                height="100%"
                display="flex"
                flexDirection="column"
                overflow="hidden"
                onContextMenu={(e) => {
                    if (
                        !e.target?.className?.includes("ck-editor") &&
                        !e.target?.parentElement?.className?.includes("ck-editor")
                    ) {
                        e.preventDefault();
                    }
                }}
            >
                <HeaderBar
                    setCollapse={setCollapse}
                    isCollapse={isCollapse}
                    profile={profile}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    closeSearch={handleCloseSearch}
                    chatData={chatData}
                    markAsRead={markAsRead}
                />
                <Box
                    display="flex"
                    height="100%"
                    width="100%"
                    porition="relative"
                    overflow="hidden"
                >
                    <SideBar
                        isCollapse={isCollapse}
                        setCollapse={setCollapse}
                        profile={profile}
                        closeSearch={handleCloseSearch}
                    />

                    <MainBox>
                        {profile?.profile && !profile?.profile?.isCompleted && alertDisplay && (
                            <Alert
                                severity="warning"
                                action={
                                    <Button
                                        onClick={() => history.push(PROFILE_PATH)}
                                        color="primary"
                                        size="small"
                                    >
                                        Compléter mon profil
                                    </Button>
                                }
                            >
                                Vous n'avez pas complété votre profil. Veuillez le compléter pour
                                accéder à tous les éléments du site
                            </Alert>
                        )}
                        {searchOpen && (
                            <DisplaySearchResult
                                searchValue={searchValue}
                                searchResult={searchResult}
                                closeSearch={handleCloseSearch}
                                defaultAvatarPath={profile?.defaultAvatarPath}
                            />
                        )}
                        {children}
                    </MainBox>

                    <Dialog
                        open={openProfileInformation}
                        maxWidth="sm"
                        fullWidth
                        onClose={handleCloseInformation}
                    >
                        <DialogTitle>
                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                <CloseIcon color="primary" onClick={handleCloseInformation} />
                            </Box>

                            <DialogContent>
                                <IncompleteProfile
                                    text="Un profil incomplet vous empêchera d'accéder à certains
                                    contenus. Pour compléter votre profil, assurez d'avoir renseigné
                                    les informations suivantes :"
                                    callToAction={handleCloseInformation}
                                />
                            </DialogContent>
                        </DialogTitle>
                    </Dialog>
                </Box>
            </Box>
        </>
    );
}

export const ConnectedContainerStore = connect(
    (state) => ({
        authData: authSelector(state),
        profile: profileSelector(state),
        chatData: chatSelector(state),
    }),
    (dispatch) => ({
        getProfile: () => dispatch(getProfileAction()),
        disconnect: () => dispatch(notConnectedAction()),
        markAsRead: (id) => dispatch(markAsReadAction(id)),
    })
)(ConnectedContainer);
