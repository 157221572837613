import { useState } from "react";
import DisplayDescription from "./DisplayDescription";
import EditionDescription from "./EditionDescription";

export const ProfileDescription = ({ companyData, isIntermittent, update, isAdmin }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionDescription
                companyData={companyData}
                setEditionMode={setEditionMode}
                submit={update}
            />
        );
    } else {
        return (
            <DisplayDescription
                isAdmin={isAdmin}
                isIntermittent={isIntermittent}
                companyData={companyData}
                setEditionMode={setEditionMode}
            />
        );
    }
};
