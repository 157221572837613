import { Box, Fab, Typography } from "@mui/material";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import AddIcon from "@mui/icons-material/Add";
import history from "services/History/history";
import { ReceivedInvitations } from "./ReceivedInvitations/ReceivedInvitations";
import { ProjectListItem } from "./ProjectListItem/ProjectListItem";
import { PageContainer } from "./PageContainer/PageContainer";
import { ProjectSkeletonListItem } from "./ProjectListItem/ProjectListItem";
import { CREATE_PATH, PROJECT_PATH } from "Constant";
import { useTheme } from "@emotion/react";
import SettingsIcon from "@mui/icons-material/Settings";

export const MyProjectPage = ({ projectData }) => {
    const theme = useTheme();

    return (
        <Box p={5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <CmtAppHeader title="Liste de mes projets" />
                <Box display="flex">
                    <Fab
                        variant="extended"
                        sx={{ ml: 5, px: 5 }}
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={() => history.push(`${PROJECT_PATH}${CREATE_PATH}`)}
                    >
                        <AddIcon sx={{ mr: 2 }} />
                        Créer un projet
                    </Fab>

                    <Fab
                        variant="extended"
                        sx={{ ml: 5, px: 5 }}
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={() => history.push(`${PROJECT_PATH}`)}
                    >
                        <SettingsIcon sx={{ mr: 2 }} />
                        Projets
                    </Fab>
                </Box>
            </Box>

            {projectData?.myProjectList?.invitations?.length > 0 && (
                <>
                    <Typography component="p" variant="subtitle1">
                        Vos invitations
                    </Typography>
                    <PageContainer
                        borderBottom={`1px solid ${theme.palette.borderColor.dark}`}
                        pb={12}
                    >
                        <ReceivedInvitations projectData={projectData} />
                    </PageContainer>
                    <Typography component="p" variant="subtitle1">
                        Vos projets
                    </Typography>
                </>
            )}

            <PageContainer>
                {projectData?.myProjectList?.current?.map((item, index) => (
                    <ProjectListItem projectData={item} key={index} index={index} />
                ))}
                {projectData?.myLoading &&
                    !projectData.error &&
                    [...Array(3)].map((_, index) => <ProjectSkeletonListItem key={index} />)}
            </PageContainer>
        </Box>
    );
};
