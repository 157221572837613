import { ClickAwayListener, IconButton, MenuItem, MenuList, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CalendarCheckbox, SidebarContainer } from "../sc.Planning";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const DisplayCalendarLine = ({ item, onClick, isChecked, isOwned, setMenu }) => {
    return (
        <Box display="flex" alignItems="center">
            <CalendarCheckbox
                onClick={(e) => onClick(item?.id, e.target.checked)}
                checked={isChecked}
                color="default"
                calendarcolor={item?.color}
            />
            <Typography component="p" ml={2} variant="h5" color={!isChecked ? "gray" : "default"}>
                {" "}
                {item?.name}{" "}
            </Typography>
            {isOwned && (
                <Box ml="auto" pr={2}>
                    <IconButton
                        color="primary"
                        onClick={(e) => {
                            setMenu({
                                anchorEl: e.currentTarget,
                                item: item,
                            });
                        }}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export const DisplayCalendarsList = ({
    planningData,
    setSelectedCalendar,
    setUpdateCalendar,
    deleteCalendar,
    isCollapse,
    setIsCollapse,
}) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleSelectCalendar = (id, isChecked) => {
        const list = isChecked
            ? planningData?.selectedCalendars?.concat(id)
            : planningData?.selectedCalendars?.filter((selectedId) => selectedId !== id);

        sessionStorage.setItem("PlanningSelectedCalendars", list.join(","));

        setSelectedCalendar(list);
    };

    return (
        <ClickAwayListener onClickAway={() => setIsCollapse(true)}>
            <SidebarContainer p={5} isCollapse={isCollapse}>
                <Typography component="p" variant="subtitle1" mb={10}>
                    Les calendriers
                </Typography>

                <Typography component="p" variant="subtitle2" my={5}>
                    {" "}
                    Mes calendriers{" "}
                </Typography>
                {planningData?.calendarList?.owner?.map((item, index) => (
                    <DisplayCalendarLine
                        key={index}
                        item={item}
                        onClick={handleSelectCalendar}
                        isChecked={planningData?.selectedCalendars.includes(item?.id)}
                        isOwned
                        setMenu={setMenuAnchor}
                    />
                ))}

                {planningData?.calendarList?.projects.length > 0 && (
                    <>
                        <Typography component="p" variant="subtitle2" mt={10} mb={5}>
                            {" "}
                            Calendriers de projets{" "}
                        </Typography>
                        {planningData?.calendarList?.projects?.map((item, index) => {
                            if (!item.name) {
                                return <></>;
                            }

                            return (
                                <DisplayCalendarLine
                                    key={index}
                                    item={item}
                                    onClick={handleSelectCalendar}
                                    isChecked={planningData?.selectedCalendars.includes(item?.id)}
                                />
                            );
                        })}
                    </>
                )}

                {planningData?.calendarList?.compagnies?.length > 0 && (
                    <>
                        <Typography component="p" variant="subtitle2" mt={10} mb={5}>
                            {" "}
                            Calendriers de structures{" "}
                        </Typography>
                        {planningData?.calendarList?.compagnies?.map((item, index) => {
                            if (!item.name) {
                                return <></>;
                            }

                            return (
                                <DisplayCalendarLine
                                    key={index}
                                    item={item}
                                    onClick={handleSelectCalendar}
                                    isChecked={planningData?.selectedCalendars.includes(item?.id)}
                                />
                            );
                        })}
                    </>
                )}

                <CmtPopover
                    open={Boolean(menuAnchor)}
                    anchorEl={menuAnchor?.anchorEl}
                    onClose={() => setMenuAnchor(null)}
                >
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                setUpdateCalendar(menuAnchor?.item);
                                setMenuAnchor(null);
                            }}
                        >
                            <EditIcon color="primary" />
                            <Typography component="p" ml={2} color="primary">
                                {" "}
                                Modifier{" "}
                            </Typography>
                        </MenuItem>

                        {menuAnchor && !menuAnchor?.item?.isDefault && (
                            <MenuItem
                                onClick={() => {
                                    deleteCalendar(menuAnchor?.item?.id);
                                    setMenuAnchor(null);
                                }}
                            >
                                <DeleteIcon color="primary" />
                                <Typography component="p" ml={2} color="primary">
                                    {" "}
                                    Supprimer{" "}
                                </Typography>
                            </MenuItem>
                        )}
                    </MenuList>
                </CmtPopover>
            </SidebarContainer>
        </ClickAwayListener>
    );
};
