import { Box, Typography, useTheme } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import CmtCard from "Components/CmtCard/CmtCard";
import { STRUCTURE_PATH } from "Constant";
import "swiper/swiper.scss";
import "./navigation.scss";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import { ResultSlider } from "../sc.ConnectedContainer";

export const DisplayUsersResult = ({ results, handleClick, defaultAvatarPath }) => {
    const theme = useTheme();

    return (
        <Box pt={5}>
            <Box pl={11}>
                <Typography component="p" variant="h4" color="whitesmoke">
                    {" "}
                    Professionnels{" "}
                </Typography>
            </Box>
            <ResultSlider
                navigation={true}
                spaceBetween={10}
                slidesPerView={"auto"}
                pagination={true}
            >
                {results?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Box
                            width={200}
                            className="pointer"
                            onClick={() => handleClick(`${STRUCTURE_PATH}/${item?.id}`)}
                        >
                            <CmtCard>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    pt={2}
                                >
                                    <AvatarComponent
                                        size={150}
                                        src={item?.profileFileName || defaultAvatarPath}
                                    />
                                    <Box
                                        width="100%"
                                        mt={2}
                                        p={2}
                                        pl={2}
                                        borderTop={`1px solid ${theme.palette.borderColor.dark}`}
                                    >
                                        <Typography component="p" variant="subtitle1">
                                            {item?.firstName} {item?.lastName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CmtCard>
                        </Box>
                    </SwiperSlide>
                ))}
            </ResultSlider>
        </Box>
    );
};
