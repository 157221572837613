import { FUNCTIONNALITY_PATH, NETWORK_PATH } from "Constant";
import { Route, Router, Switch } from "react-router-dom";
import history from "services/History/history";
import { network } from "./data";
import { DisplayFunctionnalityDetail } from "./DisplayFunctionnalityDetail";

export const FunctionnalityDetail = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route path={`${FUNCTIONNALITY_PATH}${NETWORK_PATH}`}>
                    <DisplayFunctionnalityDetail content={network} />
                </Route>
            </Switch>
        </Router>
    );
};
