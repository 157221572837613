import {
    CHANGE_PROJECT_FILTER,
    CREATE_PROJECT_ERROR,
    CREATE_PROJECT_SUCCESS,
    DELETE_PROJECT_ERROR,
    DELETE_PROJECT_SUCCESS,
    GET_MY_PROJECT_LIST_ERROR,
    GET_MY_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST_ERROR,
    GET_PROJECT_LIST_SUCCESS,
    MY_PROJECT_LOADING,
    PROJECTS_INVITATIONS_ERROR,
    PROJECTS_INVITATIONS_SUCCESS,
    PROJECT_LOADING,
} from "redux/constants/projectConstants";
import auth from "services/Api/auth";
import project from "services/Api/project";
import { notConnectedAction } from "./authActions";

export const projectLoading = () => ({
    type: PROJECT_LOADING,
    loading: true,
});

export const requestProjectListSuccess = (data) => ({
    type: GET_PROJECT_LIST_SUCCESS,
    projectList: data,
    loading: false,
});

export const requestProjectListError = (error) => ({
    type: GET_PROJECT_LIST_ERROR,
    error: error,
    loading: false,
});

export const getProjectListAction = (filters = null) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(projectLoading());

        const filtersParams = filters || getState().project.filters;

        const result = await project.getProjectList(filtersParams);

        if (result.result) {
            dispatch(requestProjectListSuccess(result?.data));
        } else {
            dispatch(requestProjectListError(result?.error));
        }

        return result;
    };
};

export const myProjectLoading = () => ({
    type: MY_PROJECT_LOADING,
    loading: true,
});

export const requestMyProjectListSuccess = (data) => ({
    type: GET_MY_PROJECT_LIST_SUCCESS,
    projectList: data,
    loading: false,
});

export const requestMyProjectListError = (error) => ({
    type: GET_MY_PROJECT_LIST_ERROR,
    error: error,
    loading: false,
});

export const getMyProjectListAction = () => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(myProjectLoading());

        const result = await project.getMyProjectList();

        if (result.result) {
            dispatch(requestMyProjectListSuccess(result?.data));
        } else {
            dispatch(requestMyProjectListError(result?.error));
        }

        return result;
    };
};

export const requestCreateProjectSuccess = (data) => ({
    type: CREATE_PROJECT_SUCCESS,
    project: data,
    loading: false,
});

export const requestCreateProjectError = (error) => ({
    type: CREATE_PROJECT_ERROR,
    error: error,
    loading: false,
});

export const createProjectAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await project.createProject(data);

        if (result.result) {
            dispatch(requestCreateProjectSuccess(result?.data));
        } else {
            dispatch(requestCreateProjectError(result?.error));
        }

        return result;
    };
};

export const requestDeleteProjectSuccess = (data) => ({
    type: DELETE_PROJECT_SUCCESS,
    projectId: data,
    loading: false,
});

export const requestDeleteProjectError = (error) => ({
    type: DELETE_PROJECT_ERROR,
    error: error,
    loading: false,
});

export const deleteProjectAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await project.deleteProject(data);

        if (result.result) {
            dispatch(requestDeleteProjectSuccess(data));
        } else {
            dispatch(requestDeleteProjectError(result?.error));
        }

        return result;
    };
};

export const requestInvitationResponseSuccess = (data) => ({
    type: PROJECTS_INVITATIONS_SUCCESS,
    projectId: data,
    loading: false,
});

export const requestInvitationResponseError = (error) => ({
    type: PROJECTS_INVITATIONS_ERROR,
    error: error,
    loading: false,
});

export const sendInvitationResponseAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await project.sendInvitationResponse(data);

        if (result?.result) {
            dispatch(requestInvitationResponseSuccess(result?.data));

            dispatch(getProjectListAction());
        } else {
            dispatch(requestInvitationResponseError(result?.error));
        }

        return result;
    };
};

export const updateFiltersAction = (filters) => ({
    type: CHANGE_PROJECT_FILTER,
    filters: filters,
});

export const changeProjectFiltersAction = (filters) => {
    return async (dispatch) => {
        sessionStorage.setItem("ProjectFiltersType", filters?.projectType);
        sessionStorage.setItem("ProjectFiltersFromDate", filters?.fromDate);
        sessionStorage.setItem("ProjectFiltersToDate", filters?.toDate);
        sessionStorage.setItem("ProjectFiltersLocation", filters?.location);
        sessionStorage.setItem("ProjectFiltersSorter", filters?.sorter);
        sessionStorage.setItem("ProjectFiltersLng", filters?.lng);
        sessionStorage.setItem("ProjectFiltersLat", filters.lat);
        sessionStorage.setItem("ProjectFiltersDistance", filters.distance);

        dispatch(updateFiltersAction(filters));

        dispatch(getProjectListAction(filters));
    };
};
