import { Box, Button, Typography } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import CmtLoading from "Components/CmtLoading/CmtLoading";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { ALL_FILE_SUPPORTED, REDIRECTION_TIME } from "Constant";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { NotificationManager } from "react-notifications";

export const EditFile = ({
    anchorEl,
    closePopover,
    fileData,
    updateFile,
}) => {
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState(() => {
        let name = fileData?.name.split('.');

        name = name?.slice(0, name?.length - 1).join('.');

        return (name);
    });

    const [file, setFile] = useState(null);

    const { getRootProps, getInputProps } = useDropzone({
        accept: ALL_FILE_SUPPORTED,
        multiple: false,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections?.length > 0) {
                NotificationManager.error('Votre image ne peut pas être ajouté', 'Erreur', REDIRECTION_TIME);
            }

            if (acceptedFiles[0]) {
                if (!value) {
                    let name = acceptedFiles[0].name.split('.');

                    name = name.slice(0, name.length - 1).join('.');

                    setValue(name);
                }

                setFile(acceptedFiles[0]);
            }
        },
    });

    const handleUpdate = async () => {
        setLoading(true);

        updateFile({
            name: value,
            folderId: fileData?.id,
            isFolder: false,
            documentFile: file,
        }).then(result => {
            setLoading(false);

            if (result?.result) {
                closePopover();
            } else {
                NotificationManager.error(result?.error?.message, 'Erreur', REDIRECTION_TIME);
            }
        })
    };

    return (
        <CmtPopover
            anchorEl={anchorEl}
            closePopover={() => closePopover()}
        >
            <input {...getInputProps()} />

            {loading && 
                <CmtLoading />
            }

            <Box p={5} minWidth={350}>
                <CmtForm
                    onSubmit={(e) => {
                        e.preventDefault();

                        handleUpdate();
                    }}
                >
                    <Typography component='p' variant='h1'>Ajouter un fichier</Typography>

                    <Box pt={5}>
                        <Button
                            size='small'
                            type='button'
                            variant={file ? 'contained' : 'outlined'}
                            color='secondary'
                            {...getRootProps()}
                        >
                            Remplacer le fichier
                        </Button>
                    </Box>

                    <FormInput label='Renommer le fichier' value={value} onChange={(e) => setValue(e.target.value)} />

                    <Box display='flex' pt={4} justifyContent='flex-end'>
                        <Button
                            color='primary'
                            variant='contained'
                            type='submit'
                            disabled={!value}
                        >
                            Valider
                        </Button>
                    </Box>
                </CmtForm>
            </Box>
        </CmtPopover>
    );
};