import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Box } from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export const CmtButtonDatePicker = ({
    disableFuture = false,
    openToYear,
    value,
    setValue,
    name,
    error,
    initialFocusedDate = null,
    label,
    onTouched = null,
    open = null,
    setOpen,
    ...rest
}) => {

    if (!open) {
        return (<></>);
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box md={2}>
                    <DatePicker
                        disableFuture={disableFuture}
                        initialFocusedDate={initialFocusedDate}
                        allowKeyboardControl
                        openTo={openToYear ? "year" : 'day'}
                        format="DD/MM/yyyy"
                        clearable
                        name="name"
                        label={label}
                        value={value || null}
                        onChange={date => {setValue(date)}}
                        autoOk
                        onOpen={() => {
                            if (!onTouched) {
                                return;
                            }
                            
                            onTouched(name, false, false);
                        }}

                        onClose={() => {
                            setOpen(null);

                            if(!onTouched) {
                                return;
                            }
                            onTouched(name, true, false);
                        }}

                        PopperProps={{
                            anchorEl: open,
                        }}

                        ampm={false}
                        helperText={error}
                        TextFieldComponent={() => null}
                        error={Boolean(error)}
                        open={Boolean(open)}
                        renderInput={() => null}
                        {...rest}
                    />
                </Box>
            </LocalizationProvider>
        </>
    );
};