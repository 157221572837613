import { Box, styled } from "@mui/system";

export const SidebarContainer = styled(Box)`
    width: 250px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 0;
    transition: transform 1s;
    border-right: ${(props) => `1px solid ${props.theme.palette.borderColor.dark}`};
    box-shadow: ${(props) => props.theme.palette.sidebar.shadow};
    background-color: ${(props) => props.theme.palette.sidebar.bgColor};
    z-index: 11;
    ${(props) => props.theme.breakpoints.down("sm")} {
        position: absolute;
        transform: ${(props) => props.isCollapse && "translate(-350px)"};
    } ;
`;
