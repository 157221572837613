import { useState } from "react";
import { DisplaySpectacleGeneralInfos } from "./DisplaySpectacleGeneralInfos";
import { EditionSpectacleGeneralInfos } from "./EditionSpectacleGeneralInfos";

export const SpectacleGeneralInfos = ({ projectData, updateProject }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionSpectacleGeneralInfos
                projectData={projectData}
                setEditionMode={setEditionMode}
                submit={updateProject}
            />
        );
    } else {
        return (
            <DisplaySpectacleGeneralInfos
                projectData={projectData}
                setEditionMode={setEditionMode}
            />
        );
    }
};
