import { useState } from "react";
import { DisplayProjectLocation } from "./DisplayProjectLocation";
import { EditionProjectLocation } from "./EditionProjectLocation";

export const ProjectLocation = ({ projectData, updateProject }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionProjectLocation
                projectData={projectData}
                setEditionMode={setEditionMode}
                submit={updateProject}
            />
        );
    } else {
        return <DisplayProjectLocation projectData={projectData} setEditionMode={setEditionMode} />;
    }
};
