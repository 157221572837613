import { Box, CircularProgress } from "@mui/material";
import { Message } from './Message.jsx';
import { useEffect, useRef } from "react";
import moment from "moment";
import { useState } from "react";
import { TypingMessage } from "./TypingMessage.jsx";
import { AnimatePresence } from 'framer-motion';
import { useTheme } from '@emotion/react';
import { ChatPerfectScroll } from './sc.ContentMessage.js';

export default function ContentMessages ({
    chat,
    profileId,
    getMessages,
    deleteMessage,
    readedMessage,
    popoverVersion,
}) {
    const theme = useTheme();

    const [historyLoading, setHistoryLoading] = useState(chat.loading);

    const today = moment().format("DD MMM YYYY");
    const yesterday = moment().subtract(1, 'days').format("DD MMM YYYY");

    const scroll = useRef(null);
    const [bottomScroll, setBottomScroll] = useState(true);

    useEffect(() => {
        if (scroll?.current?._container) {
            scroll.current._container.scrollTop = scroll.current._container?.scrollHeight;
        }
    }, [scroll]);

    useEffect(() => {
        setHistoryLoading(chat.loading);
    }, [chat]);

    useEffect(() => {
        if (bottomScroll && scroll?.current?._container) {
            scroll.current._container.scrollTop = scroll.current._container?.scrollHeight;
        }
    }, [chat, bottomScroll]);

    const handleTopScroll = async (props) => {
        if (props?.scrollTop <= 500 && !historyLoading && chat.loadableMessage) {
            setHistoryLoading(true);

            getMessages({
                userId: chat?.currentUser?.id,
                limite: 20,
                lastDate: chat?.conversation[0]?.messageDate,
            });
        }
    };
    
    const handleBottomScroll = async (props) => {
        setBottomScroll(props?.target?.scrollHeight - props?.target?.scrollTop === props?.target?.clientHeight);
    }

    const handleDeleteMessage = ({messageId}) => {
        if (!messageId || !chat?.currentUser?.id) {
            return;
        }

        deleteMessage({
            messageId: messageId,
            userId: chat.currentUser.id,
        });
    };

    return (
        <ChatPerfectScroll
            id='chatScroll'
            ref={scroll}
            onScrollUp={props => handleTopScroll(props)}
            onScroll={props => handleBottomScroll(props)}
        >
            {historyLoading && 
                <Box m='auto' my='1em'>
                    <CircularProgress color="secondary" />
                </Box>
            }
            <AnimatePresence>
                {chat?.conversation.map((message, index) => {
                    const oldTime = index === 0 ? 0 : moment(chat?.conversation[index - 1].messageDate).format("DD MMM YYYY");
                    const newTime = moment(message.messageDate).format("DD MMM YYYY");
                    
                    return (
                        <Box key={index} >
                            {oldTime !== newTime &&
                                <Box
                                    width='100%'
                                    height={0}
                                    borderBottom={`1px solid ${theme.palette.borderColor.dark}`}
                                    my='2em'
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='center'
                                >
                                    <Box
                                        backgroundColor={theme.palette.background.paper}
                                        p={1}
                                    >
                                        {newTime === today ? "Aujourd'hui" : newTime === yesterday ? 'Hier' : newTime}
                                    </Box>
                                </Box>
                            }
                            <Message
                                message={message}
                                key={`Message: ${index}`}
                                userPic={chat?.currentUser?.profileFileName}
                                profileId={profileId}
                                lastMessage={chat?.currentUser?.lastMessage}
                                deleteMessage={handleDeleteMessage}
                                readedMessage={readedMessage}
                                popoverVersion={popoverVersion}
                            />
                        </Box>
                    );
                })}
            </AnimatePresence>
            {chat?.currentUser?.isTyping &&
                <TypingMessage currentUser={chat?.currentUser} />
            }
        </ChatPerfectScroll>
    );
};