import { Box, styled } from "@mui/system";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper } from "swiper/react";
import CmtImage from "Components/CmtImage/CmtImage";
import { IconButton, Popover, Typography } from "@mui/material";

SwiperCore.use([Navigation, Pagination]);

export const MainBox = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
`;

export const ResultSlider = styled(Swiper)`
    padding-inline: 40px;
    padding-block: 10px;

    & .swiper-slide {
        box-sizing: border-box;
        width: fit-content;
    }

    & .swiper-button-next,
    .swiper-button-prev {
        color: ${(props) => props.theme.palette.primary.main};
    }

    & .swiper-button-disabled {
        opacity: 0 !important;
    }
`;

export const ImageSearchResult = styled(CmtImage)`
    max-height: 200px;
    max-width: 200px;
    height: 200px;
    object-fit: cover;
`;

export const PopoverContainer = styled(Popover)`
    & .MuiPopover-paper {
        max-width: 100%;
        width: 500px;
        top: 70px !important;
        right: 0;
        bottom: 0px !important;
        left: unset !important;
        border-radius: 0;
    }
`;

export const HeaderBarContainer = styled(Box)`
    background-color: ${(props) => props.theme.palette.background.paper};
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: 5px 10px 5px 20px;
    height: 70px;
    flex-shrink: 0;
    z-index: 12;
    box-shadow: ${(props) => props.theme.palette.headerBar.shadow};
    ${(props) => props.theme.breakpoints.down("sm")} {
        left: 0;
        padding-left: 0;
    } ;
`;

export const SearchRoot = styled(Box)`
    & .MuiSvgIcon-root {
        color: ${(props) => (props.value ? "white" : props.theme.palette.primary.main)};
    }

    & .MuiInputBase-root {
        width: 100%;
        margin-left: 0;
    }

    &:focus-within {
        & .MuiSvgIcon-root {
            color: white;
            z-index: 3;
        }
    }

    & .MuiInputBase-input {
        text-indent: 40px;
        height: 48px;
        border-radius: 30px;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
        transition: all 0.5s ease-in;
        background-color: ${(props) => props.value && props.theme.palette.primary.main};
        &:focus {
            background-color: ${(props) => props.theme.palette.primary.main};
        }
    }
`;

export const PopoverIconButton = styled(IconButton)`
    background-color: ${(props) => props.open && props.theme.palette.primary.main};
    color: ${(props) => props.open && "white"};
`;

export const NotificationElement = styled(Box)`
    border-top: 1px solid rgba(211, 211, 211, 0.4);
    background-color: ${(props) => !props.readed && "rgba(211, 211, 211, 0.4)"};
    cursor: pointer;
`;

export const NotificationDescription = styled(Typography)`
    font-size: 11px;
    line-height: 15px;
    height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    overflow: hidden;
`;

export const NotificationCircle = styled(Box)`
    width: 7px;
    height: 7px;
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.secondary.main};
`;
