import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { Popup } from "react-mapbox-gl";

export const LocationButton = styled(Button)`
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.primary.main};
    z-index: 10;
    margin: 10px;
    & .MuiSvgIcon-root {
        margin-right: 10px;
    }
    &:hover {
        background-color: ${(props) => props.theme.palette.background.paper};
        color: rgb(0, 0, 0);
    }
    ${(props) => props.theme.breakpoints.down("xs")} {
        & .MuiSvgIcon-root {
            margin-right: 0;
        }
    } ;
`;

export const MapboxPopup = styled(Popup)`
    min-width: 300px;
    cursor: pointer;
`;
