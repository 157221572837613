import { Breadcrumbs } from "@mui/material";
import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";

export const BreadCrumbContainer = styled(Breadcrumbs)`
    font-size: 12px;
    color: ${props => props.color};
`;

export const BreadCrumbLink = styled(NavLink)`
    font-size: 12px;
    color: ${props => props.color};
`;
