import { useState } from "react";
import { DisplaySpectacleAuthor } from "./DisplaySpectacleAuthor";
import { EditionSpectacleAuthor } from "./EditionSpectacleAuthor";

export const SpectacleAuthor = ({ projectData, updateProject }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionSpectacleAuthor
                projectData={projectData}
                setEditionMode={setEditionMode}
                submit={updateProject}
            />
        );
    } else {
        return <DisplaySpectacleAuthor projectData={projectData} setEditionMode={setEditionMode} />;
    }
};
