import { Box, Button, Grid, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import { DEFAULT_COMPANIES_PIC } from "Constant";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sendInvitationResponseAction } from "redux/actions/projectActions";
import { ProjectItemHeader } from "../CardHeader/CardHeader";

export const ReceivedInvitations = ({ projectData }) => {
    const dispatch = useDispatch();

    const [invitationsList, setInvitationsList] = useState(null);

    const getInvitationsList = useCallback(async () => {
        const projectList = projectData?.myProjectList?.invitations;
        const inviteList = [];

        if (!projectList || projectList.length === 0) {
            setInvitationsList([]);

            return;
        }

        projectList.forEach((element) => {
            element?.projectLocalizedEntities?.forEach((elem) => {
                if (elem?.adminAccepted && elem?.entityAccepted === null) {
                    inviteList.push({
                        ...element,
                        projectEntities: elem,
                        projectCreator: element.createdBy,
                    });
                }
            });
        });

        setInvitationsList(inviteList);
    }, [projectData?.myProjectList?.invitations]);

    useEffect(() => {
        getInvitationsList();
    }, [getInvitationsList]);

    const handleResponseToInvite = async (data) => {
        dispatch(sendInvitationResponseAction(data));
    };

    return (
        <>
            {invitationsList?.map((item, index) => (
                <Grid item xs={12} md={6} xl={4} key={index}>
                    <CmtCard className="fullHeight">
                        <Box p={5} display="flex" flexDirection="column">
                            <ProjectItemHeader title={item.name} />
                            <Box flex={1} alignItems="center" display="flex" flexDirection="column">
                                <Box
                                    mb={2}
                                    mt={2}
                                    display="flex"
                                    alignItems="center"
                                    flexDirection="column"
                                    justifyContent="center"
                                >
                                    <Box mb={{ xs: 4, xl: 6 }}>
                                        <AvatarComponent
                                            src={item?.logoFileName || DEFAULT_COMPANIES_PIC}
                                            alt="logo"
                                            size={50}
                                        />
                                    </Box>

                                    <Typography
                                        component="p"
                                        variant="subtitle2"
                                        textAlign="center"
                                        color="textSecondary"
                                        mb={3}
                                    >
                                        Vous avez été invité par {item?.projectCreator?.name} à
                                        rejoindre le projet {item?.name}
                                    </Typography>
                                </Box>

                                <Box
                                    mt="auto"
                                    width={1}
                                    display="flex"
                                    justifyContent="space-around"
                                >
                                    <Button
                                        color="disabled"
                                        variant="contained"
                                        onClick={() =>
                                            handleResponseToInvite({
                                                accept: false,
                                                id: item.id,
                                                projectEntityId: item.projectEntities.id,
                                            })
                                        }
                                    >
                                        Refuser
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() =>
                                            handleResponseToInvite({
                                                accept: true,
                                                id: item.id,
                                                projectEntityId: item.projectEntities.id,
                                            })
                                        }
                                    >
                                        Accepter
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </CmtCard>
                </Grid>
            ))}
        </>
    );
};
