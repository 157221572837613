import { Box, styled } from "@mui/system";

export const PictureBox = styled(Box)`
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;



export const EmptyPicture = styled(Box)`
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: ${props => `2px dashed ${props.theme.palette.disabled.main}`};
    & .MuiSvgIcon-root {
        font-size: 70,
    };

    & .MuiTypography-root {
        color: ${props => props.theme.palette.disabled.main};
        font-weight: 500;
    };
`;


