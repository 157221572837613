import { Box, Checkbox, Chip, ListItemText, MenuItem, Select, Typography} from "@mui/material";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { DEFAULT_COMPAGNY_SIZE_LIST } from "Constant";
import { useEffect, useState } from "react";
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';

export const NetworkCompagnySizeFilter = ({
    sizeList = DEFAULT_COMPAGNY_SIZE_LIST,
    filters,
    changeFilters
}) => {
    const [size, setSize] = useState([]);
    const [displaySize, setDisplaySize] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        let value = [];

        if (!filters.size || filters?.size?.length === 0) {
            setSize(value);
            setDisplaySize('');

            return value;
        }

        const splittedIdList = filters.size?.split(',');

        splittedIdList?.sort((a, b) => parseInt(a) > parseInt(b))?.forEach(item => {
            if (item) {
                value.push(sizeList.find(it => it.id === parseInt(item)));
            }
        });

        let displayValue = sizeList.find(item => item.id === value[0].id).name;

        if (splittedIdList.length > 1) {
            displayValue += ` (+${value.length - 1})`;
        }

        setSize(value);
        setDisplaySize(displayValue);
    }, [filters.size, sizeList]);

    return (
        <Box ml={2}>
            <Box display='flex' alignItems='center' justifyContent='center' className='fullHeight'>
                <Chip
                    variant={filters?.size ? 'default' : 'outlined'}
                    icon={<EuroSymbolIcon/>}
                    size='medium'
                    label={displaySize || "Taille de structure"}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    onDelete={displaySize ? (() => changeFilters({...filters, size: ''})) : null}
                />
            </Box>

            <CmtPopover
                anchorEl={anchorEl}
                closePopover={() => setAnchorEl(null)}
            >
                <Box p={5}>
                    <Box pb={2}>
                        <Typography component='p' variant='subtitle1'> Selectionner une taille de de structure </Typography>
                    </Box>
                    <Select
                        multiple
                        fullWidth
                        value={size}
                        onChange={(e) => {
                            changeFilters({...filters, size: e.target.value.map(elem => elem.id).join(',')})
                        }}
                        renderValue={() => {
                            return displaySize;
                        }}
                        label={"Prix"}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}
                    >
                        {sizeList?.map((item) => (
                            <MenuItem key={item.id} value={item}>
                                <Checkbox checked={size?.sort((a, b) => a.id > b.id)?.map(elem => elem.id).indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </CmtPopover>
        </Box>
    );
};