import { Box, Button, CardContent, Hidden } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useState } from "react";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function DisplayProjectDescriptionCard({ projectData, setEditionMode }) {
    const [hover, setHover] = useState(false);

    return (
        <CmtCard
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            id="descriptionCardCmtCard"
        >
            <TileTitle>Description</TileTitle>
            <CardContent>
                <Box
                    mb={8}
                    component="div"
                    style={{ padding: 10 }}
                    dangerouslySetInnerHTML={{
                        __html: projectData?.description.replace(/(?:\r\n|\r|\n)/g, "<br />"),
                    }}
                />
                <Box display="flex" justifyContent="flex-end" height={30}>
                    {hover && (
                        <Button
                            onClick={() => {
                                setEditionMode(true);
                            }}
                            variant="contained"
                            color="secondary"
                            id="descriptionCardUpdateButton"
                        >
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp>
                                <CreateOutlinedIcon className="pointer" />
                            </Hidden>
                        </Button>
                    )}
                </Box>
            </CardContent>
        </CmtCard>
    );
}
