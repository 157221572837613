import axios from './config';

const planning = {
    getAllCalendars: async () => {
        try {
            const result = await axios.get('/user/calendars');

            return ({result: true, data: result.data});
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    createCalendar: async (data) => {
        try {
            let formData = new FormData();

            formData.append('name', data?.name);
            formData.append('color', data?.color);
            formData.append('isDefault', data?.isDefault);

            const result = await axios.post(`/user/calendars/create`, formData);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    updateCalendar: async (data) => {
        try {
            let formData = new FormData();

            formData.append('name', data?.name);
            formData.append('color', data?.color);
            formData.append('isDefault', data?.isDefault);

            const result = await axios.post(`/user/calendars/${data.id}/update`, formData);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    deleteCalendar: async (data) => {
        try {
            await axios.delete(`/user/calendars/${data.id}`);

            return {result: true};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },


    getAllEvents: async (data) => {
        try {
            const result = await axios.get(`/user/calendar-events/${data.firstDay}/${data.lastDay}`);

            return ({result: true, data: result.data});
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    createEvent: async (data) => {
        try {
            let formData = new FormData();

            formData.append('title', data?.title);

            if (data.description) {
                formData.append('description', data?.description);
            }


            formData.append('startDate', data?.startDate);
            formData.append('endDate', data?.endDate);

            if (data.startHour && data.endHour) {
                formData.append('startHour', data?.startHour);
                formData.append('endHour', data?.endHour);
            }

            if (data.fullAddress) {
                formData.append('fullAddress', data?.fullAddress);
            }

            formData.append('color', data?.color);

            if (data.reminder) {
                formData.append('reminder', data?.reminder);
            }

            data.projectsShared.forEach((element, index) => {
                formData.append(`projectsShared[${index}]`, element);
            });

            const result = await axios.post(`/user/calendar-events/${data.calendarId}/create`, formData);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    updateEvent: async (data) => {
        try {
            let formData = new FormData();

            formData.append('title', data?.title);

            if (data.description) {
                formData.append('description', data?.description);
            }


            formData.append('startDate', data?.startDate);
            formData.append('endDate', data?.endDate);

            if (data.startHour && data.endHour) {
                formData.append('startHour', data?.startHour);
                formData.append('endHour', data?.endHour);
            }

            if (data.fullAddress) {
                formData.append('fullAddress', data?.fullAddress);
            }

            formData.append('color', data?.color);

            if (data.reminder) {
                formData.append('reminder', data?.reminder);
            }

            data.projectsShared.forEach((element, index) => {
                formData.append(`projectsShared[${index}]`, element);
            });

            const result = await axios.post(`/user/calendar-events/${data.idCal}/update/${data.calendarEventId}`, formData);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    deleteEvent: async (data) => {
        try {
            const result = await axios.delete(`/user/calendar-events/${data.calendarId}/${data.calendarEventId}`);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },
};

export default planning;