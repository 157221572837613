import { DISCONNECT, NOT_CONNECTED } from "redux/constants/authConstants";
import { CHANGE_NETWORK_FILTER, REQUEST_ALL_COMPANY, REQUEST_ALL_COMPANY_ERROR, REQUEST_ALL_COMPANY_SUCCESS, REQUEST_PAGED_COMPANY_ERROR, REQUEST_PAGED_COMPANY_SUCCESS } from "redux/constants/companyConstants";

const INIT_STATE = {
    companies: null,
    pagedCompanies: null,
    networkFilters: {
        category: sessionStorage.getItem('NetworkFiltersCategory') || '',
        distance: sessionStorage.getItem('NetworkFiltersDistance') || 100,
        sorter: sessionStorage.getItem('NetworkFiltersSorter') || 'distance ASC',
        location: sessionStorage.getItem('NetworkFiltersLocation') || '',
        lng: sessionStorage.getItem('NetworkFiltersLng') || '',
        lat: sessionStorage.getItem('NetworkFiltersLat') || '',
        size: sessionStorage.getItem('NetworkFiltersSize') || '',
        limit: 20,
        page: 1,
    },
    loadable: true,
    loading: false,
    errors: null,
}


export default function companyReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case REQUEST_ALL_COMPANY:
            return {...state, loading: action.loading, errors: null};
        case REQUEST_ALL_COMPANY_SUCCESS:
            return {...state, loading: action.loading, companies: action.data};
        case REQUEST_ALL_COMPANY_ERROR:
            return {...state, loading: action.loading, errors: action.error};
        case REQUEST_PAGED_COMPANY_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                pagedCompanies: state.networkFilters.page !== 1 ? [...state.pagedCompanies, ...action.data] : action.data,
                loadable: action.data.length >= state.networkFilters.limit,
            };
        case REQUEST_PAGED_COMPANY_ERROR:
            return {...state, loading: action.loading, errors: action.error};
        case CHANGE_NETWORK_FILTER:
            return {
                ...state,
                networkFilters: {
                    ...state.networkFilters,
                    ...action.filters,
                    page: 1,
                },
                loadable: true,
            };
        case DISCONNECT || NOT_CONNECTED:
            return {...INIT_STATE};
        default:
            return state;
    };
};