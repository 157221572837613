import {
    CHANGE_ANNOUNCEMENT_FILTER,
    FAVORITE_ANNOUNCEMENT,
    GET_ANNOUNCEMENTS_LIST,
    GET_ANNOUNCEMENTS_LIST_ERROR,
    GET_ANNOUNCEMENTS_LIST_SUCCESS,
    GET_MY_ANNOUNCEMENTS_LIST,
    GET_MY_ANNOUNCEMENTS_LIST_ERROR,
    GET_MY_ANNOUNCEMENTS_LIST_SUCCESS,
    UPDATE_ANNOUNCEMENT,
} from "redux/constants/announcementsConstants";
import { announcements } from "services/Api/announcements";
import auth from "services/Api/auth";
import { notConnectedAction } from "./authActions";

const requestAnnouncementsList = () => ({
    type: GET_ANNOUNCEMENTS_LIST,
    loading: true,
});

const requestAnnouncementsListSuccess = (data) => ({
    type: GET_ANNOUNCEMENTS_LIST_SUCCESS,
    loading: false,
    data: data,
});

const requestAnnouncementsListError = (error) => ({
    type: GET_ANNOUNCEMENTS_LIST_ERROR,
    loading: false,
    error: error,
});

export const getAnnouncementsListAction = (countError = 0) => {
    return async (dispatch) => {
        dispatch(requestAnnouncementsList);

        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await announcements.getAnnouncementsList();

        if (result.result) {
            dispatch(requestAnnouncementsListSuccess(result.data));

            dispatch(getMyAnnouncementsListAction());
        } else {
            dispatch(requestAnnouncementsListError(result.error));
        }
    };
};

export const updateFiltersAction = (filters) => ({
    type: CHANGE_ANNOUNCEMENT_FILTER,
    filters: filters,
});

export const changeAnnouncementsFiltersAction = (filters) => {
    return async (dispatch) => {
        sessionStorage.setItem("AnnouncementFiltersCategory", filters.category);
        sessionStorage.setItem("AnnouncementFiltersFromDate", filters.fromDate);
        sessionStorage.setItem("AnnouncementFiltersToDate", filters.toDate);
        sessionStorage.setItem("AnnouncementFiltersPrice", filters.price);
        sessionStorage.setItem("AnnouncementFiltersLocation", filters.location);
        sessionStorage.setItem("AnnouncementFiltersLng", filters.lng);
        sessionStorage.setItem("AnnouncementFiltersLat", filters.lat);
        sessionStorage.setItem("AnnouncementFiltersDistance", filters.distance);
        sessionStorage.setItem("AnnouncementFiltersSorter", filters.sorter);

        filters.announcementPage = 1;
        filters.myAnnouncementPage = 1;

        dispatch(updateFiltersAction(filters));

        dispatch(getFilteredAnnouncementAction(filters));
    };
};

const requestMyAnnouncementsList = () => ({
    type: GET_MY_ANNOUNCEMENTS_LIST,
    loading: true,
});

const requestMyAnnouncementsListSuccess = (data) => ({
    type: GET_MY_ANNOUNCEMENTS_LIST_SUCCESS,
    loading: false,
    data: data,
});

const requestMyAnnouncementsListError = (error) => ({
    type: GET_MY_ANNOUNCEMENTS_LIST_ERROR,
    loading: false,
    error: error,
});

export const getFilteredAnnouncementAction = (filters = null) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(requestAnnouncementsList());

        const state = filters || getState().announcements?.filters;

        announcements.getFilteredAnnouncementsList(state).then((result) => {
            if (result.result) {
                dispatch(requestAnnouncementsListSuccess(result.data));
            } else {
                dispatch(requestAnnouncementsListError(result.error));
            }
        });

        dispatch(getMyFilteredAnnouncementAction(state));
    };
};

export const getMyFilteredAnnouncementAction = (filters = null) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const state = filters || getState()?.announcements?.filters;

        announcements.getMyFilteredAnnouncementsList(state).then((result) => {
            if (result.result) {
                dispatch(requestMyAnnouncementsListSuccess(result.data));
            } else {
                dispatch(requestMyAnnouncementsListError(result.error));
            }
        });
    };
};

export const getMyAnnouncementsListAction = () => {
    return async (dispatch) => {
        dispatch(requestMyAnnouncementsList);

        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await announcements.getMyAnnouncementsList();

        if (result.result) {
            dispatch(requestMyAnnouncementsListSuccess(result.data));
        } else {
            dispatch(requestMyAnnouncementsListError(result.error));
        }
    };
};

export const deleteAnnouncementAction = (id) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        announcements.deleteAnnouncement(id).then((result) => {
            if (result?.result) {
                let state = getState()?.announcements?.filters;

                dispatch(changeAnnouncementsFiltersAction(state));

                return result;
            }
        });
    };
};

const requestFavoriteAnnouncement = (announcement) => ({
    type: FAVORITE_ANNOUNCEMENT,
    announcement: announcement,
});

export const favoriteAnnouncementAction = (id) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        announcements.addFavorite(id).then((result) => {
            if (result?.result) {
                dispatch(requestFavoriteAnnouncement(result?.data));
            }
        });
    };
};

export const createAnnouncementAction = (data) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = announcements.createAnnouncement(data).then((result) => {
            if (result?.result) {
                let state = getState()?.announcements?.filters;

                dispatch(changeAnnouncementsFiltersAction(state));
            }

            return result?.result;
        });

        return result;
    };
};

const requestUpdateAnnouncement = (announcement) => ({
    type: UPDATE_ANNOUNCEMENT,
    announcement: announcement,
});

export const updateAnnouncementAction = (id, data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await announcements.updateAnnouncement(id, data);

        if (result?.result) {
            dispatch(requestUpdateAnnouncement(result?.data));
        }

        return result;
    };
};

export const loadMoreAnnouncementsAction = (filters) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(updateFiltersAction(filters));

        announcements.getFilteredAnnouncementsList(filters).then((result) => {
            if (result.result) {
                dispatch(requestAnnouncementsListSuccess(result.data));
            } else {
                dispatch(requestAnnouncementsListError(result.error));
            }
        });
    };
};

export const loadMoreMyAnnouncementsAction = (filters) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(updateFiltersAction(filters));

        announcements.getMyFilteredAnnouncementsList(filters).then((result) => {
            if (result.result) {
                dispatch(requestMyAnnouncementsListSuccess(result.data));
            } else {
                dispatch(requestMyAnnouncementsListError(result.error));
            }
        });
    };
};
