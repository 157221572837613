import { Box, Typography } from "@mui/material";
import linkify from 'services/Linkify/linkify';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from "moment";
import { MAPBOX_TOKEN, MAPBOX_STYLE, ZOOM_BEGIN } from 'Constant';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapBoxGl, {
    ZoomControl,
} from 'react-mapbox-gl';
import { CmtMarker } from "Components/CmtMapBoxElements/CmtMarker/CmtMarker";

const MapBox = ReactMapBoxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: true,
    minZoom: ZOOM_BEGIN,
});

const mapStyle = ({
    minHeight: '500px',
    height: '100%',
    width: '100%',
});

export const AnnouncementInfos = ({announcementData, priceList}) => {
    return (
        <Box px={5} width='100%'>
            <Box >
                <Box pb={3} mt={10} display='flex' alignItems='center' >
                    <EuroSymbolIcon fontSize='small' />
                    <Box pl={3}>
                        <Typography component='span' variant='h4'> Prix estimé </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography component='p' variant='h5'> {priceList.find(elem => elem.id === announcementData?.priceRange).name} </Typography>
                </Box>
            </Box>

            <Box >
                <Box mt={10} display='flex' alignItems='center' >
                    <DescriptionIcon fontSize='small'/>
                    <Box pl={3}>
                        <Typography component='p' variant='h4'> Description </Typography>
                    </Box>
                </Box>
                <Box
                    component='p'
                    dangerouslySetInnerHTML={{
                        __html: linkify(announcementData.content.replace(/(?:\r\n|\r|\n)/g, '<br />')),
                    }}
                />
            </Box>

            <Box >
                <Box mt={10}  display='flex' alignItems='center' >
                    <AccessTimeIcon fontSize='small'/>
                    <Box pl={3}>
                        <Typography component='p' variant='h4'> Disponibilité </Typography>
                    </Box>
                </Box>
                <Box mt={5}>
                    <Typography component='p' > Du {moment(announcementData.fromDate).format('dddd DD MMMM')} au {moment(announcementData.toDate).format('dddd DD MMMM YYYY')} </Typography>
                </Box>
            </Box>

            <Box width='100%'>
                <Box mt={10}  display='flex' alignItems='center' >
                    <LocationOnIcon fontSize='small' />
                    <Box pl={3}>
                        <Typography component='p' variant='h4'> Localisation </Typography>
                    </Box>
                </Box>

                <Box py={5} width='100%' flexShrink={0}>
                    <MapBox
                        // eslint-disable-next-line
                        style={MAPBOX_STYLE}
                        containerStyle={mapStyle}
                        onStyleLoad={(map) => map.resize()}
                        center={[announcementData?.lng, announcementData?.lat]}
                        zoom={[ZOOM_BEGIN]}
                        flyToOptions={{ speed: 0.8 }}
                    >
                        <CmtMarker coordinates={[announcementData?.lng, announcementData?.lat]} />
                        <ZoomControl/>
                    </MapBox>
                </Box>
            </Box>
        </Box>
    );
};