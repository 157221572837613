import { useState } from "react";
import DisplayDescription from "./DisplayDescription";
import EditionDescription from './EditionDescription';

export const ProfileDescription = ({profile, update}) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (<EditionDescription profile={profile} setEditionMode={setEditionMode} submit={update} />);
    } else {
        return (<DisplayDescription profile={profile} setEditionMode={setEditionMode} />);
    }


};