export const GET_ANNOUNCEMENTS_LIST = 'GET_ANNOUNCEMENTS_LIST';
export const GET_ANNOUNCEMENTS_LIST_SUCCESS = 'GET_ANNOUNCEMENTS_LIST_SUCCESS';
export const GET_ANNOUNCEMENTS_LIST_ERROR = 'GET_ANNOUNCEMENTS_LIST_ERROR';

export const CHANGE_ANNOUNCEMENT_FILTER = 'CHANGE_ANNOUNCEMENT_FILTER';

export const CHANGE_CATEGORY_FILTER = 'CHANGE_CATEGORY_FILTER';
export const CHANGE_SORT_PARAMS = 'CHANGE_SORT_PARAMS';

export const GET_MY_ANNOUNCEMENTS_LIST = 'GET_MY_ANNOUNCEMENTS_LIST';
export const GET_MY_ANNOUNCEMENTS_LIST_SUCCESS = 'GET_MY_ANNOUNCEMENTS_LIST_SUCCESS';
export const GET_MY_ANNOUNCEMENTS_LIST_ERROR = 'GET_MY_ANNOUNCEMENTS_LIST_ERROR';

export const DELETED_ANNOUNCEMENT = 'DELETED_ANNOUNCEMENT';

export const FAVORITE_ANNOUNCEMENT = 'FAVORITE_ANNOUNCEMENT';

export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';

export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';

export const SORT_BY_CATEGORIES = 'SORT_BY_CATEGORIES';
export const SORT_BY_DATE = 'SORT_BY_DATE';

export const CHANGE_DATE_FILTER = "CHANGE_DATE_FILTER_ACTION";

export const CHANGE_SEARCH_TEXT = "CHANGE_SEARCH_TEXT";