import { connect } from "react-redux";
import {authSelector} from 'redux/selectors/authSelector';
import AuthWrapper from '../AuthWrapper/AuthWrapper.jsx';
import ResetPasswordForm from './ResetPasswordForm.jsx';
import { useEffect, useState } from "react";
import history from 'services/History/history';
import auth from "services/Api/auth.js";
import { REDIRECTION_TIME, SIGNIN_PATH } from "Constant.js";
import CmtLoading from "Components/CmtLoading/CmtLoading.jsx";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { HOME_PATH } from 'Constant';
import { Box } from "@mui/system";

export const ResetPasswordApp = ({isAuth}) => {
    const [result, setResult] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [infos, setInfos] = useState({email: '', token: ''});

    useEffect(() => {
        if (isAuth.connected) {
            history.push(HOME_PATH);
        }
    });

    useEffect(() => {
        const queyString = window.location.search;
        const urlParams = new URLSearchParams(queyString);

        setInfos(i => ({
            ...i,
            email: urlParams.get('email'),
            token: urlParams.get('token'),
        }));
    }, []);

    const handleSubmit = async (data) => {
        if (submit) {
            return;
        }

        setSubmit(true);
        setResult(null);
        
        const res = await auth.resetPassword({...infos, ...data});
        
        setResult(res);

        if (res.result) {
            NotificationManager.success('Votre demande à bien été prise en compte, vous allez être rédirigé vers la page de connexion', 'Succès', REDIRECTION_TIME);

            setTimeout(() => {history.push(SIGNIN_PATH)}, REDIRECTION_TIME);
        } else {
            NotificationManager.error(result?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);

            setSubmit(false);
        }
    };


    return (
        <Box position='absolute' top={0} bottom={0} left={0} right={0}>
            <AuthWrapper>
                {submit && <CmtLoading/>}
                <ResetPasswordForm onReset={handleSubmit} />
            </AuthWrapper>
        </Box>
    );
};

export const ResetPasswordAppStore = connect(
    (state) => ({
        isAuth: authSelector(state),
    })
) (ResetPasswordApp);
