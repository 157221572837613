import { Box } from "@mui/material";
import { AvatarComponent }  from 'Components/AvatarComponent/AvatarComponent';

export const TypingMessage = ({ currentUser }) => {
    return (
        <>
            <Box 
                py={2}
                px={5}
                display='flex'
                alignItems='center'
            >
                <Box>
                    <AvatarComponent
                        size={24}
                        src={currentUser.profileFileName}
                        alt={`${currentUser?.firstName} ${currentUser?.lastName}`}
                    />
                </Box>
                <Box component="p" ml={2} fonSize={14}>
                    {currentUser.firstName} {currentUser.lastName}
                    <Box component="span" color="text.disabled" ml={1} >
                        est en train de taper...
                    </Box>
                </Box>
            </Box>
        </>
    );
};