import { Grid, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import { Formik } from "formik";
import { CmtDatePicker } from "Components/CmtDatePicker/CmtDatePicker";
import moment from "moment";
import { StepperLine } from "../StepperLine";
import { Box } from "@mui/system";

export const Step2 = ({ stepper, cancel, submit, data }) => {
    const checkError = (values) => {
        const errors = {};

        if (!values.fromDate) {
            errors.fromDate = "Veuillez renseigner une date de début";
        } else if (values.toDate && moment(values.fromDate).isAfter(values.toDate)) {
            errors.fromDate = "La date de début ne peux pas être supérieure à la date de fin";
        }

        if (!values.toDate) {
            errors.toDate = "Veuillez renseigner une date de fin";
        } else if (values.fromDate && moment(values.toDate).isBefore(values.fromDate)) {
            errors.toDate = "La date de fin ne peux pas être inférieure à la date de début";
        }

        return errors;
    };

    return (
        <Grid item container xs={12} sm={5}>
            <Formik
                initialValues={{
                    fromDate: data.fromDate ? moment(data.fromDate).format("YYYY-MM-DD") : "",
                    toDate: data.toDate ? moment(data.toDate).format("YYYY-MM-DD") : "",
                }}
                validate={(values) => checkError(values)}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => (
                    <CmtForm onSubmit={handleSubmit} className="fullWidth">
                        <CmtCard>
                            <Box p={5}>
                                <Grid container display="flex" alignItems="center" py={3}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography
                                            component="p"
                                            variant="subtitle1"
                                            color="textSecondary"
                                        >
                                            A partir du
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <CmtDatePicker
                                            fullWidth
                                            value={values.fromDate}
                                            disablePast
                                            setValue={(value) =>
                                                setFieldValue(
                                                    "fromDate",
                                                    moment(value).format("YYYY-MM-DD")
                                                )
                                            }
                                            onTouched={setFieldTouched}
                                            name="fromDate"
                                            error={touched.fromDate && errors.fromDate}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container display="flex" alignItems="center" py={3}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography
                                            component="p"
                                            variant="subtitle1"
                                            color="textSecondary"
                                        >
                                            Jusqu'au
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <CmtDatePicker
                                            fullWidth
                                            value={values.toDate}
                                            disablePast
                                            setValue={(value) =>
                                                setFieldValue(
                                                    "toDate",
                                                    moment(value).format("YYYY-MM-DD")
                                                )
                                            }
                                            onTouched={setFieldTouched}
                                            name="toDate"
                                            error={touched.toDate && errors.toDate}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CmtCard>

                        <Box mt={4}>
                            <StepperLine
                                onCancel={cancel}
                                stepper={stepper}
                                step="Etape 2 : Les Dates"
                            />
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        </Grid>
    );
};
