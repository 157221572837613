import {
    DISCONNECT,
    REQUEST_LOGIN,
    REQUEST_LOGIN_ERROR,
    REQUEST_LOGIN_SUCCESS,
    REQUEST_SIGNUP,
    REQUEST_SIGNUP_SUCCESS,
    REQUEST_SIGNUP_ERROR,
    DELETE_ERROR,
    NOT_CONNECTED,
    REQUEST_CONNEXION,
    REQUEST_CONNEXION_SUCCESS,
    REQUEST_CONNEXION_ERROR,
} from "redux/constants/authConstants";

const INIT_STATE = {
    connected: null,
    loading: false,
    error: null,
};

export default function authReducer(state = INIT_STATE, action = {}) {
    switch (action.type) {
        case REQUEST_LOGIN:
            return {...state, connected: false, loading: action.loading, error: null};
        case REQUEST_LOGIN_SUCCESS:
            return {connected: true, loading: action.loading, error: null};
        case REQUEST_LOGIN_ERROR:
            return {connected: false, loading: action.loading, error: action.error};
        case REQUEST_SIGNUP:
            return {...state, connected: false, loading: action.loading, error: null};
        case REQUEST_SIGNUP_SUCCESS:
            return {connected: false, loading: action.loading, error: null};
        case REQUEST_SIGNUP_ERROR:
            return {connected: false, loading: action.loading, error: action.error};
        case DISCONNECT:
            return {...state, connected: false};
        case NOT_CONNECTED:
            return {...state, connected: false};
        case DELETE_ERROR:
            return {...state, error: null};
        case REQUEST_CONNEXION:
            return {...state, loading: action.loading, error: null};
        case REQUEST_CONNEXION_SUCCESS:
            return {...state, connected: true, loading: action.loading, error: null};
        case REQUEST_CONNEXION_ERROR:
            return {...state, connected: false, loading: action.loading};
        default:
            return state;
    };
};