import { Box, Chip, IconButton, ListItemText, MenuItem, Select, Typography} from "@mui/material";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import SortIcon from '@mui/icons-material/Sort';
import { useState } from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { RotatingIcons } from "./sc.Filters";

export const SortingFilters = ({
    filters,
    changeFilters,
    sortingList = []
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const field = filters?.sorter ? filters?.sorter?.split(' ')[0] : '';
    const order = filters?.sorter ? filters?.sorter?.split(' ')[1] : '';
    const value = field ? sortingList.find(elem => elem.value === field) : '';

    return (
        <Box mx={0} py={0}>
            <Box display='flex' alignItems='center' className='fullHeight'>
                <Chip
                    variant={filters?.sorter ? 'default' : 'outlined'}
                    icon={!order ? <SortIcon/> : order === 'ASC' ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
                    size='medium'
                    label={value?.label || "Trier par"}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                />
            </Box>

            <CmtPopover
                anchorEl={anchorEl}
                closePopover={() => setAnchorEl(null)}
                style={{overflow:'visible'}}
            >
                <Box p={5} minWidth={300}>
                    <Typography component='p' variant='h4'> Selectionner un tri</Typography>
                    <Box display='flex' alignItems='flex-start' mt={3}>
                        <Select
                            fullWidth
                            variant='standard'
                            value={field}
                            onChange={(e) => {
                                changeFilters({...filters, sorter: `${e.target.value} ${order}`})
                            }}
                            label={"Trier par"}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                        >
                            {sortingList.map((item, index) => (
                                <MenuItem value={item.value} key={index}> <ListItemText primary={item?.selectLabel} /> </MenuItem>
                            ))}
                        </Select>
                        <IconButton mt={2} size='medium' color='primary' onClick={() => changeFilters({...filters, sorter: `${field} ${order === 'ASC' ? 'DESC' : 'ASC'}`})}>
                            <RotatingIcons order={order} />
                        </IconButton>
                    </Box>
                </Box>
            </CmtPopover>
        </Box>
    );
};