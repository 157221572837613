import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Box } from "@mui/system";

export const CmtDeleteDialog = ({ open = false, onCancel, onDelete, children }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogContent dividers>{children}</DialogContent>

            <DialogActions>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                    <Button color="secondary" onClick={onCancel} id="cancelDeleteDialog">
                        Annuler
                    </Button>
                    <Button color="primary" onClick={onDelete} id="validateDeleteDialog">
                        Supprimer
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
