import { Box, Hidden, IconButton } from "@mui/material";
import CmtImage from "Components/CmtImage/CmtImage";
import { SearchBox } from "./SearchBox/SearchBox.jsx";
import Messages from "./Messages/Messages.jsx";
import Notifications from "./Notifications/Notifications.jsx";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "./Menu/Menu.jsx";
import { CHAT_PATH, PROFILE_PATH } from "Constant.js";
import { HeaderBarContainer } from "../sc.ConnectedContainer.js";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent.jsx";
import history from "services/History/history.js";

export const HeaderBar = ({
    profile,
    setCollapse,
    chatData,
    isCollapse,
    searchValue,
    setSearchValue,
    closeSearch,
    markAsRead,
}) => {
    return (
        <HeaderBarContainer onClick={closeSearch}>
            <Hidden smDown>
                <NavLink to="/">
                    <CmtImage src="/images/logo.svg" alt="logo" height="auto" width="150px" />
                </NavLink>
            </Hidden>
            <Hidden smUp>
                <Box pl={3} onClick={(e) => e.stopPropagation()}>
                    <MenuIcon
                        className="pointer"
                        color="primary"
                        onClick={() => {
                            if (isCollapse) {
                                setCollapse(false);
                            }
                        }}
                    />
                </Box>
            </Hidden>
            <Box display="flex" flexDirection="row" alignItems="center">
                <SearchBox value={searchValue} setValue={setSearchValue} />
                {window.location.pathname !== CHAT_PATH && <Messages chatData={chatData} />}
                <Notifications profile={profile} markAsRead={markAsRead} />
                <IconButton color="primary" size="large" onClick={() => history.push(PROFILE_PATH)}>
                    <AvatarComponent
                        src={profile?.profile?.profileFileName || profile?.defaultAvatarPath}
                        size={30}
                    />
                </IconButton>
                <Menu />
            </Box>
        </HeaderBarContainer>
    );
};
