import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

export default function DisplayModeInfos ({profile, setEditionMode}) {
    const [hover, setHover] = useState(false);

    return (
        <Box height='100px' display='flex' alignItems='center'>
            <Box>
                <Box
                    display='flex'
                    position='relative'
                    pr={10}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => setEditionMode(true)}
                >
                    <Typography
                        component="p"
                        variant="h1"
                        color='white'
                    >
                        {profile?.profile?.firstName} {profile?.profile?.lastName}
                    </Typography>
                    {hover && 
                        <Box position='absolute' right={0} ml={5} component='span' height='100%' color='white' className='pointer'>
                            <CreateOutlinedIcon />
                        </Box>
                    }
                </Box>
                <Typography color='whitesmoke' > {profile?.profile?.email}</Typography>
            </Box>
            
        </Box>
    );
};