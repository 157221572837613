import { TabItem, TabsList } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { useCallback } from "react";
import { useEffect } from "react";
import history from "services/History/history";

export const CmtTabs = ({ value, setValue, list }) => {
    const filteredList = list.filter((item) => !item?.blocked);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        window.location.hash = `#${newValue}`;
    };

    const handleTab = useCallback(() => {
        if (!window.location.hash) {
            return;
        }
        const hash = window.location.hash.replace("#", "");
        const tabList = list.map((element) => element.value);

        if (!tabList.includes(hash)) {
            return;
        }

        if (list.find((element) => element.value === hash).blocked) {
            return;
        }

        setValue(hash);
    }, [setValue, list]);

    useEffect(() => {
        handleTab();

        const listen = history.listen(handleTab);

        return () => {
            listen();
        };
    }, [handleTab]);

    return (
        <TabsList value={value} onChange={handleChange} aria-label="Tabulation">
            {filteredList?.map((item, index) => (
                <TabItem
                    key={index}
                    value={item?.value}
                    label={item?.label}
                    id={`${item?.value}Tab`}
                />
            ))}
        </TabsList>
    );
};
