import axios from './config';


const search = {
    searching: async (params) => {
        try {
            const result = await axios.get(`/user/search/${params}`);

            return ({result: true, data: result.data});
        } catch (error) {
            return ({result: false, error: error?.response?.data});
        };
    }
};

export default search;