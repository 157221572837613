import {
    Autocomplete,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { PROJECT_ACCESS_RIGHT_LIST, REDIRECTION_TIME } from "Constant";
import { Formik } from "formik";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import auth from "services/Api/auth";
import entities from "services/Api/entities";

export const InviteMemberForm = ({ profile, projectData, submit, close, disconnect }) => {
    const [list, setList] = useState([]);

    const registeredCompanies = projectData?.projectLocalizedEntities
        ?.filter((elem) => elem?.userAccepted || elem?.userAccepted === null)
        ?.map((item) => item?.localizedEntity?.id);

    const handleCompanyName = async (value) => {
        if (value.length < 3) {
            setList([]);

            return;
        }

        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();

            return;
        }

        entities.getStructureEntities({ name: value }).then((result) => {
            if (result.result) {
                let tmpList = result.data[0];

                setList(tmpList);
            }
        });
    };

    const setCompanyValue = (newValue, setFieldValue) => {
        if (!newValue) {
            setFieldValue("name", "");
            setList([]);

            return;
        }

        if (registeredCompanies.includes(newValue?.id)) {
            NotificationManager.error(
                "Cette structure appartient déjà à ce projet.",
                "Erreur",
                REDIRECTION_TIME
            );

            setFieldValue("name", "");

            return;
        }

        setFieldValue("name", newValue.name);
        setFieldValue("entityId", newValue.id || "");
    };

    const checkErrors = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = "Veuillez renseigner le nom de la structure à inviter.";
        } else if (registeredCompanies.includes(values.entityId)) {
            errors.name = "Vous avez déjà invité cette structure.";
        } else if (!values.entityId) {
            errors.name = "Vous devez séléctionner une structure dans la liste.";
        }

        if (!values.role) {
            errors.role = "Veuillez renseigner la fonction de la personne à inviter";
        }

        if (!values.accessRight && values.accessRight !== 0) {
            errors.accessRight = "Veuillez selectionner le niveau d'accès";
        }

        return errors;
    };

    return (
        <Formik
            initialValues={{
                name: "",
                entityId: "",
                role: "",
                accessRight: "",
            }}
            validate={(values) => checkErrors(values)}
            onSubmit={(values) => submit(values)}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
            }) => (
                <CmtForm onSubmit={handleSubmit}>
                    <Autocomplete
                        freeSolo
                        fullWidth
                        filterOptions={(options) => options}
                        id="nameAutoComplete"
                        inputValue={values.name}
                        onChange={(event, newValue) => {
                            setCompanyValue(newValue, (name, val) => setFieldValue(name, val));
                        }}
                        options={list}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                            <Box
                                mb={1}
                                {...props}
                                id={`autoComplete-${option.name.replaceAll(" ", "-")}`}
                                display="flex"
                                flexDirection="column"
                                width="100%"
                            >
                                <Box my={0} width="100%">
                                    <Typography component="p" variant="h4">
                                        {option.name}
                                    </Typography>
                                </Box>
                                <Box my={0} width="100%">
                                    <Typography component="p" variant="h5">
                                        {option.siret}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <FormInput
                                {...params}
                                onChange={(e) => {
                                    setFieldValue("name", e.target.value);
                                    setFieldValue("entityId", "");
                                    handleCompanyName(e.target.value);
                                }}
                                label="Nom de la structure"
                                onBlur={handleBlur}
                                error={touched.name && errors.name}
                            />
                        )}
                    />
                    <FormInput
                        name="role"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="none"
                        value={values.role}
                        label="Fonction"
                        sx={{ mb: 5 }}
                        id="structureFunction"
                        error={touched.role && errors.role}
                    />

                    <FormControl fullWidth variant="standard" size="small">
                        <InputLabel
                            id={`labelAccessRights`}
                            error={touched.accessRight && Boolean(errors.accessRight)}
                        >
                            Droits d'accès
                        </InputLabel>
                        <Select
                            labelId={`labelAccessRights`}
                            value={values.accessRight}
                            name="accessRight"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Droits d'accès"
                            id="structureRightSelect"
                            error={touched.accessRight && Boolean(errors.accessRight)}
                        >
                            {PROJECT_ACCESS_RIGHT_LIST.map((item, index) => (
                                <MenuItem
                                    value={item.accessRight}
                                    key={index}
                                    id={`Item-${item.label}`}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                            {touched.accessRight && errors.accessRight}
                        </FormHelperText>
                    </FormControl>

                    <Box display="flex" justifyContent="space-between" mt={10}>
                        <Button color="secondary" type="button" onClick={close} variant="outlined">
                            Annuler
                        </Button>

                        <Button color="primary" type="submit" variant="contained" id="inviteButton">
                            Inviter
                        </Button>
                    </Box>
                </CmtForm>
            )}
        </Formik>
    );
};
