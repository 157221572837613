import React from 'react';
import InputBase from '@mui/material/InputBase';
import { Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { cmtSearchStyle } from './cmtSearchStyle';
import { makeStyles } from '@mui/styles';
import { IconInput, IconSearch, IconSearchContainer, SearchContainer } from './sc.CmtSearch';

const useStyles = makeStyles(cmtSearchStyle);

const CmtSearch = ({
    value = '',
    setValue,
    border,
    placeholder,
    iconPosition,
    align,
    onlyIcon,
    containerStyle,
    inputStyle,
    iconStyle,
    className,
    collapse = true,
    ...rest
}) => {
    const classes = useStyles({ align, collapse, value });

    if (onlyIcon) {
        return (
            <Box display='flex' className='CmtSearch-root'>
                <IconSearchContainer value={value}>
                    <IconSearch>
                        <SearchIcon />
                    </IconSearch>
                    <IconInput
                        placeholder={placeholder || 'Recherche...'}
                        classes={{
                            root: 'inputRoot CmtSearch-input-root',
                            input: 'inputInput CmtSearch-input',
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        {...rest}
                    />
                </IconSearchContainer>
            </Box>
        );
    }

    return (
        <Box display='flex' className='CmtSearch-root'>
            <SearchContainer>
                <IconButton className={classes.searchIcon}>
                    <SearchIcon style={{iconStyle}} />
                </IconButton>
                <InputBase
                    placeholder={placeholder || 'Recherche...'}
                    classes={{
                        root: 'inputRoot CmtSearch-input-root',
                        input: 'inputInput CmtSearch-input',
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    {...rest}
                />
            </SearchContainer>
        </Box>
    );
};

export default CmtSearch;

CmtSearch.defaultProps = {
    border: true,
    containerStyle: {},
    inputStyle: {
        border: 'none',
    },
    iconPosition: 'left',
    onlyIcon: false,
    align: 'left',
};
