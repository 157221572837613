import CmtSearch from "Components/CmtSearch/CmtSearch";
import { SearchRoot } from "../../sc.ConnectedContainer";


export const SearchBox = ({value, setValue}) => {
    return (
        <SearchRoot onClick={e => e.stopPropagation()} value={value}>
            <CmtSearch
                border={false}
                onlyIcon
                iconPosition="left"
                align="left"
                placeholder="Recherchez ici"
                inputProps={{'aria-label': 'search'}}
                value={value}
                setValue={setValue}
            />
        </SearchRoot>
    );
};