import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 700,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
});

const defaultFamily = "rubik";

const defaultTheme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },

    spacing: 4,

    direction: "ltr",

    cluster: {
        color: {
            one: "#51bbd6",
            two: "#f1f075",
            three: "#f28cb1",
        },
        size: {
            one: "20px",
            two: "30px",
            three: "40px",
        },
    },

    palette: {
        type: "light",
        common: {
            black: "#000",
            white: "#fff",
            dark: "#020202",
        },
        primary: {
            main: "#F07512",
            dark: "#C15D00",
            light: "#F7BA88",
            contrastText: "#fff",
            publicMain: "#F9F1E4",
            family: defaultFamily,
        },
        secondary: {
            main: "#194D9F",
            dark: "#16458F",
            light: "#1D5ABB",
            contrastText: "#fff",
        },
        success: {
            main: "#8DCD03",
            light: "#D7F5B1",
            dark: "#5D9405",
        },
        info: {
            main: "#0795F4",
            light: "#9BE7FD",
            dark: "#0356AF",
        },
        warning: {
            main: "#FF8C00",
            light: "#FFDE99",
            dark: "#D36F1A",
            contrastText: "#fff",
        },
        error: {
            main: "#E00930",
            light: "#FFC7D1",
            dark: "#87061E",
        },
        disabled: {
            main: "rgb(211, 211, 211)",
        },
        publicTypo: {
            hFamily: "coolvetica",
            h1Size: "4.5em",
            h2Size: "3.5em",
            h3Size: "2.5em",
            h4Size: "2em",
            pFamily: "Helvetica",
            p1Size: "2em",
            p2Size: "1.5em",
            p3Size: "1.2em",
        },
        tiles: {
            shadow: "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
        },
        chat: {
            message: {
                received: {
                    backgroundColor: "rgb(137, 0, 250)",
                },
                sended: {
                    backgroundColor: "rgb(211, 211, 211)",
                },
            },
        },
        sidebar: {
            bgColor: "#fff",
            searchIconColor: "rgba(255, 255, 255, 0.6)",
            textColor: "rgba(0, 0, 0, 0.6)",
            textDarkColor: "rgba(0, 0, 0, 0.87)",
            textActiveColor: "#6200EE",
            navHoverBgColor: "rgb(229, 229, 229)",
            navActiveBgColor: "rgb(239, 229, 253)",
            borderColor: "rgba(33, 33, 33, 0.08)",
            shadow: "0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14)",
        },
        horizontalNav: {
            navigationColor: "rgba(255, 255, 255, 0.74)",
            navigationActiveColor: "rgba(255, 255, 255, 1)",
            textColor: "rgba(0, 0, 0, 0.6)",
            textDarkColor: "rgba(0, 0, 0, 0.87)",
            textActiveColor: "#6200EE",
            menuHoverBgColor: "rgb(229, 229, 229)",
            menuActiveBgColor: "rgb(239, 229, 253)",
        },
        headerBar: {
            shadow: "1px 2px 4px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#6200ee",
            searchBoxBackgroundColor: "rgba(2, 2, 2, 0.38)",
            PopoverTitle: {
                fontSize: 16,
                fontWeight: "bold",
            },
        },
        profile: {
            header: {
                titleColor: "#fff",
                subTitleColor: "rgba(255, 255, 255, 0.74)",
                backgroundColor: "rgba(98, 0, 238, 0.5)",
            },
        },
        background: {
            paper: "#FFFFFF",
            default: "#F07512",
            light: "",
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.6)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.3)",
            white: "#fff",
        },
        btn: {
            hover: "rgba(0, 0, 0, 0.08)",
        },
        lightBtn: {
            bgColor: "#f5f5f5",
            textColor: "rgba(0, 0, 0, 0.38)",
        },
        borderColor: {
            main: "red",
            dark: "rgba(0, 0, 0, 0.12)",
        },
        popupColor: {
            main: "#fff",
        },
    },

    status: {
        danger: "orange",
    },

    typography: {
        fontFamily: "rubik",
        fontWeightExtraLight: 200,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightBold: "bold",
        fontWeightExtraBold: 800,
    },

    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontFamily: defaultFamily,
                    fontSize: 20,
                    fontWeight: "500",
                    [breakpoints.up("md")]: {
                        fontSize: 22,
                    },
                },
                h2: {
                    fontFamily: defaultFamily,
                    fontSize: 18,
                    fontWeight: "bold",
                    [breakpoints.up("md")]: {
                        fontSize: 20,
                    },
                },
                h3: {
                    fontFamily: defaultFamily,
                    fontSize: 16,
                    fontWeight: "bold",
                    [breakpoints.up("md")]: {
                        fontSize: 18,
                    },
                },
                h4: {
                    fontFamily: defaultFamily,
                    fontSize: 16,
                    fontWeight: "bold",
                },
                h5: {
                    fontFamily: defaultFamily,
                    fontSize: 14,
                    fontWeight: 400,
                },

                h6: {
                    fontFamily: defaultFamily,
                    fontSize: 10,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                },

                subtitle1: {
                    fontFamily: defaultFamily,
                    fontSize: 18,
                    fontWeight: 500,
                    letterSpacing: 0.15,
                },
                subtitle2: {
                    fontFamily: defaultFamily,
                    fontSize: 10,
                    fontWeight: 400,
                    letterSpacing: 1.5,
                    textTransform: "uppercase",
                },
                body1: {
                    fontFamily: defaultFamily,
                    fontSize: 15,
                    fontWeight: 400,
                    letterSpacing: 0.5,
                },
                body2: {
                    fontFamily: defaultFamily,
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0.25,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    letterSpacing: 1.25,
                    fontSize: 12,
                },
                label: {
                    fontFamily: defaultFamily,
                },
            },
        },

        MuiFab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    [breakpoints.down("lg")]: {
                        fontSize: "0.7rem",
                    },
                    [breakpoints.down("md")]: {
                        fontSize: 10,
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontFamily: defaultFamily,
                },
            },
        },

        MuiAlert: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFamily,
                },
                action: {
                    fontFamily: defaultFamily,
                },
            },
        },

        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
            },
        },
        MuiCardLg: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    boxShadow:
                        "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                textColorPrimary: {
                    color: "rgba(0, 0, 0, 0.87)",
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#FFFFFF",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#FFFFFF",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFamily,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFamily,
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFamily,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFamily,
                },
            },
        },
    },
};

export default defaultTheme;
