import { Button, Grid, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import { COMPANIES_ACCESS_RIGHT_LIST } from "Constant";

export const ReceivedInvite = ({ profile, invitations, submitAnswer, accessRight }) => {
    return invitations.map((item, index) => (
        <Grid
            container
            key={index}
            width="100%"
            m={0}
            p={5}
            borderTop="1px solid rgba(211, 211, 211, 0.4)"
            spacing={0}
        >
            <Grid
                item
                xs={2}
                sm={1}
                pb={{ xs: 2, sm: 0 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <AvatarComponent
                    src={item?.user?.profileFileName || profile?.defaultAvatarPath}
                    size={50}
                />
            </Grid>
            <Grid
                item
                sm={2}
                xs={10}
                pb={{ xs: 2, sm: 0 }}
                px={{ xs: 0, sm: 1 }}
                display="flex"
                alignItems="center"
            >
                <Typography component="p" variant="subtitle1">
                    {item?.user?.firstName} {item?.user?.lastName}
                </Typography>
            </Grid>
            <Grid
                item
                sm={2}
                xs={12}
                pb={{ xs: 2, sm: 0 }}
                px={{ xs: 0, sm: 1 }}
                display="flex"
                alignItems="center"
            >
                <Typography component="p">{item?.user?.email || item?.email}</Typography>
            </Grid>

            {item?.position && (
                <Grid
                    item
                    sm={2}
                    xs={6}
                    pb={{ xs: 2, sm: 0 }}
                    px={{ xs: 0, sm: 1 }}
                    display="flex"
                    alignItems="center"
                >
                    <Typography component="p" ml={{ xs: 0, sm: 4 }}>
                        {item?.position}
                    </Typography>
                </Grid>
            )}

            <Grid
                item
                sm={2}
                xs={6}
                pb={{ xs: 2, sm: 0 }}
                px={{ xs: 0, sm: 1 }}
                display="flex"
                alignItems="center"
            >
                <Typography component="p" ml={{ xs: 0, sm: 4 }}>
                    {
                        COMPANIES_ACCESS_RIGHT_LIST.find(
                            (elem) => elem.accessRight === item.accessRights
                        )?.label
                    }
                </Typography>
            </Grid>

            {accessRight && (
                <Grid
                    item
                    sm={3}
                    xs={12}
                    px={{ xs: 0, sm: 2 }}
                    pt={{ xs: 2, sm: 0 }}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ mr: { xs: "auto", sm: 2 } }}
                        onClick={() =>
                            submitAnswer({
                                compagnyUserId: item?.id,
                                answerStatus: true,
                            })
                        }
                    >
                        Accepter
                    </Button>

                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() =>
                            submitAnswer({
                                compagnyUserId: item?.id,
                                answerStatus: false,
                            })
                        }
                    >
                        Refuser
                    </Button>
                </Grid>
            )}
        </Grid>
    ));
};
