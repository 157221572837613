import { Box, Fab, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import history from "services/History/history";
import { ANNOUNCEMENTS_PATH, EDIT_PATH } from "Constant";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const AnnouncementDetailHeader = ({
    title,
    id,
    isMyAnnouncement,
    deleteAnnouncement,
    basicPath,
}) => {
    return (
        <Box display='flex' px={4} py={5} flexWrap='wrap' justifyContent='space-between'>
            <Box
                onClick={() => history.goBack()}
                display='flex'
                alignItems='center'
                justifyContent='center'
                className='pointer'
            >
                <ArrowBackIcon />
                <Box pl={3}>
                    <Typography component='span' variant='h1'>{title}</Typography>
                </Box>
            </Box>

            {isMyAnnouncement &&
                <Box display='flex'>
                    <Fab variant='extended' sx={{ ml: 5, px: 5 }} size="small" color="primary" onClick={() => history.push(`${ANNOUNCEMENTS_PATH}/${id}${EDIT_PATH}`)} >
                        <EditIcon sx={{ mr: 2}} />
                        Modifier
                    </Fab>

                    <Fab variant='extended' sx={{ ml: 5, px: 5 }} size="small" color="secondary" onClick={() => deleteAnnouncement({id, basicPath})} >
                        <DeleteIcon sx={{ mr: 2}} />
                        Supprimer
                    </Fab>
                </Box>
            }
        </Box>
    );
};