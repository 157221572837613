import { useTheme } from "@emotion/react";
import { PublicTypo } from "../sc.Public";

export const ParagraphText = ({ children, fontSize = null, ...rest }) => {
    const theme = useTheme();

    return (
        <PublicTypo
            component="p"
            family={theme.palette.publicTypo.pFamily}
            fontSize={
                fontSize || {
                    xs: theme.palette.publicTypo.p3Size,
                    md: theme.palette.publicTypo.p2Size,
                }
            }
            pt={3}
            {...rest}
        >
            {children}
        </PublicTypo>
    );
};
