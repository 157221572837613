import axios from './config';

export const announcements = {
    getMyAnnouncementsList: async () => {
        try {
            const result = await axios.get('/user/profile/announcements');

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    getAnnouncementsList: async () => {
        try {
            const result = await axios.get('/user/announcements');

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    getFilteredAnnouncementsList: async (filters) => {
        try {
            let params = {};

            if (filters.category) {
                params = {...params, 'filters[category]': filters.category};
            }

            if (filters.price) {
                params = {...params, 'filters[prices]': filters.price};
            }

            if (filters.fromDate) {
                params = {...params, 'filters[fromDate]': filters.fromDate};
            }

            if (filters.toDate) {
                params = {...params, 'filters[toDate]': filters.toDate};
            }

            if (filters.lng && filters.lat && filters.distance) {
                params = {...params, 'filters[lng]': filters.lng};
                params = {...params, 'filters[lat]': filters.lat};
                params = {...params, 'filters[distance]': filters.distance};
            }

            if (filters?.sorter) {
                const sorter = filters?.sorter.split(' ');

                params = {...params, 'filters[sortField]': sorter[0]};
                params = {...params, 'filters[sortOrder]': sorter[1]};
            }
            
            params = {...params, 'filters[limit]': filters.limit};
            params = {...params, 'filters[page]': filters.announcementPage};

            params = {...params, 'filters[favorite]': filters.favorite ? '1' : '0'};

            const result = await axios.get('/user/announcements', { params: params });

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    getMyFilteredAnnouncementsList: async (filters) => {
        try {
            let params = {};

            if (filters.category) {
                params = {...params, 'filters[category]': filters.category};
            }

            if (filters.price) {
                params = {...params, 'filters[prices]': filters.price};
            }

            if (filters.fromDate) {
                params = {...params, 'filters[fromDate]': filters.fromDate};
            }

            if (filters.toDate) {
                params = {...params, 'filters[toDate]': filters.toDate};
            }

            if (filters.lng && filters.lat && filters.distance) {
                params = {...params, 'filters[lng]': filters.lng};
                params = {...params, 'filters[lat]': filters.lat};
                params = {...params, 'filters[distance]': filters.distance};
            }

            if (filters?.sorter) {
                const sorter = filters?.sorter.split(' ');

                params = {...params, 'filters[sortField]': sorter[0]};
                params = {...params, 'filters[sortOrder]': sorter[1]};
            }
            
            params = {...params, 'filters[limit]': filters.limit};
            params = {...params, 'filters[page]': filters.myAnnouncementPage};

            params = {...params, 'filters[favorite]': filters.favorite ? '1' : '0'};

            const result = await axios.get('/user/profile/announcements', { params: params });

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    getOneAnnouncements: async (id) => {
        try {
            const result = await axios.get(`/user/announcements/${id}`);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    createAnnouncement: async (data) => {
        try {
            let formData = new FormData();

            formData.append('title', data.title);
            formData.append('content', data.description);
            formData.append('priceRange', data.price);
            formData.append('fullAddress', data.fullAddress);
            formData.append('lng', data.longitude);
            formData.append('lat', data.latitude);
            formData.append('email', data.email);
            formData.append('phone', data.phoneNumber);
            formData.append('fromDate', data.fromDate);
            formData.append('toDate', data.toDate);
            formData.append('announcementType', data.announcementType);
            formData.append('createdAs', data.creatorEntity);

            data?.pictures?.forEach((elem, index) => {
                formData.append(`announcementPhotos[${index}][photoFile][file]`, elem);
                formData.append(`announcementPhotos[${index}][position]`, index + 1);
            });

            const result = await axios.post('/user/announcements/create', formData);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    updateAnnouncement: async (id, data) => {
        try {
            let formData = new FormData();

            formData.append('title', data.title);
            formData.append('content', data.description);
            formData.append('priceRange', data.price);
            formData.append('fullAddress', data.fullAddress);
            formData.append('lng', data.longitude);
            formData.append('lat', data.latitude);
            formData.append('email', data.email);
            formData.append('phone', data.phoneNumber);
            formData.append('fromDate', data.fromDate);
            formData.append('toDate', data.toDate);
            formData.append('announcementType', data.announcementType);
            formData.append('createdAs', data.creatorEntity);

            data?.pictures?.forEach((elem, index) => {
                formData.append(`announcementPhotos[${index}][photoFile][file]`, elem);
                formData.append(`announcementPhotos[${index}][position]`, index + 1);
            });

            const result = await axios.post(`/user/announcements/${id}/update`, formData);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    deleteAnnouncement: async (id) => {
        try {
            await axios.delete(`/user/announcements/${id}`);

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    addFavorite: async (id) => {
        try {
            const result = await axios.post(`/user/announcements/${id}/favorite`);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    getComments: async (id) => {
        try {
            const result = await axios.get(`/user/announcements/${id}/comments`);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    createComment: async (data) => {
        try {
            const formData = new FormData();

            formData.append('content', data.content);

            const result = await axios.post(`/user/announcements/${data.id}/comments/create`, formData);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    updateComment: async (data) => {
        try {
            let formData = new FormData();

            formData.append('content', data.content);

            const result = await axios.post(`/user/announcements/${data.idAnnouncement}/comments/${data.idComment}/update`, formData);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    deleteComment: async (data) => {
        try {
            await axios.delete(`/user/announcements/${data.idAnnouncement}/comments/${data.idComment}`);

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },

    getAnnouncementsTypeList: async () => {
        try {
            const result = await axios.get('/user/announcement-types');

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        };
    },
};