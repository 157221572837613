import moment from 'moment';
import axios from './config';


const intermittent = {
    createIntermittent: async (data) => {
        try {
            let formData = new FormData();

            formData.append('audiensNb', data.audiensNb);
            formData.append('address1', data.address1);
            formData.append('address2', data.address2);
            formData.append('zipCode', data.zipCode);
            formData.append('city', data.city);

            const types = data?.sectorType?.map(element => element?.id);

            types?.forEach((element, index) => {
                formData.append(`compagniesTypes[${index}]`, element);
            });

            
            const result = await axios.post('/user/intermittents/create', formData);

            return {result: true, data: result.data};
        } catch (error) {
            return {result: false, error: error?.response?.data};
        }
    },

    updateIntermittent: async (data) => {
        try {
            let formData = new FormData();

            formData.append('audiensNb', data.audiensNb);
            formData.append('address1', data.address1);

            if (data?.address2) {
                formData.append('address2', data.address2);
            }

            formData.append('zipCode', data.zipCode);
            formData.append('city', data.city);
            formData.append('creationDate', moment(data.creationDate).format('YYYY-MM-DD'));

            if (data?.contactEmail) {
                formData.append(`contactEmail`, data?.contactEmail);
            }

            if (data?.phoneNumber) {
                formData.append(`phoneNumber`, data?.phoneNumber);
            }

            if (data?.website) {
                formData.append(`website`, data?.website);
            }

            data?.socials?.forEach((item, index) => {
                formData.append(`socials[${index}][socialType]`, item.typeId);
                formData.append(`socials[${index}][value]`, item.value);
            });

            const types = data?.compagniesTypes?.map(element => element?.id);

            types?.forEach((element, index) => {
                formData.append(`compagniesTypes[${index}]`, element);
            });


            const result = await axios.post('/user/intermittents/update', formData);

            return {result: true, data: result.data};
        } catch (error) {
            return {result: false, error: error?.response?.data};
        }
    },

    deleteIntermittent: async () => {
        try {
            await axios.delete('/user/intermittents');

            return ({result: true});
        } catch (error) {
            return ({result: false, error: error?.response?.data});
        }
    }
};

export default intermittent;