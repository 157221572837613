import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getFeedBackAction, sendMessageAction } from "redux/actions/feedbackActions";
import { feedbackSelector } from "redux/selectors/feedbackSelector";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { profileSelector } from "redux/selectors/profileSelector";
import ContentMessages from "./ContentMessages/ContentMessages";
import SendingContent from "./SendingContent/SendingContent";

export const Feedback = ({ profile, feedbackData, getFeedBack, open, onClose, sendMessage }) => {
    useEffect(() => {
        if (!feedbackData.conversation) {
            getFeedBack();
        }
    }, [feedbackData?.conversation, getFeedBack]);

    const handleSendMessage = (message) => {
        sendMessage({
            userId: profile?.profile?.id,
            message: message,
        });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between">
                    <Typography component="p" variant="subtitle1">
                        Feedback
                    </Typography>
                    <IconButton color="secondary" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ height: "70vh" }}>
                <SendingContent sendMessage={handleSendMessage} />
                <ContentMessages feedbackData={feedbackData} profileId={profile?.profile?.id} />
            </DialogContent>
        </Dialog>
    );
};

export const FeedbackStore = connect(
    (state) => ({
        feedbackData: feedbackSelector(state),
        profile: profileSelector(state),
    }),
    (dispatch) => ({
        getFeedBack: () => dispatch(getFeedBackAction()),
        sendMessage: (data) => dispatch(sendMessageAction(data)),
    })
)(Feedback);
