import axios from "./config";

const feedback = {
    getConversation: async (data) => {
        try {
            const result = await axios.get("/user/feedback");

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    createMessage: async ({ message }) => {
        try {
            let formData = new FormData();

            if (message) {
                formData.append("message", message);
            }

            /* return {
                result: true,
                data: {
                    message: message,
                    id: id++,
                    messageDate: moment().utc(),
                    userFrom: { id: 10 },
                },
            }; */

            const result = await axios.post(`/user/feedback`, formData);

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },
};

export default feedback;
