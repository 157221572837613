
export const chatSelector = ({chat}) => chat

export const filteredChatUsersSelector = ({chat}) => {
    if (!chat.filter || !chat.users) {
        return chat;
    }

    let list = chat.users.filter(item => {
        const name = `${item.firstName} ${item.lastName}`;
        
        return (name.toLowerCase().includes(chat.filter.toLowerCase()));
    });

    return {...chat, users: list};
}


export const messageSelector = ({currentUser, users}) => {
    if (!currentUser || !users.conversation) {
        return [];
    }
    const res = users.conversation.find(element => element.id === currentUser.channelId);
    if (res) {
        return res;
    }
    return null;
}