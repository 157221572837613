import { Box, Button } from "@mui/material";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import { useTheme } from "@emotion/react";


export default function AddCommentForm ({submit}) {
    const theme = useTheme();

    const [message, setMessage] = useState('');

    const handleSubmit = async () => {
        const result = await submit(message);

        if (result.result) {
            setMessage('');
        }
    };

    return (
        <Box
            mt='auto'
            width='100%'
            py={3}
            px={7}
            borderTop={`1px solid ${theme.palette.borderColor.dark}`}
        >
            <FormInput
                multiline
                rows={3}
                variant='standard'
                value={message}
                onChange={e => setMessage(e.target.value)}
                margin="normal"
                placeholder="Envoyer un commentaire"
                InputProps={{ disableUnderline: true }}
            />

            <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={!message}
                >
                    <SendIcon />
                    Envoyer
                </Button>
            </Box>
        </Box>
    );
};