import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";

export const Round = ({ children, size, ...rest }) => {
    const theme = useTheme();

    return (
        <Box
            borderRadius="50%"
            width={size}
            height={size}
            backgroundColor="white"
            border={`2px solid ${theme.palette.primary.main}`}
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent="center"
            {...rest}
        >
            {children}
        </Box>
    );
};
