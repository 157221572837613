import { Box, Button, CardContent, FormHelperText, Hidden, TextField } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function EditionDescription ({companyData, submit, setEditionMode}) {
    const [description, setDescription] = useState(companyData?.description || '');

    const handleSubmit = (e) => {
        e.preventDefault();

        submit({description: description});
        setEditionMode(false);
    }

    return (
        <CmtCard>
            <TileTitle>Description</TileTitle>
            <CardContent>
                <CmtForm onSubmit={handleSubmit}>
                    <Box mb={10}>
                        <TextField
                            id="standard-multiline-flexible"
                            placeholder="Description"
                            label="Présentez votre entreprise en quelques mots..."
                            multiline
                            variant='standard'
                            fullWidth
                            aria-describedby="descriptionHelperText"
                            value={description}
                            onChange={(e) => {setDescription(e.target.value)}}
                            maxRows={4}
                            inputProps={{ maxLength: 500 }}
                        />
                        <Box width='100%' display='flex' justifyContent='flex-end'>
                            <FormHelperText  id="descriptionHelperText" >{description.length} / 500 </FormHelperText>
                        </Box>
                    </Box>
                    <Box width='100%' display='flex' justifyContent='flex-end' height={30} >
                        <Box mr={2}>
                            <Button type='button' onClick={() => setEditionMode(false)} variant='contained' color='secondary'>
                                <Hidden xsDown> Annuler </Hidden>
                                <Hidden smUp> <CloseIcon /> </Hidden>
                            </Button>
                        </Box>

                        <Box ml={2}>
                            <Button type='submit' id='descriptionSubmitButton' color='primary' variant='contained'>
                                <Hidden xsDown> Valider </Hidden>
                                <Hidden smUp> <CheckIcon /> </Hidden>
                            </Button>
                        </Box>
                    </Box>
                </CmtForm>
            </CardContent>
        </CmtCard>
    );
};