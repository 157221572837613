import { useTheme } from "@emotion/react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { PublicMenu } from "Components/Public/Menu/PublicMenu";
import { Footer } from "../Partial/Footer";
import { TextBlock } from "../Partial/TextBlock";
import { PublicButton, PublicTypo } from "../sc.Public";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";
import { FUNCTIONNALITY_PATH } from "Constant";
import history from "services/History/history";
import CmtImage from "Components/CmtImage/CmtImage";

const FunctionnalityGridElem = ({ title, text, theme, children }) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <Box mt={30} mb={10} px={10} position="relative">
                <PublicTypo
                    family={theme.palette.publicTypo.hFamily}
                    size={theme.palette.publicTypo.h2Size}
                    color="primary"
                    textAlign={"center"}
                >
                    {title}
                </PublicTypo>

                <PublicTypo
                    component="p"
                    family={theme.palette.publicTypo.pFamily}
                    size={theme.palette.publicTypo.p3Size}
                    pt={3}
                >
                    {text}
                </PublicTypo>

                <Box py={15} display="flex" justifyContent={"center"}>
                    <PublicButton
                        variant="contained"
                        color="error"
                        onClick={() => history.push(FUNCTIONNALITY_PATH)}
                    >
                        En savoir plus
                    </PublicButton>
                </Box>
                {children}
            </Box>
        </Grid>
    );
};

export const Functionnality = () => {
    const theme = useTheme();

    return (
        <Box backgroundColor={theme.palette.primary.publicMain} pt={20}>
            <PublicMenu />
            <Box py={10}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <Box
                                display={"flex"}
                                height="100%"
                                justifyContent={"center"}
                                flexDirection="column"
                                pr={10}
                            >
                                <PublicTypo
                                    family={theme.palette.publicTypo.hFamily}
                                    size={theme.palette.publicTypo.h2Size}
                                    color="primary"
                                >
                                    Fonctionnalités
                                </PublicTypo>

                                <PublicTypo
                                    component="p"
                                    family={theme.palette.publicTypo.pFamily}
                                    size={theme.palette.publicTypo.p2Size}
                                    pt={3}
                                >
                                    Une idée de spectacle ? Trouvez tout le nécessaire pour
                                    concrétiser vos projets les plus fous !
                                </PublicTypo>
                            </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={12} lg={5}>
                            <Box pl={10}>
                                <CmtImage
                                    src={"/images/Public/Functionnality/Fichier1.png"}
                                    width="100%"
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box
                        mt={10}
                        border={`1px dashed ${theme.palette.primary.main}`}
                        px={20}
                        py={10}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Box pr={15}>
                                    <PublicTypo
                                        family={theme.palette.publicTypo.hFamily}
                                        size={theme.palette.publicTypo.h4Size}
                                        color="primary"
                                    >
                                        Développez, suivez et produisez votre spectacle.
                                    </PublicTypo>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Box pl={10}>
                                    <PublicTypo
                                        family={theme.palette.publicTypo.hFamily}
                                        size={theme.palette.publicTypo.h4Size}
                                        color="primary"
                                        textAlign="center"
                                    >
                                        Créez
                                    </PublicTypo>
                                    <PublicTypo
                                        component="p"
                                        family={theme.palette.publicTypo.pFamily}
                                        size={theme.palette.publicTypo.p2Size}
                                        pt={3}
                                    >
                                        Itas aut earchilis et quodisi mustrum que reicipis int
                                        laborempore quoditas.
                                    </PublicTypo>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Box pl={10}>
                                    <PublicTypo
                                        family={theme.palette.publicTypo.hFamily}
                                        size={theme.palette.publicTypo.h4Size}
                                        color="primary"
                                        textAlign="center"
                                    >
                                        Suivez
                                    </PublicTypo>
                                    <PublicTypo
                                        component="p"
                                        family={theme.palette.publicTypo.pFamily}
                                        size={theme.palette.publicTypo.p2Size}
                                        pt={3}
                                    >
                                        Itas aut earchilis et quodisi mustrum que reicipis int
                                        laborempore quoditas.
                                    </PublicTypo>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container spacing={4} mt={20} mb={10}>
                        <FunctionnalityGridElem
                            title="Annonces"
                            text="Trouvez ce qui manque à la bonne conduite de vos projets. Everumenduci omnimus aut lacepel lupicaes nemolor rovitatati nam quaturitio. Nam quis am quassit aut fuga. Ut es aut pratur atur, ut ut haruptature plibustrum as iliam qui illorro conserumet a cons"
                            theme={theme}
                        >
                            <ShapeImg
                                src="/images/Public/Shape/Shape7.png"
                                top="-100px"
                                left={"calc(50% - 50px)"}
                            />
                        </FunctionnalityGridElem>

                        <FunctionnalityGridElem
                            title="Annuaire"
                            text="Intégrez une troupe et réalisez vos spectacles.Ugitatem sant, autas et platum exeratibust, utendae poritius, quo tem essitae struptur? Maio eariatis qui nus que volecti onseditia quuntor ectaece stibus, Opta velessene doluptaeriat lat eos estiate labo. Bearum quibeati"
                            theme={theme}
                        >
                            <ShapeImg
                                src="/images/Public/Shape/Shape2.png"
                                top="-100px"
                                left={"calc(50% - 50px)"}
                            />
                        </FunctionnalityGridElem>

                        <FunctionnalityGridElem
                            title="Projet"
                            text="Trouvez ce qui manque à la bonne conduite de vos projets. Everumenduci omnimus aut lacepel lupicaes nemolor rovitatati nam quaturitio. Nam quis am quassit aut fuga. Ut es aut pratur atur, ut ut haruptature plibustrum as iliam qui illorro conserumet a cons"
                            theme={theme}
                        >
                            <ShapeImg
                                rotate="90deg"
                                src="/images/Public/Shape/Shape3.png"
                                top="-125px"
                                width="50px"
                                left={"calc(50% - 25px)"}
                            />
                            <ShapeImg
                                src="/images/Public/Shape/Shape9.png"
                                top="-50px"
                                height="50px"
                                left={"calc(50% - 50px)"}
                            />
                        </FunctionnalityGridElem>

                        <FunctionnalityGridElem
                            title="Agenda"
                            text="Trouvez ce qui manque à la bonne conduite de vos projets. Everumenduci omnimus aut lacepel lupicaes nemolor rovitatati nam quaturitio. Nam quis am quassit aut fuga. Ut es aut pratur atur, ut ut haruptature plibustrum as iliam qui illorro conserumet a cons"
                            theme={theme}
                        >
                            <ShapeImg
                                src="/images/Public/Shape/Shape6.png"
                                top="-100px"
                                left={"calc(50% - 50px)"}
                            />
                        </FunctionnalityGridElem>

                        <FunctionnalityGridElem
                            title="Bons plans"
                            text="Trouvez ce qui manque à la bonne conduite de vos projets. Everumenduci omnimus aut lacepel lupicaes nemolor rovitatati nam quaturitio. Nam quis am quassit aut fuga. Ut es aut pratur atur, ut ut haruptature plibustrum as iliam qui illorro conserumet a cons"
                            theme={theme}
                        >
                            <ShapeImg
                                src="/images/Public/Shape/Shape1.png"
                                top="-100px"
                                left={"calc(50% - 50px)"}
                            />
                        </FunctionnalityGridElem>

                        <FunctionnalityGridElem
                            title="Chat"
                            text="Trouvez ce qui manque à la bonne conduite de vos projets. Everumenduci omnimus aut lacepel lupicaes nemolor rovitatati nam quaturitio. Nam quis am quassit aut fuga. Ut es aut pratur atur, ut ut haruptature plibustrum as iliam qui illorro conserumet a cons"
                            theme={theme}
                        >
                            <ShapeImg
                                src="/images/Public/Shape/Shape19.png"
                                rotate="90deg"
                                top="-100px"
                                left={"calc(50% - 50px)"}
                            />
                        </FunctionnalityGridElem>
                    </Grid>

                    <Grid container mb={30}>
                        <Grid item xs={12} lg={5}>
                            <Box>
                                <CmtImage
                                    src={"/images/Public/Functionnality/Fichier2.png"}
                                    width="100%"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={12} lg={6}>
                            <Box
                                display={"flex"}
                                height="100%"
                                justifyContent={"center"}
                                flexDirection="column"
                                pr={10}
                            >
                                <PublicTypo
                                    family={theme.palette.publicTypo.hFamily}
                                    size={theme.palette.publicTypo.h2Size}
                                    lineHeight={1}
                                    color="primary"
                                >
                                    Travaillez de concert avec les acteurs de vos projets
                                </PublicTypo>

                                <PublicTypo
                                    component="p"
                                    family={theme.palette.publicTypo.pFamily}
                                    size={theme.palette.publicTypo.p2Size}
                                    pt={10}
                                >
                                    Une idée de spectacle ? Trouvez tout le nécessaire pour
                                    concrétiser vos projets les plus fous !
                                </PublicTypo>

                                <PublicTypo
                                    component="p"
                                    family={theme.palette.publicTypo.pFamily}
                                    size={theme.palette.publicTypo.p2Size}
                                    pt={10}
                                >
                                    Bus ut volorum, iditiis as estiis utateni minc Quatius
                                    anihicatem vollo et maximenditis de nimodit arum aliquae et quo
                                    odis audae es modistis et volu
                                </PublicTypo>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};
