import { Box, Typography } from "@mui/material";
import { AvatarComponent }  from 'Components/AvatarComponent/AvatarComponent.jsx';
import { getColorStatus } from "Apps/Chat/getColorStatus";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { UserLineContainer } from './sc.UserLine';


export default function UserLine({
    user,
    onClick,
    currentUser,
    profileId,
    popoverVersion,
}) {
    const today = moment().format('LL');
    const lastMessageDate = moment(user?.lastMessage?.messageDate).format('LL');

    const date = today === lastMessageDate ? moment(user?.lastMessage?.messageDate).format('HH:MM') : lastMessageDate;

    return (
        <UserLineContainer
            selected={!popoverVersion && user?.id === currentUser?.id}
            popoverVersion={popoverVersion}
            onClick={onClick}
        >
            {popoverVersion ? 
                <Tooltip title={`${user.firstName} ${user.lastName}`}>
                    <Box className='pointer' position='relative'>     {/* Avatar et pastille Status */}
                        <AvatarComponent size={45} src={user.profileFileName} />
                        <Box
                            width={10}
                            height={10}
                            borderRadius='50%'
                            border='1px solid rgb(255, 255, 255)'
                            position='absolute'
                            right={0}
                            top={0}
                            backgroundColor={getColorStatus(profileId === user.id || Boolean(user.mercureId))}
                        />
                    </Box>
                </Tooltip>
            :
                <Box className='pointer' position='relative'>     {/* Avatar et pastille Status */}
                    <AvatarComponent size={45} src={user.profileFileName} />
                    <Box 
                        width={10}
                        height={10}
                        borderRadius='50%'
                        border='1px solid rgb(255, 255, 255)'
                        position='absolute'
                        right={0}
                        top={0}
                        backgroundColor={getColorStatus(profileId === user.id || Boolean(user.mercureId))}
                    />
                </Box>
            }
            
            {!popoverVersion && 
                <Box width='100%' mr={3} display='flex' flexDirection='column' ml='1em'>
                    <Box display='flex' justifyContent='space-between'>
                        <Typography component="p" variant='body2' >{user.firstName} {user.lastName} </Typography>
                        <Box color="text.secondary" fontSize={12} ml="auto">
                            {user?.lastMessage && date}
                        </Box>
                    </Box>
                    <Typography component='p' variant='body2' fontSize={10} color='textSecondary'>{user?.lastMessage?.messageText}</Typography>
                </Box>
            }
        </UserLineContainer>
    );
};