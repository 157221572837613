import { Box, Button, CardContent, Fab, Grid, Hidden } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { useState } from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { IntermittentInfosElem } from "../../sc.Profile";
import { IconView, TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

export const IntermittentStatus = ({ profile, handleEdit, setOpenAddIntermittent }) => {
    const [hover, setHover] = useState(false);

    return (
        <CmtCard onMouseEnter={() => setHover(true)} onMouseLeave={(e) => setHover(false)}>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <TileTitle>Status Intermittent</TileTitle>
                {!profile?.profile?.intermittent && (
                    <Fab
                        variant="extended"
                        sx={{ ml: 5, px: 5, mt: 5, mr: 3 }}
                        size="small"
                        color="primary"
                        aria-label="Déclarer"
                        onClick={() => setOpenAddIntermittent(true)}
                    >
                        <AddIcon sx={{ mr: 2 }} />
                        Déclarer
                    </Fab>
                )}
            </Box>
            <CardContent>
                {profile?.profile?.intermittent && (
                    <Grid container>
                        <IntermittentInfosElem item container xs={12} mb={{ xs: 4, sm: 6 }}>
                            <Box display="flex" alignItems="flex-start">
                                <IconView>
                                    <PermIdentityIcon />
                                </IconView>
                                <Box ml={2}>
                                    <Box component="span" fontSize={12} color="primary">
                                        Numéro Audiens
                                    </Box>
                                    <Box component="div" display="block">
                                        {profile?.profile?.intermittent?.audiensNb}
                                    </Box>
                                </Box>
                            </Box>
                        </IntermittentInfosElem>
                        <IntermittentInfosElem item container xs={12} mb={{ xs: 4, sm: 6 }}>
                            <Box display="flex" alignItems="flex-start">
                                <IconView>
                                    <HomeIcon />
                                </IconView>
                                <Box ml={2} mt={0}>
                                    <Box component="span" fontSize={12} color="primary">
                                        Adresse
                                    </Box>
                                    <Box mb={2} component="div" display="block">
                                        {profile.profile.intermittent.address1}
                                    </Box>
                                    <Box mb={2} component="div" display="block">
                                        {profile.profile.intermittent.address2}
                                    </Box>
                                    <Box component="div" display="block">
                                        {profile.profile.intermittent.zipCode}{" "}
                                        {profile.profile.intermittent.city}
                                    </Box>
                                </Box>
                            </Box>
                        </IntermittentInfosElem>
                        <Grid
                            item
                            xs={12}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                            height={30}
                        >
                            {hover && (
                                <Button
                                    onClick={() => {
                                        handleEdit(profile?.profile?.intermittent?.id);
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    id="infosCardUpdateButton"
                                >
                                    <Hidden xsDown> Modifier </Hidden>
                                    <Hidden smUp>
                                        <CreateOutlinedIcon className="pointer" />
                                    </Hidden>
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </CmtCard>
    );
};
