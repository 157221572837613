import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import CmtCard from "Components/CmtCard/CmtCard";

export const CreateWrapper = styled(CmtCard)`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    padding: 50px;
    ${(props) => props.theme.breakpoints.down("lg")} {
        padding: 20px;
    } ;
`;

export const EditWrapper = styled(Box)`
    position: absolute;
    min-height: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: fit-content;
    background-color: ${(props) => props.theme.palette.background.paper};
    box-shadow: ${(props) => props.theme.shadows[5]};
    ${(props) => props.theme.breakpoints.down("sm")} {
        height: 100%;
        width: 90:
        overflow-y: auto;
        overflow-x: hidden;
    };
`;

export const IntermittentInfosElem = styled(Grid)`
    display: flex;
    alignItems: center;
    margin-block: 20px;
    transition: 1s;
    cursor: pointer';
    border-radius: 5px;
`;

export const CompaniesElem = styled(Grid)`
    display: flex;
    justify-content: center;
    alignitems: center;
    margin-block: 20px;
    transition: 1s;
    cursor: pointer;
    border-radius: 5px;
    padding-block: 10px;
    &:hover {
        background-color: ${(props) => props.theme.palette.primary.light};
    }
`;
