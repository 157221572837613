import React from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";

export const PageContainer = ({ children, restProps }) => {
    return (
        <Slide in={true} direction="up" mountOnEnter unmountOnExit>
            <Box width="100%" py={8} px={{ xs: 0, sm: 4, md: 6, lg: 8 }} {...restProps}>
                <Grid container spacing={6}>
                    {children}
                </Grid>
            </Box>
        </Slide>
    );
};
