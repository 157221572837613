import { Box, Button, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTheme } from "@emotion/react";

export const DisplayAnnouncementContact = ({announcementData, isMyAnnouncement}) => {
    return (
        <Box p={5}>
            <Box>
                <Box display='flex' alignItems='center'>
                    <AvatarComponent src={announcementData?.createdBy?.profileFileName} size={50} />
                    <Box ml={3}>
                        <Typography component='p'>
                            {announcementData?.createdBy?.firstName} {announcementData?.createdBy?.lastName}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box >
                <Box display='flex' alignItems='center' pt={5}>
                    <EmailIcon/>
                    <Box ml={2} overflow='hidden'>
                        <Typography component='p' variant='body2'>
                            {announcementData?.email}
                        </Typography>
                    </Box>
                </Box>

                <Box display='flex' alignItems='center' pt={4}>
                    <PhoneIcon/>
                    <Box ml={2} overflow='hidden'>
                        <Typography component='p' variant='body2'>
                            {announcementData?.phone}
                        </Typography>
                    </Box>
                </Box>

                {!isMyAnnouncement && 
                    <Box display='flex' justifyContent='center' my={5}>
                        <Button variant='contained' color='primary' >Contacter l'auteur</Button>
                    </Box>
                }
            </Box>
        </Box>
    );
};


export const AnnouncementContact = ({announcementData, isMyAnnouncement}) => {
    const theme = useTheme();

    return (
        <Box width='350px' flexShrink={0} boxShadow={theme.palette.sidebar.shadow} >
            <DisplayAnnouncementContact announcementData={announcementData} isMyAnnouncement={isMyAnnouncement} />
        </Box>
    );
};