import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";

export const SideBarMenuContainer = styled(Box)`
    padding: 20px 16px 10px 0px;
    border-bottom: ${props => `1px solid ${props.theme.palette.sidebar.borderColor}`};
    height: 100%;
`;

export const MenuTitle = styled(Typography)`
    font-size: 10px;
    margin-left: 16px;
    margin-block: 20px;
`;


export const FeedBackContainer = styled(Box)`
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 40;
    transition: 1s;
    color: ${props => props.theme.palette.secondary.light};
    background-color: rgba(200, 200, 200, 0.4);
    padding-left: 30px;
    padding-Block: 10px;
    &:hover {
        color: ${props => props.theme.palette.secondary.dark};
        background-color: 'rgba(200, 200, 200, 0.5)',
    };
`;