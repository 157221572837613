import { DISCONNECT, NOT_CONNECTED } from "redux/constants/authConstants";
import { CHANGE_DOCUMENTS_FILTER, GET_DOCUMENTS, GET_DOCUMENTS_ERROR, GET_DOCUMENTS_SUCCESS } from "redux/constants/documentsConstants";

const INIT_STATE = {
    documentsList: null,
    documentsInfos: null,
    path: '',
    filters: {
        category: sessionStorage.getItem('DocumentFilterCategory') || '',
        sorter: sessionStorage.getItem('DocumentFilterSorter') || 'createdAt DESC',
    },
    loading: false,
    error: null,
};

export default function documentsReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_DOCUMENTS:
            return {...state, loading: action.loading, error: null};
        case GET_DOCUMENTS_SUCCESS:
            return {...state, loading: action.loading, error: null, documentsList: action.data[0], documentsInfos: action.data[1], path: action.path};
        case GET_DOCUMENTS_ERROR:
            return {...state, loading: action.loading, error: null};
        case CHANGE_DOCUMENTS_FILTER:
            return {...state, filters: action?.filters};
        case DISCONNECT || NOT_CONNECTED:
            return {...INIT_STATE};
        default:
            return state;
    };
};