import { styled } from "@mui/system";
import React from "react";

const Image = styled((props) => <img src={props.src} alt={props.alt || "picture"} {...props} />)`
    height: ${(props) => (props.height ? `${props.height}` : props.size && `${props.size}px`)};
    width: ${(props) => (props.width ? `${props.width}` : props.size && `${props.size}px`)};
    object-fit: ${(props) => props.fit};
`;

const CmtImage = ({
    src,
    alt,
    size = null,
    objectFit = "",
    height = null,
    width = null,
    ...rest
}) => {
    return (
        <Image
            src={src}
            alt={alt}
            size={size}
            fit={objectFit}
            height={height}
            width={width}
            {...rest}
        />
    );
};

export default CmtImage;
