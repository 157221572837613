import { Grid } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import { Formik } from "formik";
import { StepperLine } from "../StepperLine";
import { UploadBlock } from "./UploadBlock";
import { Box } from "@mui/system";

export const Step6 = ({stepper, cancel, submit, data}) => {
    const checkError = (values) => {
        const errors = {};

        if (values.pictures.length === 0) {
            errors.pictures = 'Veuillez ajouter au moins une photo à votre annonce';
        }

        return (errors);
    };

    return (
        <Grid item container xs={12} lg={6} xl={5}>
        <Formik
            initialValues={{pictures: data.pictures || []}}
            validate={values => checkError(values)}
            onSubmit={(values) => {
                submit(values);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
            }) => (
                    <CmtForm onSubmit={handleSubmit} className='fullWidth'>
                        <UploadBlock 
                            values={values}
                            error={touched.pictures && errors.pictures}
                            addPicture={(pictures) => setFieldValue('pictures', [...values.pictures, ...pictures])}
                            updatePicture={(picture, index) => {
                                let data = values.pictures;

                                data[index] = picture;

                                setFieldValue('pictures', [...data]);
                            }}
                            deletePicture={(index) => {
                                const pictures = values.pictures;
                                
                                pictures.splice(index, 1);

                                setFieldValue('pictures', pictures)
                            }}
                        />

                        <Box mt={4}>
                            <StepperLine
                                onCancel={cancel}
                                stepper={stepper}
                                step="Etape 6 : Les Photos"
                            />
                        </Box>
                    </CmtForm>
            )}
        </Formik>
        </Grid>
    );
};