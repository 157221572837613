import { useTheme } from "@emotion/react";
import { AccordionDetails, AccordionSummary, Box, Button, Container, Grid } from "@mui/material";
import { PublicMenu } from "Components/Public/Menu/PublicMenu";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";
import { useState } from "react";
import { Footer } from "../Partial/Footer";
import { ParagraphText } from "../Partial/ParagraphText";
import { PriceBlockElem } from "../Partial/PriceBlockElem";
import { TitleText } from "../Partial/TitleText";
import { AccordionElem, PaymentSwitch, PrettoSlider } from "../sc.Public";

export const Price = () => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <Box backgroundColor={theme.palette.primary.publicMain} pt={30} pb={5}>
                <PublicMenu />

                <Container maxWidth="lg">
                    <TitleText>Des tarifs personalisés</TitleText>

                    <Grid container>
                        <Grid item xs={12} md={9} lg={8}>
                            <TitleText
                                fontSize={{
                                    xs: theme.palette.publicTypo.h4Size,
                                    lg: theme.palette.publicTypo.h3Size,
                                }}
                                pt={20}
                                pb={20}
                            >
                                Une formule adaptée en fonction de vos exigences
                            </TitleText>
                        </Grid>
                        <Grid item xs={12} md={8} lg={6}>
                            <Box pr={10}>
                                <ParagraphText textAlign="center" pb={5}>
                                    Nombre d'utilisateurs
                                </ParagraphText>
                                <PrettoSlider
                                    size="large"
                                    valueLabelDisplay="auto"
                                    aria-label="pretto slider"
                                    defaultValue={20}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} lg={6}>
                            <Box>
                                <ParagraphText textAlign="center" pb={5}>
                                    Paiement
                                </ParagraphText>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <ParagraphText
                                        pt={0}
                                        pr={2}
                                        fontSize={theme.palette.publicTypo.p3Size}
                                    >
                                        Mensuel
                                    </ParagraphText>
                                    <PaymentSwitch color="primary" />
                                    <ParagraphText
                                        pt={0}
                                        pl={2}
                                        fontSize={theme.palette.publicTypo.p3Size}
                                    >
                                        Annuel
                                    </ParagraphText>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box
                        py={3}
                        px={5}
                        my={15}
                        borderRadius={1}
                        border={`2px solid ${theme.palette.primary.main}`}
                    >
                        <ParagraphText>
                            Nem volut oditisquo est, consecu Ilicto dolupidicias derferiset aut
                            volest ilparchitati.
                        </ParagraphText>
                    </Box>

                    <Grid container position="relative" mb={15}>
                        <PriceBlockElem
                            zIndex={10}
                            title="- 0€ -"
                            subtitle="1ère scène"
                            list={[
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                            ]}
                        >
                            <Box px={3} pt={3} display="flex" mt="auto">
                                <Button sx={{ width: "100%" }} variant="contained">
                                    Souscrire
                                </Button>
                            </Box>
                        </PriceBlockElem>

                        <PriceBlockElem
                            zIndex={10}
                            title="- X€ -"
                            subtitle="Bercy"
                            list={[
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                            ]}
                        >
                            <Box px={3} pt={3} display="flex" mt="auto">
                                <Button sx={{ width: "100%" }} variant="contained">
                                    Souscrire
                                </Button>
                            </Box>
                        </PriceBlockElem>

                        <PriceBlockElem
                            zIndex={10}
                            title="- XX€ -"
                            subtitle="Stade de FR"
                            list={[
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                                "Ullit, vidit quidebit que ima nam volor as.",
                            ]}
                        >
                            <Box px={3} pt={3} display="flex" mt="auto">
                                <Button sx={{ width: "100%" }} variant="contained">
                                    Souscrire
                                </Button>
                            </Box>
                        </PriceBlockElem>

                        <Grid
                            container
                            position="absolute"
                            bottom={0}
                            sx={{ display: { xs: "none", lg: "flex" } }}
                        >
                            <Grid item xs={0} lg={5}>
                                <Box
                                    component="img"
                                    width="100%"
                                    src={"/images/Public/Price/Fichier1.png"}
                                />
                            </Grid>
                            <Grid item xs={0} lg={4} />
                            <Grid item xs={0} lg={3}>
                                <Box height="100%" display="flex">
                                    <Box
                                        mt="auto"
                                        component="img"
                                        width="100%"
                                        src={"/images/Public/Price/Fichier2.png"}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box pt={10}>
                <Container maxWidth="lg">
                    <TitleText position="relative" pb={20}>
                        Questions fréquentes
                        <ShapeImg src={"/images/Public/Shape/Shape2.png"} right={0} />
                    </TitleText>

                    <Box pb={30} mb={30} position="relative">
                        {[...Array(5)].map((item, index) => (
                            <AccordionElem
                                disableGutters
                                elevation={0}
                                sx={{ px: 10 }}
                                expanded={expanded === `panel${index}`}
                                onChange={() =>
                                    setExpanded(
                                        expanded === `panel${index}` ? false : `panel${index}`
                                    )
                                }
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <ParagraphText fontWeight="bold">
                                        Collapsible Group Item #1
                                    </ParagraphText>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ParagraphText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Suspendisse malesuada lacus ex, sit amet blandit leo
                                        lobortis eget. Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                                        blandit leo lobortis eget.
                                    </ParagraphText>
                                </AccordionDetails>
                            </AccordionElem>
                        ))}

                        <ShapeImg
                            src={"/images/Public/Shape/Shape5.png"}
                            bottom={0}
                            left={0}
                            rotate="90deg"
                        />

                        <ShapeImg
                            src={"/images/Public/Shape/Shape9.png"}
                            bottom={25}
                            right={0}
                            height={50}
                            rotate="90deg"
                        />
                        <ShapeImg
                            src={"/images/Public/Shape/Shape13.png"}
                            bottom={25}
                            right={50}
                            height={50}
                            rotate="-90deg"
                        />
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    );
};
