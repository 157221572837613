import { Box, Button, Typography } from "@mui/material";
import history from "services/History/history";
import { DisplayAnnouncementResult } from "./DisplayAnnouncementResult";
import { DisplayCompaniesResult } from "./DisplayCompaniesResult";
import { DisplayUsersResult } from "./DisplayUsersResult";
import { DisplayProjectResult } from "./DisplayProjectResult";
import CloseIcon from '@mui/icons-material/Close';

export const DisplaySearchResult = ({searchValue, searchResult, closeSearch, defaultAvatarPath}) => {
    const handleClick = (path) => {
        closeSearch();

        history.push(path);
    };

    const checkResult = () => {
        switch(true) {
            case searchResult?.announcements?.length > 0:
                return true;
            case searchResult?.companies?.length > 0:
                return true;
            case searchResult?.projects?.length > 0:
                return true;
            case searchResult?.users?.length > 0:
                return true;
            default:
                return false;
        };
    };

    return (
        <Box
            position='absolute'
            p={5}
            top={0} left={0} right={0} bottom={0}
            overflow='auto hidden'
            zIndex={20}
            backgroundColor='rgba(0, 0, 0, 0.8)'
        >
            <Box display='flex' justifyContent='space-between' width='60rem' m='auto'>
                <Box>
                    <Typography component='p' variant='h1' color='whitesmoke'> Recherche : {searchValue}</Typography>
                    {searchValue && searchValue?.length < 3 && 
                        <Typography component='p' color='whitesmoke'> Tapez {3 - searchValue?.length} caractère{(3 - searchValue?.length) > 1 ? 's' : ''} de plus pour lancer la recherche</Typography>
                    }
                </Box>
                <Box className='pointer' onClick={closeSearch}>
                    <Typography component='p' color='whitesmoke'>
                        <CloseIcon fontSize='large' />
                    </Typography>
                </Box>
            </Box>

            {checkResult() ?
                <>
                    {searchResult?.announcements?.length > 0 &&
                        <DisplayAnnouncementResult results={searchResult?.announcements} handleClick={handleClick} />
                    }

                    {searchResult?.companies?.length > 0 &&
                        <DisplayCompaniesResult results={searchResult?.companies} handleClick={handleClick} />
                    }

                    {searchResult?.projects?.length > 0 &&
                        <DisplayProjectResult results={searchResult?.projects} handleClick={handleClick} />
                    }

                    {searchResult?.users?.length > 0 &&
                        <DisplayUsersResult results={searchResult?.users} handleClick={handleClick} defaultAvatarPath={defaultAvatarPath} />
                    }
                </>
            :
                searchResult && 
                    <Box width='60rem' m='auto' mt={10}>
                        <Typography component='p' color='whitesmoke'>Aucun résultat n'a été trouvé</Typography> 
                        <Box mt={1}>
                            <Button color='secondary' variant='contained' onClick={closeSearch}>
                                <Box p={1}>
                                    <Typography component='p' variant='h5'>
                                        Quitter la recherche
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
            }
            
        </Box>
    );
};