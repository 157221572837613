import { Box, Button, CardContent, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CakeIcon from "@mui/icons-material/Cake";
import WorkIcon from "@mui/icons-material/Work";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useState } from "react";
import { TileTitle, IconView } from "Components/CmtProfileContainer/sc.ProfileContainer";
import moment from "moment";

export default function DisplayInfosCard({ companyData, setEditionMode, isAdmin }) {
    const [hover, setHover] = useState(false);

    return (
        <CmtCard
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            id="infosCardCmtCard"
        >
            <TileTitle>Identité</TileTitle>
            <CardContent>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                    <IconView>
                        <CakeIcon />
                    </IconView>
                    <Box ml={5}>
                        <Box component="span" fontSize={12} color="primary">
                            Date de création
                        </Box>
                        <Box fontSize={16}>
                            {companyData?.creationDate
                                ? moment(companyData?.creationDate).format("DD MMMM YYYY")
                                : "Non renseigné"}
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                    <IconView>
                        <CakeIcon />
                    </IconView>
                    <Box ml={5}>
                        <Box component="span" fontSize={12} color="primary">
                            Date d'inscription
                        </Box>
                        <Box fontSize={16}>
                            {companyData?.createdAt
                                ? moment(companyData?.createdAt).format("DD MMMM YYYY")
                                : "Non renseigné"}
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" alignItems="flex-start" mb={{ xs: 4, sm: 6 }}>
                    <IconView>
                        <WorkIcon />
                    </IconView>
                    <Box ml={5}>
                        <Box component="span" fontSize={12} color="primary">
                            Domaine d'activité
                        </Box>
                        <Box fontSize={16}>
                            <Box>
                                {companyData?.compagniesTypes?.map((item, index) => (
                                    <Typography key={index} component="p">
                                        {item?.name}
                                    </Typography>
                                )) || "Non renseigné"}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="flex-end" height={30}>
                    {hover && isAdmin && (
                        <Button
                            onClick={() => {
                                setEditionMode(true);
                            }}
                            variant="contained"
                            color="secondary"
                            id="infosCardUpdateButton"
                        >
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp>
                                {" "}
                                <CreateOutlinedIcon className="pointer" />{" "}
                            </Hidden>
                        </Button>
                    )}
                </Box>
            </CardContent>
        </CmtCard>
    );
}
