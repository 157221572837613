import { FormHelperText, Grid, InputLabel } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import { Formik } from "formik";
import CmtEditor from "Components/CmtEditor/CmtEditor";
import { StepperLine } from "../StepperLine";
import { Box } from "@mui/system";
import { EditorFormControl } from "./sc.Step3";

export const Step3 = ({stepper, cancel, submit, data}) => {
    const checkError = (values) => {
        const errors = {};

        if (!values.description.replace(/<[^>]+>/g, '').replace(/&nbsp/g, '')) {
            errors.description = 'Veuillez ajouter une description à votre projet';
        }

        return (errors);
    };

    return (
        <Grid item container xs={12} sm={7}>
            <Formik
                initialValues={{description: data.description || ''}}
                validate={values => checkError(values)}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                }) => (
                    <CmtForm onSubmit={handleSubmit} className='fullWidth'>
                        <CmtCard>
                            <Grid item xs={12} p={5}>
                                <InputLabel id="description">Description</InputLabel>
                                <EditorFormControl >
                                    <CmtEditor
                                        labelId="description"
                                        value={values.description}
                                        onBlur={() => setFieldTouched('description', true, false)}
                                        onChange={val => {
                                            setFieldValue('description', val);
                                        }}
                                    />
                                    <FormHelperText error>{touched.description && errors.description}</FormHelperText>
                                </EditorFormControl>
                            </Grid>
                        </CmtCard>

                        <Box mt={4}>
                            <StepperLine
                                onCancel={cancel}
                                stepper={stepper}
                                step="Etape 3 : Déscription de l'annonce"
                            />
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        </Grid>
    );
};