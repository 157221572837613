import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtProgressBar from "Components/CmtProgressBar/CmtProgressBar";
import { PROJECT_PATH } from "Constant";
import history from "services/History/history";
import { ProjectElemContainer } from "../sc.Structure";

export const ProjectTab = ({ profile, companyData, isIntermittent }) => {
    return (
        <Grid container sx={{ mt: 10 }} spacing={0}>
            {companyData?.createdProjects?.map((item, index) => (
                <Grid item xs={12} sm={3} key={index} position="relative" sx={{ p: 2 }}>
                    <CmtCard
                        onClick={() => history.push(`${PROJECT_PATH}/${item?.id}`)}
                        className="fullHeight"
                        sx={{ position: "relative", cursor: "pointer" }}
                    >
                        <ProjectElemContainer
                            sx={{
                                background: `center / cover no-repeat url(${
                                    item?.coverFileName || "/images/defaultProjectImage.jpg"
                                })`,
                            }}
                        />

                        <Box
                            color="white"
                            mb={2}
                            mt={2}
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            justifyContent="center"
                            position="relative"
                        >
                            <Box mb={{ xs: 4, xl: 6 }}>
                                <AvatarComponent
                                    size={50}
                                    src={item.logoFileName || "/images/dashboard/icon-german.png"}
                                    alt="logo"
                                />
                            </Box>

                            <Typography component="div" variant="h4" mb={4}>
                                {item?.name}
                            </Typography>

                            <CmtProgressBar
                                value={40}
                                label="12/45 Task completed"
                                labelPos="bottom-center"
                                textColor="white"
                            />
                        </Box>
                    </CmtCard>
                </Grid>
            ))}
        </Grid>
    );
};
