export default function getDocumentsIconsType (type) {
    switch (true) {
        case type === 'Dossier':
            return 'Dossiers.png';
        case type === 'ProjectFolder':
            return 'Dossiers Projets.png';
        case type === 'Word':
            return 'Fichiers Word.png';
        case type === 'Excel':
            return 'Fichiers Excel.png';
        case type === 'Image':
            return 'Fichiers Images.png';
        case type === 'Audio':
            return 'Fichiers Audio.png';
        case type === 'Vidéo':
            return 'Fichiers Vidéos.png';
        case type === 'Powerpoint':
            return 'Fichiers Powerpoint.png';
        case type === 'PDF':
            return 'Fichiers PDF.png';
        case type === 'Texte':
            return 'Fichiers Texte.png';
        default:
            return 'Fichiers Autres.png';
    };
};