import { styled } from "@mui/system";
import PerfectScrollbar from "react-perfect-scrollbar";

export const PerfectScroll = styled(PerfectScrollbar)`
    height: ${(props) => props.height || "100%"};
    width: ${(props) => props.width || "100%"};
    max-width: ${(props) => props.maxWidth || "100%"};
    max-height: ${(props) => props.maxHeight || "100%"};
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s ease;
    position: relative;
    touchaction: auto;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
    & .ps__rail-y,
    & .ps__rail-x {
        position: absolute;
    }
`;
