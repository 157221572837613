import { useState } from "react";
import DisplayProjectInfosCard from "./DisplayProjectInfosCard";
import EditionProjectInfosCard from "./EditionProjectInfosCard";

export const ProjectInfosCard = ({ projectData, updateProject }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionProjectInfosCard
                projectData={projectData}
                setEditionMode={setEditionMode}
                submit={updateProject}
            />
        );
    } else {
        return (
            <DisplayProjectInfosCard projectData={projectData} setEditionMode={setEditionMode} />
        );
    }
};
