import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";
import { COMPANIES_ACCESS_RIGHT_LIST, REDIRECTION_TIME } from "Constant";
import { useState } from "react";
import { NotificationManager } from "react-notifications";

export const DisplayMemberInfos = ({
    item,
    list,
    setEditMode,
    deleteInvite,
    index,
    profile,
    accessRight,
}) => {
    const [deletePopine, setDeletePopine] = useState(false);
    const filteredList = list.filter((elem) => elem.accessRights === 0);

    const handleSetDeletePopine = (data) => {
        if (filteredList.length > 0) {
            setDeletePopine(data);
        } else {
            NotificationManager.error(
                "Vous devez toujours avoir un administrateur dans votre structure",
                "Erreur",
                REDIRECTION_TIME
            );
        }
    };

    return (
        <>
            <Grid
                container
                key={index}
                width="100%"
                m={0}
                p={5}
                borderTop="1px solid rgba(211, 211, 211, 0.4)"
                spacing={0}
            >
                <Grid item sm={1} xs={2} pb={{ xs: 2, sm: 0 }} display="flex" alignItems="center">
                    <AvatarComponent
                        src={item?.user?.profileFileName || profile?.defaultAvatarPath}
                        size={50}
                    />
                </Grid>
                <Grid
                    item
                    sm={2}
                    xs={10}
                    pb={{ xs: 2, sm: 0 }}
                    px={{ xs: 0, sm: 1 }}
                    display="flex"
                    alignItems="center"
                >
                    <Typography component="p" variant="subtitle1">
                        {item?.user?.firstName} {item?.user?.lastName}
                    </Typography>
                </Grid>
                <Grid
                    item
                    sm={2}
                    xs={12}
                    pb={{ xs: 2, sm: 0 }}
                    px={{ xs: 0, sm: 1 }}
                    display="flex"
                    alignItems="center"
                >
                    <Typography component="p">{item?.user?.email || item?.email}</Typography>
                </Grid>

                <Grid
                    item
                    sm={2}
                    xs={6}
                    pb={{ xs: 2, sm: 0 }}
                    px={{ xs: 0, sm: 1 }}
                    display={{ xs: item.position ? "flex" : "none", sm: "flex" }}
                    alignItems="center"
                >
                    <Typography component="p" ml={{ xs: 0, sm: 4 }}>
                        {item?.position}
                    </Typography>
                </Grid>

                <Grid
                    item
                    sm={2}
                    xs={6}
                    pb={{ xs: 2, sm: 0 }}
                    px={{ xs: 0, sm: 1 }}
                    display="flex"
                    alignItems="center"
                >
                    <Typography component="p" ml={{ xs: 0, sm: 4 }}>
                        {
                            COMPANIES_ACCESS_RIGHT_LIST.find(
                                (elem) => elem.accessRight === item.accessRights
                            )?.label
                        }
                    </Typography>
                </Grid>

                {accessRight && (
                    <Grid
                        item
                        sm={3}
                        xs={12}
                        px={{ xs: 0, sm: 2 }}
                        pt={{ xs: 2, sm: 0 }}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ mr: { xs: "auto", sm: 2 } }}
                            onClick={() => setEditMode(true)}
                        >
                            Modifier
                        </Button>

                        {filteredList.length <= 1 && filteredList[0].id === item?.id ? (
                            <Tooltip title="Vous devez toujours avoir un administrateur dans votre structure">
                                <Box>
                                    <Button variant="outlined" disabled>
                                        Supprimer
                                    </Button>
                                </Box>
                            </Tooltip>
                        ) : (
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSetDeletePopine({ id: item?.id })}
                            >
                                Supprimer
                            </Button>
                        )}
                    </Grid>
                )}
            </Grid>

            <CmtDeleteDialog
                open={Boolean(deletePopine)}
                onCancel={() => setDeletePopine(false)}
                onDelete={() => {
                    deleteInvite(item.id);
                    setDeletePopine(false);
                }}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        Êtes-vous sûr de vouloir supprimer cette personne de votre structure ?
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>
        </>
    );
};
