import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const UserLineContainer = styled(Box)`
    height: 80px;
    border-bottom: ${props => props.popoverVersion ? 'none' : `1px solid ${props.theme.palette.borderColor.dark}`};
    display: flex;
    cursor: pointer;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: ${props => props.popoverVersion ? 'center' : 'flex-start'};
    padding-left: ${props => !props.popoverVersion && '1em'};
    position: relative;
    background-color: ${props => props.selected && props.theme.palette.primary.light};
    &:hover {
        background-color: ${props => !props.popoverVersion && props.theme.palette.primary.light};
    };
`;