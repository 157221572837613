import { Box, styled } from "@mui/system";
import { Calendar } from "react-big-calendar";

export const ButtonLineBox = styled(Box)`
    display: flex;
    ${(props) => props.theme.breakpoints.down("xl")} {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        & > .MuiFab-root {
            margin-block: 5px;
        }
    } ;
`;

export const BigCalendar = styled(Calendar)`
    & .rbc-btn-group {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;
