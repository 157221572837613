import CmtGridContainer from "Components/CmtGridContainer/CmtGridContainer";
import { Box, Dialog, Grid } from "@mui/material";
import { CreateCompagnyApp } from "Apps/Compagny/CreateCompany/CreateCompanyApp";
import { CompagnyList } from "./CompagnyList/CompagnyList";
import { CreateIntermittentApp } from "Apps/Intermittent/CreateIntermittent/CreateIntermittentApp";
import { useEffect, useState } from "react";
import { IntermittentStatus } from "./IntermittentStatus/IntermittentStatus";
import { CreateWrapper } from "../sc.Profile";
import auth from "services/Api/auth";
import companies from "services/Api/companies";
import { STRUCTURE_PATH } from "Constant";
import history from "services/History/history";
import { JoinCompagnyApp } from "Apps/Compagny/CreateCompany/JoinCompagnyApp";
import { CompagnyInvitation } from "./CompagnyInvitation/CompagnyInvitation";
import { SendedInvitation } from "./SendedInvitation/SendedInvitation";
import { useMemo } from "react";

export const CompagnyTab = ({
    profile,
    getProfile,
    disconnect,
    submitInvitationResponse,
    deleteInvite,
}) => {
    const [companiesTypes, setCompaniesTypes] = useState(null);
    const [openAddStructure, setOpenAddStructure] = useState(false);
    const [openAddIntermittent, setOpenAddIntermittent] = useState(false);

    const handleEdit = (id) => {
        history.push(`${STRUCTURE_PATH}/${id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                disconnect();

                return;
            }

            companies.getCompaniesType().then((result) => {
                if (!result.result) {
                    disconnect();

                    return;
                }

                setCompaniesTypes(result.data);
            });
        };

        fetchData();
    }, [disconnect]);

    const registeredCompanies = useMemo(() => {
        return profile?.profile?.compagnyUserActives.filter(
            (elem) => elem.adminAccepted && elem.userAccepted
        );
    }, [profile.profile?.compagnyUserActives]);

    const receivedInvitation = useMemo(() => {
        return profile?.profile?.compagnyUserActives?.filter(
            (elem) => elem.adminAccepted && elem.userAccepted === null
        );
    }, [profile.profile?.compagnyUserActives]);

    const sendedInvitation = useMemo(() => {
        return profile?.profile?.compagnyUserActives?.filter(
            (elem) => elem.adminAccepted === null && elem.userAccepted
        );
    }, [profile.profile?.compagnyUserActives]);

    return (
        <>
            <CmtGridContainer spacing={0}>
                {receivedInvitation?.length > 0 && (
                    <Grid item xs={12}>
                        <Box p={2}>
                            <CompagnyInvitation
                                list={receivedInvitation}
                                submitResponse={submitInvitationResponse}
                            />
                        </Box>
                    </Grid>
                )}
                {sendedInvitation?.length > 0 && (
                    <Grid item xs={12}>
                        <Box p={2}>
                            <SendedInvitation list={sendedInvitation} deleteInvite={deleteInvite} />
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} sm={9}>
                    <Box p={2}>
                        <CompagnyList
                            list={registeredCompanies}
                            handleEdit={handleEdit}
                            setOpenAddStructure={setOpenAddStructure}
                        />
                    </Box>
                </Grid>
                {
                    <Grid item xs={12} sm={3}>
                        <Box p={2}>
                            <IntermittentStatus
                                profile={profile}
                                handleEdit={handleEdit}
                                disconnect={disconnect}
                                setOpenAddIntermittent={setOpenAddIntermittent}
                            />
                        </Box>
                    </Grid>
                }
            </CmtGridContainer>
            <Dialog
                open={openAddIntermittent}
                onClose={() => setOpenAddIntermittent(false)}
                maxWidth="sm"
                fullWidth
            >
                <CreateWrapper>
                    <CreateIntermittentApp
                        sectorTypes={companiesTypes}
                        getProfile={getProfile}
                        disabled={Boolean(profile?.profile?.intermittent)}
                        disconnect={disconnect}
                        onClose={() => setOpenAddIntermittent(false)}
                    />
                </CreateWrapper>
            </Dialog>

            <Dialog
                open={openAddStructure}
                onClose={() => setOpenAddStructure(false)}
                maxWidth="md"
                fullWidth
            >
                <AddStructure
                    getProfile={getProfile}
                    disconnect={disconnect}
                    profile={profile}
                    companiesTypes={companiesTypes}
                    onClose={() => setOpenAddStructure(false)}
                />
            </Dialog>
        </>
    );
};

const AddStructure = ({ getProfile, disconnect, companiesTypes, onClose, profile }) => {
    const [createMode, setCreateMode] = useState(false);

    if (createMode) {
        return (
            <CreateWrapper>
                <CreateCompagnyApp
                    getProfile={getProfile}
                    disconnect={disconnect}
                    companiesTypes={companiesTypes}
                    profile={profile}
                    onClose={onClose}
                />
            </CreateWrapper>
        );
    }

    return (
        <CreateWrapper>
            <JoinCompagnyApp
                getProfile={getProfile}
                disconnect={disconnect}
                companiesTypes={companiesTypes}
                profile={profile}
                onClose={onClose}
                setCreateMode={setCreateMode}
            />
        </CreateWrapper>
    );
};
