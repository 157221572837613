import { Box, Chip, Hidden, Typography, useTheme } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import AppsIcon from "@mui/icons-material/Apps";
import { ANNOUNCEMENT_SORT_TABLE, GRID_MODE, LIST_MODE } from "Constant";
import { useState } from "react";
import { SortingFilters } from "Components/CmtFilters/SortingFilters";
import { ProjectFilters } from "./ProjectFilters";

export const ProjectOptions = ({
    categoriesList,
    filters,
    changeFilters,
    resultNumber,
    displayMode,
    setDisplayMode,
}) => {
    const theme = useTheme();

    const [locationMode, setLocationMode] = useState(null);

    const DisplayModeChip = () => {
        return (
            <Chip
                label={
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box component="div" px={1}>
                            <ListIcon
                                color={displayMode === LIST_MODE ? "primary" : "secondary"}
                                fontSize="medium"
                                onClick={() => setDisplayMode(LIST_MODE)}
                                className="pointer"
                            />
                        </Box>
                        <Box component="div" px={1}>
                            <AppsIcon
                                color={displayMode === GRID_MODE ? "primary" : "secondary"}
                                fontSize="medium"
                                onClick={() => setDisplayMode(GRID_MODE)}
                                className="pointer"
                            />
                        </Box>
                    </Box>
                }
            />
        );
    };

    return (
        <>
            <Hidden mdDown>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box my={3} pr={3} borderRight={`1px solid ${theme.palette.borderColor.dark}`}>
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                    </Box>

                    <Box
                        p={3}
                        flexGrow={1}
                        display="flex"
                        justifyContent="flex-start"
                        flexWrap="wrap"
                    >
                        <ProjectFilters
                            categoriesList={categoriesList}
                            filters={filters}
                            changeFilters={changeFilters}
                            locationMode={locationMode}
                            setLocationMode={setLocationMode}
                            displayMode={displayMode}
                        />
                    </Box>

                    <Box my={3} pl={3} borderLeft={`1px solid ${theme.palette.borderColor.dark}`}>
                        <DisplayModeChip />
                    </Box>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box display="flex" flexDirection="column" flexWrap="wrap">
                    <Box p={3} pl={0} className="fullWidth">
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                    </Box>

                    <Box p={3} pl={0} flexGrow={1} display="flex" flexDirection="column">
                        <Typography component="p" variant="h5">
                            Filtrer par
                        </Typography>
                        <Box mt={3} display="flex" flexWrap="wrap" justifyContent="space-between">
                            <ProjectFilters
                                categoriesList={categoriesList}
                                filters={filters}
                                changeFilters={changeFilters}
                                locationMode={locationMode}
                                setLocationMode={setLocationMode}
                                displayMode={displayMode}
                            />
                        </Box>
                    </Box>

                    <Box
                        p={3}
                        pl={0}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <Typography component="p" variant="h5">
                            Trier par
                        </Typography>
                        <SortingFilters
                            filters={filters}
                            changeFilters={changeFilters}
                            sortingList={ANNOUNCEMENT_SORT_TABLE}
                        />
                    </Box>
                </Box>
            </Hidden>
        </>
    );
};
