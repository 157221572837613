import { Box, Button, CardContent } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { Formik } from "formik";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export const ChangeEmail = ({submit}) => {
    const checkError = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Veuillez renseigner votre nouvel email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Adresse email invalide';
        }

        if (!values.confirmEmail) {
            errors.confirmEmail = 'Veuillez confirmer votre email';
        } else if (values.confirmEmail !== values?.email) {
            errors.confirmEmail = "Vos champs ne sont pas identique"
        }
    }

    return (
        <CmtCard>
            <TileTitle> Modifier mon email</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{email: '', confirmEmail: ''}}
                    validate={values => checkError(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        submit({email: values.email});
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                    }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        isSubmitting,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <FormInput 
                                type="email"
                                name="email"
                                label="Nouvelle adresse email"
                                id='changeEmail'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email} 
                                error={touched.email && errors.email}
                                />
                            <FormInput 
                                type="email" 
                                name="confirmEmail" 
                                label="Confirmation d'email"
                                id='changeConfirmEmail'
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.confirmEmail && errors.confirmEmail}
                                />
                            <Box width='100%' display='flex' justifyContent='flex-end' mt={7}>
                                <Button type="submit" color='secondary' variant="contained" disabled={isSubmitting}>
                                    Modifier
                                </Button>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    )

}