import { Box } from "@mui/material";
import { connect } from "react-redux";
import { profileSelector } from "redux/selectors/profileSelector";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import {
    deleteAccountAction,
    deleteInviteAction,
    getProfileAction,
    submitInvitationResponseAction,
    updateProfileAction,
} from "redux/actions/profileActions";
import { useEffect, useState } from "react";
import companies from "services/Api/companies";
import history from "services/History/history";
import { COMPAGNYTAB, HOME_PATH, PROFILETAB, SECURITYTAB } from "Constant";
import { ProfileTab } from "./ProfileTab/ProfileTab";
import { SecurityTab } from "./SecurityTab/SecurityTab";
import auth from "services/Api/auth";
import { CompagnyTab } from "./CompagnyTab/CompagnyTab";
import { notConnectedAction } from "redux/actions/authActions";
import profileUser from "services/Api/profileUser";
import { ProfileContainer } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { PerfectScroll } from "Components/PerfectScroll/sc.PerfectScroll";
import { CmtTabs } from "Components/CmtTabs/CmtTabs";

export const ProfileApp = ({
    profile,
    updateProfile,
    deleteMyAccount,
    getProfile,
    disconnect,
    submitInvitationResponse,
    deleteInvite,
}) => {
    const [tabValue, setTabValue] = useState(PROFILETAB);
    const [positionType, setPositionType] = useState(null);
    const [socialType, setSocialType] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                disconnect();

                return;
            }

            const position = await companies.getPositionType();
            const socials = await profileUser.getSocialType();

            if (!position.result || !socials.result) {
                history.push(HOME_PATH);
            }

            setPositionType(position.data);
            setSocialType(socials.data);
        };

        fetchData();
    }, [disconnect]);

    return (
        <PerfectScroll>
            <ProfileContainer>
                <ProfileHeader profile={profile} update={updateProfile} />
                <Box display="flex" justifyContent="flex-end" mb={5}>
                    <CmtTabs
                        value={tabValue}
                        setValue={setTabValue}
                        list={[
                            { value: PROFILETAB, label: "Profil" },
                            { value: SECURITYTAB, label: "Confidentialité" },
                            { value: COMPAGNYTAB, label: "Structures" },
                        ]}
                    />
                </Box>
                {tabValue === PROFILETAB && (
                    <ProfileTab
                        profile={profile}
                        updateProfile={updateProfile}
                        positionType={positionType}
                        socialType={socialType}
                    />
                )}

                {tabValue === SECURITYTAB && (
                    <SecurityTab
                        profile={profile}
                        updateProfile={updateProfile}
                        deleteMyAccount={deleteMyAccount}
                    />
                )}

                {tabValue === COMPAGNYTAB && (
                    <CompagnyTab
                        profile={profile}
                        updateProfile={updateProfile}
                        deleteMyAccount={deleteMyAccount}
                        getProfile={getProfile}
                        disconnect={disconnect}
                        submitInvitationResponse={submitInvitationResponse}
                        deleteInvite={deleteInvite}
                    />
                )}
            </ProfileContainer>
        </PerfectScroll>
    );
};

export const ProfileAppStore = connect(
    (state) => ({
        profile: profileSelector(state),
    }),
    (dispatch) => ({
        updateProfile: (values) => dispatch(updateProfileAction(values)),
        deleteMyAccount: () => dispatch(deleteAccountAction()),
        getProfile: () => dispatch(getProfileAction()),
        disconnect: () => dispatch(notConnectedAction()),
        submitInvitationResponse: (data) => dispatch(submitInvitationResponseAction(data)),
        deleteInvite: (data) => dispatch(deleteInviteAction(data)),
    })
)(ProfileApp);
