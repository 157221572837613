import {
    Box,
    Button,
    CardContent,
    FormControl,
    Grid,
    Hidden,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { CmtTimePicker } from "Components/CmtDatePicker/CmtTimePicker";
import moment from "moment";
import { DEFAULT_PUBLIC_TYPE_LIST, DEFAULT_TYPE_LIST } from "Constant";

export const EditionSpectacleGeneralInfos = ({
    projectData,
    submit,
    setEditionMode,
    spectacleTypeList = DEFAULT_TYPE_LIST,
    publicTypeList = DEFAULT_PUBLIC_TYPE_LIST,
}) => {
    return (
        <Box p={2}>
            <CmtCard>
                <TileTitle>Infos Générale</TileTitle>
                <CardContent>
                    <Formik
                        initialValues={{
                            title: projectData?.spectacle?.title || "",
                            category: projectData?.spectacle?.category?.id || "",
                            publicType: projectData?.spectacle?.publicType.id || "",
                            duration: projectData?.spectacle?.duration || "",
                            status: projectData?.spectacle?.status || "",
                        }}
                        onSubmit={(values) => {
                            submit({ spectacle: { ...projectData?.spectacle, ...values } });

                            setEditionMode(false);
                        }}
                    >
                        {({
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            setFieldTouched,
                            setFieldValue,
                        }) => (
                            <CmtForm onSubmit={handleSubmit}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <FormInput
                                            label="Titre du spectacle"
                                            name="title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="spectacleTypeLabel">
                                                Type de spectacle
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                variant="standard"
                                                labelId="spectacleTypeLabel"
                                                value={values.category}
                                                name="category"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {spectacleTypeList?.map((item, index) => (
                                                    <MenuItem
                                                        value={item.id}
                                                        key={`spectacleType ${index}`}
                                                        id={`spectacleType${index}`}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="spectacleTypeLabel">
                                                Type de public
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                variant="standard"
                                                labelId="publicTypeLabel"
                                                value={values.publicType}
                                                name="publicType"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {publicTypeList?.map((item, index) => (
                                                    <MenuItem
                                                        value={item.id}
                                                        key={`spectacleType ${index}`}
                                                        id={`spectacleType${index}`}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CmtTimePicker
                                            name="duration"
                                            onBlur={handleBlur}
                                            value={values.duration}
                                            label="Durée du spectacle"
                                            setValue={(value) => {
                                                setFieldValue(
                                                    "duration",
                                                    value ? moment(value).format("HH:mm") : ""
                                                );
                                            }}
                                            onTouched={setFieldTouched}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="spectacleStatusLabel">
                                                Status
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                variant="standard"
                                                labelId="spectacleStatusLabel"
                                                value={values.status}
                                                name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <MenuItem value={true} id={`spectacleStatus-true`}>
                                                    En tournée
                                                </MenuItem>
                                                <MenuItem
                                                    value={false}
                                                    id={`spectacleStatus-false`}
                                                >
                                                    Archivé
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Box display="flex" justifyContent="flex-end" height={30} mt={5}>
                                    <Box mr={2}>
                                        <Button
                                            type="button"
                                            onClick={() => setEditionMode(false)}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            <Hidden xsDown> Annuler </Hidden>
                                            <Hidden smUp>
                                                <CloseIcon />
                                            </Hidden>
                                        </Button>
                                    </Box>

                                    <Box ml={2}>
                                        <Button
                                            type="submit"
                                            id="submitDescriptionCard"
                                            variant="contained"
                                            color="primary"
                                        >
                                            <Hidden xsDown> Valider </Hidden>
                                            <Hidden smUp>
                                                <CheckIcon />
                                            </Hidden>
                                        </Button>
                                    </Box>
                                </Box>
                            </CmtForm>
                        )}
                    </Formik>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
