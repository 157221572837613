import { Box, Button, CardContent, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useState } from "react";
import { MAPBOX_TOKEN, MAPBOX_STYLE, ZOOM_MIN } from "Constant";
import { CmtMarker } from "Components/CmtMapBoxElements/CmtMarker/CmtMarker";
import { useRef } from "react";
import ReactMapBoxGl, { ZoomControl } from "react-mapbox-gl";

const MapBox = ReactMapBoxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: true,
    minZoom: ZOOM_MIN,
});

const mapStyle = {
    minHeight: "400px",
    height: "100%",
    width: "100%",
};

export const DisplayProjectLocation = ({ projectData, setEditionMode }) => {
    const [hover, setHover] = useState(false);
    const mapRef = useRef(null);

    return (
        <CmtCard
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            id="locationCardCmt"
        >
            <TileTitle>Localisation</TileTitle>
            <CardContent>
                <Box>
                    {!projectData?.fullAddress ? (
                        <Typography mt={5} component="p" variant="subtitle2">
                            Aucune adresse n'a été renseigné
                        </Typography>
                    ) : (
                        <>
                            <Box mb={3}>
                                <Typography component="span" variant="subtitle2">
                                    Adresse sélectionnée :
                                </Typography>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    fontWeight="bold"
                                    ml={1}
                                >
                                    {projectData.fullAddress}
                                </Typography>
                            </Box>
                            <MapBox
                                // eslint-disable-next-line
                                style={MAPBOX_STYLE}
                                containerStyle={mapStyle}
                                ref={mapRef}
                                center={[2.209667, 46.8]}
                                zoom={[ZOOM_MIN]}
                                flyToOptions={{ speed: 0.8 }}
                            >
                                {projectData?.lng && projectData?.lat && (
                                    <CmtMarker
                                        style={{ zIndex: 1 }}
                                        coordinates={[projectData.lng, projectData.lat]}
                                    />
                                )}
                                <ZoomControl />
                            </MapBox>
                        </>
                    )}
                </Box>
                <Box display="flex" justifyContent="flex-end" height={30} mt={5}>
                    {hover && (
                        <Button
                            onClick={() => {
                                setEditionMode(true);
                            }}
                            variant="contained"
                            color="secondary"
                            id="locationCardUpdateButton"
                        >
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp>
                                <CreateOutlinedIcon className="pointer" />
                            </Hidden>
                        </Button>
                    )}
                </Box>
            </CardContent>
        </CmtCard>
    );
};
