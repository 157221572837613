import { Box, Button, CardContent, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtImage from "Components/CmtImage/CmtImage";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useState } from "react";

export const DisplaySpectaclePoster = ({ projectData, setEditionMode }) => {
    const [hover, setHover] = useState(false);

    return (
        <Box sx={{ p: 2 }}>
            <CmtCard
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                id="posterCardCmtCard"
            >
                <TileTitle>Affiche</TileTitle>
                <CardContent
                    sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                >
                    {projectData?.spectacle?.posterFileName ? (
                        <CmtImage
                            src={URL.createObjectURL(projectData?.spectacle?.posterFileName)}
                        />
                    ) : (
                        <Typography component="p" variant="subtitle2" my={3}>
                            Aucune affiche n'a été ajouté
                        </Typography>
                    )}

                    <Box mt={5} display="flex" justifyContent="flex-end" height={30}>
                        {hover && (
                            <Button
                                onClick={() => {
                                    setEditionMode(true);
                                }}
                                variant="contained"
                                color="secondary"
                                id="descriptionCardUpdateButton"
                            >
                                <Hidden xsDown> Modifier </Hidden>
                                <Hidden smUp>
                                    <CreateOutlinedIcon className="pointer" />
                                </Hidden>
                            </Button>
                        )}
                    </Box>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
