import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid } from "@mui/material";
import CmtImage from "Components/CmtImage/CmtImage";
import { SIGNIN_PATH, SIGNUP_PATH } from "Constant";
import history from "services/History/history";
import { PublicTypo, PublicButton } from "../sc.Public";

export const AuthBlock = () => {
    const theme = useTheme();

    return (
        <Container maxWidth="lg">
            <Grid container pb={20} pt={10} sx={{ px: 10 }}>
                <Grid item md={12} lg={6} pb={15}>
                    <PublicTypo
                        family={theme.palette.publicTypo.hFamily}
                        fontSize={theme.palette.publicTypo.h2Size}
                    >
                        Impul'Scène
                    </PublicTypo>
                    <PublicTypo
                        family={theme.palette.publicTypo.pFamily}
                        fontWeight="light"
                        fontSize={{
                            xs: theme.palette.publicTypo.p2Size,
                        }}
                        pt={5}
                    >
                        Rencontrez, échangez, créez ! Impul'scène vous donne tous les outils pour
                        faire décoller vos projets.
                    </PublicTypo>
                    <Box
                        width="100%"
                        display="flex"
                        pt={10}
                        flexDirection={{ xs: "column", sm: "row" }}
                        flexWrap={"wrap"}
                    >
                        <PublicButton
                            variant="contained"
                            sx={{ px: 13, my: 2, mr: { xs: 0, sm: 2 } }}
                            onClick={() => history.push(SIGNUP_PATH)}
                        >
                            Inscription
                        </PublicButton>

                        <PublicButton
                            variant="contained"
                            color="error"
                            sx={{ px: 13, my: 2, ml: { xs: 0, sm: 2 } }}
                            onClick={() => history.push(SIGNIN_PATH)}
                        >
                            Connexion
                        </PublicButton>
                    </Box>
                </Grid>
                <Grid item lg={6} md={12} display={{ xs: "none", sm: "block" }}>
                    <CmtImage src="/images/Public/Home/Fichier1.png" width="100%" />
                </Grid>
            </Grid>
        </Container>
    );
};
