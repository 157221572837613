import { CHAT_PATH, REDIRECTION_TIME } from "Constant";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import auth from "services/Api/auth";
import chat from "services/Api/chat";
import history from "services/History/history";
import {
    RECEIVE_MESSAGE,
    REQUEST_USERS,
    REQUEST_USERS_SUCCESS,
    REQUEST_USERS_ERROR,
    REQUEST_CONVERSATION,
    REQUEST_CONVERSATION_SUCCESS,
    REQUEST_CONVERSATION_ERROR,
    REQUEST_SEND_MESSAGE,
    REQUEST_SEND_MESSAGE_SUCCESS,
    REQUEST_SEND_MESSAGE_ERROR,
    UPDATE_FILTER,
    UPDATE_STATUS,
    SET_CURRENT_USER,
    REQUEST_TO_REAL_TIME,
    REQUEST_DELETE_MESSAGE_SUCCESS,
    REQUEST_DELETE_MESSAGE_ERROR,
    SUBSCRIPTION_REAL_TIME,
    CHANGE_IS_TYPING,
    DELETE_MESSAGE,
    READED_MESSAGE,
} from "../constants/chatConstants";
import { notConnectedAction } from "./authActions";

let notificationUserList = [];
/**
 *
 * notificationUserList : storage list of user who have sended message for limiting spaming
 * { id: userId(int), lastNotificationDate: lastMessageDate(moment())}
 */

export const changeFilterAction = (value) => ({
    type: UPDATE_FILTER,
    filter: value,
});

export const changeStatusAction = (value) => ({
    type: UPDATE_STATUS,
    status: value,
});

export const changeCurrentUserAction = (currentUser) => ({
    type: SET_CURRENT_USER,
    currentUser: currentUser,
});

export const setCurrentUserAction = (currentUser) => {
    return (dispatch) => {
        if (!currentUser || !currentUser.id) {
            return;
        }

        dispatch(changeCurrentUserAction(currentUser));

        dispatch(getConversationAction({ userId: currentUser?.id }));
    };
};

/* --------------------------Get Users-------------------------- */

export const requestChatUserList = () => ({
    type: REQUEST_USERS,
    loading: true,
});

export const requestChatUserListSuccess = (userList) => ({
    type: REQUEST_USERS_SUCCESS,
    userList: userList,
    loading: false,
});

export const requestChatUserListError = (error) => ({
    type: REQUEST_USERS_ERROR,
    loading: false,
    error: error,
});

export const getChatUserListAction = (data) => {
    return async (dispatch) => {
        if (!data?.checkVerified) {
            const check = await auth.checkIsAuth();

            if (!check) {
                dispatch(notConnectedAction());

                return;
            }
        }

        dispatch(requestChatUserList());

        const result = await chat.getUserList();

        if (result.result) {
            dispatch(requestChatUserListSuccess(result?.data));
        } else {
            dispatch(requestChatUserListError(result?.error));
        }

        return result;
    };
};

/* --------------------------Get Conversation-------------------------- */

export const requestConversation = () => ({
    type: REQUEST_CONVERSATION,
    loading: true,
});

export const requestConversationSuccess = (conversation) => ({
    type: REQUEST_CONVERSATION_SUCCESS,
    messages: conversation,
    loading: false,
});

export const requestConversationError = (error) => ({
    type: REQUEST_CONVERSATION_ERROR,
    loading: false,
    error: error,
});

export const getConversationAction = (values) => {
    return async (dispatch) => {
        dispatch(requestConversation());

        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await chat.getMessages(values);

        if (!result.result) {
            dispatch(requestConversationError(result?.error));
        } else {
            dispatch(requestConversationSuccess(result?.data?.reverse()));
        }

        return;
    };
};

/* --------------------------Send Message-------------------------- */

export const requestSendMessage = () => ({
    type: REQUEST_SEND_MESSAGE,
    loading: true,
});

export const requestSendMessageSuccess = (message) => ({
    type: REQUEST_SEND_MESSAGE_SUCCESS,
    message: message,
    loading: false,
});

export const requestSendMessageError = (error) => ({
    type: REQUEST_SEND_MESSAGE_ERROR,
    loading: false,
    error: error,
});

export const sendMessageAction = (data) => {
    return async (dispatch) => {
        dispatch(requestSendMessage());

        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await chat.createMessage(data);

        if (result.result) {
            dispatch(requestSendMessageSuccess(result?.data));
        } else {
            dispatch(requestSendMessageError(result.error));
        }
    };
};

export const requestDeleteMessageSuccess = ({ userId, messageId }) => ({
    type: REQUEST_DELETE_MESSAGE_SUCCESS,
    userId: userId,
    messageId: messageId,
});

export const requestDeleteMessageError = (error) => ({
    type: REQUEST_DELETE_MESSAGE_ERROR,
    error: error,
});

export const deleteMessageAction = ({ userId, messageId }) => {
    return async (dispatch) => {
        if (!userId || !messageId) {
            return;
        }

        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await chat.deleteMessage({
            userId: userId,
            messageId: messageId,
        });

        if (result?.result) {
            dispatch(
                requestDeleteMessageSuccess({
                    userId: userId,
                    messageId: messageId,
                })
            );
        } else {
            dispatch(requestDeleteMessageError(result.error));
        }

        return result;
    };
};

export const requestRealTimeServer = () => ({
    type: REQUEST_TO_REAL_TIME,
    loading: true,
});

export const handleSubscriptionAction = (profileId, data) => ({
    type: SUBSCRIPTION_REAL_TIME,
    profileId: profileId,
    user: data,
});

export const handleReceiveMessageAction = (data) => {
    return async (dispatch, getState) => {
        const state = getState().chat;

        const user = state?.users?.find((elem) => elem.id === data.userFrom?.id);

        const accessNotification = notificationUserList.find((elem) => elem.id === user.id);
        const displayNotification = accessNotification
            ? moment().diff(moment(accessNotification?.lastNotificationDate), "seconds") * 1000 >=
              REDIRECTION_TIME
            : true;

        if (window.location.pathname !== CHAT_PATH && displayNotification) {
            const index = notificationUserList.findIndex((elem) => elem.id === user.id);

            if (index === -1) {
                notificationUserList.push({ id: user.id, lastNotificationDate: moment() });
            } else {
                notificationUserList[index].lastNotificationDate = moment();
            }

            NotificationManager.info(
                data.messageText || "A partagé un fichier",
                `${user ? `${user.firstName} ${user.lastName}` : "Nouveau message"}.`,
                REDIRECTION_TIME,
                () => {
                    dispatch(setCurrentUserAction(user));

                    history.push(CHAT_PATH);
                }
            );
        }

        dispatch({
            type: RECEIVE_MESSAGE,
            message: data,
        });
    };
};

export const handleIsTypingAction = (data) => ({
    type: CHANGE_IS_TYPING,
    typing: data,
});

export const handleDeleteMessageAction = (data) => ({
    type: DELETE_MESSAGE,
    message: data,
});

export const readedMessageAction = () => ({
    type: READED_MESSAGE,
});
