import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { COMPANIES_ACCESS_RIGHT_LIST } from "Constant";
import { Formik } from "formik";

export const InviteMemberForm = ({ profile, companyData, submit, close }) => {
    const checkErrors = (values) => {
        let errors = {};

        let invitedUser = companyData.compagnyUsers?.map((item) => item?.email || item.user?.email);

        if (!values.email) {
            errors.email = "Veuillez renseigner l'email de la personne à inviter";
        } else if (values.email === profile?.profile?.email) {
            errors.email = "Vous ne pouvez pas vous inviter vous même";
        } else if (invitedUser.includes(values.email)) {
            errors.email = "Vous avez déjà invité cet utilisateur";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "L'adresse email fourni est invalide";
        }

        if (!values.position) {
            errors.position = "Veuillez renseigner la fonction de la personne à inviter";
        }

        if (!values.accessRight && values.accessRight !== 0) {
            errors.accessRight = "Veuillez selectionner le niveau d'accès";
        }

        return errors;
    };

    return (
        <Formik
            initialValues={{
                email: "",
                position: "",
                accessRight: "",
            }}
            validate={(values) => checkErrors(values)}
            onSubmit={(values) => submit(values)}
        >
            {({ values, errors, touched, handleChange, handleSubmit, handleBlur }) => (
                <CmtForm onSubmit={handleSubmit}>
                    <FormInput
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="none"
                        value={values.email}
                        label="Email"
                        sx={{ mb: 5 }}
                        error={touched.email && errors.email}
                    />
                    <FormInput
                        name="position"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="none"
                        value={values.position}
                        label="Fonction"
                        sx={{ mb: 5 }}
                        error={touched.position && errors.position}
                    />

                    <FormControl fullWidth variant="standard" size="small">
                        <InputLabel
                            id={`labelAccessRights`}
                            error={touched.accessRight && Boolean(errors.accessRight)}
                        >
                            Droits d'accès
                        </InputLabel>
                        <Select
                            labelId={`labelAccessRights`}
                            value={values.accessRight}
                            name="accessRight"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Droits d'accès"
                            error={touched.accessRight && Boolean(errors.accessRight)}
                        >
                            {COMPANIES_ACCESS_RIGHT_LIST.map((item, index) => (
                                <MenuItem value={item.accessRight} key={index}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                            {touched.accessRight && errors.accessRight}
                        </FormHelperText>
                    </FormControl>

                    <Box display="flex" justifyContent="space-between" mt={10}>
                        <Button color="secondary" type="button" onClick={close} variant="outlined">
                            Annuler
                        </Button>

                        <Button color="primary" type="submit" variant="contained">
                            Inviter
                        </Button>
                    </Box>
                </CmtForm>
            )}
        </Formik>
    );
};
