import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtImage from "Components/CmtImage/CmtImage";
import moment from "moment";
import history from "services/History/history";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import { ANNOUNCEMENTS_PATH, EDIT_PATH, MY_ANNOUNCEMENTS_PATH } from "Constant";
import { GridElem, HiddenIcon, TranslatedIcon } from "Components/CmtTableElements/sc.TableElements";
import { useState } from "react";
import { RestrictedModale } from "Components/RestrictedModale/RestrictedModale";

export const AnnouncementsGridElement = ({
    item,
    index,
    basicPath,
    changeFavorite,
    deleteAnnouncement,
    path,
    profile,
    openRestrictedModale,
}) => {
    const isMyAnnouncement = path === MY_ANNOUNCEMENTS_PATH ? true : false;

    return (
        <Grid
            item
            xs={12}
            md={6}
            lg={4}
            xl={3}
            key={index}
            onClick={() => {
                if (!profile?.profile.isCompleted) {
                    openRestrictedModale();
                } else {
                    history.push(`${basicPath}/${item?.id}`);
                }
            }}
        >
            <CmtCard className="pointer">
                <GridElem display="flex">
                    <Box p={3}>
                        <CmtImage
                            size={100}
                            src={
                                item?.announcementPhotos &&
                                item?.announcementPhotos[0]?.photoFileName
                            }
                            objectFit="cover"
                        />
                    </Box>
                    <Box p={3} display="flex" flexDirection="column" flexGrow={1}>
                        <Box display="flex">
                            <Typography component="div" variant="subtitle1" lineHeight={1}>
                                {" "}
                                {item.title}{" "}
                            </Typography>
                            <Box ml="auto">
                                <Typography component="i">
                                    {" "}
                                    {moment(item?.fromDate).format("DD/MM/YYYY")}{" "}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography component="div" variant="subtitle2" color="textSecondary">
                            {" "}
                            {item?.createdBy?.firstName} {item?.createdBy?.lastName}{" "}
                        </Typography>
                        <Box pt={2} mt="auto">
                            <Typography component="p" variant="h5" color="textSecondary">
                                {" "}
                                {item?.announcementType?.name}{" "}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        position="absolute"
                        bottom={0}
                        right={10}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {isMyAnnouncement ? (
                            <>
                                <TranslatedIcon
                                    className="action-option icons"
                                    onClick={() =>
                                        history.push(
                                            `${ANNOUNCEMENTS_PATH}/${item?.id}${EDIT_PATH}`
                                        )
                                    }
                                >
                                    <EditIcon fontSize="medium" color="primary" />
                                </TranslatedIcon>

                                <HiddenIcon
                                    className="action-option icons"
                                    onClick={() =>
                                        deleteAnnouncement({ id: item?.id, basicPath: basicPath })
                                    }
                                >
                                    <DeleteIcon fontSize="medium" color="primary" />
                                </HiddenIcon>
                            </>
                        ) : item?.isFavorite ? (
                            <IconButton className="icons" onClick={() => changeFavorite(item?.id)}>
                                <FavoriteIcon fontSize="medium" color="primary" />{" "}
                            </IconButton>
                        ) : (
                            <IconButton className="icons" onClick={() => changeFavorite(item?.id)}>
                                <FavoriteBorderIcon fontSize="medium" color="primary" />{" "}
                            </IconButton>
                        )}
                    </Box>
                </GridElem>
            </CmtCard>
        </Grid>
    );
};

const SkeletonAnnouncementGrid = () => {
    return (
        <Grid item xs={12} md={6} lg={4} xl={3}>
            <CmtCard className="pointer">
                <GridElem display="flex">
                    <Box p={3}>
                        <Skeleton variant="rectangular" width={100} height={100} />
                    </Box>
                    <Box p={3} display="flex" flexDirection="column" flexGrow={1}>
                        <Box display="flex">
                            <Skeleton variant="text" width="70%" />

                            <Box ml="auto" width="20%">
                                <Skeleton variant="text" width="100%" />
                            </Box>
                        </Box>
                        <Skeleton variant="text" width="40%" />
                        <Box pt={2} mt="auto">
                            <Skeleton variant="text" width="50%" />
                        </Box>
                    </Box>
                    <Box display="flex" position="absolute" bottom={0} right={10} mb={2}>
                        <Skeleton variant="circular" width={40} height={40} />
                    </Box>
                </GridElem>
            </CmtCard>
        </Grid>
    );
};

export const AnnouncementsGrid = ({
    list,
    basicPath,
    handleClickFavorite,
    deleteAnnouncement,
    profileId,
    path,
    loading,
    profile,
}) => {
    const [restrictedModale, setResctrictedModale] = useState(false);

    return (
        <Box flexGrow={1} mt={5}>
            <Grid container spacing={4}>
                {list?.map((item, index) => (
                    <AnnouncementsGridElement
                        item={item}
                        index={index}
                        basicPath={basicPath}
                        changeFavorite={handleClickFavorite}
                        deleteAnnouncement={deleteAnnouncement}
                        profileId={profileId}
                        path={path}
                        profile={profile}
                        openRestrictedModale={() => setResctrictedModale(true)}
                    />
                ))}
                {loading &&
                    [...Array(4)].map((_, index) => <SkeletonAnnouncementGrid key={index} />)}
            </Grid>
            {restrictedModale && <RestrictedModale close={() => setResctrictedModale(false)} />}
        </Box>
    );
};
