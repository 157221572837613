import { useState } from "react";
import DisplayContact from "./DisplayContact";
import EditionContact from "./EditionContact";

export const ProfileContact = ({
    companyData,
    isIntermittent,
    profile,
    positionType,
    update,
    socialType,
    isAdmin,
}) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionContact
                setEditionMode={setEditionMode}
                positionType={positionType}
                submit={update}
                socialType={socialType}
                companyData={companyData}
            />
        );
    } else {
        return (
            <DisplayContact
                profile={profile}
                companyData={companyData}
                setEditionMode={setEditionMode}
                isIntermittent={isIntermittent}
                isAdmin={isAdmin}
            />
        );
    }
};
