import axios from "./config";

const chat = {
    getUserList: async () => {
        try {
            const result = await axios.get("/user/chat/list", {
                crossDomain: true,
                withCredentials: true,
            });

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getMessages: async ({ userId, limite, lastDate }) => {
        try {
            let params = {};

            if (limite) {
                params.limite = limite;
            }

            if (lastDate) {
                params.lastdate = new Date(lastDate).getTime() / 1000;
            }

            const result = await axios.get(`/user/messages/${userId}`, { params: params });

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    createMessage: async ({ userId, messageText, messageFile }) => {
        try {
            let formData = new FormData();

            if (messageText) {
                formData.append("messageText", messageText);
            }

            if (messageFile) {
                formData.append("messageFile[file]", messageFile);
            }

            const result = await axios.post(`/user/messages/${userId}/create`, formData);

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    deleteMessage: async ({ userId, messageId }) => {
        try {
            await axios.delete(`/user/messages/${messageId}`);

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    changeTyping: async ({ userId, isTyping }) => {
        try {
            axios.post(`/user/chat/${userId}/user-typing/${isTyping === true ? 1 : 0}`);

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },
};

export default chat;
