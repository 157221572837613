import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "redux/reducers/authReducer";
import profileReducer from "redux/reducers/profileReducer";
import companyReducer from "redux/reducers/companyReducer";
import announcementsReducer from "redux/reducers/announcementsReducer";
import chatReducer from "redux/reducers/chatReducer";
import projectReducer from "redux/reducers/projectReducer";
import documentsReducer from "redux/reducers/documentsReducer";
import notesReducer from "redux/reducers/notesReducer";
import planningReducer from "redux/reducers/planningReducer";
import feedbackReducer from "redux/reducers/feedbackReducer";

export default createStore(
    combineReducers({
        auth: authReducer,
        profile: profileReducer,
        company: companyReducer,
        announcements: announcementsReducer,
        chat: chatReducer,
        project: projectReducer,
        documents: documentsReducer,
        notes: notesReducer,
        planning: planningReducer,
        feedback: feedbackReducer,
    }),
    composeWithDevTools(applyMiddleware(thunk))
);

/*
 * Création du store avec les différents reducers à l'aide de combineReducers
 * composeWithDevsTools() permet d'utiliser les extension de dev de redux avec un autre middleware
 * thunk permet de réaliser des opérations async dans les actions qui sont appelé juste avant les reducers
 */
