import { Slide } from "@mui/material";
import { Box } from "@mui/system";
import { UpdateEventForm } from "./UpdateEventForm";

export const UpdateEvent = ({item, planningData, projectList, handleUpdateEvent}) => {
    return (
        <Box display='flex' flexGrow={1} height='100%'>
            <Slide in={true} direction="up" mountOnEnter unmountOnExit>
                <Box height='100%' width='100%'>
                    <UpdateEventForm item={item} projectList={projectList} planningData={planningData} submit={handleUpdateEvent} />
                </Box>
            </Slide>
        </Box>
    );
};