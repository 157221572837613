import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { useDropzone } from "react-dropzone";
import CmtImage from "Components/CmtImage/CmtImage";
import CmtGridContainer from 'Components/CmtGridContainer/CmtGridContainer';
import { NotificationManager } from "react-notifications";
import { REDIRECTION_TIME } from "Constant";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { EmptyPicture, PictureBox } from './sc.Step6';

const format = 'image/png, image/jpg, image/jpeg';

export const DisplayPicture = ({picture, index, updatePicture, deletePicture}) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpg, image/jpeg, image/png',
        multiple: false,
        maxSize: 5242880,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections?.length > 0) {
                let errorMessage = '';

                if (fileRejections[0]?.errors[0]?.code === 'file-invalid-type') {
                    errorMessage = `Votre fichier doit ếtre dans l'un des formats suivant: ${format}`;
                }

                NotificationManager.error(errorMessage || 'Votre image ne peut pas être ajouté', 'Erreur', REDIRECTION_TIME);
            }

            if (acceptedFiles?.length > 0) {
                updatePicture(acceptedFiles[0], index);
            }
        },
    });

    return (
        <PictureBox onClick={(e) => e.stopPropagation()} {...getRootProps()}>
            <input type='file' accept='image/jpg, image/jpeg, image/png' {...getInputProps()} />
            <CmtImage
                size={150}
                src={picture?.photoFileName || URL.createObjectURL(picture)}
                objectFit='cover'
            />
            <Box position='absolute' color='red' right={-10} top={-10} >
                <CancelRoundedIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        deletePicture(index);
                    }}
                />
            </Box>
        </PictureBox>
    );
};


export const UploadBlock = ({error, values, addPicture, updatePicture, deletePicture}) => {
    let arr = [1, 2, 3];

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpg, image/jpeg, image/png',
        maxFiles: 3,
        maxSize: 5242880,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections?.length > 0) {
                let errorMessage = '';

                if (fileRejections[0]?.errors[0]?.code === 'file-invalid-type') {
                    errorMessage = `Votre fichier doit ếtre dans l'un des formats suivant: ${format}`;
                }
    
                NotificationManager.error(errorMessage || 'Votre image ne peut pas être ajouté', 'Erreur', REDIRECTION_TIME);
            }

            let pictures = [];

            acceptedFiles.forEach((elem, index) => {
                if ((values.pictures.length + index + 1) > arr.length) {
                    NotificationManager.warning('Vous avez atteint la limite de 3 photos disponible', 'Attention', REDIRECTION_TIME);

                    return;
                }

                pictures.push(elem);
            });

            addPicture(pictures);
        },
    });

    return (
        <CmtCard className='pointer' {...getRootProps()} >
            <Box p={8}>
                <input type='file' accept='image/jpg, image/jpeg, image/png' {...getInputProps()} />
                <CmtGridContainer display='flex' justifyContent='center' position='relative'>
                    <Grid item xs={12} display='flex' justifyContent='center' position='relative'>
                            <Button size="large" variant='outlined' color="primary">
                                <AddPhotoAlternateIcon color='primary'/>
                                <Typography component="p" variant="h5" > Ajouter une photo</Typography>
                            </Button>
                    </Grid>
                    {arr.map((value, index) => (
                        <Grid item xs={12} lg={5} xl={4} display='flex' justifyContent='center' position='relative' key={index}>
                            {values.pictures.length < value ?
                                <EmptyPicture>
                                    <AddPhotoAlternateIcon color='disabled' />
                                    <Typography component="p" variant="h5" > Photo n°{value}</Typography>
                                </EmptyPicture>
                            :
                                <DisplayPicture
                                    picture={values.pictures[index]}
                                    index={index}
                                    updatePicture={updatePicture}
                                    deletePicture={deletePicture}
                                />
                            }
                        </Grid>
                    ))}
                </CmtGridContainer>
                <FormHelperText error>{error}</FormHelperText>
            </Box>
        </CmtCard>
    );
};