import { Box, Hidden } from "@mui/material";
import { LocationFilters } from "Components/CmtFilters/LocationFilters";
import { SortingFilters } from "Components/CmtFilters/SortingFilters";
import { GRID_MODE, NETWORK_SORT_TABLE } from "Constant";
import { useState } from "react";
import { NetworkCategoryFilter } from "./NetworkCategoryFilter";
import { NetworkCompagnySizeFilter } from "./NetworkCompagnySizeFilter";

export const NetworkOptions = ({
    filters,
    changeFilters,
    companiesTypes,
    displayMode,
    handleLocalisation,
    handleSearchLocalisation,
}) => {
    const [locationMode, setLocationMode] = useState(null);

    return (
        <>
            <NetworkCategoryFilter
                filters={filters}
                changeFilters={changeFilters}
                categoriesList={companiesTypes}
            />

            <LocationFilters
                filters={filters}
                changeFilters={changeFilters}
                handleLocalisation={handleLocalisation}
                locationMode={locationMode}
                setLocationMode={setLocationMode}
                handleSearchLocalisation={handleSearchLocalisation}
            />

            <NetworkCompagnySizeFilter
                filters={filters}
                changeFilters={changeFilters}
            />

            {displayMode === GRID_MODE &&
                <Hidden smDown>
                    <Box ml='auto' display='flex' alignItems='center'>
                        <SortingFilters
                            filters={filters}
                            changeFilters={changeFilters}
                            sortingList={NETWORK_SORT_TABLE}
                        />
                    </Box>
                </Hidden>
            }
        </>
    );
};