import { REDIRECTION_TIME } from "Constant";
import NotificationManager from "react-notifications/lib/NotificationManager";
import auth from "services/Api/auth";
import companies from "services/Api/companies";
import { CreateCompanyForm } from "./CreateCompanyForm";

export const CreateCompagnyApp = ({ getProfile, disconnect, companiesTypes, onClose, profile }) => {
    const handleSubmit = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();

            return;
        }

        const result = await companies.createCompagnie(data);

        if (!result.result) {
            NotificationManager.error(
                result?.error?.message || "Une Erreur s'est produite",
                "Erreur",
                REDIRECTION_TIME
            );

            return { result: false };
        } else {
            NotificationManager.success(
                "Votre structure à bien été crée",
                "Succès",
                REDIRECTION_TIME
            );

            getProfile();

            onClose();

            return { result: true };
        }
    };

    return (
        <CreateCompanyForm
            submit={handleSubmit}
            companiesType={companiesTypes}
            onClose={onClose}
            profile={profile}
        />
    );
};
