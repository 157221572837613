import { SORT_BY_CATEGORIES, SORT_BY_DATE } from "redux/constants/announcementsConstants";

export const HUB_URL = process.env.REACT_APP_HUB_URL || "";

export const SIGNIN_PATH = "/login";
export const SIGNUP_PATH = "/inscription";
export const FORGET_PATH = "/mot-de-passe-oublie";
export const VALID_EMAIL_PATH = "/validation-email";
export const RESET_PASSWORD = "/reinitialisation-mot-de-passe";
export const HOME_PATH = "/";
export const COMPLETE_PROFILE_PATH = "/je-complete-mon-profil";
export const DASHBOARD_PATH = "/tableau-de-bord";
export const DISCONNECT_PATH = "/déconnexion";
export const PROFILE_PATH = "/profil";
export const SETTING_PATH = "/parametres";
export const STRUCTURE_PATH = "/structure";
export const NETWORK_PATH = "/annuaire";
export const ANNOUNCEMENTS_PATH = "/annonces";
export const MY_ANNOUNCEMENTS_PATH = "/mes-annonces";
export const CREATE_COMPANY_PATH = "/creer-ma-compagnie";
export const UPDATE_COMPANY_PATH = "/modifier-ma-compagnie";
export const CHAT_PATH = "/chat";
export const GOOD_PLAN_PATH = "/bons-plans";
export const PROJECT_PATH = "/projets";
export const MY_PROJECT_PATH = "/mes-projets";
export const PLANNING_PATH = "/planning";
export const NOTE_PATH = "/notes";
export const FEEDBACK_PATH = "/feedback";
export const DOCUMENTS_PATH = "/documents";

export const FUNCTIONNALITY_PATH = "/fonctionnalités";
export const PRICES_PATH = "/tarifs";
export const TESTIMONY_PATH = "/temoignages";
export const ABOUT_PATH = "/a-propos";
export const CONTACT_PATH = "/nous-contacter";

export const CREATE_PATH = "/nouveau";
export const EDIT_PATH = "/modifier";
export const CREATE_FOLDER_PATH = "/creer-un-dossier";
export const ADD_FILE_PATH = "/ajouter-un-fichier";

export const UNOTHAURIZED_PATH = "/inaccessible";

export const REDIRECTION_TIME = 5000;
export const DEFAULT_PROFILE_PIC = "/images/icons/OutlineAccountCircle.png";
export const DEFAULT_COMPANIES_PIC = "/images/logo-symbol.svg";

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || "";
export const MAPBOX_STYLE = process.env.REACT_APP_MAPBOX_STYLE || "";

export const MAP_FIRST_MAX = 3;
export const MAP_SECOND_MAX = 4;

export const INTERMITTENT_TYPE = "INTERMITTENT_TYPE";
export const COMPAGNY_TYPE = "COMPAGNY_TYPE";

export const COUNT_MAX = 10;
export const ZOOM_MAX = 15;
export const ZOOM_MIN = 5;
export const ZOOM_BEGIN = 13;

export const PROFILETAB = "profil";
export const SECURITYTAB = "confidentialite";
export const COMPAGNYTAB = "structures";
export const PROJECTSTAB = "projets";
export const SPECTACLETAB = "spectacle";
export const TECHNICALINFOSTAB = "informations techniques";
export const MEMBERSTAB = "membres";

export const PROJECT_TAB = "projet";
export const SHARE_TAB = "partage";
export const SPECTACLE_TAB = "spectacle";

export const LOCATION_MODE = "LOCATION_MODE";
export const SEARCH_MODE = "SEARCH_MODE";

export const MAP_MODE = "MAP_MODE";
export const GRID_MODE = "GRID_MODE";
export const LIST_MODE = "LIST_MODE";

export const ALL_ANNOUNCEMENTS = "ALL_ANNOUNCEMENTS";
export const MY_ANNOUNCEMENTS = "MY_ANNOUNCEMENTS";

export const DATE_LAST_TWO_DAYS = "DATE_LAST_TWO_DAYS";
export const DATE_LAST_WEEK = "DATE_LAST_WEEK";
export const DATE_LAST_MONTH = "DATE_LAST_MONTH";

export const DEFAULT_CHAT_MOOD = "Je ne suis pas dans le mood";

export const DEFAULT_DATE_LIST = [
    { id: DATE_LAST_TWO_DAYS, name: "Ces 2 derniers jours" },
    { id: DATE_LAST_WEEK, name: "Cette semaine" },
    { id: DATE_LAST_MONTH, name: "Ce mois-ci" },
    { id: null, name: "Toutes les annonces" },
];

export const DEFAULT_PRICE_LIST = [
    { id: 0, name: "Gratuit" },
    { id: 1, name: "Moins de 50€" },
    { id: 2, name: "Entre 50€ et 100€" },
    { id: 3, name: "Entre 100€ et 500€" },
    { id: 4, name: "Entre 500 et 1000€" },
    { id: 5, name: "Entre 1000€ et 5000€" },
    { id: 6, name: "Plus de 5000€" },
];

export const DEFAULT_COMPAGNY_SIZE_LIST = [
    { id: 0, name: "1 salarié" },
    { id: 1, name: "Entre 2 et 5 salariés" },
    { id: 2, name: "Entre 6 et 15 salariés" },
    { id: 3, name: "Entre 16 et 30 salariés" },
    { id: 4, name: "Entre 31 et 50 salariés" },
    { id: 5, name: "Plus de 50 salariés" },
];

export const DOCUMENTS_SORT_TABLE = [
    { label: "Catégories", selectLabel: "Catégories", value: "category" },
    { label: "Noms", selectLabel: "Noms", value: "name" },
    { label: "Date de création", selectLabel: "Date de création", value: "createdAt" },
];

export const ANNOUNCEMENT_SORT_TABLE = [
    { label: "Catégories", selectLabel: "Catégories", value: "category" },
    { label: "Noms", selectLabel: "Noms", value: "title" },
    { label: "Distance", selectLabel: "Distance", value: "distance" },
    { label: "Prix", selectLabel: "Prix", value: "price" },
    { label: "Date de publication", selectLabel: "Date de publication", value: "publicationDate" },
    { label: "Date de début", selectLabel: "Date de début", value: "startDate" },
];

export const NETWORK_SORT_TABLE = [
    { label: "Catégories", selectLabel: "Catégories", value: "category" },
    { label: "Noms", selectLabel: "Noms", value: "name" },
    { label: "Distance", selectLabel: "Distance", value: "distance" },
    { label: "Taille de structure", selectLabel: "Taille de structure", value: "employees" },
];

export const NOTES_SORT_TABLE = [
    { label: "Titre", selectLabel: "Titre", value: "title" },
    { label: "Date de création", selectLabel: "Date de création", value: "creationDate" },
    { label: "Date de rappel", selectLabel: "Date de rappel", value: "reminderDate" },
];

export const DEFAULT_SORT_BY_LIST = [
    { id: SORT_BY_DATE, name: "Dates" },
    { id: SORT_BY_CATEGORIES, name: "Catégories" },
];

export const IMAGE_FILE_SUPPORTED = "image/jpeg, image/png, image/gif, image/webp";

export const AUDIO_FILE_SUPPORTED = "audio/midi, audio/mpeg, audio/webm, audio/ogg, audio/wav";

export const VIDEO_FILE_SUPPORTED = "video/mp4, video/webm, video/ogg, video/mpeg";

export const WORD_FILE_SUPPORTED =
    "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const EXCEL_FILE_SUPPORTED =
    "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const POWERPOINT_FILE_SUPPORTED =
    "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation";

export const PDF_FILE_SUPPORTED = "application/pdf";

export const TEXT_FILE_SUPPORTED = "text/plain";

export const APPLICATION_FILE_SUPPORTED = "application/json";

export const ALL_FILE_SUPPORTED = `${IMAGE_FILE_SUPPORTED},${AUDIO_FILE_SUPPORTED},${VIDEO_FILE_SUPPORTED},${WORD_FILE_SUPPORTED},${EXCEL_FILE_SUPPORTED},${POWERPOINT_FILE_SUPPORTED},${PDF_FILE_SUPPORTED},${TEXT_FILE_SUPPORTED},${APPLICATION_FILE_SUPPORTED}`;

export const COMPANIES_ACCESS_RIGHT_LIST = [
    { label: "Administrateur", accessRight: 0 },
    { label: "Utilisateur", accessRight: 1 },
];

export const PROJECT_ACCESS_RIGHT_LIST = [
    { label: "Administrateur", accessRight: 0 },
    { label: "Participant", accessRight: 1 },
];

export const COUNT_ERROR = "COUNT_ERROR";

export const DEFAULT_TYPE_LIST = [
    { id: 1, name: "Ventriloquie" },
    { id: 2, name: "Spectacle classique" },
    { id: 3, name: "Stand-up" },
];

export const DEFAULT_PUBLIC_TYPE_LIST = [
    { id: 1, name: "Public majeur" },
    { id: 2, name: "Adolescent" },
    { id: 3, name: "Jeunes enfants" },
    { id: 4, name: "Enfants en bas age" },
];

export const DEFAULT_PROD_TYPE_LIST = [
    { id: 1, name: "Producteur" },
    { id: 2, name: "Co-Producteur" },
    { id: 3, name: "Diffuseur" },
];

export const DEFAULT_AUTHOR_TYPE_LIST = [
    { id: 1, name: "Auteur.trice" },
    { id: 2, name: "Chorégraphe" },
    { id: 3, name: "Compositeur.trice" },
    { id: 4, name: "Convepteur.trice" },
    { id: 5, name: "Directeur.trice Artistique" },
    { id: 6, name: "Metteur.se en scène" },
];
