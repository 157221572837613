import { DISCONNECT, NOT_CONNECTED } from "redux/constants/authConstants";
import {
    READED_FEEDBACK_MESSAGE,
    REQUEST_FEEDBACK_ERROR,
    REQUEST_FEEDBACK_SUCCESS,
    REQUEST_SEND_FEEDBACK_MESSAGE_SUCCESS,
} from "redux/constants/feedbackConstants";

const INIT_STATE = {
    conversation: null,
    loading: false,
    error: null,
    loadableMessage: true,
};

const readedMessage = (state) => {
    let conversation = state.conversation;

    conversation.forEach((elem) => (elem.messageRead = true));

    return { ...state, conversation: conversation };
};

export default function feedbackReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case DISCONNECT || NOT_CONNECTED:
            return { ...INIT_STATE };
        case REQUEST_SEND_FEEDBACK_MESSAGE_SUCCESS:
            let conversation = state?.conversation || [];
            conversation.push(action.message);

            return { ...state, conversation: [...conversation] };
        case READED_FEEDBACK_MESSAGE:
            return readedMessage(state);
        case REQUEST_FEEDBACK_SUCCESS:
            return { ...state, conversation: action.data };
        case REQUEST_FEEDBACK_ERROR:
            return { ...state, error: action.error };
        default:
            return state;
    }
}
