import { alpha } from '@mui/material';

export const cmtSearchStyle = theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        marginLeft: props => props.align === 'right' ? 'auto' : 0,
        display: 'flex',
        alignItems: 'center',
    },
    searchIcon: {
        height: 48,
        width: 48,
        position: 'absolute',
        zIndex: 1,
        pointerEvents: 'none',
        '&.right': {
        left: 'auto',
        right: 0,
        '& + $inputRoot $inputInput': {
            paddingLeft: theme.spacing(2),
            paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        },
        },
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },

    inputInput: {
        padding: theme.spacing(2, 2, 2, 0),
        paddingLeft: `calc(1em + ${theme.spacing(6)}px)`,
        transition: theme.transitions.create('width'),
        width: props => props.collapse ? 162 : '100%',
        height: 35,
        boxSizing: 'border-box',
        '&:focus': {
            backgroundColor: alpha(theme.palette.background.paper, 0.5),
            width: props => props.collapse && 235,
        },
    },

    searchIconBox: {
        borderRadius: 30,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '& $inputInput': {
            width: props => !props.value ? 48 : 235,
            height: 48,
            borderRadius: 30,
            [theme.breakpoints.down('sm')]: {
                width: props => props.value && 150,
            },

            '&:not-focus': {
                display: props => !props.value && 'none',
            },
            '&:focus': {
                width: 150,
                [theme.breakpoints.up('sm')]: {
                    width: 235,
                },
            },
        },
    },
});

