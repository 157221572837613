import { Box, CircularProgress } from "@mui/material";

export default function CmtLoading () {
    return (
        <Box 
            position='absolute' backgroundColor='rgba(0, 0, 0, 0.1)'
            left={0} right={0} top={0} bottom={0}
            display='flex' justifyContent='center' alignItems='center'
        >
            <CircularProgress/>
        </Box>
    );
};