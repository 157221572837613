import { Box, Button, CardContent, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useState } from "react";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function DisplayDescription({
    companyData,
    isIntermittent,
    setEditionMode,
    isAdmin,
}) {
    const [hover, setHover] = useState(false);

    return (
        <CmtCard
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            id="descriptionCmtCard"
        >
            <TileTitle>Description</TileTitle>
            <CardContent>
                <Typography component="p">
                    {" "}
                    {companyData?.description ||
                        companyData?.user?.description ||
                        "Vous n'avez renseigné aucune déscription"}{" "}
                </Typography>
                <Box width="100%" display="flex" justifyContent="flex-end" height={30}>
                    {hover && !isIntermittent && isAdmin && (
                        <Button
                            onClick={() => {
                                setEditionMode(true);
                            }}
                            variant="contained"
                            color="secondary"
                            id="descriptionUpdateButton"
                        >
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp>
                                {" "}
                                <CreateOutlinedIcon className="pointer" />{" "}
                            </Hidden>
                        </Button>
                    )}
                </Box>
            </CardContent>
        </CmtCard>
    );
}
