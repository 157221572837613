import { DocumentsTypesFilter } from "./DocumentsTypesFilter";

export const DocumentsOptions = ({
    filters,
    changeFilters,
    categoriesList,
}) => {
    return (
        <>
            <DocumentsTypesFilter filters={filters} changeFilters={changeFilters} categoriesList={categoriesList} />

        </>
    );
}