import { Typography, Fab, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import CmtCard from "Components/CmtCard/CmtCard";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { InviteMemberForm } from "./InviteMemberForm";
import { SendedInvite } from "./SendedInvite";
import { MemberLine } from "./MembersLine";
import { ReceivedInvite } from "./ReceivedInvite";

export const MembersTab = ({
    companyData,
    profile,
    handleUpdate,
    handleInviteMember,
    handleDeleteInvite,
    submitAnswer,
}) => {
    const [openInvite, setOpenInvite] = useState(false);

    const inviteMember = async (data) => {
        handleInviteMember(data).then((result) => {
            if (result.result) {
                setOpenInvite(false);
            }
        });
    };

    const accessRight =
        companyData.compagnyUsers.find((element) => element?.user?.id === profile?.profile?.id)
            ?.accessRights === 0;

    const memberList = companyData?.compagnyUsers.filter(
        (elem) => elem.userAccepted && elem.adminAccepted
    );

    const sendedInvitationList = companyData?.compagnyUsers.filter(
        (elem) => elem.userAccepted === null && elem.adminAccepted
    );

    const receivedInvitationList = companyData?.compagnyUsers.filter(
        (elem) => elem.userAccepted && elem.adminAccepted === null
    );

    return (
        <Box height="100%" width="100%" p={2}>
            <CmtCard>
                <Box m={5} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography component="p" variant="subtitle1">
                        Membres
                    </Typography>
                    <Fab
                        variant="extended"
                        sx={{ ml: 5, px: 5 }}
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={() => setOpenInvite(true)}
                    >
                        <AddIcon sx={{ mr: 2 }} />
                        Inviter une personne
                    </Fab>
                </Box>
                {memberList.map((item, index) => (
                    <MemberLine
                        item={item}
                        key={index}
                        list={companyData?.compagnyUsers}
                        index={index}
                        profile={profile}
                        update={handleUpdate}
                        accessRight={accessRight}
                        deleteInvite={handleDeleteInvite}
                    />
                ))}
            </CmtCard>

            {sendedInvitationList?.length > 0 && (
                <CmtCard sx={{ mt: 10 }}>
                    <Box m={5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography component="p" variant="subtitle1">
                            Invitations envoyées
                        </Typography>
                    </Box>

                    <SendedInvite
                        profile={profile}
                        invitations={sendedInvitationList}
                        accessRight={accessRight}
                        deleteInvite={handleDeleteInvite}
                    />
                </CmtCard>
            )}

            {receivedInvitationList?.length > 0 && (
                <CmtCard sx={{ mt: 10 }}>
                    <Box m={5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography component="p" variant="subtitle1">
                            Invitations reçues
                        </Typography>
                    </Box>

                    <ReceivedInvite
                        profile={profile}
                        invitations={receivedInvitationList}
                        accessRight={accessRight}
                        submitAnswer={submitAnswer}
                    />
                </CmtCard>
            )}

            <Dialog
                open={openInvite}
                onClose={() => setOpenInvite(false)}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>
                    <Typography component="p" variant="subtitle1">
                        Inviter une personne dans la structure
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <InviteMemberForm
                        close={() => setOpenInvite(false)}
                        submit={inviteMember}
                        profile={profile}
                        companyData={companyData}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};
