import { Box, styled } from "@mui/system";
import CmtImage from "Components/CmtImage/CmtImage";

export const ProjectImage = styled(CmtImage)`
    max-width: 400px;
    max-height: 300px;
    object-fit: 'contain';
`;

export const ProjectElemContainer = styled(Box)`
    position: absolute;
    color: white;
    height: 100%;
    width: 100%;
    outline: none;
    flex: 1;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    };
`;