import { useTheme } from "@emotion/react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtImage from "Components/CmtImage/CmtImage";
import { PublicMenu } from "Components/Public/Menu/PublicMenu";
import { SwiperSlide } from "swiper/react";
import { RatingStar } from "../Home/sc.Home";
import { Footer } from "../Partial/Footer";
import { ParagraphText } from "../Partial/ParagraphText";
import { TitleText } from "../Partial/TitleText";
import { SwiperWrapper } from "../sc.Public";

const Testimony = ({ imgSrc, rating, text }) => {
    return (
        <Grid item md={6} xs={12}>
            <CmtCard>
                <Box display="flex">
                    <Box p={3}>
                        <AvatarComponent src={imgSrc} size={100} />
                    </Box>

                    <Box p={3} display="flex" flexDirection="column" flexGrow={1}>
                        <RatingStar name="read-only" value={rating} readOnly size="1.5em" />
                        <Typography pt={5} component="p">
                            {text}
                        </Typography>
                    </Box>
                </Box>
            </CmtCard>
        </Grid>
    );
};

export const DisplayFunctionnalityDetail = ({ content }) => {
    const theme = useTheme();

    return (
        <Box backgroundColor={theme.palette.primary.publicMain} pt={20}>
            <PublicMenu />

            <Box py={10}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} lg={5}>
                            <TitleText
                                fontSize={{
                                    xs: theme.palette.publicTypo.h3Size,
                                    md: theme.palette.publicTypo.h2Size,
                                    lg: theme.palette.publicTypo.h1Size,
                                }}
                                textAlign={"center"}
                            >
                                {content?.block1?.title}
                            </TitleText>

                            <ParagraphText>{content?.block1?.text}</ParagraphText>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={12} lg={6}>
                            <CmtImage src={content?.block1?.image} width="100%" />
                        </Grid>

                        <Grid item xs={12} lg={9}>
                            <Box pl={30} pb={10}>
                                <ParagraphText>{content?.block2?.text}</ParagraphText>
                            </Box>
                        </Grid>

                        <Grid item xs={12} lg={7}>
                            <CmtImage src={content?.block3?.image} width="100%" />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={12} lg={4}>
                            <Box display="flex" height="100%" alignItems="center">
                                <ParagraphText>{content?.block3?.text}</ParagraphText>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container mt={30}>
                        <Grid item xs={12} lg={8}>
                            <TitleText>{content?.block4?.title}</TitleText>

                            <ParagraphText pt={10}>{content?.block4?.text}</ParagraphText>
                        </Grid>

                        <Grid item xs={12} lg={4} position="relative">
                            <Box
                                borderRadius="50%"
                                width="15rem"
                                height="15rem"
                                backgroundColor="white"
                                position="absolute"
                                top={0}
                                left={0}
                                border={`2px solid ${theme.palette.primary.main}`}
                                display="flex"
                                flexDirection={"column"}
                                alignItems={"center"}
                                justifyContent="center"
                            >
                                <ParagraphText color="primary" pb={2} textAlign="center">
                                    {content?.block4?.subtext}
                                </ParagraphText>
                            </Box>
                            <CmtImage src={content?.block4?.image} width="100%" />
                        </Grid>

                        <Grid container mt={20}>
                            <Grid item xs={12}>
                                <TitleText pb={10}>{content?.block5?.title}</TitleText>
                            </Grid>

                            <Grid container item xs={12} spacing={4}>
                                {content?.block5?.ratingBlock?.map((item, index) => (
                                    <Testimony
                                        imgSrc={item?.image}
                                        rating={item?.rating}
                                        text={item?.text}
                                        key={index}
                                    />
                                ))}
                            </Grid>
                        </Grid>

                        <Grid container mt={20}>
                            <Grid item xs={12}>
                                <TitleText pb={10}>{content?.block6?.title}</TitleText>
                            </Grid>

                            <Grid item xs={12}>
                                <SwiperWrapper
                                    navigation={true}
                                    centeredSlides={true}
                                    slidesPerView={"auto"}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                >
                                    {content?.block6?.profileBlock?.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <Box border={"10px solid blue"}>
                                                <Box px={10}>
                                                    <TitleText pb={10}>{item?.title}</TitleText>
                                                    <ParagraphText>{item?.text}</ParagraphText>
                                                </Box>
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </SwiperWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Footer />
        </Box>
    );
};
