import { Box, ClickAwayListener } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { useEffect } from "react";

export const CmtContextualMenu = ({
    open,
    positionX,
    positionY,
    children,
    onClose,
    width = 100,
    height = 100,
    ...rest
}) => {

    useEffect(() => {
        window.addEventListener('resize', () => {
            onClose();
        });
    }, [onClose]);


    if (!open) {
        return (<></>);
    }

    let x = positionX;
    let y = positionY;

    if (window.innerHeight - y < (height + 50)) {
        y = window.innerHeight - (height + 50);
    }

    if (window.innerWidth - x < (width + 50)) {
        x = window.innerWidth - (width + 50);
    }

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box position='fixed' top={y} left={x} {...rest}>
                <CmtCard className='fullHeight fullWidth'>
                    {children}
                </CmtCard>
            </Box>
        </ClickAwayListener>
    );
};