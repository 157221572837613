import {
    CHANGE_ANNOUNCEMENT_FILTER,
    FAVORITE_ANNOUNCEMENT,
    GET_ANNOUNCEMENTS_LIST,
    GET_ANNOUNCEMENTS_LIST_ERROR,
    GET_ANNOUNCEMENTS_LIST_SUCCESS,
    GET_MY_ANNOUNCEMENTS_LIST,
    GET_MY_ANNOUNCEMENTS_LIST_ERROR,
    GET_MY_ANNOUNCEMENTS_LIST_SUCCESS,
    UPDATE_ANNOUNCEMENT,
} from "redux/constants/announcementsConstants";
import {
    NOT_CONNECTED,
    DISCONNECT,
} from 'redux/constants/authConstants';

const INIT_STATE = {
    announcementsList: null,
    announcementsInfos: null,
    myAnnouncementsList: null,
    myAnnouncementsInfos: null,
    filters: {
        category: sessionStorage.getItem('AnnouncementFiltersCategory') || '',
        fromDate: sessionStorage.getItem('AnnouncementFiltersFromDate') || '',
        toDate: sessionStorage.getItem('AnnouncementFiltersToDate') || '',
        price: sessionStorage.getItem('AnnouncementFiltersPrice') || '',
        location: sessionStorage.getItem('AnnouncementFiltersLocation') || '',
        lng: sessionStorage.getItem('AnnouncementFiltersLng') || '',
        lat: sessionStorage.getItem('AnnouncementFiltersLat') || '',
        distance: sessionStorage.getItem('AnnouncementFiltersDistance') || 100,
        sorter: sessionStorage.getItem('AnnouncementFilterSorter') || 'publicationDate DESC',
        favorite: false,
        announcementPage: 1,
        myAnnouncementPage: 1,
        limit: 20,
    },
    announcementLoadable: true,
    myAnnouncementLoadable: true,
    loading: false,
    error: null,
};

const updateAnnouncement = (state, announcement) => {
    let announcementList = state.announcementsList;
    let myAnnouncementList = state.myAnnouncementsList;

    const indexAnnouncement = announcementList.findIndex(item => {
        return item.id === announcement.id;
    });

    announcementList[indexAnnouncement] = announcement;

    const indexMyAnnouncement = myAnnouncementList.findIndex(item => {
        return item.id === announcement.id;
    });

    myAnnouncementList[indexMyAnnouncement] = announcement;


    return {...state, announcementsList: announcementList, myAnnouncementsList: myAnnouncementList};
};

export default function announcementsReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_ANNOUNCEMENTS_LIST:
            return {...state, loading: action.loading, error: null};
        case GET_ANNOUNCEMENTS_LIST_SUCCESS:
            return {
                ...state,
                announcementsList: state.filters.announcementPage !== 1 ?
                    [...state.announcementsList, ...action.data[0]] :
                    action.data && action.data[0],
                announcementsInfos: action.data && action.data[1],
                announcementLoadable: action.data[0].length >= state.filters.limit,
                loading: action.loading,
                error: null
            };
        case GET_ANNOUNCEMENTS_LIST_ERROR:
            return {...state, loading: action.loading, error: action.error};
        case GET_MY_ANNOUNCEMENTS_LIST:
            return {...state, loading: action.loading, error: null};
        case GET_MY_ANNOUNCEMENTS_LIST_SUCCESS:
            return {
                ...state,
                myAnnouncementsList: state.filters.myAnnouncementPage !== 1 ?
                    [...state.myAnnouncementsList, ...action.data[0]] :
                    action.data && action.data[0],
                myAnnouncementsInfos: action.data && action.data[1],
                myAnnouncementLoadable: action.data[0].length >= state.filters.limit,
                loading: action.loading,
                error: null
            };
        case GET_MY_ANNOUNCEMENTS_LIST_ERROR:
            return {...state, loading: action.loading, error: action.error};
        case CHANGE_ANNOUNCEMENT_FILTER:
            return {
                ...state, 
                filters: {...action.filters},
                announcementLoadable: true,
                myAnnouncementLoadable: true
            };
        case FAVORITE_ANNOUNCEMENT:
            return updateAnnouncement(state, action.announcement);
        case UPDATE_ANNOUNCEMENT:
            return updateAnnouncement(state, action.announcement);
        case DISCONNECT || NOT_CONNECTED:
            return {...INIT_STATE};
        default: 
            return state;
    };
};