import { profileSelector } from "redux/selectors/profileSelector";
import { connect } from "react-redux";
import { companySelector } from "redux/selectors/companySelector";
import {
    getAllCompaniesAction,
    changeNetworkFiltersAction,
    loadMoreEntitiesAction,
    getPagedEntitiesAction,
} from "redux/actions/companyAction";
import NetworkMap from "./NetworkMap";
import { useEffect, useMemo, useState } from "react";
import { HOME_PATH, ZOOM_BEGIN, ZOOM_MIN } from "Constant";
import { Box } from "@mui/material";
import companies from "services/Api/companies";
import history from "services/History/history";
import auth from "services/Api/auth";
import NetworkFilter from "./Filters/NetworkFilter";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import { MAP_MODE } from "Constant";
import { NetworkGrid } from "./NetworkGrid";
import { PerfectScroll } from "Components/PerfectScroll/sc.PerfectScroll";

export const NetworkApp = ({
    profile,
    companiesData,
    getCompanies,
    changeFilters,
    loadMoreEntities,
    getPagedCompanies,
}) => {
    const [displayMode, setDisplayMode] = useState(
        localStorage.getItem("networkDisplayMode") || MAP_MODE
    );

    const [historyLoading, setHistoryLoading] = useState(companiesData.loading);

    const [companiesTypes, setCompaniesTypes] = useState(null);

    const coords = useMemo(() => {
        const lng = profile?.profile?.compagny?.lng;
        const lat = profile?.profile?.compagny?.lat;

        return lng && lat ? [lng, lat] : null;
    }, [profile]);

    const [selected, setSelected] = useState(null);
    const [viewport, setViewport] = useState({
        location: coords,
        center: coords,
        zoom: coords ? [ZOOM_BEGIN] : [ZOOM_MIN],
    });

    useEffect(() => {
        localStorage.setItem("networkDisplayMode", displayMode);
    }, [displayMode]);

    const handleLocalisation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setSelected(null);

            setViewport({
                location: [position.coords.longitude, position.coords.latitude],
                center: [position.coords.longitude, position.coords.latitude],
                zoom: [ZOOM_BEGIN],
            });

            changeFilters({
                ...profile?.filters,
                lng: position.coords.longitude,
                lat: position.coords.latitude,
            });
        });
    };

    const handleSearchLocalisation = (lng, lat) => {
        if (!lng || !lat) {
            setViewport({
                location: null,
                center: null,
                zoom: [ZOOM_MIN],
            });

            return;
        }

        setViewport({
            location: [lng, lat],
            center: [lng, lat],
            zoom: [ZOOM_BEGIN],
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                history.push(HOME_PATH);
            }

            const result = await companies.getCompaniesType();

            if (!result.result) {
                history.push(HOME_PATH);

                return;
            }

            setCompaniesTypes(result.data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        setHistoryLoading(companiesData.loading);

        if (!companiesData.companies && !companiesData.loading) {
            getCompanies();
        }

        if (!companiesData.pagedCompanies && !companiesData.loading) {
            getPagedCompanies();
        }
    }, [companiesData, getCompanies, getPagedCompanies]);

    if (!companiesData.companies || !companiesTypes) {
        return <></>;
    }

    const handleScrollDown = (props) => {
        if (
            props?.scrollTopMax - props?.scrollTop <= 300 &&
            companiesData.loadable &&
            !historyLoading
        ) {
            setHistoryLoading(true);

            loadMoreEntities(companiesData.networkFilters);
        }
    };

    if (displayMode === MAP_MODE) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
                position="relative"
                p={5}
                flexGrow={1}
            >
                <CmtAppHeader title="Le réseau des professionnels" />
                <NetworkFilter
                    filters={companiesData.networkFilters}
                    changeFilters={changeFilters}
                    companiesTypes={companiesTypes}
                    displayMode={displayMode}
                    resultNumber={companiesData?.companies[1]?.total}
                    setDisplayMode={setDisplayMode}
                    handleLocalisation={handleLocalisation}
                    handleSearchLocalisation={handleSearchLocalisation}
                />
                <Box flexGrow={1} mt={5} height="100%">
                    <CmtCard className="fullHeight">
                        <NetworkMap
                            profile={profile}
                            company={companiesData}
                            viewport={viewport}
                            setViewport={setViewport}
                            selected={selected}
                            setSelected={setSelected}
                            handleLocalisation={handleLocalisation}
                            defaultProfileFileName={profile?.defaultAvatarPath}
                        />
                    </CmtCard>
                </Box>
            </Box>
        );
    }

    return (
        <PerfectScroll onScrollDown={(props) => handleScrollDown(props)}>
            <Box
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
                position="relative"
                p={5}
                flexGrow={1}
            >
                <CmtAppHeader title="Le réseau des professionnels" />
                <NetworkFilter
                    filters={companiesData.networkFilters}
                    changeFilters={changeFilters}
                    companiesTypes={companiesTypes}
                    displayMode={displayMode}
                    resultNumber={companiesData?.companies[1]?.total}
                    setDisplayMode={setDisplayMode}
                    handleLocalisation={handleLocalisation}
                    handleSearchLocalisation={handleSearchLocalisation}
                />
                <NetworkGrid
                    companiesData={companiesData}
                    defaultProfileFileName={profile?.defaultAvatarPath}
                    profile={profile}
                />
            </Box>
        </PerfectScroll>
    );
};

export const NetworkAppStore = connect(
    (state) => ({
        profile: profileSelector(state),
        companiesData: companySelector(state),
    }),
    (dispatch) => ({
        getCompanies: () => dispatch(getAllCompaniesAction()),
        changeFilters: (data) => dispatch(changeNetworkFiltersAction(data)),
        loadMoreEntities: (data) => dispatch(loadMoreEntitiesAction(data)),
        getPagedCompanies: (data) => dispatch(getPagedEntitiesAction(data)),
    })
)(NetworkApp);
