import { Box, Fab, Typography } from "@mui/material";
import {
    ANNOUNCEMENTS_PATH,
    CREATE_PATH,
    EDIT_PATH,
    LIST_MODE,
    MY_ANNOUNCEMENTS_PATH,
    REDIRECTION_TIME,
    UNOTHAURIZED_PATH,
} from "Constant";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
    changeAnnouncementsFiltersAction,
    createAnnouncementAction,
    deleteAnnouncementAction,
    getFilteredAnnouncementAction,
    loadMoreAnnouncementsAction,
    loadMoreMyAnnouncementsAction,
    updateAnnouncementAction,
} from "redux/actions/announcementsActions";
import { notConnectedAction } from "redux/actions/authActions";
import { announcementsListSelector } from "redux/selectors/announcementsSelector";
import { profileSelector } from "redux/selectors/profileSelector";
import { announcements } from "services/Api/announcements";
import auth from "services/Api/auth";
import history from "services/History/history";
import { AnnouncementDetail } from "./AnnouncementDetail/AnnouncementDetail";
import { AnnouncementsList } from "./AnnouncementsList/AnnouncementsList";
import CmtAppHeader from "../../Components/CmtAppHeader/CmtAppHeader";
import { CreateAnnouncementsApp } from "./CreateAnnouncementsApp/CreateAnnouncementsApp";
import { AnnouncementsOptions } from "./AnnouncementsOptions/AnnouncementsOptions";
import { EditAnnouncementsApp } from "./CreateAnnouncementsApp/EditAnnouncementsApp";
import { NotificationManager } from "react-notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { PerfectScroll } from "Components/PerfectScroll/sc.PerfectScroll";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";
import { UnauthorizedPage } from "Components/UnauthorizedPage/UnauthorizedPage";
import { RestrictedRoute } from "Components/ResctrictedRoute/RestrictedRoute";

const TitleLine = ({ title, viewPath, viewLabel }) => {
    return (
        <Box pb={5} display="flex" justifyContent="space-between" flexWrap="wrap">
            <CmtAppHeader title={title} />
            <Box display="flex">
                <Fab
                    variant="extended"
                    sx={{ ml: 5, px: 5 }}
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => history.push(`${ANNOUNCEMENTS_PATH}${CREATE_PATH}`)}
                >
                    <PostAddIcon sx={{ mr: 2 }} />
                    Créer une annonce
                </Fab>

                <Fab
                    variant="extended"
                    size="small"
                    sx={{ ml: 5, px: 5 }}
                    color="primary"
                    aria-label="add"
                    onClick={() => history.push(viewPath)}
                >
                    <SettingsIcon sx={{ mr: 2 }} />
                    {viewLabel}
                </Fab>
            </Box>
        </Box>
    );
};

const EditTitleLine = ({ title }) => {
    return (
        <Box pb={5} display="flex" justifyContent="space-between" flexWrap="wrap">
            <CmtAppHeader title={title} />
            <Box display="flex">
                <Fab
                    variant="extended"
                    size="small"
                    sx={{ ml: 5, px: 5 }}
                    color="primary"
                    aria-label="add"
                    onClick={() => history.push(ANNOUNCEMENTS_PATH)}
                >
                    <SettingsIcon sx={{ mr: 2 }} />
                    Liste des annonces
                </Fab>

                <Fab
                    variant="extended"
                    size="small"
                    sx={{ ml: 5, px: 5 }}
                    color="primary"
                    aria-label="add"
                    onClick={() => history.push(MY_ANNOUNCEMENTS_PATH)}
                >
                    <SettingsIcon sx={{ mr: 2 }} />
                    Mes annonces
                </Fab>
            </Box>
        </Box>
    );
};

export function AnnouncementsApp({
    announcement,
    disconnect,
    getAnnouncementsList,
    createAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    changeFilter,
    profile,
    loadMoreAnnouncements,
    loadMoreMyAnnouncements,
}) {
    const [displayMode, setDisplayMode] = useState(LIST_MODE);

    const [deletePopine, setDeletePopine] = useState(null);

    const [historyLoading, setHistoryLoading] = useState(announcement?.loading);

    const [typeList, setTypeList] = useState(null);

    const fetchData = useCallback(async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();

            return;
        }

        announcements.getAnnouncementsTypeList().then((result) => {
            if (!result.result) {
                return;
            }

            setTypeList(result.data);
        });
    }, [disconnect]);

    useEffect(() => {
        if (!typeList) {
            fetchData();
        }
    }, [typeList, fetchData]);

    useEffect(() => {
        if (announcement.error) {
            return;
        }

        setHistoryLoading(announcement.loading);

        if (!announcement.announcementsList && !announcement.loading) {
            getAnnouncementsList();
        }
    }, [announcement, getAnnouncementsList]);

    const handleSubmit = async (values) => {
        const result = await createAnnouncement(values);

        if (result) {
            NotificationManager.success(
                "Votre annonce à été crée correctement",
                "Succès",
                REDIRECTION_TIME
            );

            history.push(ANNOUNCEMENTS_PATH);
        } else {
            NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
        }
    };

    const handleUpdate = async (id, data) => {
        const result = await updateAnnouncement(id, data);

        if (result?.result) {
            NotificationManager.success(
                "Votre annonce à bien été mise à jour",
                "Succès",
                REDIRECTION_TIME
            );

            history.push(ANNOUNCEMENTS_PATH);
        } else {
            NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
        }
    };

    const handleScrollDown = (props, loadable, filters, myAnnouncement) => {
        if (props?.scrollTopMax - props?.scrollTop <= 300 && loadable && !historyLoading) {
            setHistoryLoading(true);

            if (myAnnouncement) {
                loadMoreMyAnnouncements(filters);
            } else {
                loadMoreAnnouncements(filters);
            }
        }
    };

    return (
        <Box
            display="flex"
            height="100%"
            flexDirection="column"
            flexWrap="nowrap"
            position="relative"
        >
            <Switch>
                <Route path={`${ANNOUNCEMENTS_PATH}${UNOTHAURIZED_PATH}`}>
                    <UnauthorizedPage />
                </Route>
                <Route exact path={ANNOUNCEMENTS_PATH}>
                    <PerfectScroll
                        onScrollDown={(props) => {
                            handleScrollDown(
                                props,
                                announcement?.announcementLoadable,
                                {
                                    ...announcement.filters,
                                    announcementPage: announcement.filters.announcementPage + 1,
                                },
                                false
                            );
                        }}
                    >
                        <Box p={5}>
                            <TitleLine
                                title="Liste des annonces"
                                viewLabel="Mes annonces"
                                viewPath={MY_ANNOUNCEMENTS_PATH}
                            />
                            <AnnouncementsOptions
                                categoriesList={typeList}
                                filters={announcement?.filters}
                                changeFilters={changeFilter}
                                resultNumber={announcement?.announcementsInfos?.total || 0}
                                displayMode={displayMode}
                                setDisplayMode={setDisplayMode}
                            />
                            <AnnouncementsList
                                list={announcement.announcementsList}
                                profileId={profile?.profile?.id}
                                basicPath={ANNOUNCEMENTS_PATH}
                                displayMode={displayMode}
                                filters={announcement?.filters}
                                changeFilters={changeFilter}
                                deleteAnnouncement={setDeletePopine}
                                path={ANNOUNCEMENTS_PATH}
                                loading={announcement.loading && !announcement.error}
                                profile={profile}
                            />
                        </Box>
                    </PerfectScroll>
                </Route>

                <Route exact path={MY_ANNOUNCEMENTS_PATH}>
                    <PerfectScroll
                        onScrollDown={(props) => {
                            handleScrollDown(
                                props,
                                announcement?.myAnnouncementLoadable,
                                {
                                    ...announcement.filters,
                                    myAnnouncementPage: announcement.filters.myAnnouncementPage + 1,
                                },
                                true
                            );
                        }}
                    >
                        <Box p={5}>
                            <TitleLine
                                title="Mes annonces"
                                viewLabel="Liste des annonces"
                                viewPath={ANNOUNCEMENTS_PATH}
                            />
                            <AnnouncementsOptions
                                categoriesList={typeList}
                                filters={announcement?.filters}
                                changeFilters={changeFilter}
                                resultNumber={announcement?.myAnnouncementsInfos?.total || 0}
                                displayMode={displayMode}
                                setDisplayMode={setDisplayMode}
                            />
                            <AnnouncementsList
                                list={announcement.myAnnouncementsList}
                                profileId={profile?.profile?.id}
                                basicPath={MY_ANNOUNCEMENTS_PATH}
                                displayMode={displayMode}
                                filters={announcement?.filters}
                                changeFilters={changeFilter}
                                deleteAnnouncement={setDeletePopine}
                                path={MY_ANNOUNCEMENTS_PATH}
                                loading={announcement.loading && !announcement.error}
                                profile={profile}
                            />
                        </Box>
                    </PerfectScroll>
                </Route>

                <Route exact path={`${ANNOUNCEMENTS_PATH}${CREATE_PATH}`}>
                    <RestrictedRoute profile={profile} baseUrl={ANNOUNCEMENTS_PATH}>
                        <Box p={5} className="fullHeight" display="flex" flexDirection="column">
                            <EditTitleLine title={"Rédiger une annonce"} />
                            <CreateAnnouncementsApp
                                profile={profile}
                                submit={handleSubmit}
                                disconnect={disconnect}
                                cancel={() => history.goBack()}
                            />
                        </Box>
                    </RestrictedRoute>
                </Route>

                <Route
                    exact
                    path={[
                        `${ANNOUNCEMENTS_PATH}/:id${EDIT_PATH}`,
                        `${MY_ANNOUNCEMENTS_PATH}/:id${EDIT_PATH}`,
                    ]}
                    children={({ match }) => (
                        <RestrictedRoute profile={profile} baseUrl={ANNOUNCEMENTS_PATH}>
                            <Box p={5} className="fullHeight" display="flex" flexDirection="column">
                                <EditTitleLine title={"Modifier une annonce"} />
                                <EditAnnouncementsApp
                                    announcementId={match?.params?.id}
                                    announcementTypeList={typeList}
                                    profile={profile}
                                    submit={(data) => handleUpdate(match?.params?.id, data)}
                                    cancel={() => history.goBack()}
                                />
                            </Box>
                        </RestrictedRoute>
                    )}
                />

                <Route
                    exact
                    path={[`${ANNOUNCEMENTS_PATH}/:id`, `${MY_ANNOUNCEMENTS_PATH}/:id`]}
                    children={({ match }) => (
                        <RestrictedRoute profile={profile} baseUrl={ANNOUNCEMENTS_PATH}>
                            <AnnouncementDetail
                                id={match?.params?.id}
                                profileId={profile?.profile?.id}
                                deleteAnnouncement={setDeletePopine}
                                match={match}
                                basicPath={match.path.replace("/:id", "")}
                            />
                        </RestrictedRoute>
                    )}
                />
            </Switch>

            <CmtDeleteDialog
                open={Boolean(deletePopine)}
                onCancel={() => setDeletePopine(null)}
                onDelete={() => {
                    deleteAnnouncement(deletePopine.id);
                    setDeletePopine(null);

                    history.push(deletePopine.basicPath);
                }}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        Êtes-vous sûr de vouloir supprimer cette annonce ?
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>
        </Box>
    );
}

export const AnnouncementsAppStore = connect(
    (state) => ({
        announcement: announcementsListSelector(state),
        profile: profileSelector(state),
    }),
    (dispatch) => ({
        getAnnouncementsList: () => dispatch(getFilteredAnnouncementAction()),
        disconnect: () => dispatch(notConnectedAction()),
        createAnnouncement: (value) => dispatch(createAnnouncementAction(value)),
        updateAnnouncement: (id, values) => dispatch(updateAnnouncementAction(id, values)),
        deleteAnnouncement: (id) => dispatch(deleteAnnouncementAction(id)),
        changeFilter: (filter) => dispatch(changeAnnouncementsFiltersAction(filter)),
        loadMoreAnnouncements: (filter) => dispatch(loadMoreAnnouncementsAction(filter)),
        loadMoreMyAnnouncements: (filter) => dispatch(loadMoreMyAnnouncementsAction(filter)),
    })
)(AnnouncementsApp);
