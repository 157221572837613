import { Box, Button, CardContent, Hidden } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function EditionAddress ({companyData, submit, setEditionMode}) {
    const checkError = (values) => {
        const errors = {};

        
        return errors;
    };

    return (
        <CmtCard>
            <TileTitle>Adresse</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{
                        address1: companyData?.address1,
                        address2: companyData?.address2 || '',
                        zipCode: companyData?.zipCode,
                        city: companyData?.city,
                    }}
                    validate={values => checkError(values)}
                    onSubmit={(values) => {
                        submit(values);
                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <FormInput
                                label="Adresse 1"
                                name="address1"
                                id='address1'
                                fullWidth={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address1} 
                                error={touched.address1 && errors.address1}
                            />

                            <FormInput
                                label="Adresse 2"
                                name="address2"
                                id='address2'
                                fullWidth={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address2} 
                                error={touched.address2 && errors.address2}
                            />
                            
                            <FormInput
                                label="Code postal"
                                name="zipCode"
                                id='zipCode'
                                fullWidth={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.zipCode} 
                                error={touched.zipCode && errors.zipCode}
                            />
                            
                            <FormInput
                                label="Ville"
                                name="city"
                                id='city'
                                fullWidth={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city} 
                                error={touched.city && errors.city}
                            />
                            
                            <Box width='100%' display='flex' justifyContent='flex-end' mt={3} height={30}>
                                <Box mr={2}>
                                    <Button type='button' onClick={() => setEditionMode(false)} variant='contained' color='secondary'>
                                        <Hidden xsDown> Annuler </Hidden>
                                        <Hidden smUp> <CloseIcon /> </Hidden>
                                    </Button>
                                </Box>

                                <Box ml={2}>
                                    <Button type='submit' color='primary' variant='contained' id='contactSubmitButton'>
                                        <Hidden xsDown> Valider </Hidden>
                                        <Hidden smUp> <CheckIcon /> </Hidden>
                                    </Button>
                                </Box>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    );
};