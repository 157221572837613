import { Box, Grid } from "@mui/material";
import CmtGridContainer from 'Components/CmtGridContainer/CmtGridContainer';
import { ChangeEmail } from "./ChangeEmail";
import { ChangePassword } from "./ChangePassword";
import { DeleteAccount } from "./DeleteAccount";

export const SecurityTab = ({updateProfile, deleteMyAccount}) => {
    return (
        <CmtGridContainer justifyContent='center' spacing={0}>
            <Grid item xs={12} lg={6}>
                <Box p={2}>
                    <ChangeEmail submit={updateProfile} />
                </Box>
            </Grid>
            <Grid item xs={12} lg={6} >
                <Box p={2}>
                    <ChangePassword submit={updateProfile} />
                </Box>
            </Grid>
            <Grid item xs={12} lg={6} >
                <Box p={2}>
                    <DeleteAccount submit={updateProfile} deleteMyAccount={deleteMyAccount} />
                </Box>
            </Grid>
        </CmtGridContainer>
    );
};