import { Box, Hidden, Typography } from "@mui/material";
import CmtBreadCrumbs from "Components/CmtBreadCrumbs/CmtBreadCrumbs";

export default function CmtAppHeader ({title, threadList}) {
    return (
        <Box
            pb={5}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
        >
            <Typography component="p" variant='h1' >
                {title}
            </Typography>

            <Hidden smDown>
                {threadList?.length > 0 && 
                    <Box ml='auto'>
                        <CmtBreadCrumbs items={threadList} />
                    </Box>
                }
            </Hidden>
        </Box>
    );
};