import { Typography, Box } from "@mui/material";
import CmtLoadingPage from "Components/CmtLoadingPage/CmtLoadingPage";
import { REDIRECTION_TIME, SIGNIN_PATH } from "Constant";
import { useState, useEffect } from "react";
import Auth from "services/Api/auth";
import history from "services/History/history";

export default function ValidEmail () {
    const [result, setResult] = useState(null);

    const sendValidation = async (email, token) => {
        const res = await Auth.validEmail(email, token);

        setResult(res);

        if (res.result) {
            setTimeout(() => {history.push(SIGNIN_PATH)}, REDIRECTION_TIME);
        }
    };

    useEffect(() => {
        const queyString = window.location.search;
        const urlParams = new URLSearchParams(queyString);

        const email = urlParams.get('email');
        const token = urlParams.get('token');

        sendValidation(email, token);
    }, []);
    
    if (result === null) {
        return (<CmtLoadingPage/>);
    }
    
    if (result.result === false) {
        return (
            <Box height='100vh' width='100%' display='flex' justifyContent='center' alignItems='center'>
                <Typography component="p" variant='h1'>{result.error.message}</Typography>
            </Box>
        );
    }
    
    if (result.result) {
        return (
            <Box height='100vh' width='100%' display='flex' justifyContent='center' alignItems='center'>
                <Typography component="p" variant='h1'>Votre adresse mail à été vérifié, vous allez à présent être redirigé !</Typography>
            </Box>
        );
    }
};