import { Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { useState } from "react";
import { AddIconButton } from "./sc.TodoList";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { TodoElem } from "./TodoElem";
import { AddTodo } from "./AddTodo";

export const TodoList = ({ projectData, updateProject }) => {
    const [openAdd, setOpenAdd] = useState(false);

    const handleMoveTask = (result) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination?.index === source?.index) {
            return;
        }

        let projectChecks = projectData?.projectChecks;

        projectChecks.splice(
            result?.destination.index,
            0,
            ...projectChecks.splice(result?.source?.index, 1)
        );

        for (let a = 0; a < projectChecks.length; a++) {
            projectChecks[a].position = a + 1;
        }

        updateProject({ projectChecks: projectChecks });
    };

    const handleCheckTask = (taskId, isDone) => {
        let projectChecks = projectData?.projectChecks;

        const index = projectChecks.findIndex((item) => item.id === taskId);
        projectChecks[index].isDone = isDone;

        updateProject({ projectChecks: projectChecks });
    };

    const handleDeleteLine = (projectId) => {
        let projectChecks = projectData?.projectChecks?.filter((elem) => elem.id !== projectId);

        updateProject({ projectChecks: projectChecks });
    };

    const handleUpdateTask = (value) => {
        let projectChecks = projectData?.projectChecks;

        const index = projectChecks.findIndex((item) => item.id === value.id);

        projectChecks[index] = value;

        const result = updateProject({ projectChecks: projectChecks }).then((result) => {
            return result;
        });

        return result;
    };

    return (
        <CmtCard>
            <Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ m: 4 }}
                >
                    <Typography component="p">Todo globale du projet</Typography>
                    <AddIconButton
                        color="primary"
                        size="small"
                        onClick={() => setOpenAdd(true)}
                        id="openAddTaskButton"
                    >
                        <AddIcon />
                    </AddIconButton>
                </Box>

                {openAdd && (
                    <AddTodo
                        projectData={projectData}
                        updateProject={updateProject}
                        setOpenAdd={setOpenAdd}
                    />
                )}

                {projectData?.projectChecks?.length > 0 && (
                    <DragDropContext
                        onDragEnd={(result) => {
                            handleMoveTask(result);
                        }}
                    >
                        <Box>
                            <Droppable droppableId="taskList">
                                {(provided) => (
                                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                                        {projectData?.projectChecks?.map((item, index) => (
                                            <TodoElem
                                                item={item}
                                                index={index}
                                                key={index}
                                                checkItem={(isDone) =>
                                                    handleCheckTask(item.id, isDone)
                                                }
                                                deleteLine={() => handleDeleteLine(item?.id)}
                                                updateTask={handleUpdateTask}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Box>
                    </DragDropContext>
                )}
            </Box>
        </CmtCard>
    );
};
