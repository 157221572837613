import { useTheme } from "@emotion/react";
import { PublicTypo } from "../sc.Public";

export const TextBlock = ({ title, text }) => {
    const theme = useTheme();

    return (
        <>
            <PublicTypo
                family={theme.palette.publicTypo.hFamily}
                size={theme.palette.publicTypo.h2Size}
                color="primary"
            >
                {title}
            </PublicTypo>

            <PublicTypo
                component="p"
                family={theme.palette.publicTypo.pFamily}
                size={theme.palette.publicTypo.p2Size}
                pt={3}
            >
                {text}
            </PublicTypo>
        </>
    );
};
