import { HUB_URL } from "Constant";

const connexion = {
    connectToRealTimeServer: async ({ profileId, next }) => {
        const url = new URL(HUB_URL);

        url.searchParams.append("topic", `/notification/{userId}`);
        url.searchParams.append("topic", `/chat/{userId}`);
        url.searchParams.append("topic", "/.well-known/mercure/subscriptions/{topic}/{subscriber}");

        const eventSource = new EventSource(url, { withCredentials: true });

        eventSource.onmessage = (e) =>
            next({
                profileId: profileId,
                message: e,
            });

        return { result: true, data: eventSource };
    },
};

export default connexion;
