import { Grid } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { Formik } from "formik";
import { StepperLine } from "../StepperLine";
import { Box } from "@mui/system";


export const Step7 = ({stepper, profile, cancel, submit, data}) => {
    const checkError = (values) => {
        const errors = {};

        if (!values.phoneNumber) {
            errors.phoneNumber = 'Veuillez renseigner un numéreo de téléphone';
        } else if (!/^0[1-9][0-9]{8}/i.test(values.phoneNumber)) {
            errors.phoneNumber = 'veuillez renseigner un numéro de téléphone valide';
        }

        if (!values.email) {
            errors.email = 'Veuillez renseigner une adresse email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Adresse email invalide';
        }

        return (errors);
    };

    return (
        <Grid item container xs={12} sm={5}>
            <Formik
                initialValues={{
                    email: data.email || profile?.profile?.contactEmail || '',
                    phoneNumber: data.phoneNumber || profile?.profile?.phoneNumber || '',
                }}
                validate={values => checkError(values)}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    
                    submit(values).then(() => setSubmitting(false))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                }) => (
                    <CmtForm onSubmit={handleSubmit} className='fullWidth'>
                        <CmtCard>
                            <Box p={5}>
                                <FormInput
                                    type="text"
                                    name="phoneNumber"
                                    label="Numéro de téléphone"
                                    id='announcementPhoneNumber'
                                    variant='standard'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phoneNumber} 
                                    error={touched.phoneNumber && errors.phoneNumber}
                                />
                                <FormInput
                                    type="text"
                                    name="email"
                                    label="Email"
                                    variant='standard'
                                    id='announcementEmail'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email} 
                                    error={touched.email && errors.email}
                                />
                            </Box>
                        </CmtCard>

                        <Box mt={4}>
                            <StepperLine
                                onCancel={cancel}
                                stepper={stepper}
                                nextButtonText='Créer'
                                step="Etape 7 : Informations de contact"
                            />
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        </Grid>
    );
};