import { Button } from "@mui/material";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@mui/system";
import { TextFieldRoot } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function EditionModeProjectInfos ({project, setEditionMode, submit}) {
    const checkError = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Veuillez renseigner votre prénom';
        }

        return errors;
    };

    return (
        <Formik
            initialValues={{
                name: project.name || '',
            }}
            validate={values => checkError(values)}
            onSubmit={(values) => {
                submit(values);
                setEditionMode(false);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
            }) => (
                <CmtForm onSubmit={handleSubmit}>
                    <Box display='flex' alignItems='flex-end' justifyContent='center'>
                        <TextFieldRoot
                            width='300px'
                            type="text"
                            name="name"
                            label="Nom du project"
                            id='projectName'
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name} 
                            error={touched.name && errors.name}
                        />
                        
                        <Button type='submit' >
                            <Box color='white'>
                                <CheckIcon/>
                            </Box>
                        </Button>
                    </Box>
                </CmtForm>
            )}
        </Formik>
    );
};