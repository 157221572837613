import React from "react";
import Box from "@mui/material/Box";
import { IncompleteProfile } from "Components/IncompletProfile/IncompleteProfile";

export const UnauthorizedPage = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <IncompleteProfile secondText={"Il vous manque les informations suivantes :"} />
        </Box>
    );
};
