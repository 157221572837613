import { Box } from "@mui/material";
import CmtUploadImage from "Components/CmtUploadImage/CmtUploadImage";
import { DEFAULT_COMPANIES_PIC } from "Constant";
import { ProjectHeaderInfos } from "./ProjectHeaderInfos/ProjectHeaderInfos";
import { ProfileHeaderRoot } from "Components/CmtProfileContainer/sc.ProfileContainer";

export const ProjectHeader = ({ project, updateProject }) => {
    if (!project) {
        return <></>;
    }

    return (
        <ProfileHeaderRoot display="flex" justifyContent="space-between">
            <Box position="absolute" left={0} top={0} width="100%" height="100%" minHeight={370}>
                <CmtUploadImage
                    filename={project?.coverFileName || "/images/profile-bg-img.png"}
                    submit={(image) =>
                        updateProject({
                            coverFile: image,
                        })
                    }
                />
            </Box>
            <Box position="relative" display="flex" width="fit-content" zIndex={3}>
                <Box
                    display="flex"
                    flexDirection={{ xs: "row", sm: "row" }}
                    alignItems="center"
                    marginRight={3}
                    height={80}
                    width={80}
                    flexShrink={0}
                    borderRadius="50%"
                    overflow="hidden"
                >
                    <CmtUploadImage
                        filename={project?.logoFileName || DEFAULT_COMPANIES_PIC}
                        submit={(image) =>
                            updateProject({
                                logoFile: image,
                            })
                        }
                        isAvatar={true}
                    />
                </Box>
                <ProjectHeaderInfos project={project} updateProject={updateProject} />
            </Box>
        </ProfileHeaderRoot>
    );
};
