import { AppBar, Button, IconButton, TextField } from "@mui/material";
import { Box, styled } from "@mui/system";

export const MenuSearchBar = styled(TextField)`
    height: 30px;
    width: 200px;
    & .MuiInputBase-root {
        padding-left: 10px;
        color: white;
        height: 30px;
        border-radius: 30px;
        border: 2px solid rgba(255, 255, 255, 1);
        & .MuiSvgIcon-root {
            color: white;
        }
    }
`;

export const MenuButtonLink = styled(Button)`
    font-family: "Helvetica";
    text-transform: none;
    padding: 0;
    font-size: 20px;
    font-weight: 300;
    min-width: 150px;
    border-bottom: 2px solid rgba(255, 255, 255, 1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const FloatingMenuAppBar = styled(AppBar)`
    position: absolute;
    overflow: hidden;
    right: 3.1rem;
    top: 2.8rem;
    height: 100px;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    transition: 1s;
    width: ${(props) => (props.open ? "77%" : "100px")};
    box-shadow: 0 0 0 0;
    z-index: 20;
`;

export const BurgerButton = styled(IconButton)`
    height: 104px;
    width: 104px;
    border: ${(props) => `3px solid ${props.theme.palette.primary.publicMain}`};
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: ${(props) => props.theme.palette.primary.main};

    &:hover {
        background-color: ${(props) => props.theme.palette.primary.main};
    }

    & .MuiSvgIcon-root {
        color: rgb(255, 255, 255, 1);
        font-size: 70px;
    }
`;

export const MenuContainer = styled(Box)`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    height: 80px;
    transition: 1s;
    z-index: 20;
`;

export const MenuAppBar = styled(Box)`
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    background-color: ${(props) => props.theme.palette.primary.main};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    transition: 0.5s;
    padding-block: 10px;
    width: ${(props) => (props.open ? "100%" : "0px")};
`;

export const MenuBurgerButton = styled(IconButton)`
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
    z-index: 10;
    background-color: ${(props) => props.theme.palette.primary.publicMain};
    &:hover {
        background-color: ${(props) => props.theme.palette.primary.publicMain};
    }
`;
