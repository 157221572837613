import { Box } from "@mui/material";
import { useState } from "react";
import { Step1 } from "./CreationStep/Step1/Step1";
import { Step2 } from "./CreationStep/Step2/Step2";
import { Step3 } from "./CreationStep/Step3/Step3";
import { Step4 } from "./CreationStep/Step4/Step4";
import { Step5 } from "./CreationStep/Step5/Step5";
import { Step6 } from "./CreationStep/Step6/Step6";
import { Step7 } from "./CreationStep/Step7/Step7";
import { ProgessBar } from "./sc.ProcessAnnouncement";


const components = [
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
];

export const CreateAnnouncementsApp = ({profile, cancel, submit}) => {
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({});

    const SpecificStep = components[step];

    const Stepper = () => {
        return (
            <ProgessBar
                variant="progress"
                steps={7}
                activeStep={step}
            />
        );
    }

    return (
        <Box display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
            <SpecificStep 
                profile={profile}
                data={formData}
                stepper={Stepper}
                cancel={() => {
                    if (step > 0) {
                        setStep(step - 1);
                    } else {
                        cancel();
                    }
                }}
                submit={async (values) => {
                    const data = {...formData, ...values};
                    
                    if (step < 6) {
                        setStep(step + 1);
                    } else {
                        await submit(data);
                    }
                    
                    setFormData(data);
                }}
            />
        </Box>
    );
};