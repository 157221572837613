import { Box, Button, Grid, Hidden, Typography, alpha } from "@mui/material";
import FormInput from "../../../Components/CmtFormInput/CmtFormInput.jsx";
import { SIGNIN_PATH } from "Constant";
import CmtImage from "Components/CmtImage/CmtImage.jsx";
import CmtForm from "Components/CmtForm/CmtForm.jsx";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { CmtDatePicker } from "Components/CmtDatePicker/CmtDatePicker.jsx";
import moment from "moment";
import { useTheme } from "@emotion/react";

export default function SignUpForm({ onRegister }) {
    const theme = useTheme();
    const limitDate = moment().subtract(18, "year");

    const checkError = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = "Veuillez renseigner un nom";
        }

        if (!values.email) {
            errors.email = "Veuillez renseigner une addresse Email";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Adresse email invalide";
        }

        if (!values.password) {
            errors.password = "Veuillez renseigner un mot de passe";
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = "Veuillez confirmer votre mot de passe";
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = "Vos champs ne sont pas identique";
        }

        if (!values.birthday) {
            errors.birthday = "Veuillez indiquer votre date de naissance";
        } else if (moment(values.birthday).isAfter(limitDate)) {
            errors.birthday = "Vous devez avoir plus de 18 ans pour pouvoir vous inscrire";
        }

        return errors;
    };

    return (
        <>
            <Box p={8} width="100%">
                <Box mb={7}>
                    <CmtImage src={"/images/logo.svg"} height={50} />
                </Box>
                <Typography component="div" variant="h1" color="primary">
                    Créer un compte
                </Typography>
                <Formik
                    initialValues={{
                        firstName: "",
                        email: "",
                        password: "",
                        confirmPassword: "",
                        birthday: null,
                    }}
                    validate={(values) => checkError(values)}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        onRegister({
                            ...values,
                            birthday: moment(values.birthday).format("YYYY-MM-DD"),
                        }).then(() => setSubmitting(false));
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <FormInput
                                type="email"
                                name="email"
                                label="Email"
                                variant="standard"
                                id="signUpEmail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && errors.email}
                            />

                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <FormInput
                                        type="password"
                                        name="password"
                                        label="Mot de passe"
                                        variant="standard"
                                        id="signUpPassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        error={touched.password && errors.password}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <FormInput
                                        type="password"
                                        name="confirmPassword"
                                        label="Confirmation mot de passe"
                                        id="signUpConfirmPassword"
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        error={touched.confirmPassword && errors.confirmPassword}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <FormInput
                                        type="text"
                                        name="firstName"
                                        label="Prénom"
                                        margin="none"
                                        variant="standard"
                                        id="signUpFirstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        error={touched.firstName && errors.firstName}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <CmtDatePicker
                                        disableFuture
                                        fullWidth
                                        openToYear
                                        initialFocusedDate={moment().subtract(18, "year")}
                                        value={values.birthday}
                                        setValue={(value) => {
                                            setFieldValue("birthday", value);
                                        }}
                                        onTouched={setFieldTouched}
                                        label="Date de naissance"
                                        id="signUpBirthday"
                                        error={touched.birthday && errors.birthday}
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                mt={10}
                                mb={5}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    id="signUpButton"
                                >
                                    <Typography component="p">S'inscrire</Typography>
                                </Button>

                                <NavLink to={SIGNIN_PATH}>
                                    <Typography component="p">J'ai déjà un compte</Typography>
                                </NavLink>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </Box>
            <Hidden smDown>
                <Box
                    p={5}
                    width="50%"
                    flexShrink={0}
                    backgroundColor={alpha(theme.palette.primary.main, 0.12)}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CmtImage src="/images/Auth/sign-up-img.png" />
                </Box>
            </Hidden>
        </>
    );
}
