import { CardContent, FormHelperText, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import { ProjectEditor } from "Apps/Project/sc.CreateProject";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import CmtEditor from "Components/CmtEditor/CmtEditor";

export const CreateProjectDescription = ({
    setFieldTouched,
    setFieldValue,
    values,
    touched,
    errors,
}) => {
    return (
        <Box p={2}>
            <CmtCard>
                <TileTitle> Description </TileTitle>
                <CardContent>
                    <InputLabel id="description">Description</InputLabel>
                    <ProjectEditor>
                        <CmtEditor
                            labelId="description"
                            value={values.description}
                            onBlur={() => setFieldTouched("description", true, false)}
                            onChange={(val) => {
                                setFieldValue("description", val);
                            }}
                        />
                        <FormHelperText error>
                            {touched.description && errors.description}
                        </FormHelperText>
                    </ProjectEditor>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
