import { Chip, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { useState } from "react";
import FavoriteIcon from '@mui/icons-material/Favorite';

export const AnnouncementFavoriteOption = ({filters, changeFilters}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <Box mx={1} py={2}>
            <Box display='flex' alignItems='center' justifyContent='center' className='fullHeight'>
                <Chip
                    variant={filters?.favorite ? 'default' : 'outlined'}
                    icon={<FavoriteIcon/>}
                    size='medium'
                    label={'Favoris'}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    onDelete={filters?.favorite ? (() => changeFilters({...filters, favorite: false})) : null}
                />
            </Box>

            <CmtPopover
                anchorEl={anchorEl}
                closePopover={() => setAnchorEl(null)}
            >
                <Box p={5} display='flex' alignItems='center'>
                    <Switch checked={filters.favorite} onChange={(e) => changeFilters({...filters, favorite: e.target.checked})} />
                    <Typography component='p'>Afficher uniquement les favoris</Typography>
                </Box>
            </CmtPopover>
        </Box>
    );
};