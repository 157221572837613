import { createContext, useContext } from "react";

export const MapContext = createContext({});

export const MapControl = ({control, onSelect, initValue = ''}) => {
    const { mapRef } = useContext(MapContext);

    const { map } = mapRef.current.state;

    if (map._controls?.length >= 3) {
        return null;
    }

    
    map.addControl(control, 'top-left');

    if (initValue) {
        control.setInput(initValue);
    }
    
    control.on('result', function (e) {
        onSelect(e);
    });

    return null;
};
