import { DISCONNECT, NOT_CONNECTED } from "redux/constants/authConstants";
import {
    REQUEST_USERS_SUCCESS,
    REQUEST_CONVERSATION_SUCCESS,
    UPDATE_FILTER,
    UPDATE_STATUS,
    ONLINE_STATUS,
    REQUEST_USERS,
    REQUEST_USERS_ERROR,
    SET_CURRENT_USER,
    REQUEST_SEND_MESSAGE_SUCCESS,
    REQUEST_CONVERSATION,
    REQUEST_CONVERSATION_ERROR,
    REQUEST_TO_REAL_TIME,
    REQUEST_TO_REAL_TIME_SUCCESS,
    REQUEST_TO_REAL_TIME_ERROR,
    REQUEST_DELETE_MESSAGE_SUCCESS,
    SUBSCRIPTION_REAL_TIME,
    RECEIVE_MESSAGE,
    CHANGE_IS_TYPING,
    DELETE_MESSAGE,
    READED_MESSAGE,
} from "../constants/chatConstants";

const INIT_STATE = {
    users: null,
    status: ONLINE_STATUS,
    eventSource: null,
    conversation: [],
    currentUser: null,
    loadableMessage: true,
    filter: "",
    loading: false,
    error: null,
};

const addMessages = (state, action) => {
    if (
        state?.currentUser?.id !== action?.message?.userFrom?.id &&
        state?.currentUser?.id !== action?.message?.userTo?.id
    ) {
        return state;
    }

    if (!action?.message?.messageText) {
        return {
            ...state,
            conversation: [...state.conversation, action.message],
            loading: action.loading,
        };
    }

    let userList = state.users;

    const userListIndex = userList.findIndex((element) => {
        return action?.message?.userTo?.id === element.id;
    });

    if (userListIndex === -1) {
        return;
    }

    userList[userListIndex].lastMessage = action.message;
    userList[userListIndex].lastMessage.messageRead = true;

    let currentUser = state.currentUser;

    if (currentUser?.id === userList[userListIndex]?.id) {
        currentUser.lastMessage = action.message;
        currentUser.lastMessage.messageRead = true;
    }

    return {
        ...state,
        conversation: [...state.conversation, action.message],
        users: [...userList],
        currentUser: { ...currentUser },
        loading: action.loading,
    };
};

const deleteMessage = (state, action) => {
    if (action.userId !== state.currentUser.id) {
        return state;
    }

    const conversation = state.conversation;

    const messageIndex = conversation.findIndex((element) => {
        return element.id === action.messageId;
    });

    if (messageIndex) {
        conversation.splice(messageIndex, 1);
    }

    return { ...state, conversation: [...conversation] };
};

const handleSubscriptionUser = (state, action) => {
    if (!action?.user?.payload?.userId || !state.users) {
        return state;
    }

    let userList = state.users;
    let currentUser = state.currentUser;

    const userListIndex = userList.findIndex((element) => {
        return element.id === action?.user?.payload?.userId;
    });

    if (userListIndex === -1) {
        return state;
    }

    userList[userListIndex].mercureId = action.user.active ? action.user.subscriber : null;

    if (!action?.user?.active) {
        userList[userListIndex].isTyping = false;

        if (currentUser?.id === userList[userListIndex]?.id) {
            currentUser.isTyping = false;
        }
    }

    return { ...state, users: [...userList], currentUser: { ...currentUser } };
};

const handleReceiveMessage = (state, action) => {
    let userList = state.users;

    const userIndex = userList.findIndex((element) => {
        return action?.message?.userFrom?.id === element.id;
    });

    userList[userIndex].lastMessage = action.message;
    userList[userIndex].lastMessage.messageRead = false;

    if (action?.message?.userFrom?.id !== state?.currentUser?.id) {
        return { ...state, users: [...userList] };
    }

    let currentUser = state.currentUser;

    currentUser.lastMessage = action?.message;
    currentUser.lastMessage.messageRead = false;

    const conversation = state.conversation;

    conversation.push(action.message);

    return {
        ...state,
        conversation: [...conversation],
        users: [...userList],
        currentUser: { ...currentUser },
    };
};

const handleTyping = (state, action) => {
    let userList = state.users;
    let currentUser = state.currentUser;

    const userListIndex = userList.findIndex((element) => {
        return element.id === action.typing.senderId;
    });

    userList[userListIndex].isTyping = action?.typing?.isTyping;

    if (userList[userListIndex]?.id === currentUser?.id) {
        currentUser.isTyping = action?.typing?.isTyping;
    }

    return { ...state, users: [...userList], currentUser: { ...currentUser } };
};

const handleDeleteMessage = (state, action) => {
    if (action?.message?.userFrom?.id !== state?.currentUser?.id) {
        return state;
    }

    let conversation = state.conversation;

    const messageIndex = conversation.findIndex((element) => {
        return element?.id === action?.message?.id;
    });

    if (messageIndex === -1) {
        return state;
    }

    conversation.splice(messageIndex, 1);

    return { ...state, conversation: [...conversation] };
};

const readedMessage = (state) => {
    let currentUser = state.currentUser;

    if (!currentUser?.lastMessage) {
        return state;
    }

    currentUser.lastMessage.messageRead = true;

    let userList = state.users;

    const userIndex = userList.findIndex((element) => {
        return element?.id === currentUser?.id;
    });

    userList[userIndex].lastMessage.messageRead = true;

    return { ...state, currentUser: { ...currentUser }, userList: [...userList] };
};

export default function chatReducer(state = INIT_STATE, action = {}) {
    switch (action.type) {
        case REQUEST_USERS:
            return { ...state, loading: action.loading };
        case REQUEST_USERS_SUCCESS:
            return { ...state, loading: action.loading, users: [...action.userList] };
        case REQUEST_USERS_ERROR:
            return { ...state, loading: action.loading, error: action.error };
        case REQUEST_CONVERSATION:
            return { ...state, loading: action.loading };
        case REQUEST_CONVERSATION_SUCCESS:
            return {
                ...state,
                conversation: [...action.messages, ...state.conversation],
                loadableMessage: action.messages.length < 20 ? false : true,
                loading: action.loading,
            };
        case REQUEST_CONVERSATION_ERROR:
            return { ...state, loading: action.loading, error: action.error };
        case UPDATE_FILTER:
            return { ...state, filter: action.filter };
        case UPDATE_STATUS:
            return { ...state, status: action.status };
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.currentUser,
                loadableMessage: true,
                conversation: [],
            };
        case REQUEST_SEND_MESSAGE_SUCCESS:
            return addMessages(state, action);
        case REQUEST_TO_REAL_TIME:
            return { ...state, loading: action.loading };
        case REQUEST_TO_REAL_TIME_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                error: null,
                eventSource: action.eventSource,
            };
        case REQUEST_TO_REAL_TIME_ERROR:
            return { ...state, loading: action.loading, error: action.error };
        case REQUEST_DELETE_MESSAGE_SUCCESS:
            return deleteMessage(state, action);
        case SUBSCRIPTION_REAL_TIME:
            return handleSubscriptionUser(state, action);
        case RECEIVE_MESSAGE:
            return handleReceiveMessage(state, action);
        case CHANGE_IS_TYPING:
            return handleTyping(state, action);
        case DELETE_MESSAGE:
            return handleDeleteMessage(state, action);
        case READED_MESSAGE:
            return readedMessage(state);
        case DISCONNECT || NOT_CONNECTED:
            return { ...INIT_STATE };
        default:
            return state;
    }
}
