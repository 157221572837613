import { useTheme } from "@emotion/react";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PublicMenu } from "Components/Public/Menu/PublicMenu";
import { Footer } from "../Partial/Footer";
import { ParagraphText } from "../Partial/ParagraphText";
import { TitleText } from "../Partial/TitleText";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CallIcon from "@mui/icons-material/Call";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";

export const Contact = () => {
    const theme = useTheme();

    return (
        <Box backgroundColor={theme.palette.primary.publicMain} pt={30}>
            <PublicMenu />

            <Container maxWidth="lg">
                <TitleText>Nous contacter</TitleText>

                <Grid container pb={20}>
                    <Grid item container xs={12} lg={6}>
                        <ParagraphText pr={10}>
                            Molupta dia dolestrum laborio commolu ptaest et acera et erit pro magnis
                            illabores consequia ipsam ditatest, con peritia taestiore, optatur
                            sequisi bearumende maximporis dole.
                        </ParagraphText>

                        <Grid item xs={12} lg={7}>
                            <Box>
                                <TitleText
                                    mt={10}
                                    borderBottom={`1px solid ${theme.palette.primary.main}`}
                                    color="primary"
                                    fontSize={theme.palette.publicTypo.p2Size}
                                    pb={5}
                                    pl={5}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <AlternateEmailIcon sx={{ mr: 5 }} />
                                    impulscene@bcubix.com
                                </TitleText>

                                <TitleText
                                    mt={5}
                                    borderBottom={`1px solid ${theme.palette.primary.main}`}
                                    color="primary"
                                    fontSize={theme.palette.publicTypo.p2Size}
                                    pb={5}
                                    pl={5}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <CallIcon sx={{ mr: 5 }} />
                                    01.23.45.67.89
                                </TitleText>

                                <ParagraphText
                                    color="primary"
                                    fontSize={theme.palette.publicTypo.p3Size}
                                >
                                    Vous pouvez nous contacter par courrier à l'adresse suivante:
                                </ParagraphText>
                                <TitleText
                                    mt={5}
                                    borderBottom={`1px solid ${theme.palette.primary.main}`}
                                    color="primary"
                                    fontSize={theme.palette.publicTypo.p2Size}
                                    pb={5}
                                    pl={5}
                                    display="flex"
                                    alignItems="center"
                                    lineHeight={1}
                                    fontWeight="500"
                                >
                                    <CallIcon sx={{ mr: 5 }} />
                                    <Box>
                                        <Box>BCubix</Box>
                                        <Box>1 rue des Déchargeurs</Box>
                                        <Box>75001 Paris</Box>
                                    </Box>
                                </TitleText>

                                <Box display="flex" pt={15} justifyContent="center">
                                    <Box
                                        component="img"
                                        src={"/images/Public/Images/facebook.svg"}
                                        height="3em"
                                        mx={2}
                                    />
                                    <Box
                                        component="img"
                                        src={"/images/Public/Images/instagram.svg"}
                                        height="3em"
                                        mx={2}
                                    />
                                    <Box
                                        component="img"
                                        src={"/images/Public/Images/linkedin.svg"}
                                        height="3em"
                                        mx={2}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Box position="relative" px={{ xs: 0, lg: 25 }}>
                            <Box pt={5} position="relative">
                                <Box display={{ xs: "none", lg: "block" }}>
                                    <ShapeImg
                                        src="/images/Public/Shape/Shape8.png"
                                        top="100px"
                                        left={-75}
                                    />
                                    <ShapeImg
                                        src="/images/Public/Shape/Shape2.png"
                                        top="210px"
                                        left={-75}
                                    />
                                    <ShapeImg
                                        src="/images/Public/Shape/Shape12.png"
                                        bottom="100px"
                                        left={-50}
                                    />
                                    <ShapeImg
                                        src="/images/Public/Shape/Shape20.png"
                                        bottom="200px"
                                        right={-75}
                                        rotate="90deg"
                                    />
                                    <ShapeImg
                                        src="/images/Public/Shape/Shape20.png"
                                        bottom="100px"
                                        right={-75}
                                        rotate="90deg"
                                    />
                                    <ShapeImg
                                        src="/images/Public/Shape/Shape10.png"
                                        bottom="300px"
                                        right={-75}
                                        rotate="-90deg"
                                    />
                                </Box>

                                <Box
                                    py={5}
                                    px={3}
                                    backgroundColor="#f8bc0c"
                                    position="relative"
                                    zIndex={10}
                                >
                                    <Box
                                        backgroundColor={theme.palette.primary.publicMain}
                                        border={`1px solid ${theme.palette.primary.main}`}
                                        p={10}
                                    >
                                        <Formik
                                            initialValues={{
                                                firstname: "",
                                                lastname: "",
                                                email: "",
                                                telephone: "",
                                                message: "",
                                            }}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(true);
                                                setTimeout(() => {
                                                    setSubmitting(false);
                                                }, 400);
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleSubmit,
                                                handleBlur,
                                                isSubmitting,
                                            }) => (
                                                <CmtForm onSubmit={handleSubmit}>
                                                    <FormInput
                                                        name="firstname"
                                                        label="Prénom"
                                                        variant="standard"
                                                        id="contactFirstName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.firstname}
                                                        error={
                                                            touched.firstname && errors.firstname
                                                        }
                                                    />

                                                    <FormInput
                                                        name="lastname"
                                                        label="Nom"
                                                        variant="standard"
                                                        id="contactLastName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.lastname}
                                                        error={touched.lastname && errors.lastname}
                                                    />

                                                    <FormInput
                                                        type="email"
                                                        name="email"
                                                        label="Email"
                                                        variant="standard"
                                                        id="contactEmail"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        error={touched.email && errors.email}
                                                    />

                                                    <FormInput
                                                        name="telephone"
                                                        label="Téléphone"
                                                        variant="standard"
                                                        id="contactTelephone"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.telephone}
                                                        error={
                                                            touched.telephone && errors.telephone
                                                        }
                                                    />

                                                    <TextField
                                                        id="contactTextarea"
                                                        sx={{ mt: 10 }}
                                                        name="message"
                                                        value={values.message}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Tapez un message..."
                                                        multiline
                                                        rows={5}
                                                        fullWidth={true}
                                                    />

                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        my={5}
                                                    >
                                                        <Button
                                                            id="loginButton"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                        >
                                                            <Typography>Envoyer</Typography>
                                                        </Button>
                                                    </Box>
                                                </CmtForm>
                                            )}
                                        </Formik>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    );
};
