import { LocalizationProvider, MobileTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";

export const CmtTimePicker = ({
    disableFuture = false,
    value,
    setValue,
    name,
    error = null,
    initialFocusedDate = null,
    label,
    onTouched = null,
    open = null,
    setOpen,
    ...rest
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileTimePicker
                disableFuture={disableFuture}
                initialFocusedDate={initialFocusedDate}
                allowKeyboardControl
                openTo={"hours"}
                toolbarTitle=""
                clearable
                name="name"
                label={label}
                value={value || null}
                onChange={(date) => {
                    setValue(date);
                }}
                autoOk
                onOpen={() => {
                    if (!onTouched) {
                        return;
                    }

                    onTouched(name, false, false);
                }}
                onClose={() => {
                    if (!onTouched) {
                        return;
                    }
                    onTouched(name, true, false);
                }}
                ampm={false}
                helperText={error}
                renderInput={(params) => {
                    params.error = Boolean(error);
                    params.label = label;

                    params.inputProps.value = value;
                    return (
                        <TextField
                            {...params}
                            fullWidth
                            variant="standard"
                            helperText={error}
                            type="text"
                            margin="none"
                            color="primary"
                        />
                    );
                }}
                error={Boolean(error)}
                {...rest}
            />
        </LocalizationProvider>
    );
};
