export const cmtUploadImageStyle = ({
    uploadContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
        width: '100%',
        height: '100%',
    },

    imageBox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        outline: 'none',
        transition: 'border 0.24s ease-in-out',
        cursor: 'pointer',
        position: 'relative',
        width: '100%',
        height: '100%',
        "&:before": {
            content: "''",
            width: '100%',
            height: '100%',
            transition: 'all 2s',
            backgroundColor: props => props.hover && "rgba(0, 0, 0, 0.2)",
            borderRadius: props => props.isAvatar && "50%",
        }
    },

    photoIcon: {
        position: 'absolute',
        color: 'rgba(255, 255, 255, 1)',
    },

    containerImage: {
        width: 80,
        height: 80,
        borderRadius: '50%',
    },

    imageAvatar: {
        borderRadius: '50%',
        backgroundsize: 'contain',
        width: 80,
        height: 80,
    }
});