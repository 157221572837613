import { Box } from "@mui/material";
import { RestrictedRoute } from "Components/ResctrictedRoute/RestrictedRoute";
import { UnauthorizedPage } from "Components/UnauthorizedPage/UnauthorizedPage";
import { DOCUMENTS_PATH, UNOTHAURIZED_PATH } from "Constant";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
    changeDocumentsFiltersAction,
    createFileFolderAction,
    deleteFileFolderAction,
    getDocumentsDataAction,
    moveFileFolderAction,
    updateFileFolderAction,
} from "redux/actions/documentsActions";
import { documentsListSelector } from "redux/selectors/documentsReducer";
import { profileSelector } from "redux/selectors/profileSelector";
import { DisplayDocuments } from "./DisplayDocuments/DisplayDocuments";

export const DocumentsApp = ({
    documentsData,
    profileData,
    getDocumentsData,
    createFileFolder,
    changeFilters,
    deleteFileFolder,
    moveFileFolder,
    updateFileFolder,
}) => {
    return (
        <Box
            display="flex"
            height="100%"
            flexDirection="column"
            flexWrap="nowrap"
            position="relative"
        >
            <Switch>
                <Route path={`${DOCUMENTS_PATH}${UNOTHAURIZED_PATH}`}>
                    <UnauthorizedPage />
                </Route>
                <Route
                    exact
                    path={[`${DOCUMENTS_PATH}`, `${DOCUMENTS_PATH}/:id`]}
                    children={({ match }) => (
                        <RestrictedRoute profile={profileData} baseUrl={DOCUMENTS_PATH}>
                            <DisplayDocuments
                                path={`${match?.params?.id ? `/${match?.params?.id}` : ""}`}
                                documentsData={documentsData}
                                getDocumentsData={getDocumentsData}
                                createFileFolder={createFileFolder}
                                changeFilters={changeFilters}
                                deleteFileFolder={deleteFileFolder}
                                moveFileFolder={moveFileFolder}
                                updateFileFolder={updateFileFolder}
                            />
                        </RestrictedRoute>
                    )}
                />
            </Switch>
        </Box>
    );
};

export const DocumentsAppStore = connect(
    (state) => ({
        documentsData: documentsListSelector(state),
        profileData: profileSelector(state),
    }),
    (dispatch) => ({
        getDocumentsData: (path) => dispatch(getDocumentsDataAction(path)),
        createFileFolder: (data) => dispatch(createFileFolderAction(data)),
        changeFilters: (data) => dispatch(changeDocumentsFiltersAction(data)),
        deleteFileFolder: (data) => dispatch(deleteFileFolderAction(data)),
        moveFileFolder: (list, newPath) => dispatch(moveFileFolderAction(list, newPath)),
        updateFileFolder: (data) => dispatch(updateFileFolderAction(data)),
    })
)(DocumentsApp);
