import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { styled } from '@mui/system';

SwiperCore.use([Navigation]);

export const SwiperContainer = styled(Swiper)`
    padding-inline: 20px;
    padding-block: 50px;
    display: flex;
    justify-content: center;
    & .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    };

    & .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 500px;
        padding-inline: 100px;
        ${props => props.theme.breakpoints.down("sm")} {
            padding-inline: 50px;
        };
        object-fit: contain;
    };

    & .swiper-button-next, .swiper-button-prev {
        color: ${props => props.theme.palette.primary.main};
    };
`;