import { Box, Button, Typography, alpha, Hidden } from "@mui/material";
import FormInput from '../../../Components/CmtFormInput/CmtFormInput.jsx';
import CmtImage from "Components/CmtImage/CmtImage.jsx";
import CmtForm from "Components/CmtForm/CmtForm.jsx";
import { Formik } from "formik";
import { useTheme } from "@emotion/react";


export default function ResetPasswordForm ({onReset}) {
    const theme = useTheme();

    const checkError = (values) => {
        let errors = {};

        if (!values.newPassword) {
            errors.newPassword = 'Veuillez renseigner un mot de passe';
        }

        return errors;
    };

    return (
        <>
            <Box p={8} width='100%' onClick={(e) => e.stopPropagation()}>
                <Box mb={7}>
                    <CmtImage src={'/images/logo.svg'} height={50} />
                </Box>
                <Typography component="div" variant="h1" color='primary'>Réinitialisez votre mot de passe</Typography>
                <Formik
                    initialValues={{newPassword: ''}}
                    validate={values => checkError(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        onReset(values);
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        isSubmitting,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <FormInput 
                                type="password"
                                name="newPassword"
                                label="Nouveau mot de passe"
                                value={values.newPassword} 
                                variant='standard'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.newPassword && errors.newPassword}
                            />
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                    <Typography component='p'>Réinitialiser</Typography>
                                </Button>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </Box>
            <Hidden smDown>
                <Box
                    p={5}
                    width='50%'
                    flexShrink={0}
                    backgroundColor={alpha(theme.palette.primary.main, 0.12)}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <CmtImage src="/images/Auth/forgot-img.png" />
                </Box>
            </Hidden>
        </>
    );
};


