import { Tab } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import history from "services/History/history";
import { NavMenuContainer } from "./sc.NavMenu";

const TabLink = (props) => {
    return (
        <Tab
            onClick={() => {
                history.push(props.value);
            }}
            {...props}
        />
    );
};

export const CmtNavMenu = ({ navList }) => {
    const [pathName, setPathName] = useState(window.location.pathname);

    useEffect(() => {
        const listen = history.listen(() => {
            setPathName(window.location.pathname);
        });

        return () => {
            listen();
        };
    }, []);

    return (
        <NavMenuContainer value={pathName}>
            {navList?.map((item, index) => (
                <TabLink label={item.label} value={item.value} key={index} />
            ))}
        </NavMenuContainer>
    );
};
