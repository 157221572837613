import { Checkbox, FormControl } from "@mui/material";
import { Box, styled } from "@mui/system";

export const CalendarCheckbox = styled(Checkbox)`
    color: ${(props) => props.calendarcolor || props.theme.palette.primary.main};
    & .Mui-checked {
        color: ${(props) => props.calendarcolor || props.theme.palette.primary.main};
    }
`;

export const CalendarEventEditorFormControl = styled(FormControl)`
    width: 100%;
    & .ck-editor__editable {
        width: 100%;
        min-height: 20vh;
        ${(props) => props.theme.breakpoints.down("sm")} {
            min-height: 20vh;
            height: 20vh;
        }
    }
`;

export const SidebarContainer = styled(Box)`
    width: 250px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-shrink: 0;
    transition: transform 2s;
    border-right: ${(props) => `1px solid ${props.theme.palette.borderColor.dark}`};
    box-shadow: ${(props) => props.theme.palette.sidebar.shadow};
    background-color: ${(props) => props.theme.palette.sidebar.bgColor};
    z-index: 5;
    ${(props) => props.theme.breakpoints.down("lg")} {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        transform: ${(props) => props.isCollapse && "translate(250px)"};
    } ;
`;
