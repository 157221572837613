export const network = {
    block1: {
        title: "Annuaire",
        text: "Estor arum sam el magnatur, sum, volutatur, qui dolorro cone et latus ra cuptatum, consequid utaspero beaque ende ipsam, totatiistia nihil modis erit labo. Itaquam, iusam aspel in rem ulpa doluptis et et hiliquam, sitate is rendunt.",
        image: "/images/Public/Network/Fichier1.png",
    },
    block2: {
        text: "Estor arum sam el magnatur, sum, volutatur, qui dolorro cone et latus ra cuptatum, consequid utaspero beaque ende ipsam, totatiistia nihil modis erit labo. Itaquam, iusam aspel in rem ulpa doluptis et et hiliquam, sitate is rendunt.",
    },
    block3: {
        text: "Estor arum sam el magnatur, sum, volutatur, qui dolorro cone et latus ra cuptatum, consequid utaspero beaque ende ipsam, totatiistia nihil modis erit labo. Itaquam, iusam aspel in rem ulpa doluptis et et hiliquam, sitate is rendunt.",
        image: "/images/Public/Network/Fichier2.png",
    },
    block4: {
        title: "Un développement exponentiel",
        text: "Estor arum sam el magnatur, sum, volutatur, qui dolorro cone et latus ra cuptatum, consequid utaspero beaque ende ipsam, totatiistia nihil modis erit labo. Itaquam, iusam aspel in rem utaspero beaque ende Itaquam, iusam aspel in rem utaspero",
        subtext: "Nos usagers doublent leur nombre de spectacle par année",
        image: "/images/Public/Network/Fichier3.png",
    },
    block5: {
        title: "Qu'en pensent les usagers ?",
        ratingBlock: [
            {
                image: "/images/Public/Images/P1032127.jpg",
                rating: 4,
                text: "« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »",
            },

            {
                image: "/images/Public/Images/P1032127.jpg",
                rating: 5,
                text: "« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »",
            },
        ],
    },
    block6: {
        title: "Exemples de profils",
        profileBlock: [
            {
                title: "1. Chat",
                text: "Estor arum sam el magnatur, sum, volutatur, qui dolorro cone et latus ra cuptatum, consequid utaspero beaque ende ipsam, totatiistia nihil modis erit labo. Itaquam, iusam aspel in rem ulpa doluptis et et hiliquam, sitate is rendunt.",
            },

            {
                title: "2. Chat",
                text: "Estor arum sam el magnatur, sum, volutatur, qui dolorro cone et latus ra cuptatum, consequid utaspero beaque ende ipsam, totatiistia nihil modis erit labo. Itaquam, iusam aspel in rem ulpa doluptis et et hiliquam, sitate is rendunt.",
            },

            {
                title: "3. Chat",
                text: "Estor arum sam el magnatur, sum, volutatur, qui dolorro cone et latus ra cuptatum, consequid utaspero beaque ende ipsam, totatiistia nihil modis erit labo. Itaquam, iusam aspel in rem ulpa doluptis et et hiliquam, sitate is rendunt.",
            },
        ],
    },
};
