import { Box, Button, CardContent, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { REDIRECTION_TIME } from "Constant";
import { NotificationManager } from "react-notifications";
import { useDropzone } from "react-dropzone";
import styled from "@emotion/styled";
import CmtImage from "Components/CmtImage/CmtImage";
import { useState } from "react";

const format = "image/png, image/jpg, image/jpeg";

const PosterBox = styled(Box)`
    display: flex;
    flexdirection: column;
    overflow: hidden;
    justify-content: center;
    background-color: rgba(240, 240, 240, 1);
    border-radius: 5px;
    border: 2px dashed grey;
    cursor: pointer;
`;

export const EditionSpectaclePoster = ({ projectData, submit, setEditionMode }) => {
    const [picture, setPicture] = useState(null);

    const { getRootProps, getInputProps } = useDropzone({
        accept: format,
        maxFiles: 1,
        maxSize: 5242880,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections?.length > 0) {
                let errorMessage = "";

                if (fileRejections[0]?.errors[0]?.code === "file-invalid-type") {
                    errorMessage = `Votre fichier doit ếtre dans l'un des formats suivant: ${format}`;
                }

                NotificationManager.error(
                    errorMessage || "Votre image ne peut pas être ajouté",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }

            if (acceptedFiles[0]) {
                setPicture(acceptedFiles[0]);
            }
        },
    });

    return (
        <Box sx={{ p: 2 }}>
            <CmtCard sx={{ p: 2 }}>
                <TileTitle>Affiche</TileTitle>
                <CardContent>
                    <input {...getInputProps()} />
                    <PosterBox {...getRootProps()}>
                        {picture || projectData?.spectacle?.posterFileName ? (
                            <CmtImage
                                src={URL.createObjectURL(
                                    picture || projectData?.spectacle?.posterFileName
                                )}
                                style={{ maxWidth: "100%" }}
                            />
                        ) : (
                            <Typography component="p" textAlign={"center"}>
                                Cliquez ici pour sélectionner une image ou déposez là directement.
                            </Typography>
                        )}
                    </PosterBox>

                    <Box display="flex" justifyContent="flex-end" height={30} mt={5}>
                        <Box mr={2}>
                            <Button
                                type="button"
                                onClick={() => setEditionMode(false)}
                                variant="contained"
                                color="secondary"
                            >
                                <Hidden xsDown> Annuler </Hidden>
                                <Hidden smUp>
                                    <CloseIcon />
                                </Hidden>
                            </Button>
                        </Box>

                        <Box ml={2}>
                            <Button
                                type="submit"
                                onClick={() => {
                                    submit({
                                        spectacle: {
                                            ...projectData?.spectacle,
                                            posterFileName: picture,
                                        },
                                    });
                                    setEditionMode(false);
                                }}
                                id="submitDescriptionCard"
                                variant="contained"
                                color="primary"
                            >
                                <Hidden xsDown> Valider </Hidden>
                                <Hidden smUp>
                                    <CheckIcon />
                                </Hidden>
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
