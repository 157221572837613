import { SideBarHeader } from "./SideBarHeader/SideBarHeader.jsx";
import { SideBarUsers } from "./SideBarUsers/SideBarUsers.jsx";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SidebarContainer } from "./sc.SideBar.js";

export const SideBar = ({
    profile,
    chat,
    setStatus,
    setFilter,
    setCurrentUser,
    popoverVersion,
    isCollapse,
    setIsCollapse,
}) => {
    return (
        <ClickAwayListener onClickAway={() => setIsCollapse(true)}>
            <SidebarContainer popoverVersion={popoverVersion} isCollapse={isCollapse}>
                {!popoverVersion && (
                    <SideBarHeader
                        profile={profile}
                        filter={chat.filter}
                        status={chat.status}
                        setStatus={setStatus}
                        setFilter={setFilter}
                    />
                )}

                <SideBarUsers
                    users={chat?.users}
                    currentUser={chat?.currentUser}
                    profileId={profile?.profile?.id}
                    setCurrentUser={(value) => {
                        setIsCollapse(true);
                        setCurrentUser(value);
                    }}
                    popoverVersion={popoverVersion}
                />
            </SidebarContainer>
        </ClickAwayListener>
    );
};
