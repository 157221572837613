import { AvatarGroup } from '@mui/material';
import { styled } from '@mui/system';

export const RootAvatarGroup = styled(AvatarGroup)`
    & .MuiAvatar-root {
        border-color: ${props => props.theme.palette.secondary.light};
        border-width: 2px;
        margin-left: -5px;
        transition: all 0.3s ease;

        &:hover {
            border-color: ${props => props.theme.palette.primary.main};
            box-shadow: rgba(0,0,0,0.2) 0px 2px 4px -1px;
            z-index: 5 !important;
        };

        &:hover .Cmt-hoverEffect {
            margin-left: 0;
        },
    };
`;