import { Box } from "@mui/material";
import { REDIRECTION_TIME } from "Constant";
import NotificationManager from "react-notifications/lib/NotificationManager";
import auth from "services/Api/auth";
import intermittent from "services/Api/intermittent";
import { CreateIntermittentForm } from "./CreateIntermittentForm";

export const CreateIntermittentApp = ({
    getProfile,
    disabled,
    disconnect,
    sectorTypes,
    onClose,
}) => {
    const handleSubmit = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();

            return;
        }

        const result = await intermittent.createIntermittent(data);

        if (!result.result) {
            NotificationManager.error(
                result?.error?.message || "Une Erreur s'est produite",
                "Erreur",
                REDIRECTION_TIME
            );

            return { result: false };
        } else {
            NotificationManager.success(
                "Votre status d'intermittent à bien été crée",
                "Succès",
                REDIRECTION_TIME
            );

            getProfile();

            onClose();

            return { result: true };
        }
    };

    return (
        <Box>
            <CreateIntermittentForm
                submit={handleSubmit}
                disabled={disabled}
                sectorTypes={sectorTypes}
            />
        </Box>
    );
};
