import { Fab, Hidden, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { DisplayCalendarsList } from "./DisplayCalendarsList";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { CreateCalendar } from "../CreateCalendar/CreateCalendar";
import history from "services/History/history";
import { CREATE_PATH, EDIT_PATH, PLANNING_PATH, REDIRECTION_TIME } from "Constant";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";
import { NotificationManager } from "react-notifications";
import { UpdateCalendar } from "../UpdateCalendar/UpdateCalendar";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import linkify from "services/Linkify/linkify";
import { useTheme } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import CircleIcon from "@mui/icons-material/Circle";
import { BigCalendar, ButtonLineBox } from "./sc.DisplayPlanning";

const localizer = momentLocalizer(moment);

export const DisplayPlanning = ({
    planningData,
    profileData,
    setSelectedCalendar,
    handleCreateCalendar,
    deleteCalendar,
    updateCalendar,
    deleteEvent,
    colorList,
    changeFilters,
}) => {
    const [openCreateCalendar, setOpenCreateCalendar] = useState(false);
    const [openUpdateCalendar, setOpenUpdateCalendar] = useState(null);
    const [openDeleteCalendar, setOpenDeleteCalendar] = useState(null);

    const [actualDate, setActualDate] = useState(moment());

    const theme = useTheme();
    const mdBreakPoint = useMediaQuery(theme?.breakpoints.down("md"));

    const [isCollapse, setIsCollapse] = useState(true);

    const [openDetail, setOpenDetail] = useState(null);
    const [openDeleteEvent, setOpenDeleteEvent] = useState(false);

    const handleDeleteCalendar = async (data) => {
        deleteCalendar(data).then((result) => {
            if (!result.result) {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }
        });
    };

    const handleDeleteEvent = async (data) => {
        deleteEvent(data).then((result) => {
            if (!result.result) {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }
        });
    };

    const handleChangeDateRange = (date, view) => {
        let startRange = null;
        let endRange = null;

        if (view === "month") {
            startRange = moment(date).startOf("month").startOf("week").format("YYYY-MM-DD");

            endRange = moment(date).endOf("month").endOf("week").format("YYYY-MM-DD");
        } else if (view === "week") {
            startRange = moment(date).startOf("week").format("YYYY-MM-DD");

            endRange = moment(date).endOf("week").format("YYYY-MM-DD");
        } else {
            startRange = moment(date).format("YYYY-MM-DD");

            endRange = moment(date).format("YYYY-MM-DD");
        }

        changeFilters({
            ...planningData.filters,
            mode: view,
            firstDay: startRange,
            lastDay: endRange,
        });
    };

    const setEventPropGetter = (event) => {
        let style = {};

        if (profileData?.profile?.id !== event?.calendar?.createdBy?.id) {
            style = {
                backgroundColor: "rgba(200, 200, 200, 0.9)",
            };
        } else {
            style = {
                backgroundColor: event.color,
                borderLeft: `10px solid ${event?.calendar?.color}`,
            };
        }

        return {
            style: style,
        };
    };

    return (
        <>
            <Box display="flex" flexGrow={1}>
                <Box height="100%" width="100%" p={5} display="flex" flexDirection="column">
                    <Hidden xlUp>
                        <Box display="flex" justifyContent="space-between">
                            <CmtAppHeader title="Planning" />
                            <MenuIcon
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsCollapse(false);
                                }}
                                className="pointer"
                            />
                        </Box>
                    </Hidden>
                    <Box display="flex" justifyContent="space-between" mb={5} flexWrap="wrap">
                        <Hidden xlDown>
                            <CmtAppHeader title="Planning" />
                        </Hidden>
                        <ButtonLineBox>
                            <Fab
                                sx={{ ml: 5, px: 5 }}
                                variant="extended"
                                size="small"
                                color="primary"
                                onClick={() => setOpenCreateCalendar(true)}
                            >
                                <AddIcon sx={{ mr: 2 }} />
                                Ajouter un calendrier
                            </Fab>

                            <Fab
                                sx={{ ml: 5, px: 5 }}
                                variant="extended"
                                size="small"
                                color="primary"
                                onClick={() =>
                                    history.push(`${PLANNING_PATH}/evenement${CREATE_PATH}`)
                                }
                            >
                                <AddIcon sx={{ mr: 2 }} />
                                Ajouter un évènement
                            </Fab>
                        </ButtonLineBox>
                    </Box>
                    <BigCalendar
                        localizer={localizer}
                        selectable={true}
                        onNavigate={(date, view) => {
                            setActualDate(date);
                            handleChangeDateRange(date, view);
                        }}
                        step={60}
                        defaultDate={new Date()}
                        onView={(view) => handleChangeDateRange(actualDate, view)}
                        messages={{
                            today: "Aujourd'hui",
                            previous: "Précédente",
                            next: "Suivante",
                            month: "Mois",
                            day: "Jour",
                            week: "Semaine",
                        }}
                        min={new Date(0, 0, 0, 8, 0, 0)}
                        max={new Date(0, 0, 0, 22, 0, 0)}
                        events={
                            planningData?.eventList?.map((item) => {
                                const startHour = new Date(
                                    moment(
                                        `${moment(item.startDate).format("YYYY-MM-DD")}${
                                            item.startHour
                                                ? moment(item.startHour).format(" HH:mm")
                                                : ""
                                        }`
                                    )
                                );
                                const endHour = new Date(
                                    moment(
                                        `${moment(item.endDate).format("YYYY-MM-DD")}${
                                            item.endHour
                                                ? moment(item.endHour).format(" HH:mm")
                                                : ""
                                        }`
                                    )
                                );

                                const allDay = item?.startHour ? false : true;

                                return {
                                    ...item,
                                    title: (
                                        <Box width="100%">
                                            <Typography component="p">{item?.title}</Typography>
                                            {item?.projectsShared?.length > 0 && (
                                                <Box display="flex" alignItems="center">
                                                    <CircleIcon
                                                        sx={{ ml: "auto", mr: 2 }}
                                                        fontSize={"10px"}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    ),
                                    start: startHour,
                                    end: endHour,
                                    allDay: allDay,
                                };
                            }) || []
                        }
                        eventPropGetter={setEventPropGetter}
                        onSelectEvent={(item, event) =>
                            setOpenDetail({ item: item, anchorEl: event.currentTarget })
                        }
                        view={mdBreakPoint ? "day" : planningData?.filters?.mode}
                        views={mdBreakPoint ? ["day"] : ["day", "week", "month"]}
                    />
                </Box>
                <DisplayCalendarsList
                    planningData={planningData}
                    setSelectedCalendar={setSelectedCalendar}
                    setUpdateCalendar={setOpenUpdateCalendar}
                    deleteCalendar={setOpenDeleteCalendar}
                    isCollapse={isCollapse}
                    setIsCollapse={setIsCollapse}
                />
            </Box>

            <CreateCalendar
                open={openCreateCalendar}
                onClose={() => setOpenCreateCalendar(false)}
                submit={handleCreateCalendar}
                colorList={colorList}
            />

            <UpdateCalendar
                open={Boolean(openUpdateCalendar)}
                onClose={() => setOpenUpdateCalendar(null)}
                calendarData={openUpdateCalendar}
                submit={updateCalendar}
                colorList={colorList}
            />

            <CmtDeleteDialog
                open={Boolean(openDeleteCalendar)}
                onCancel={() => setOpenDeleteCalendar(null)}
                onDelete={() => {
                    handleDeleteCalendar({ id: openDeleteCalendar });

                    setOpenDeleteCalendar(null);
                }}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        Êtes-vous sûr de vouloir supprimer ce calendrier ?
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>

            <CmtPopover
                open={Boolean(openDetail)}
                anchorEl={openDetail?.anchorEl}
                onClose={() => setOpenDetail(null)}
            >
                <Box pl={5} pr={1} pt={1} minWidth={300}>
                    <Box display="flex" justifyContent="flex-end">
                        {openDetail?.item?.calendar?.createdBy?.id === profileData?.profile?.id && (
                            <>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        history.push(
                                            `${PLANNING_PATH}/evenement/${openDetail?.item?.id}${EDIT_PATH}`
                                        )
                                    }
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        setOpenDeleteEvent(openDetail?.item);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        )}
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => setOpenDetail(null)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box pt={1}>
                        <Typography component="p" variant="subtitle1" fontSize={20}>
                            {openDetail?.item?.title}
                        </Typography>
                        <Typography component="p" fontSize={12}>
                            {openDetail?.item?.startDate === openDetail?.item?.endDate
                                ? `Le ${moment(openDetail?.item?.startDate).format("DD MMMM YYYY")}`
                                : `Du ${moment(openDetail?.item?.startDate).format(
                                      "DD MMMM YYYY"
                                  )} au ${moment(openDetail?.item?.endDate).format(
                                      "DD MMMM YYYY"
                                  )}`}
                        </Typography>

                        <Typography component="p" fontSize={12} mb={3}>
                            {openDetail?.item?.startHour &&
                                `De ${moment(openDetail?.item?.startHour).format(
                                    "HH:mm"
                                )} a ${moment(openDetail?.item?.endHour).format("HH:mm")}`}
                        </Typography>

                        {openDetail?.item?.reminder && (
                            <>
                                <Typography component="p" fontSize={12} pt={5} fontWeight="bold">
                                    Rappel
                                </Typography>
                                <Typography component="p" fontSize={12} mb={3}>
                                    {moment(openDetail?.item?.reminder).format(
                                        "DD MMMM YYYY  HH:mm"
                                    )}
                                </Typography>
                            </>
                        )}

                        {openDetail?.item?.fullAddress && (
                            <>
                                <Typography component="p" fontSize={12} pt={5}>
                                    Lieu
                                </Typography>
                                <Typography component="p" fontSize={12}>
                                    {openDetail?.item?.fullAddress}
                                </Typography>
                            </>
                        )}

                        {openDetail?.item?.description && (
                            <Box
                                component="p"
                                dangerouslySetInnerHTML={{
                                    __html: linkify(
                                        openDetail?.item?.description?.replace(
                                            /(?:\r\n|\r|\n)/g,
                                            "<br />"
                                        )
                                    ),
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </CmtPopover>

            <CmtDeleteDialog
                open={Boolean(openDeleteEvent)}
                onCancel={() => setOpenDeleteEvent(null)}
                onDelete={() => {
                    handleDeleteEvent({
                        calendarId: openDeleteEvent.calendar?.id,
                        calendarEventId: openDeleteEvent?.id,
                    });

                    setOpenDetail(null);
                    setOpenDeleteEvent(null);
                }}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        Êtes-vous sûr de vouloir supprimer cet évènement ?
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>
        </>
    );
};
