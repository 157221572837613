import { useTheme } from "@emotion/react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtImage from "Components/CmtImage/CmtImage";
import { ABOUT_PATH, FUNCTIONNALITY_PATH, PRICES_PATH, TESTIMONY_PATH } from "Constant";
import history from "services/History/history";
import { PublicTypo } from "../sc.Public";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const ListElem = ({ text, link = "", theme }) => {
    return (
        <PublicTypo
            onClick={() => history.push(link)}
            component="li"
            family={theme.palette.publicTypo.pFamily}
            color="#FFFFFF"
            sx={{ cursor: "pointer" }}
        >
            {text}
        </PublicTypo>
    );
};

export const Footer = () => {
    const theme = useTheme();

    return (
        <Box backgroundColor="#312D2E">
            <Container maxWidth="lg">
                <Grid container py={15} spacing={4}>
                    <Grid item xs={12} sm={5} md={3}>
                        <Box pr={{ xs: 10, sm: 20 }} pl={{ xs: 10, sm: 0 }}>
                            <CmtCard sx={{ p: 3 }}>
                                <CmtImage src={"/images/Logo-Baseline.svg"} width="100%" />
                            </CmtCard>
                            <Box display="flex" alignItems="center" flexDirection="column" pt={5}>
                                <PublicTypo
                                    family={theme.palette.publicTypo.pFamily}
                                    color="#FFFFFF"
                                    mb={1}
                                >
                                    Une marque de
                                </PublicTypo>
                                <Box
                                    component="img"
                                    src={"/images/bcubix_logo_white.svg"}
                                    height="5em"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={{ xs: "center", sm: "flex-start" }}
                        >
                            <PublicTypo
                                family={theme.palette.publicTypo.hFamily}
                                size={theme.palette.publicTypo.h4Size}
                                color="primary"
                            >
                                Impul'Scène
                            </PublicTypo>

                            <Box component="ul" pl={5}>
                                <ListElem
                                    text="Fonctionnalités"
                                    link={FUNCTIONNALITY_PATH}
                                    theme={theme}
                                />

                                <ListElem text="Tarifs" link={PRICES_PATH} theme={theme} />

                                <ListElem text="Témoignages" link={TESTIMONY_PATH} theme={theme} />

                                <ListElem text="À propos" link={ABOUT_PATH} theme={theme} />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems={{ xs: "center", sm: "flex-start" }}
                        >
                            <PublicTypo
                                family={theme.palette.publicTypo.hFamily}
                                size={theme.palette.publicTypo.h4Size}
                                color="primary"
                            >
                                Impul'Scène
                            </PublicTypo>

                            <Box component="ul" pl={5}>
                                <ListElem
                                    text="Mentions Légales"
                                    link={FUNCTIONNALITY_PATH}
                                    theme={theme}
                                />

                                <ListElem text="CGV" link={PRICES_PATH} theme={theme} />

                                <ListElem text="CGU" link={TESTIMONY_PATH} theme={theme} />

                                <ListElem text="Cookies" link={ABOUT_PATH} theme={theme} />

                                <ListElem text="Crédits" link={ABOUT_PATH} theme={theme} />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={3}
                        sm={6}
                        borderLeft={{ md: `1px dashed ${theme.palette.primary.main}` }}
                    >
                        <Box
                            pl={{ xs: 0, sm: 10 }}
                            display="flex"
                            flexDirection="column"
                            alignItems={{ xs: "center", sm: "flex-start" }}
                        >
                            <PublicTypo
                                family={theme.palette.publicTypo.hFamily}
                                size={theme.palette.publicTypo.h4Size}
                                color="primary"
                                textAlign="center"
                            >
                                Contactez nous
                            </PublicTypo>

                            <Box
                                display="flex"
                                pt={5}
                                justifyContent={{ xs: "left", md: "center" }}
                            >
                                <Box
                                    component="img"
                                    src={"/images/Public/Images/facebook.svg"}
                                    height="3em"
                                    mx={2}
                                />
                                <Box
                                    component="img"
                                    src={"/images/Public/Images/instagram.svg"}
                                    height="3em"
                                    mx={2}
                                />
                                <Box
                                    component="img"
                                    src={"/images/Public/Images/linkedin.svg"}
                                    height="3em"
                                    mx={2}
                                />
                            </Box>
                            <Box pl={5} pt={5} display="flex" alignItems="center">
                                <PublicTypo
                                    family={theme.palette.publicTypo.pFamily}
                                    color="#FFFFFF"
                                >
                                    <LocalPhoneIcon />
                                </PublicTypo>
                                <PublicTypo
                                    pl={5}
                                    family={theme.palette.publicTypo.pFamily}
                                    color="#FFFFFF"
                                >
                                    01 47 20 00 01
                                </PublicTypo>
                            </Box>

                            <Box pl={5} pt={2} display="flex" alignItems="center">
                                <PublicTypo
                                    family={theme.palette.publicTypo.pFamily}
                                    color="#FFFFFF"
                                >
                                    <EmailIcon />
                                </PublicTypo>
                                <PublicTypo
                                    pl={5}
                                    family={theme.palette.publicTypo.pFamily}
                                    color="#FFFFFF"
                                >
                                    hi@impulscene.com
                                </PublicTypo>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
