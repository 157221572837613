import { Box } from "@mui/material";
import { LoginAppStore } from "Apps/Auth/LoginApp/LoginApp";
import { SignUpAppStore } from "Apps/Auth/SignUpApp/SignUpApp";
import { DASHBOARD_PATH, FORGET_PATH, HOME_PATH, SIGNIN_PATH, SIGNUP_PATH } from "Constant";
import history from "services/History/history";
import { authSelector } from "redux/selectors/authSelector";
import { connect } from "react-redux";
import { ForgetPasswordAppStore } from "Apps/Auth/ForgetPasswordApp/ForgetPasswordApp";
import { Switch, Route, Router } from "react-router-dom";
import { CloseIcon, Popine } from "./sc.Home";
import { useTheme } from "@emotion/react";
import { AuthBlock } from "./AuthBlock";
import { PublicMenu } from "Components/Public/Menu/PublicMenu";
import { PresentationBlock } from "./PresentationBlock";
import { FunctionalityBlock } from "./FunctionalityBlock";
import { TestimonyBlock } from "./TestimonyBlock";
import { PriceBlock } from "./PriceBlock";
import { NewsletterBlock } from "./NewsletterBlock";
import { Footer } from "../Partial/Footer";
import { useEffect } from "react";

export const Home = ({ isAuth }) => {
    const theme = useTheme();

    const handleChangePath = (path) => {
        history.push(path);
    };

    useEffect(() => {
        if (isAuth.connected) {
            history.push(DASHBOARD_PATH);
        }
    });

    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route path={SIGNUP_PATH}>
                        <Popine>
                            <SignUpAppStore>
                                <CloseIcon onClick={() => handleChangePath(HOME_PATH)} />
                            </SignUpAppStore>
                        </Popine>
                    </Route>
                    <Route path={SIGNIN_PATH}>
                        <Popine onClick={() => history.push(HOME_PATH)}>
                            <LoginAppStore>
                                <CloseIcon onClick={() => handleChangePath(HOME_PATH)} />
                            </LoginAppStore>
                        </Popine>
                    </Route>
                    <Route path={FORGET_PATH}>
                        <Popine onClick={() => history.push(HOME_PATH)}>
                            <ForgetPasswordAppStore>
                                <CloseIcon onClick={() => handleChangePath(HOME_PATH)} />
                            </ForgetPasswordAppStore>
                        </Popine>
                    </Route>
                </Switch>
            </Router>

            <Box backgroundColor={theme.palette.primary.publicMain} pt={20}>
                <PublicMenu floatingMode={true} />

                <AuthBlock />

                <PresentationBlock />

                <FunctionalityBlock />

                <TestimonyBlock />

                <PriceBlock />

                <NewsletterBlock />

                <Footer />
            </Box>
        </>
    );
};

export const HomeStore = connect((state) => ({
    isAuth: authSelector(state),
}))(Home);
