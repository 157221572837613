import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import { RestrictedModale } from "Components/RestrictedModale/RestrictedModale";
import { STRUCTURE_PATH } from "Constant";
import { useState } from "react";
import history from "services/History/history";

export const NetworkGrid = ({ profile, companiesData, defaultProfileFileName }) => {
    const [restrictedModale, setResctrictedModale] = useState(false);

    return (
        <Box flexGrow={1} mt={5}>
            <Grid container spacing={4}>
                {companiesData?.companies[0]?.map((item, index) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        xl={3}
                        key={index}
                        onClick={() => {
                            if (!profile?.profile?.isCompleted) {
                                setResctrictedModale(true);
                            } else {
                                history.push(`${STRUCTURE_PATH}/${item.id}`);
                            }
                        }}
                        sx={{ cursor: "pointer" }}
                    >
                        <CmtCard>
                            <Box display="flex">
                                <Box p={3}>
                                    <AvatarComponent
                                        src={item?.logoFileName || defaultProfileFileName}
                                        size={100}
                                    />
                                </Box>
                                <Box p={3} display="flex" flexDirection="column" flexGrow={1}>
                                    <Box display="flex">
                                        <Typography component="div" variant="subtitle1">
                                            {item.name}
                                        </Typography>
                                        <Box ml="auto">
                                            <Typography component="i" variant="h6">
                                                A {item?.distance} km
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography
                                        component="div"
                                        variant="subtitle2"
                                        color="textSecondary"
                                    >
                                        {item.city}
                                    </Typography>
                                    <Box pt={3} mt="auto">
                                        <Typography
                                            component="p"
                                            variant="h5"
                                            color="textSecondary"
                                        >
                                            {item?.compagniesTypes ? `Structure` : "Intermittent"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </CmtCard>
                    </Grid>
                ))}
                {companiesData?.loading &&
                    !companiesData?.error &&
                    [...Array(4)].map((_, index) => <NetworkSkeletonGrid key={index} />)}
            </Grid>
            {restrictedModale && <RestrictedModale close={() => setResctrictedModale(false)} />}
        </Box>
    );
};

const NetworkSkeletonGrid = () => {
    return (
        <Grid item xs={12} md={6} lg={4} xl={3}>
            <CmtCard>
                <Box display="flex">
                    <Box p={3}>
                        <Skeleton variant="circular" height={100} width={100} />
                    </Box>
                    <Box p={3} display="flex" flexDirection="column" flexGrow={1}>
                        <Box display="flex">
                            <Skeleton variant="text" width="50%" height={30} />

                            <Skeleton variant="text" width="20%" sx={{ ml: "auto" }} />
                        </Box>
                        <Skeleton variant="text" width="40%" />

                        <Box pt={3} mt="auto">
                            <Skeleton variant="text" width="30%" />
                        </Box>
                    </Box>
                </Box>
            </CmtCard>
        </Grid>
    );
};
