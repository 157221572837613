import { Tooltip, MenuItem, MenuList, Paper, Popover, Box } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import {useState} from 'react';
import { DISCONNECT_PATH, SETTING_PATH } from 'Constant';
import history from "services/History/history.js";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PopoverIconButton } from "Apps/ConnectedContainer/sc.ConnectedContainer";

export default function Menu () {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const onLogoutClick = () => {
        handlePopoverClose();

        history.push(DISCONNECT_PATH);
    };

    return (
        <>
            <Tooltip title="Menu">
                <PopoverIconButton
                    open={open}
                    onClick={handlePopoverOpen}
                    size='large'
                    color='primary'
                >
                    <MoreVertIcon/>
                </PopoverIconButton>
            </Tooltip>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper elevation={8}>
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                handlePopoverClose();
                                history.push(SETTING_PATH)
                            }}
                        >
                            <SettingsIcon />
                            <Box ml={2} >Paramètres</Box>
                        </MenuItem>
                        <MenuItem onClick={onLogoutClick}>
                            <ExitToAppIcon />
                            <Box ml={2}>Se déconnecter</Box>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Popover>
        </>
    );
}