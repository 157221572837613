import { Box, Button, Tooltip, Typography } from "@mui/material";
import { CHAT_PATH } from "Constant";
import history from "services/History/history";
import UserLine from "./UserLine";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";

export function SideBarUsers({ users, currentUser, setCurrentUser, profileId, popoverVersion }) {
    if (!users || users.length === 0) {
        return <></>;
    }

    return (
        <Box pt={0} flexGrow={1} width="100%" display="flex" flexDirection="column">
            {!popoverVersion ? (
                <Box p={4} backgroundColor="rgba(211, 211, 211, 0.4)">
                    <Typography variant="subtitle2" component="p" color="textSecondary">
                        Contacts
                    </Typography>
                </Box>
            ) : (
                <Box mt={2}>
                    <Tooltip title={"Ouvrir en plein écran"}>
                        <Button onClick={() => history.push(CHAT_PATH)} textAlign="center">
                            <ZoomOutMapIcon color="primary" fontSize="large" />{" "}
                        </Button>
                    </Tooltip>
                </Box>
            )}
            <Box flexGrow={1}>
                {users.map((user) => (
                    <UserLine
                        user={user}
                        currentUser={currentUser}
                        key={user.id}
                        profileId={profileId}
                        onClick={() => setCurrentUser(user)}
                        popoverVersion={popoverVersion}
                    />
                ))}
            </Box>
        </Box>
    );
}
