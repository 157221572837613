import { Box } from "@mui/material";
import { connect } from "react-redux";
import { notConnectedAction } from "redux/actions/authActions.js";
import {
    changeFilterAction,
    changeStatusAction,
    deleteMessageAction,
    getChatUserListAction,
    getConversationAction,
    readedMessageAction,
    sendMessageAction,
    setCurrentUserAction,
} from "redux/actions/chatActions.js";
import { filteredChatUsersSelector } from "redux/selectors/chatSelector.js";
import { profileSelector } from "redux/selectors/profileSelector.js";
import auth from "services/Api/auth.js";
import { ChatContainer } from "./ChatContainer/ChatContainer.jsx";
import { SideBar } from "./SideBar/SideBar.jsx";
import chat from "services/Api/chat";
import { useEffect } from "react";
import { useState } from "react";

export const ChatApp = ({
    profile,
    chatData,
    setStatus,
    setFilter,
    setCurrentUser,
    getMessages,
    sendMessage,
    deleteMessage,
    disconnect,
    readedMessage,
    popoverVersion = false,
}) => {
    const [isCollapse, setIsCollapse] = useState(true);

    const handleSendMessage = (message) => {
        const contactId = chatData?.currentUser?.id;

        sendMessage({
            userId: contactId,
            messageText: message,
        });
    };

    const handleSendMediaMessage = (file) => {
        const contactId = chatData?.currentUser?.id;

        sendMessage({
            userId: contactId,
            messageFile: file,
        });
    };

    const handleIsTyping = async (isTyping) => {
        if (!chatData.currentUser.id) {
            return;
        }

        auth.checkIsAuth().then((result) => {
            if (result) {
                chat.changeTyping({
                    userId: chatData.currentUser.id,
                    isTyping: isTyping,
                });
            } else {
                disconnect();
            }
        });
    };

    const handleSetCurrentUser = (value) => {
        if (chatData?.currentUser) {
            handleIsTyping(false);
        }

        setCurrentUser(value);
    };

    useEffect(() => {
        if (!chatData?.users) {
            return;
        }

        if (!chatData?.currentUser) {
            setCurrentUser(chatData?.users[0]);
        }
    }, [chatData, setCurrentUser]);

    if (!chatData) {
        return <></>;
    }

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            flexDirection={popoverVersion ? "row-reverse" : "row"}
        >
            <ChatContainer
                profile={profile}
                chat={chatData}
                getMessages={getMessages}
                sendMessage={handleSendMessage}
                sendMediaMessage={handleSendMediaMessage}
                deleteMessage={deleteMessage}
                sendIsTyping={handleIsTyping}
                readedMessage={readedMessage}
                popoverVersion={popoverVersion}
                setIsCollapse={setIsCollapse}
            />

            <SideBar
                profile={profile}
                chat={chatData}
                setStatus={setStatus}
                setFilter={setFilter}
                setCurrentUser={handleSetCurrentUser}
                popoverVersion={popoverVersion}
                isCollapse={isCollapse}
                setIsCollapse={setIsCollapse}
            />
        </Box>
    );
};

export const ChatAppStore = connect(
    (state) => ({
        profile: profileSelector(state),
        chatData: filteredChatUsersSelector(state),
    }),
    (dispatch) => ({
        setFilter: (value) => dispatch(changeFilterAction(value)),
        setStatus: (value) => dispatch(changeStatusAction(value)),
        getUsers: () => dispatch(getChatUserListAction()),
        setCurrentUser: (value) => dispatch(setCurrentUserAction(value)),
        getMessages: (value) => dispatch(getConversationAction(value)),
        sendMessage: (value) => dispatch(sendMessageAction(value)),
        deleteMessage: (data) => dispatch(deleteMessageAction(data)),
        disconnect: () => dispatch(notConnectedAction()),
        readedMessage: () => dispatch(readedMessageAction()),
    })
)(ChatApp);
