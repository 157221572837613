import { Tabs } from "@mui/material";
import { styled } from "@mui/system";

export const NavMenuContainer = styled(Tabs)`
    border-bottom: ${props => `1px solid ${props.theme.palette.borderColor.dark}`};
    & span {
        font-size: 0.9em;
        ${props => props.theme.breakpoints.down("sm")} {
            font-size: 0.7em;
        };
    };
`;