import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { DEFAULT_COMPANIES_PIC } from "Constant";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notConnectedAction } from "redux/actions/authActions";
import { announcements } from "services/Api/announcements";
import auth from "services/Api/auth";
import { StepperLine } from "../StepperLine";

export const Step1 = ({ stepper, profile, submit, data }) => {
    const dispatch = useDispatch();

    const [listEntity, setListEntity] = useState(null);
    const [announcementTypeList, setAnnouncementTypeList] = useState(null);

    const fetchData = useCallback(async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await announcements.getAnnouncementsTypeList();

        if (!result.result) {
            return;
        }

        setAnnouncementTypeList(result.data);
    }, [dispatch]);

    useEffect(() => {
        if (!announcementTypeList) {
            fetchData();
        }
    }, [announcementTypeList, fetchData]);

    useEffect(() => {
        let list = [];

        profile?.profile?.compagnyUsers?.forEach((element) => {
            list.push({ ...element });
        });

        setListEntity(list);
    }, [profile]);

    const checkError = (values) => {
        const errors = {};

        if (!values.title) {
            errors.title = "Veuillez renseigner le titre de votre annonce";
        }

        if (!values.announcementType) {
            errors.announcementType = "Veuillez renseigner le type de votre annonce";
        }

        if (!values.creatorEntity) {
            errors.creatorEntity =
                "Veuillez indiquer en tant que quel professionnel vous publiez votre annonce";
        }

        return errors;
    };

    return (
        <Grid item container xs={12} sm={5}>
            <Formik
                initialValues={{
                    title: data.title || "",
                    announcementType: data.announcementType || "",
                    creatorEntity: data.creatorEntity || "",
                }}
                validate={(values) => checkError(values)}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({ values, errors, touched, handleChange, handleSubmit, handleBlur }) => (
                    <CmtForm onSubmit={handleSubmit} className="fullWidth">
                        <CmtCard>
                            <Box p={5}>
                                <FormInput
                                    name="title"
                                    variant="standard"
                                    label="Titre"
                                    id="announcementTitle"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                    fullWidth
                                    error={touched.title && errors.title}
                                />

                                <FormControl fullWidth variant="standard" margin="normal">
                                    <InputLabel
                                        id="labelAdsType"
                                        error={
                                            touched.announcementType &&
                                            Boolean(errors.announcementType)
                                        }
                                    >
                                        Type d'annonce
                                    </InputLabel>
                                    <Select
                                        labelId="labelAdsType"
                                        id={"announcementTypesSelect"}
                                        value={values.announcementType}
                                        name="announcementType"
                                        onChange={handleChange}
                                        error={
                                            touched.announcementType &&
                                            Boolean(errors.announcementType)
                                        }
                                        onBlur={handleBlur}
                                    >
                                        {announcementTypeList?.map((item, index) => (
                                            <MenuItem
                                                value={item.id}
                                                key={`announcementType ${index}`}
                                                id={`announcementTypeItem-${index}`}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {touched.announcementType && errors.announcementType}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl fullWidth variant="standard">
                                    <InputLabel
                                        id="labelAdsType"
                                        error={
                                            touched.creatorEntity && Boolean(errors.creatorEntity)
                                        }
                                    >
                                        Créer en tant que
                                    </InputLabel>
                                    <Select
                                        labelId="labelAdsType"
                                        id={"creatorEntityTypesSelect"}
                                        value={values.creatorEntity}
                                        name="creatorEntity"
                                        onChange={handleChange}
                                        fullWidth
                                        error={
                                            touched.creatorEntity && Boolean(errors.creatorEntity)
                                        }
                                        onBlur={handleBlur}
                                        renderValue={() => {
                                            if (
                                                values.creatorEntity === profile?.profile?.id ||
                                                values.creatorEntity ===
                                                    profile?.profile?.intermittent?.id
                                            ) {
                                                return (
                                                    <Box
                                                        display="flex"
                                                        width="100%"
                                                        alignItems="center"
                                                        ml={3}
                                                    >
                                                        <AvatarComponent
                                                            src={
                                                                profile?.profile?.profileFileName ||
                                                                profile?.defaultAvatarPath
                                                            }
                                                            alt={"Avatar"}
                                                        />
                                                        <Box ml={2}>
                                                            {profile?.profile?.firstName}{" "}
                                                            {profile?.profile?.lastName}{" "}
                                                            (Intermittent)
                                                        </Box>
                                                    </Box>
                                                );
                                            } else {
                                                const value = listEntity?.find(
                                                    (item) =>
                                                        item.compagny.id === values.creatorEntity
                                                );

                                                return (
                                                    <Box
                                                        display="flex"
                                                        width="100%"
                                                        alignItems="center"
                                                        ml={3}
                                                    >
                                                        <AvatarComponent
                                                            src={
                                                                value?.coverFileName ||
                                                                DEFAULT_COMPANIES_PIC
                                                            }
                                                            alt={"Avatar"}
                                                        />
                                                        <Box ml={2}>{value?.compagny?.name}</Box>
                                                    </Box>
                                                );
                                            }
                                        }}
                                    >
                                        {profile?.profile?.intermittent && (
                                            <MenuItem value={profile?.profile?.intermittent.id}>
                                                <Box
                                                    display="flex"
                                                    width="100%"
                                                    alignItems="center"
                                                    ml={3}
                                                >
                                                    <AvatarComponent
                                                        src={
                                                            profile?.profile?.profileFileName ||
                                                            profile?.defaultAvatarPath
                                                        }
                                                        alt={"Avatar"}
                                                    />
                                                    <Box ml={2}>
                                                        {profile?.profile?.firstName}{" "}
                                                        {profile?.profile?.lastName} (Intermittent)
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        )}
                                        {listEntity?.map((item, index) => (
                                            <MenuItem
                                                value={item?.compagny?.id}
                                                key={`entity ${index}`}
                                                id={`entity-${index}`}
                                            >
                                                <Box
                                                    display="flex"
                                                    width="100%"
                                                    alignItems="center"
                                                    ml={3}
                                                >
                                                    <AvatarComponent
                                                        src={
                                                            item?.compagny?.logoFileName ||
                                                            DEFAULT_COMPANIES_PIC
                                                        }
                                                        alt={"Avatar"}
                                                    />
                                                    <Box ml={2}>{item?.compagny?.name}</Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {touched.creatorEntity && errors.creatorEntity}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </CmtCard>

                        <Box mt={4}>
                            <StepperLine stepper={stepper} step="Etape 1 : Le projet" />
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        </Grid>
    );
};
