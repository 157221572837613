import { Box, CardContent, Fab, Grid, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { DEFAULT_COMPANIES_PIC } from "Constant";
import { CompaniesElem } from "../../sc.Profile";
import AddIcon from "@mui/icons-material/Add";

export const CompagnyList = ({ list, handleEdit, setOpenAddStructure }) => {
    return (
        <CmtCard>
            <Box display="flex" justifyContent="space-between" mb={3}>
                <TileTitle>Structures</TileTitle>
                <Fab
                    variant="extended"
                    sx={{ ml: 5, px: 5, mt: 5, mr: 3 }}
                    size="small"
                    color="primary"
                    aria-label="Déclarer"
                    id="openCreateCompagnyButton"
                    onClick={() => setOpenAddStructure(true)}
                >
                    <AddIcon sx={{ mr: 2 }} />
                    Déclarer une structure
                </Fab>
            </Box>
            <CardContent>
                <Grid container>
                    {list?.map((item, index) => {
                        return (
                            <CompaniesElem
                                item
                                container
                                sm={3}
                                xs={12}
                                key={index}
                                onClick={() => handleEdit(item?.compagny?.id)}
                                id={`company-${index}`}
                            >
                                <AvatarComponent
                                    src={item?.compagny?.logoFileName || DEFAULT_COMPANIES_PIC}
                                    size={50}
                                />
                                <Box ml={5} mt={2}>
                                    <Typography
                                        component="p"
                                        variant="subtitle1"
                                        color="primary"
                                        lineHeight={1}
                                    >
                                        {item?.compagny?.name}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        display="block"
                                        fontSize={12}
                                        color="secondary"
                                        lineHeight={1}
                                    >
                                        {item?.position}
                                    </Typography>
                                </Box>
                            </CompaniesElem>
                        );
                    })}
                </Grid>
            </CardContent>
        </CmtCard>
    );
};
