import {
    Box,
    CardContent,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { DEFAULT_COMPANIES_PIC } from "Constant";

export const CreateProjectEntity = ({
    profile,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    listEntity,
}) => {
    return (
        <Box p={2}>
            <CmtCard>
                <TileTitle> Structure Créatrice </TileTitle>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelAdsType">Créer en tant que</InputLabel>
                                <Select
                                    labelId="labelAdsType"
                                    id={"creatorEntityTypesSelect"}
                                    value={values.creatorEntity}
                                    name="creatorEntity"
                                    onChange={handleChange}
                                    error={touched.creatorEntity && Boolean(errors.creatorEntity)}
                                    onBlur={handleBlur}
                                >
                                    {profile?.profile?.intermittent && (
                                        <MenuItem value={profile?.profile?.intermittent.id}>
                                            <Box
                                                display="flex"
                                                width="100%"
                                                alignItems="center"
                                                ml={2}
                                            >
                                                <AvatarComponent
                                                    src={
                                                        profile?.profile?.profileFileName ||
                                                        profile?.defaultAvatarPath
                                                    }
                                                    alt={"Avatar"}
                                                />

                                                <Box component="p" ml={2}>
                                                    {profile?.profile?.firstName}{" "}
                                                    {profile?.profile?.lastName} (Intermittent)
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    )}
                                    {listEntity?.map((item, index) => (
                                        <MenuItem
                                            value={item?.compagny?.id}
                                            key={`entity ${index}`}
                                            id={`entity-${index}`}
                                        >
                                            <Box
                                                display="flex"
                                                width="100%"
                                                alignItems="center"
                                                ml={2}
                                            >
                                                <AvatarComponent
                                                    src={
                                                        item?.compagny?.logoFileName ||
                                                        DEFAULT_COMPANIES_PIC
                                                    }
                                                    alt={"Avatar"}
                                                />
                                                <Box component="p" ml={2}>
                                                    {item?.compagny?.name}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>
                                    {touched.creatorEntity && errors.creatorEntity}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
