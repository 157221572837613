import { Box, Grid } from "@mui/material";
import CmtGridContainer from "Components/CmtGridContainer/CmtGridContainer";
import { ProfileInfosCard } from "./ProfileInfos/ProfileInfosCard";
import { ProfileContact } from "./ProfileContact/ProfileContact";
import { ProfileDescription } from "./ProfileDescription/ProfileDescription";
import { ProfileAddress } from "./ProfileAddress/ProfileAddress";

export const ProfileTab = ({
    profile,
    companyData,
    updateProfile,
    companiesTypes,
    socialType,
    isIntermittent,
    isAdmin,
}) => {
    return (
        <CmtGridContainer spacing={0}>
            <Grid item xs={12} lg={4}>
                <Box p={2}>
                    <ProfileInfosCard
                        isAdmin={isAdmin}
                        companyData={companyData}
                        companiesTypes={companiesTypes}
                        update={updateProfile}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} lg={8} className="fullHeight" sx={{ p: 2 }}>
                <ProfileDescription
                    isIntermittent={isIntermittent}
                    companyData={companyData}
                    update={updateProfile}
                    isAdmin={isAdmin}
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Box p={2}>
                    <ProfileAddress
                        companyData={companyData}
                        profile={profile}
                        isAdmin={isAdmin}
                        update={updateProfile}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Box p={2}>
                    <ProfileContact
                        isIntermittent={isIntermittent}
                        companyData={companyData}
                        socialType={socialType}
                        isAdmin={isAdmin}
                        update={updateProfile}
                    />
                </Box>
            </Grid>
        </CmtGridContainer>
    );
};
