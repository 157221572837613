import { Slide } from "@mui/material";
import { Box } from "@mui/system";
import { CreateEventForm } from "./CreateEventForm";

export const CreateEvent = ({planningData, projectList, handleCreateEvent}) => {
    return (
        <Box display='flex' flexGrow={1} height='100%'>
            <Slide in={true} direction="up" mountOnEnter unmountOnExit>
                <Box height='100%' width='100%'>
                    <CreateEventForm projectList={projectList} planningData={planningData} submit={handleCreateEvent} />
                </Box>
            </Slide>
        </Box>
    );
};