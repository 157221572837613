import { Box, Button, Typography } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import CmtLoading from "Components/CmtLoading/CmtLoading";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { REDIRECTION_TIME } from "Constant";
import { useState } from "react";
import { NotificationManager } from "react-notifications";

export const EditFolder = ({
    anchorEl,
    closePopover,
    folderData,
    updateFolder,
}) => {
    const [value, setValue] = useState(folderData?.name);

    const [loading, setLoading] = useState(false);

    const handleUpdate = async () => {
        setLoading(true);

        updateFolder({
            name: value,
            folderId: folderData?.id,
            isFolder: true,
        }).then(result => {
            setLoading(false);

            if (result?.result) {
                closePopover();
            } else {
                NotificationManager.error(result?.error?.message, 'Erreur', REDIRECTION_TIME);
            }
        })
    };



    return (
        <CmtPopover
            anchorEl={anchorEl}
            closePopover={() => closePopover()}
        >
            {loading && 
                <CmtLoading />
            }
            <Box p={5} minWidth={350}>
                <CmtForm 
                    onSubmit={(e) => {
                        e.preventDefault();

                        handleUpdate();
                    }}
                >
                    <Typography component='p' variant='h1'>Renommer le dossier</Typography>
                    <FormInput label='Nom du dossier' value={value} onChange={(e) => setValue(e.target.value)} />
                    <Box display='flex' pt={4} justifyContent='flex-end'>
                        <Button
                            color='primary'
                            variant='contained'
                            type='submit'
                            disabled={!value}
                        >
                            Valider
                        </Button>
                    </Box>
                </CmtForm>
            </Box>
        </CmtPopover>
    );
};