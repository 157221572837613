import { useState } from "react";
import DisplayModeInfos from "./DisplayModeInfos";
import EditionModeInfos from "./EditionModeInfos";

export default function StructureHeaderInfos({ companyData, isIntermittent, update, isAdmin }) {
    const [editionMode, setEditionMode] = useState(false);

    if (!editionMode) {
        return (
            <DisplayModeInfos
                isIntermittent={isIntermittent}
                companyData={companyData}
                setEditionMode={setEditionMode}
                isAdmin={isAdmin}
            />
        );
    } else {
        return (
            <EditionModeInfos
                companyData={companyData}
                setEditionMode={setEditionMode}
                submit={update}
            />
        );
    }
}
