import { Box, Chip, Slider, Typography } from "@mui/material";
import { useState } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { LOCATION_MODE, MAPBOX_TOKEN, SEARCH_MODE } from "Constant";
import { useEffect } from "react";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { LocationButton, LocationPopover } from "./sc.Filters";
import { LocationLineContainer } from './sc.Filters';

const InitInput = ({
    locationSlider,
    setLocationSlider,
    setLocation,
}) => {
    const [isInit, setIsInit] = useState(false);

    const[sliderValue, setSliderValue] = useState(locationSlider);

    useEffect(() => {
        if (isInit) {
            return;
        }

        setIsInit(true);

        var geocoder = new MapboxGeocoder({
            accessToken: MAPBOX_TOKEN,
            countries: 'fr',
        });

        geocoder.addTo('#geocoder-container');

        geocoder.on('result', function (e) {
            setLocation(e);
        });
    }, [setLocation, isInit]);

    return (
        <Box px={10} py={5}>
            <Typography component='p' > Rechercher dans un rayon de </Typography>
            <Box pt={15}>
                <Slider
                    value={sliderValue}
                    defaultValue={100}
                    max={400}
                    step={10}
                    valueLabelDisplay='on'
                    size='small'
                    valueLabelFormat={`${locationSlider}km`}
                    onChange={(e, value) => setSliderValue(value)}
                    onChangeCommitted={(e, value) => {
                        setSliderValue(value);
                        setLocationSlider(value);
                    }}
                />
            </Box>
        </Box>
    );
};

export const LocationFilters = ({
    filters,
    changeFilters,
    locationMode,
    setLocationMode,
}) => {
    const type = filters.location;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleLocalisation = () => {
        navigator.geolocation.getCurrentPosition(function(position) {
            setLocationMode(LOCATION_MODE);

            changeFilters({...filters, lng: position.coords.longitude, lat: position.coords.latitude})
        });
    };

    return (
        <Box mx={1} py={2}>
            <Box display='flex' alignItems='center' justifyContent='center' className='fullHeight'>
                <Chip
                    variant={type ? 'default' : 'outlined'}
                    icon={<LocationOnIcon/>}
                    size='medium'
                    label={type || "Localisation"}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    onDelete={type ? (() => changeFilters({...filters, location: '', lng: '', lat: ''})) : null}
                />
            </Box>

            <LocationPopover
                anchorEl={anchorEl}
                closePopover={() => {
                    setAnchorEl(null)
                }}
            >
                <Box display='flex' overflow='visible' height='fit-content'>
                    <LocationLineContainer pb={5} pt={4} px={3} id='geocoder-container'></LocationLineContainer>
                    <Box pb={5} pt={4} pr={3}>
                        <LocationButton
                            type='button'
                            onClick={() => {
                                handleLocalisation();
                            }}
                            color='primary'
                            variant={locationMode === LOCATION_MODE ? 'contained' : 'outlined'}
                        >
                            <MyLocationIcon />
                        </LocationButton>
                    </Box>
                </Box>
                <InitInput
                    locationSlider={filters.distance}
                    locationInputValue={filters?.location}
                    setLocationSlider={(value) => changeFilters({...filters, distance: value})}
                    setLocation={(e) => {
                        setLocationMode(SEARCH_MODE);

                        changeFilters({
                            ...filters,
                            location: e.result?.place_name_fr,
                            lng: e.result?.geometry?.coordinates[0],
                            lat: e.result?.geometry?.coordinates[1]
                        });
                    }}
                />
                
            </LocationPopover>
        </Box>
    );
};