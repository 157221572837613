import { useTheme } from "@emotion/react";
import { Button, Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { PublicTypo } from "../sc.Public";

export const NewsletterBlock = () => {
    const theme = useTheme();
    return (
        <Box backgroundColor="#107ca4" py={10}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} md={4} display="flex" alignItems="center">
                        <PublicTypo
                            family={theme.palette.publicTypo.hFamily}
                            size={theme.palette.publicTypo.h2Size}
                            color="#FFFFFF"
                        >
                            Fonctionnalités
                        </PublicTypo>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <PublicTypo
                            variant="h1"
                            family={theme.palette.publicTypo.pFamily}
                            color="#FFFFFF"
                        >
                            Impul’Scène frappe les trois coups et met en place sa newsletter pour
                            vous tenir au courant de toutes les actualités du spectacle vivant.
                        </PublicTypo>

                        <Box display="flex" py={10}>
                            <FormInput
                                type="email"
                                name="email"
                                variant="outlined"
                                id="loginEmail"
                                size="small"
                                placeholder="Votre adresse email ici"
                                sx={{
                                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                                    borderRadius: "5px",
                                    mr: 2,
                                }}
                                margin="none"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ ml: 2, textTransform: "none" }}
                            >
                                S'abonner
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
