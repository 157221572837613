import { Box, Typography } from "@mui/material";
import { HOME_PATH } from "Constant";
import { useEffect } from "react";
import { connect } from "react-redux";
import { disconnectAction } from "redux/actions/authActions";
import auth from "services/Api/auth";
import history from "services/History/history";

export const DisconnectApp = ({disconnect}) => {

    useEffect(() => {
        auth.onLogout();
        
        disconnect();

        history.push(HOME_PATH);
    }, [disconnect]);


    return (
        <Box position='fixed' top={0} bottom={0} left={0} right={0} display='flex' justifyContent='center' alignItems='center'>
            <Typography component="p" variant='h1'> Vous avez bien été déconnécté, vous allez à présent être redirigé vers la page de connexion</Typography>
        </Box>
    );
};

export const DisconnetAppStore = connect(
    () => ({}),
    (dispatch) => ({
        disconnect: () => dispatch(disconnectAction()),
    })
) (DisconnectApp);