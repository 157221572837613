import axios from "./config";

const entities = {
    getAllEntities: async (filters) => {
        try {
            let params = {
                "filters[page]": filters.page,
            };

            if (filters?.category) {
                params = { ...params, "filters[category]": filters.category.id };
            }

            if (filters?.lat && filters?.lng && filters?.distance) {
                params = { ...params, "filters[lng]": filters.lng };
                params = { ...params, "filters[lat]": filters.lat };
                params = { ...params, "filters[distance]": filters.distance };
            }

            if (filters?.size) {
                params = { ...params, "filters[employees]": filters.size };
            }

            if (filters?.sorter) {
                const sorter = filters?.sorter.split(" ");

                params = { ...params, "filters[sortField]": sorter[0] };
                params = { ...params, "filters[sortOrder]": sorter[1] };
            }

            const result = await axios.get("/user/localized-entities", { params });

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getEntities: async (parameters) => {
        try {
            let params = {
                "filters[name]": parameters.name,
            };

            const result = await axios.get("/user/localized-entities", {
                params: parameters ? params : null,
            });

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getStructureEntities: async (parameters) => {
        try {
            let params = {
                "filters[name]": parameters.name,
            };

            const result = await axios.get("/user/localized-entities", {
                params: parameters ? params : null,
            });

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getOneEntity: async (id) => {
        try {
            const result = await axios.get(`/user/localized-entities/${id}`);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },
};

export default entities;
