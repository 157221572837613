export default function formatFileSize (fileSize) {
    switch (true) {
        case fileSize >= 1000000000:
            return (`${(fileSize / 1000000000).toFixed(2)} Go`);
        case fileSize >= 1000000:
            return (`${(fileSize / 1000000).toFixed(2)} Mo`);
        case fileSize >= 1000:
            return (`${(fileSize / 1000).toFixed(2)} Ko`);
        case fileSize >= 1:
            return (`${fileSize} Octets`);
        default:
            return ('0');
    }
};