import { Router, Switch, Route } from "react-router-dom";
import history from "services/History/history";
import {
    ABOUT_PATH,
    ANNOUNCEMENTS_PATH,
    CHAT_PATH,
    DASHBOARD_PATH,
    DISCONNECT_PATH,
    DOCUMENTS_PATH,
    FEEDBACK_PATH,
    FORGET_PATH,
    FUNCTIONNALITY_PATH,
    GOOD_PLAN_PATH,
    HOME_PATH,
    MY_ANNOUNCEMENTS_PATH,
    MY_PROJECT_PATH,
    NETWORK_PATH,
    NOTE_PATH,
    PLANNING_PATH,
    PROFILE_PATH,
    PROJECT_PATH,
    RESET_PASSWORD,
    SETTING_PATH,
    SIGNIN_PATH,
    SIGNUP_PATH,
    STRUCTURE_PATH,
    TESTIMONY_PATH,
    VALID_EMAIL_PATH,
    PRICES_PATH,
    CONTACT_PATH,
} from "Constant";
import ValidEmail from "Apps/Auth/ValidEmail/ValidEmailApp";
import { ResetPasswordAppStore } from "Apps/Auth/ResetPasswordApp/ResetPasswordApp.jsx";
import defaultTheme from "services/Themes/defaultTheme";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedContainerStore } from "Apps/ConnectedContainer/ConnectedContainer";
import { DisconnetAppStore } from "Apps/Auth/DisconnectApp/DisconnectApp";
import { authSelector } from "redux/selectors/authSelector";
import { getConnectionAction } from "redux/actions/authActions";
import { NetworkAppStore } from "Apps/Network/NetworkApp";
import { HomeStore } from "Apps/Public/Home/Home";
import { ProfileAppStore } from "Apps/Profile/ProfileApp";
import "react-notifications/lib/notifications.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AnnouncementsAppStore } from "Apps/Announcements/AnnouncementsApp";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { ChatAppStore } from "Apps/Chat/ChatApp";
import {
    addNewNotificationAction,
    connectToRealTimeAction,
    getProfileAction,
} from "redux/actions/profileActions";
import {
    getChatUserListAction,
    handleDeleteMessageAction,
    handleIsTypingAction,
    handleReceiveMessageAction,
    handleSubscriptionAction,
} from "redux/actions/chatActions";
import { useCallback, useEffect } from "react";
import moment from "moment";
import { ProjectAppStore } from "Apps/Project/ProjectApp";
import { DocumentsAppStore } from "Apps/Documents/DocumentsApp";
import { NotesAppStore } from "Apps/Notes/NotesApp";
import { ParametersAppStore } from "Apps/Parameters/ParametersApp";
import { createTheme, ThemeProvider } from "@mui/material";
import { StructureApp } from "Apps/Structure/StructureApp";
import { PlanningAppStore } from "Apps/Planning/PlanningApp";
import { Functionnality } from "Apps/Public/Functionnality/Functionnality";
import { FunctionnalityDetail } from "Apps/Public/FunctionnalityDetail/FunctionnalityDetail";
import { About } from "Apps/Public/About/About";
import { Testimony } from "Apps/Public/Testimony/Testimony";
import { Price } from "Apps/Public/Price/Price";
import { Contact } from "Apps/Public/Contact/Contact";

let frLocale = require("moment/locale/fr");

function App() {
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);

    const handleReceiveMercure = useCallback(
        ({ profileId, message }) => {
            const data = JSON.parse(message.data);
            const type = data.type;

            switch (type) {
                case "Subscription":
                    dispatch(handleSubscriptionAction(profileId, data));
                    break;
                case "new-message":
                    if (data.userTo?.id !== profileId) {
                        break;
                    }

                    dispatch(handleReceiveMessageAction(data));
                    break;
                case "delete-message":
                    dispatch(handleDeleteMessageAction(data));
                    break;
                case "is-typing":
                    dispatch(handleIsTypingAction(data));
                    break;
                case "notification":
                    dispatch(addNewNotificationAction(data));
                    break;
                default:
                    break;
            }
        },
        [dispatch]
    );

    useEffect(() => {
        moment.defineLocale("fr", frLocale);
    }, []);

    const initApp = useCallback(async () => {
        if (auth.connected === null) {
            dispatch(getConnectionAction());

            return;
        } else if (auth.connected === false) {
            return;
        }

        const profile = await dispatch(getProfileAction());

        if (!profile.result) {
            return;
        }

        dispatch(
            connectToRealTimeAction({
                profileId: profile?.data?.id,
                next: handleReceiveMercure,
            })
        );

        dispatch(getChatUserListAction());
    }, [dispatch, handleReceiveMercure, auth]);

    useEffect(() => {
        initApp();
    }, [initApp]);

    if (auth?.connected === null) {
        return <></>;
    }

    return (
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <Router history={history}>
                <Switch>
                    <Route
                        exact
                        path={[HOME_PATH, SIGNIN_PATH, SIGNUP_PATH, FORGET_PATH]}
                        component={HomeStore}
                    />

                    <Route exact path={FUNCTIONNALITY_PATH} component={Functionnality} />
                    <Route path={FUNCTIONNALITY_PATH} component={FunctionnalityDetail} />
                    <Route exact path={ABOUT_PATH} component={About} />
                    <Route exact path={TESTIMONY_PATH} component={Testimony} />
                    <Route exact path={PRICES_PATH} component={Price} />
                    <Route exact path={CONTACT_PATH} component={Contact} />

                    <Route exact path={DASHBOARD_PATH}>
                        <ConnectedContainerStore></ConnectedContainerStore>
                    </Route>

                    <Route path={VALID_EMAIL_PATH}>
                        <ValidEmail />
                    </Route>

                    <Route exact path={RESET_PASSWORD}>
                        <ResetPasswordAppStore />
                    </Route>

                    <Route path={NETWORK_PATH}>
                        <ConnectedContainerStore>
                            <NetworkAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={DISCONNECT_PATH}>
                        <DisconnetAppStore />
                    </Route>

                    <Route path={PROFILE_PATH}>
                        <ConnectedContainerStore alertDisplay={false}>
                            <ProfileAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route
                        exact
                        path={`${STRUCTURE_PATH}/:id`}
                        children={({ match }) => (
                            <ConnectedContainerStore>
                                <StructureApp id={match?.params?.id} />
                            </ConnectedContainerStore>
                        )}
                    />

                    <Route path={[ANNOUNCEMENTS_PATH, MY_ANNOUNCEMENTS_PATH]}>
                        <ConnectedContainerStore>
                            <AnnouncementsAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={CHAT_PATH}>
                        <ConnectedContainerStore>
                            <ChatAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={GOOD_PLAN_PATH}>
                        <ConnectedContainerStore></ConnectedContainerStore>
                    </Route>

                    <Route path={PROJECT_PATH}>
                        <ConnectedContainerStore>
                            <ProjectAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={MY_PROJECT_PATH}>
                        <ConnectedContainerStore>
                            <ProjectAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={PLANNING_PATH}>
                        <ConnectedContainerStore>
                            <PlanningAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={NOTE_PATH}>
                        <ConnectedContainerStore>
                            <NotesAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={FEEDBACK_PATH}>
                        <ConnectedContainerStore></ConnectedContainerStore>
                    </Route>

                    <Route path={DOCUMENTS_PATH}>
                        <ConnectedContainerStore>
                            <DocumentsAppStore />
                        </ConnectedContainerStore>
                    </Route>

                    <Route path={SETTING_PATH}>
                        <ConnectedContainerStore>
                            <ParametersAppStore />
                        </ConnectedContainerStore>
                    </Route>
                </Switch>
                <NotificationContainer />
            </Router>
        </ThemeProvider>
    );
}

export default App;
