import { useState } from "react";
import { DisplayMemberInfos } from "./DisplayMemberInfos";
import { EditMemberInfos } from "./EditMemberInfos";

export const MemberLine = ({ item, list, profile, update, index, deleteInvite, accessRight }) => {
    const [editMode, setEditMode] = useState(false);

    if (!editMode) {
        return (
            <DisplayMemberInfos
                item={item}
                setEditMode={setEditMode}
                list={list}
                deleteInvite={deleteInvite}
                index={index}
                profile={profile}
                accessRight={accessRight}
            />
        );
    }

    return (
        <EditMemberInfos
            item={item}
            setEditMode={setEditMode}
            index={index}
            update={update}
            profile={profile}
        />
    );
};
