import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { makeStyles } from '@mui/styles';
import Cropper from "react-cropper";
import ReactModal from "react-modal";
import { imageCropperStyle } from "./imageCropperStyle";
import CloseIcon from '@mui/icons-material/Close';
import "cropperjs/dist/cropper.css";
const useStyles = makeStyles(imageCropperStyle);

const ImageCropper = ({
    className,
    isOpen,
    imageURL,
    onClose,
    onValidate,
    isAvatar,
    ...props
}) => {
    const classes = useStyles();
    const cropperRef = useRef(null);

    const contentClassName = classes.content;
    const overlayClassName = classes.overlay;

    const [isCropDataSet, setIsCropDataSet] = useState(false);


    const onValidateCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;

        onValidate(cropper.getCroppedCanvas());
        setIsCropDataSet(false);
        onClose();
    };

    const onCrop = () => {
        if (cropperRef && cropperRef.current && !isCropDataSet) {
            const imageElement = cropperRef?.current;
            const cropper = imageElement?.cropper;

            const tmp = cropper?.getCanvasData();
            cropperRef?.current?.cropper?.setCropBoxData({
                left: tmp.left,
                top: tmp.top,
                width: tmp.width,
                height: tmp.height,
            });
            setIsCropDataSet(true);
        }
    };
    
    const onCloseCropper = () => {
        setIsCropDataSet(false);
        onClose();
    };

    return (
        <ReactModal
            portalClassName={className}
            className={contentClassName}
            overlayClassName={overlayClassName}
            isOpen={isOpen}
            {...props}
        >
            <Cropper
                ref={cropperRef}
                src={imageURL}
                minCropBoxWidth={isAvatar ? 200 : 300}
                viewMode={2}
                aspectRatio={isAvatar ? 2 / 3 : 21 / 9}
                guides={false}
                zoomable={false}
                ready={onCrop}
                className={classes.cropper}
            />
            <Button onClick={onValidateCrop} className={classes.validateButton} >
                Enregistrer
            </Button>
            <Button onClick={onCloseCropper} className={classes.closeButton}>
                <CloseIcon />
            </Button>
        </ReactModal>
    );

};

export default ImageCropper;