import React from 'react';
import Typography from '@mui/material/Typography';
import { BreadCrumbContainer, BreadCrumbLink } from './sc.CmtBreadCrumbs';

const CmtBreadCrumbs = ({ items, color = 'inherit', ...rest }) => {
    return (
        <BreadCrumbContainer aria-label="breadcrumb" color={color} {...rest}>
            {items.map((item, index) =>
                item.isActive ? (
                <Typography component='p' key={index} fontSize={12} fontWeight={600}>
                    {item.label}
                </Typography>
                ) : (
                <BreadCrumbLink key={index} color={color} to={item.link || '/'}>
                    {item.label}
                </BreadCrumbLink>
                ),
            )}
        </BreadCrumbContainer>
    );
};

export default CmtBreadCrumbs;
