import { Typography, Box, CircularProgress } from "@mui/material";

export default function CmtLoadingPage () {
    return (
        <Box 
            position='absolute' backgroundColor='rgba(0, 0, 0, 0.1)'
            left={0} right={0} top={0} bottom={0} zIndex={100}
            display='flex' justifyContent='center' alignItems='center'
            flexDirection='column'
        >
            <Typography component='h1' fontSize={40} color='rgba(0, 0, 0, 0.4)' mb='2em'>Veuillez Patienter</Typography> 
            <CircularProgress size={50} />
        </Box>
    );
};