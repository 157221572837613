import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { Message } from "./Message.jsx";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

export default function ContentMessages({ feedbackData, profileId }) {
    const [panel, setPanel] = useState(false);

    if (!feedbackData.conversation || feedbackData?.conversation?.length === 0) {
        return <></>;
    }

    return (
        <>
            <Typography component="p" variant="subtitle1" mb={5}>
                Vos anciens feedback
            </Typography>
            <AnimatePresence>
                {feedbackData?.conversation?.map((message, index) => {
                    return (
                        <Accordion
                            expanded={panel === message.id}
                            onChange={() => setPanel(panel === message.id ? false : message.id)}
                            key={index}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>
                                    Envoyé le {moment(message?.messageDate).format("LL à HH:mm")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Message message={message} profileId={profileId} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </AnimatePresence>
        </>
    );
}
