import { useTheme } from "@emotion/react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import CmtImage from "Components/CmtImage/CmtImage";
import { PublicMenu } from "Components/Public/Menu/PublicMenu";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";
import { Footer } from "../Partial/Footer";
import { ParagraphText } from "../Partial/ParagraphText";
import { TitleText } from "../Partial/TitleText";

export const About = () => {
    const theme = useTheme();

    return (
        <Box backgroundColor={theme.palette.primary.publicMain} pt={30}>
            <PublicMenu />

            <Container maxWidth="lg">
                <Grid container spacing={4} pb={40} mb={30} position="relative">
                    <Grid item xs={12} md={8}>
                        <Box height="100%" display="flex" alignItems="center">
                            <TitleText
                                fontSize={{
                                    xs: theme.palette.publicTypo.h3Size,
                                    md: theme.palette.publicTypo.h2Size,
                                    lg: theme.palette.publicTypo.h1Size,
                                }}
                            >
                                À propos
                            </TitleText>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CmtImage width="100%" src={"/images/Public/About/Fichier1.png"} />
                    </Grid>

                    <Grid item xs={12}>
                        <TitleText pb={5}> Qui sommes nous </TitleText>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box pr={10}>
                            <CmtImage width="100%" src={"/images/Public/About/Fichier2.png"} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <ParagraphText pr={10}>
                            Beatur, qui consedi dolores ciandae cumqui officabo. Fic te sinvendaepre
                            peliquis vellis enis et quunt. Ucit lat venessi mporatessum, offic to
                            volut ommo mos repelenducia nonsequissi doluptatis modi ditaecus eum
                            quunt. Ovidescitem ium imaximi, nis exerum inctaque officietur, nia
                            volorer ferumquo blaut
                        </ParagraphText>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <ParagraphText pr={10}>
                            quas distios res aceaque cus molo to berferum, consed modiam as
                            eatendelent ut unduci dolutem necto excepe cullit quunt quatemosae nem
                            arcide nat veni quos anis con rem cor atioreiunt et ea cuptatur sitibus
                            aut resti cusdandel int. sequiatia volore, sequas doluptiunt, conetum
                            sequis et que suntin eum fuga.
                        </ParagraphText>
                    </Grid>

                    <Grid item xs={12}>
                        <TitleText py={5}> Que propose t'on</TitleText>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <ParagraphText pr={10}>
                            Ovidescitem ium imaximi, nis exerum inctaque officietur, nia volorer
                            ferumquo blaut ullam exerume porehentione quas distios res aceaque cus
                            molo to berferum, consed modiam as eatendelent ut unduci dolutem necto
                            excepe cullit quunt quatemosae nem arcide sitiaep udictem seroviduci
                            consenem nat veni quos anis con rem cor atioreiunt et ea cuptatur
                            sitibus aut resti cullit quunt quatemosae nem arcide sitiaep sequis et
                            que cusdan del int sequiatia volore, sequas doluptiunt, conetum sequis
                            et que suntin eum fuga.
                        </ParagraphText>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Box pr={10}>
                            <CmtImage width="100%" src={"/images/Public/About/Fichier3.png"} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Box pr={10} pt={15}>
                            <CmtImage width="100%" src={"/images/Public/About/Fichier4.png"} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <TitleText py={5} pt={10}>
                            Pour qui le proposons nous
                        </TitleText>
                        <ParagraphText pr={10}>
                            Ovidescitem ium imaximi, nis exerum inctaque officietur, nia volorer
                            ferumquo blaut ullam exerume porehentione quas distios res aceaque cus
                            molo to berferum, consed modiam as eatendelent ut unducitem seroviduci
                            consenem nat veni quos anis con rem cor atioreiunt et ea cuptatur
                            sitibus aut resti cusdandel int.sequiatia volore, sequas doluptiunt,
                            conetum sequis et que suntin ut unducitem seroviduci eum fuga.
                        </ParagraphText>
                    </Grid>

                    <Grid item xs={12}>
                        <TitleText py={5} pt={10}>
                            Comment le mettons nous en place
                        </TitleText>
                        <ParagraphText pr={10}>
                            Ovidescitem ium imaximi, nis exerum inctaque officietur, nia volorer
                            ferumquo blaut ullam exerume porehentione quas distios res aceaque cus
                            molo to berferum, consed modiam as eatendelent ut unducitem seroviduci
                            consenem nat veni quos anis con rem cor atioreiunt et ea cuptatur
                            sitibus aut resti cusdandel int.sequiatia volore, sequas doluptiunt,
                            conetum sequis et que suntin ut unducitem seroviduci eum fuga.
                        </ParagraphText>
                    </Grid>

                    <Grid item xs={12}>
                        <TitleText py={5} pt={10}>
                            L'équipe
                        </TitleText>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <Box px={20}>
                            <Box
                                component="img"
                                borderRadius="50%"
                                src={"/images/Public/Images/1574755504325.jpeg"}
                                width="100%"
                            />
                            <TitleText
                                textAlign="center"
                                fontSize={theme.palette.publicTypo.h3Size}
                                pt={5}
                            >
                                CEO
                            </TitleText>
                            <TitleText
                                textAlign="center"
                                fontSize={theme.palette.publicTypo.h4Size}
                                color="black"
                            >
                                Fabien Brossier
                            </TitleText>

                            <ParagraphText pt={5}>
                                Veni quos anis con rem cor atioreiunt et ea cuptatur sitibus aut
                                resti cusdandel int. sequiatia volore.
                            </ParagraphText>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <Box px={20}>
                            <Box
                                component="img"
                                borderRadius="50%"
                                src={"/images/Public/Images/1600943325278.jpeg"}
                                width="100%"
                            />
                            <TitleText
                                textAlign="center"
                                fontSize={theme.palette.publicTypo.h3Size}
                                pt={5}
                            >
                                Developpeur
                            </TitleText>
                            <TitleText
                                textAlign="center"
                                fontSize={theme.palette.publicTypo.h4Size}
                                color="black"
                            >
                                Bryan Bouillot
                            </TitleText>
                            <ParagraphText pt={5}>
                                Veni quos anis con rem cor atioreiunt et ea cuptatur sitibus aut
                                resti cusdandel int. sequiatia volore.
                            </ParagraphText>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <Box px={20}>
                            <Box
                                component="img"
                                borderRadius="50%"
                                src={"/images/Public/Images/1620738989931.jpeg"}
                                width="100%"
                            />
                            <TitleText
                                textAlign="center"
                                fontSize={theme.palette.publicTypo.h3Size}
                                pt={5}
                            >
                                Webdesigner
                            </TitleText>
                            <TitleText
                                textAlign="center"
                                fontSize={theme.palette.publicTypo.h4Size}
                                color="black"
                            >
                                Edouard Laur
                            </TitleText>
                            <ParagraphText pt={5}>
                                Veni quos anis con rem cor atioreiunt et ea cuptatur sitibus aut
                                resti cusdandel int. sequiatia volore.
                            </ParagraphText>
                        </Box>
                    </Grid>
                    <ShapeImg src={"/images/Public/Shape/Shape4.png"} bottom={0} right={0} />
                    <ShapeImg src={"/images/Public/Shape/Shape6.png"} bottom={0} left={0} />
                </Grid>
            </Container>

            <Footer />
        </Box>
    );
};
