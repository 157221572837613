export const planningSelector = ({planning}) => {
    if (!planning?.eventList) {
        return planning;
    }

    let list = [];
    
    planning?.eventList?.forEach(item => {
        let listId = [item?.calendar?.id];

        item?.projectsShared.forEach(it => listId?.push(it?.id));

        const result = listId?.filter(element => planning.selectedCalendars?.includes(element));

        if (result?.length > 0) {
            list.push(item);
        }
    });

    return {...planning, eventList: list};
};

export const allPlanningSelector = ({planning}) => planning;