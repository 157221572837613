import moment from "moment";
import axios from "./config";

const project = {
    getProjectList: async (filters) => {
        try {
            let params = {};
            if (filters.projectType) {
                params = { ...params, "filters[projectType]": filters.projectType };
            }

            if (filters.fromDate) {
                params = { ...params, "filters[fromDate]": filters.fromDate };
            }

            if (filters.toDate) {
                params = { ...params, "filters[toDate]": filters.toDate };
            }

            if (filters.lng && filters.lat && filters.distance) {
                params = { ...params, "filters[lng]": filters.lng };
                params = { ...params, "filters[lat]": filters.lat };
                params = { ...params, "filters[distance]": filters.distance };
            }

            if (filters?.sorter) {
                const sorter = filters?.sorter.split(" ");

                params = { ...params, "filters[sortField]": sorter[0] };
                params = { ...params, "filters[sortOrder]": sorter[1] };
            }

            const result = await axios.get("/user/projects/public", { params: params });

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getMyProjectList: async () => {
        try {
            const result = await axios.get("/user/projects");

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getOneProject: async (id) => {
        try {
            const result = await axios.get(`/user/projects/${id}`);

            let data = result?.data;

            data.spectacle = {
                title: "M&Moi",
                category: 2,
                publicType: 1,
                duration: "02:05",
                status: true,
                production: [
                    { category: 1, name: "Fabien BROSSIER" },
                    { category: 3, name: "Bryan BOUILLOT" },
                    { category: 3, name: "Edouard LAUR" },
                ],
            };

            return { result: true, data: data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    createProject: async (values) => {
        try {
            const formData = new FormData();

            formData.append("name", values.name);
            formData.append("description", values.description);
            formData.append("projectType", values.projectType);
            formData.append("isPublic", values.isPublic || false);
            formData.append("performancesNb", values.performancesNb || "");
            formData.append(
                "startDate",
                values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : ""
            );
            formData.append(
                "endDate",
                values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : ""
            );

            if (values.fullAddress && values.lng && values.lat) {
                formData.append("fullAddress", values.fullAddress || "");
                formData.append("lng", values.lng || "");
                formData.append("lat", values.lat || "");
            }

            const result = await axios.post(`/user/projects/${values.entityId}/create`, formData);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    editProject: async (values) => {
        try {
            const formData = new FormData();

            formData.append("name", values.name);
            formData.append("description", values.description);
            formData.append("projectType", values.projectType);
            formData.append("isPublic", values.isPublic);
            formData.append("performancesNb", values.performancesNb || "");

            formData.append(
                "startDate",
                values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : ""
            );
            formData.append(
                "endDate",
                values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : ""
            );
            values.projectChecks?.forEach((elem, index) => {
                formData.append(`projectChecks[${index}][title]`, elem.title);
                formData.append(`projectChecks[${index}][description]`, elem.description || "");
                formData.append(`projectChecks[${index}][position]`, elem.position);
                formData.append(`projectChecks[${index}][isDone]`, elem.isDone ? 1 : 0);
            });

            if (values.fullAddress && values.lng && values.lat) {
                formData.append("fullAddress", values.fullAddress || "");
                formData.append("lng", values.lng || "");
                formData.append("lat", values.lat || "");
            }

            if ("logoFile" in values) {
                formData.append("logoFile[file]", values.logoFile);
            }

            if ("coverFile" in values) {
                formData.append("coverFile[file]", values.coverFile);
            }

            const result = await axios.post(`/user/projects/${values.entityId}/update`, formData);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    deleteProject: async (id) => {
        try {
            const result = await axios.delete(`/user/projects/${id}`);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getProjectType: async () => {
        try {
            const result = await axios.get("/user/project-types");

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    inviteMember: async (data) => {
        try {
            const formData = new FormData();

            formData.append("role", data?.role);
            formData.append("accessRights", data?.accessRight);

            const result = await axios.post(
                `/user/project-entities/${data?.projectId}/${data?.entityId}/create`,
                formData
            );

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    deleteInvitation: async (data) => {
        try {
            await axios.delete(`/user/project-entities/${data.entityId}`);

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    updateMember: async (data) => {
        try {
            const formData = new FormData();

            formData.append("role", data?.role);
            formData.append("accessRights", data?.accessRights);

            const result = await axios.post(
                `/user/project-entities/${data?.invitationId}/update`,
                formData
            );

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    sendInvitationResponse: async (data) => {
        try {
            await axios.post(
                `/user/project-entities/${data?.projectEntityId}/answer/${data.accept ? 1 : 0}`
            );

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },
};

export default project;
