import { Autocomplete, Box, Button, Typography } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { REDIRECTION_TIME } from "Constant";
import { Formik } from "formik";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import auth from "services/Api/auth";
import entities from "services/Api/entities";

const INIT_VALUE = {
    name: "",
    compagniesTypes: [],
    siret: "",
    description: "",
    address1: "",
    address2: "",
    zipCode: "",
    city: "",
    size: "",
};

export const JoinCompanyForm = ({ submit, disconnect, onClose, setCreateMode, profile }) => {
    const [list, setList] = useState([]);

    const [disabled, setDisabled] = useState(true);

    const registeredCompanies = profile?.profile?.compagnyUserActives
        ?.filter((elem) => elem?.userAccepted || elem?.userAccepted === null)
        ?.map((item) => item?.compagny?.id);

    const handleCompanyName = async (value) => {
        if (value.length < 3) {
            setList([]);

            return;
        }

        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();

            return;
        }

        entities.getStructureEntities({ name: value }).then((result) => {
            if (result.result) {
                let tmpList = result.data[0];

                tmpList.push({
                    name: "JE NE TROUVE PAS MA STRUCTURE",
                    default: true,
                });

                setList(tmpList);
            }
        });
    };

    const setCompanyValue = (newValue, setFieldValue) => {
        if (!newValue) {
            setFieldValue("name", "");
            setList([]);

            return;
        }

        setDisabled(false);

        if (newValue?.default) {
            setCreateMode(true);

            return;
        }

        if (registeredCompanies.includes(newValue?.id)) {
            NotificationManager.error(
                "Vous appartenez déjà à cette structure.",
                "Erreur",
                REDIRECTION_TIME
            );

            setFieldValue("name", "");

            return;
        }

        entities.getOneEntity(newValue.id).then((result) => {
            if (result?.result) {
                setFieldValue("name", result?.data?.name || "");
                setFieldValue("siret", result?.data?.siret || "");
                setFieldValue("address1", result?.data?.address1 || "");
                setFieldValue("address2", result?.data?.address2 || "");
                setFieldValue("zipCode", result?.data?.zipCode || "");
                setFieldValue("city", result?.data?.city || "");
                setFieldValue("compagniesTypes", result?.data?.compagniesTypes || []);
                setFieldValue("size", result?.data?.employeeRange || "");
                setFieldValue("description", result?.data?.description || "");
            } else {
                setFieldValue("name", "");

                NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
            }
        });
    };

    const checkError = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = "Veuillez renseigner le nom de votre structure";
        }

        if (!values.siret) {
            errors.siret = "Veuillez indiquer votre numéro de siret";
        } else if (!/^[0-9]/i.test(values.siret)) {
            errors.siret = "Votre numéro de siret ne doit être composé que de chiffres";
        } else if (values.siret.length < 14) {
            errors.siret = "Votre numéro de siret doit être composé de 14 chiffres";
        }

        if (!values.address1) {
            errors.address1 = "Veuillez renseigner votre adresse";
        }

        if (!values.zipCode) {
            errors.zipCode = "Veuillez renseigner votre code postal";
        } else if (!/^[0-9]/i.test(values.zipCode)) {
            errors.zipCode = "Votre code postale est incorrect";
        }

        if (!values.city) {
            errors.city = "Veuillez renseigner votre ville";
        }

        if (values.compagniesTypes === "") {
            errors.compagniesTypes = "Veuillez indiquer le type de votre structure";
        }

        if (!values.size) {
            errors.size = "Veuillez renseigner la taille de votre sctructure";
        }

        return errors;
    };

    return (
        <Box>
            <Typography component="div" variant="h1">
                Rejoindre une structure existante
            </Typography>
            <Formik
                initialValues={INIT_VALUE}
                validate={(values) => checkError(values)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const result = await submit(values);

                    if (result?.result) {
                        setList([]);
                        resetForm();
                    }

                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                }) => (
                    <CmtForm onSubmit={handleSubmit}>
                        <Autocomplete
                            freeSolo
                            fullWidth
                            filterOptions={(options) => options}
                            id="nameAutoComplete"
                            inputValue={values.name}
                            onChange={(event, newValue) => {
                                setCompanyValue(newValue, (name, val) => setFieldValue(name, val));
                            }}
                            options={list}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                                <Box
                                    mb={1}
                                    {...props}
                                    id={`autoComplete-${option.name.replaceAll(" ", "-")}`}
                                    display="flex"
                                    flexDirection="column"
                                    width="100%"
                                >
                                    <Box my={0} width="100%">
                                        <Typography component="p" variant="h4">
                                            {option.name}
                                        </Typography>
                                    </Box>
                                    <Box my={0} width="100%">
                                        <Typography component="p" variant="h5">
                                            {option.siret}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <FormInput
                                    {...params}
                                    onChange={(e) => {
                                        setFieldValue("name", e.target.value);
                                        handleCompanyName(e.target.value);
                                    }}
                                    label="Nom de la structure"
                                    onBlur={handleBlur}
                                    error={touched.name && errors.name}
                                />
                            )}
                        />

                        <Box width="100%" display="flex" mt={10} justifyContent="space-between">
                            <Button
                                id="cancelCompanyButton"
                                type="button"
                                variant="outlined"
                                color="secondary"
                                onClick={onClose}
                            >
                                <Typography>Annuler</Typography>
                            </Button>
                            <Button
                                id="createCompanyButton"
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={disabled || isSubmitting}
                            >
                                <Typography>Rejoindre</Typography>
                            </Button>
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        </Box>
    );
};
