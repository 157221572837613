import { Box } from "@mui/material";

export const ShapeImg = ({
    src,
    height = "100px",
    width = "100px",
    position = "absolute",
    rotate = "0",
    ...rest
}) => {
    return (
        <Box
            component="img"
            position={position}
            height={height}
            width={width}
            src={src}
            sx={{
                transform: `rotate(${rotate})`,
            }}
            {...rest}
        />
    );
};
