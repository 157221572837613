import axios from "axios";
import { REDIRECTION_TIME } from "Constant";
import { NotificationManager } from "react-notifications";
import axiosRetry from "axios-retry";

let api_count = 0;

let api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error?.response?.status;

        if (api_count === 3) {
            NotificationManager.error(
                "Une erreur est survenu, essayez de rafraichir la page.",
                "Erreur",
                REDIRECTION_TIME
            );
            api_count = 0;
        }

        if (status >= 500) {
            NotificationManager.error(
                "Une erreur est survenu, essayez de rafraichir la page.",
                "Erreur",
                REDIRECTION_TIME
            );
        }

        throw error;
    }
);

axiosRetry(api, {
    retries: 3,
    retryDelay: (retryCount) => {
        api_count = retryCount;

        return retryCount * 2000;
    },
    retryCondition: () => {
        if (!window.navigator.onLine) {
            return true;
        }

        return false;
    },
});

export default api;
