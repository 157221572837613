import { Box, Button, CardContent, Grid, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { useState } from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { DEFAULT_AUTHOR_TYPE_LIST } from "Constant";

export const DisplaySpectacleAuthor = ({ projectData, setEditionMode }) => {
    const [hover, setHover] = useState(false);

    return (
        <Box p={2}>
            <CmtCard
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                id="authorCardCmtCard"
            >
                <TileTitle>Auteurs</TileTitle>
                <CardContent>
                    <Grid container spacing={4}>
                        {projectData?.spectacle?.authors?.map((item, index) => (
                            <Grid item xs={12} sm={6} lg={4} key={index}>
                                <Box
                                    borderRadius="5px"
                                    backgroundColor="rgba(240, 240, 240, 1)"
                                    p={2}
                                >
                                    <Typography fontSize={16}>
                                        {item?.firstName} {item?.lastName}
                                    </Typography>
                                    <Typography component="span" fontSize={12}>
                                        {
                                            DEFAULT_AUTHOR_TYPE_LIST?.find(
                                                (elem) => elem.id === item?.category
                                            ).name
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Box display="flex" justifyContent="flex-end" height={30} mt={5}>
                        {hover && (
                            <Button
                                onClick={() => {
                                    setEditionMode(true);
                                }}
                                variant="contained"
                                color="secondary"
                                id="descriptionCardUpdateButton"
                            >
                                <Hidden xsDown> Modifier </Hidden>
                                <Hidden smUp>
                                    <CreateOutlinedIcon className="pointer" />
                                </Hidden>
                            </Button>
                        )}
                    </Box>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
