import {
    CREATE_PATH,
    EDIT_PATH,
    MY_PROJECT_PATH,
    PROJECT_PATH,
    REDIRECTION_TIME,
    UNOTHAURIZED_PATH,
} from "Constant";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { notConnectedAction } from "redux/actions/authActions";
import {
    changeProjectFiltersAction,
    deleteProjectAction,
    getMyProjectListAction,
    getProjectListAction,
} from "redux/actions/projectActions";
import { profileSelector } from "redux/selectors/profileSelector";
import { projectSelector } from "redux/selectors/projectSelector";
import { CreateProjectApp } from "./CreateProject/CreateProjectApp/CreateProjectApp";
import history from "services/History/history";
import { Box, Typography } from "@mui/material";
import { NotificationManager } from "react-notifications";
import { ProjectDetail } from "./ProjectDetail/ProjectDetail";
import { UnauthorizedPage } from "Components/UnauthorizedPage/UnauthorizedPage";
import { PerfectScroll } from "Components/PerfectScroll/sc.PerfectScroll";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";
import { RestrictedRoute } from "Components/ResctrictedRoute/RestrictedRoute";
import { ProjectPage } from "./ProjectPage";
import { MyProjectPage } from "./MyProjectPage";

export const ProjectApp = ({
    projectData,
    profile,
    getProjectList,
    getMyProjectList,
    deleteProject,
    changeFilter,
}) => {
    const [deletePopine, setDeletePopine] = useState(null);

    useEffect(() => {
        if (window.location.pathname === `${PROJECT_PATH}${UNOTHAURIZED_PATH}`) {
            return;
        }

        if (!profile?.profile) {
            return;
        }

        if (
            !profile?.profile?.isCompleted ||
            (!profile?.profile?.intermittent && profile?.profile?.compagnyUsers?.length === 0)
        ) {
            history.push(`${PROJECT_PATH}${UNOTHAURIZED_PATH}`);
        }
    }, [profile]);

    useEffect(() => {
        if (projectData.error) {
            return;
        }

        if (!projectData?.myProjectList && !projectData.myLoading && !projectData?.error) {
            getMyProjectList();
        }

        if (!projectData?.projectList && !projectData?.loading && !projectData?.error) {
            getProjectList();
        }
    }, [projectData, getProjectList, getMyProjectList]);

    if (!profile?.profile) {
        return <></>;
    }

    return (
        <>
            <PerfectScroll>
                <Switch>
                    <Route exact path={`${PROJECT_PATH}${UNOTHAURIZED_PATH}`}>
                        <UnauthorizedPage />
                    </Route>

                    <Route exact path={PROJECT_PATH}>
                        <RestrictedRoute profile={profile} baseUrl={PROJECT_PATH}>
                            <ProjectPage
                                projectData={projectData}
                                getProjectList={getProjectList}
                                changeFilter={changeFilter}
                            />
                        </RestrictedRoute>
                    </Route>

                    <Route exact path={MY_PROJECT_PATH}>
                        <RestrictedRoute profile={profile} baseUrl={MY_PROJECT_PATH}>
                            <MyProjectPage
                                projectData={projectData}
                                getMyProjectList={getMyProjectList}
                            />
                        </RestrictedRoute>
                    </Route>

                    <Route
                        exact
                        path={[`${PROJECT_PATH}${CREATE_PATH}`, `${MY_PROJECT_PATH}${CREATE_PATH}`]}
                    >
                        <RestrictedRoute profile={profile} baseUrl={PROJECT_PATH}>
                            <CreateProjectApp profile={profile} />
                        </RestrictedRoute>
                    </Route>

                    <Route
                        exact
                        path={`${PROJECT_PATH}/:id${EDIT_PATH}`}
                        children={({ match }) => (
                            <RestrictedRoute profile={profile} baseUrl={PROJECT_PATH}>
                                <ProjectDetail
                                    projectId={match?.params?.id}
                                    deletePopine={setDeletePopine}
                                    profile={profile}
                                />
                            </RestrictedRoute>
                        )}
                    />
                </Switch>
            </PerfectScroll>

            <CmtDeleteDialog
                open={Boolean(deletePopine)}
                onCancel={() => setDeletePopine(null)}
                onDelete={async () => {
                    deleteProject(deletePopine).then((result) => {
                        if (result.result) {
                            history.push(PROJECT_PATH);
                        } else {
                            NotificationManager.error(
                                result?.error?.message || "Une Erreur s'est produite",
                                "Erreur",
                                REDIRECTION_TIME
                            );
                        }
                    });
                    setDeletePopine(null);
                }}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        Êtes-vous sûr de vouloir supprimer ce projet ?
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>
        </>
    );
};

export const ProjectAppStore = connect(
    (state) => ({
        profile: profileSelector(state),
        projectData: projectSelector(state),
    }),
    (dispatch) => ({
        disconnect: () => dispatch(notConnectedAction()),
        getProjectList: () => dispatch(getProjectListAction()),
        getMyProjectList: () => dispatch(getMyProjectListAction()),
        deleteProject: (id) => dispatch(deleteProjectAction(id)),
        changeFilter: (data) => dispatch(changeProjectFiltersAction(data)),
    })
)(ProjectApp);
