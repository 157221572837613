import {
    ADD_NEW_NOTIFICATION,
    REQUEST_ANSWER_INVITE_ERROR,
    REQUEST_ANSWER_INVITE_SUCCESS,
    REQUEST_DELETE_INVITE_ERROR,
    REQUEST_DELETE_INVITE_SUCCESS,
    REQUEST_NOTIFICATION_ERROR,
    REQUEST_NOTIFICATION_READ_ERROR,
    REQUEST_NOTIFICATION_READ_SUCCESS,
    REQUEST_NOTIFICATION_SUCCESS,
    REQUEST_PROFILE,
    REQUEST_PROFILE_ERROR,
    REQUEST_PROFILE_SUCCESS,
    REQUEST_REAL_TIME_ERROR,
    REQUEST_REAL_TIME_SUCCESS,
    REQUEST_UPDATE_PROFILE_ERROR,
    REQUEST_UPDATE_PROFILE_SUCCESS,
} from "redux/constants/profileConstants";
import auth from "services/Api/auth";
import companies from "services/Api/companies";
import profileUser from "services/Api/profileUser";
import connexion from "services/RealTime/connexion";
import { disconnectAction, notConnectedAction } from "./authActions";

export const requestProfile = () => ({
    type: REQUEST_PROFILE,
    loading: true,
});

export const requestProfileSuccess = (result) => ({
    type: REQUEST_PROFILE_SUCCESS,
    loading: false,
    data: result,
});

export const requestProfileError = (error) => ({
    type: REQUEST_PROFILE_ERROR,
    loading: false,
    error: error,
});

export const getProfileAction = (data) => {
    return async (dispatch) => {
        dispatch(requestProfile());

        if (!data?.checkVerified) {
            const check = await auth.checkIsAuth();

            if (!check) {
                dispatch(notConnectedAction());

                return;
            }
        }

        const result = await profileUser.getAuthUser();

        if (!result.result) {
            dispatch(requestProfileError(result.err));
        } else {
            dispatch(requestProfileSuccess(result.data));
        }

        return result;
    };
};

export const requestUpdateProfileSuccess = (data) => ({
    type: REQUEST_UPDATE_PROFILE_SUCCESS,
    loading: false,
    data: data,
});

export const requestUpdateProfileError = (error) => ({
    type: REQUEST_UPDATE_PROFILE_ERROR,
    loading: false,
    error: error,
});

export const updateProfileAction = (values) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await profileUser.updateProfile(values);

        if (!result.result) {
            dispatch(requestUpdateProfileError(result.err));
        } else {
            dispatch(requestUpdateProfileSuccess(result.data));
        }
    };
};

export const deleteAccountAction = () => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await profileUser.deleteProfile();

        if (result.result) {
            dispatch(disconnectAction());
        }
    };
};

export const requestNotificationReadSuccess = (notification) => ({
    type: REQUEST_NOTIFICATION_READ_SUCCESS,
    notification: notification,
});

export const requestNotificationReadError = (error) => ({
    type: REQUEST_NOTIFICATION_READ_ERROR,
    error: error,
});

export const markAsReadAction = (id) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await profileUser.markNotificationAsRead(id);

        if (result.result) {
            dispatch(requestNotificationReadSuccess(result?.data));
        } else {
            dispatch(requestNotificationReadError(result?.error));
        }

        return result;
    };
};

export const requestNotificationSuccess = (notifications) => ({
    type: REQUEST_NOTIFICATION_SUCCESS,
    notifications: notifications,
});

export const requestNotificationError = (error) => ({
    type: REQUEST_NOTIFICATION_ERROR,
    error: error,
});

export const getNotificationsAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await profileUser.getNotifications(data);

        if (result.result) {
            dispatch(requestNotificationSuccess(result?.data));
        } else {
            dispatch(requestNotificationError(result?.error));
        }

        return result;
    };
};

export const requestAnswerInviteSuccess = (invite) => ({
    type: REQUEST_ANSWER_INVITE_SUCCESS,
    invite: invite,
});

export const requestAnswerInviteError = (error) => ({
    type: REQUEST_ANSWER_INVITE_ERROR,
    error: error,
});

export const submitInvitationResponseAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = companies.answerInvite(data).then((result) => {
            if (result.result) {
                dispatch(requestAnswerInviteSuccess(result?.data));
            } else {
                dispatch(requestAnswerInviteError(result?.error));
            }

            return result;
        });

        return result;
    };
};

export const requestDeleteInviteSuccess = (id) => ({
    type: REQUEST_DELETE_INVITE_SUCCESS,
    id: id,
});

export const requestDeleteInviteError = (error) => ({
    type: REQUEST_DELETE_INVITE_ERROR,
    error: error,
});

export const deleteInviteAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = companies.deleteMember(data.compagnyUserId).then((result) => {
            if (result.result) {
                dispatch(requestDeleteInviteSuccess(data?.compagnyUserId));
            } else {
                dispatch(requestDeleteInviteError(result?.error));
            }

            return result;
        });

        return result;
    };
};

export const addNewNotificationAction = (notification) => ({
    type: ADD_NEW_NOTIFICATION,
    notification: notification,
});

export const requestRealTimeServerSuccess = (eventSource) => ({
    type: REQUEST_REAL_TIME_SUCCESS,
    eventSource: eventSource,
    loading: false,
});

export const requestRealTimeServerError = (error) => ({
    type: REQUEST_REAL_TIME_ERROR,
    error: error,
    loading: false,
});

export const connectToRealTimeAction = (data) => {
    return async (dispatch) => {
        const result = await connexion.connectToRealTimeServer(data);

        if (result.result) {
            dispatch(requestRealTimeServerSuccess(result.data));
        } else {
            dispatch(requestRealTimeServerError(result.error));
        }
    };
};
