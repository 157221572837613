import {
    Box,
    Button,
    CardContent,
    FormControl,
    FormHelperText,
    Grid,
    Hidden,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import { DeleteIconButton, TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { FieldArray, Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { DEFAULT_AUTHOR_TYPE_LIST } from "Constant";

export const EditionSpectacleAuthor = ({
    projectData,
    submit,
    setEditionMode,
    authorList = DEFAULT_AUTHOR_TYPE_LIST,
}) => {
    const checkError = (values) => {
        let errors = {};

        values.authors.forEach((item, index) => {
            let error = null;

            if (!item.firstName) {
                error = {
                    ...error,
                    firstName: "Veuillez indiquer le prénom du professionnel",
                };
            }

            if (!item.lastName) {
                error = {
                    ...error,
                    lastName: "Veuillez indiquer le Nom du professionnel",
                };
            }

            if (!item.category) {
                error = {
                    ...error,
                    category: "Veuillez renseigner la catégorie",
                };
            }

            if (error) {
                errors.authors = { ...errors.authors, [index]: error };
            }
        });

        return errors;
    };

    return (
        <Box p={2}>
            <CmtCard>
                <TileTitle>Auteurs</TileTitle>
                <CardContent>
                    <Formik
                        initialValues={{
                            authors: projectData?.spectacle?.authors || [],
                        }}
                        validate={(values) => checkError(values)}
                        onSubmit={(values) => {
                            submit({ spectacle: { ...projectData?.spectacle, ...values } });

                            setEditionMode(false);
                        }}
                    >
                        {({ values, touched, errors, handleSubmit, handleBlur, handleChange }) => (
                            <CmtForm onSubmit={handleSubmit}>
                                <Grid container spacing={4}>
                                    <FieldArray name="authors">
                                        {({ remove, push }) => (
                                            <>
                                                {values.authors.map((item, index) => (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        spacing={4}
                                                        key={index}
                                                    >
                                                        <Grid item xs={5}>
                                                            <FormControl
                                                                variant="standard"
                                                                margin={"none"}
                                                                fullWidth
                                                            >
                                                                <InputLabel id="spectacleTypeLabel">
                                                                    Catégorie
                                                                </InputLabel>
                                                                <Select
                                                                    name={`authors.${index}.category`}
                                                                    value={item.category}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    fullWidth
                                                                    error={
                                                                        touched.authors &&
                                                                        touched.authors[index]
                                                                            ?.category &&
                                                                        errors.authors &&
                                                                        Boolean(
                                                                            errors.authors[index]
                                                                                ?.category
                                                                        )
                                                                    }
                                                                >
                                                                    {authorList?.map((it, ind) => (
                                                                        <MenuItem
                                                                            value={it.id}
                                                                            key={`authorType ${ind}`}
                                                                            id={`authorType${ind}`}
                                                                        >
                                                                            {it.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <FormHelperText error>
                                                                    {touched.authors &&
                                                                        touched.authors[index]
                                                                            ?.category &&
                                                                        errors.authors &&
                                                                        errors.authors[index]
                                                                            ?.category}
                                                                </FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3} display="flex">
                                                            <FormInput
                                                                label="Prénom"
                                                                name={`authors.${index}.firstName`}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                margin="none"
                                                                value={item.firstName}
                                                                error={
                                                                    touched.authors &&
                                                                    touched.authors[index]
                                                                        ?.firstName &&
                                                                    errors.authors &&
                                                                    errors.authors[index]?.firstName
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} display="flex">
                                                            <FormInput
                                                                label="Nom"
                                                                name={`authors.${index}.lastName`}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                margin="none"
                                                                value={item.lastName}
                                                                error={
                                                                    touched.authors &&
                                                                    touched.authors[index]
                                                                        ?.lastName &&
                                                                    errors.authors &&
                                                                    errors.authors[index]?.lastName
                                                                }
                                                            />
                                                            <DeleteIconButton
                                                                sx={{ mt: 3, ml: 2 }}
                                                                key={`authorsDeleteButton ${index}`}
                                                                onClick={() => remove(index)}
                                                            >
                                                                <CloseIcon />
                                                            </DeleteIconButton>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Box
                                                    display="flex"
                                                    mt={5}
                                                    mb={3}
                                                    justifyContent={"flex-end"}
                                                    width="100%"
                                                >
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            push({
                                                                firstName: "",
                                                                lastName: "",
                                                                category: "",
                                                            })
                                                        }
                                                    >
                                                        <CheckIcon /> Ajouter une personne
                                                    </Button>
                                                </Box>
                                            </>
                                        )}
                                    </FieldArray>
                                </Grid>
                                <Box display="flex" justifyContent="flex-end" height={30} mt={5}>
                                    <Box mr={2}>
                                        <Button
                                            type="button"
                                            onClick={() => setEditionMode(false)}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            <Hidden xsDown> Annuler </Hidden>
                                            <Hidden smUp>
                                                <CloseIcon />
                                            </Hidden>
                                        </Button>
                                    </Box>

                                    <Box ml={2}>
                                        <Button
                                            type="submit"
                                            id="submitDescriptionCard"
                                            variant="contained"
                                            color="primary"
                                        >
                                            <Hidden xsDown> Valider </Hidden>
                                            <Hidden smUp>
                                                <CheckIcon />
                                            </Hidden>
                                        </Button>
                                    </Box>
                                </Box>
                            </CmtForm>
                        )}
                    </Formik>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
