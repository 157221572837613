import { DISCONNECT, NOT_CONNECTED } from "redux/constants/authConstants";
import {
    CHANGE_NOTES_FILTER,
    GET_NOTES,
    GET_NOTES_ERROR,
    GET_NOTES_SUCCESS,
    UPDATE_ONE_NOTE,
} from "redux/constants/notesConstants";

const INIT_STATE = {
    notesList: null,
    notesInfos: null,
    filters: {
        category: sessionStorage.getItem("NotesFilterCategory") || "",
        sorter: sessionStorage.getItem("NotesFilterSorter") || "creationDate DESC",
    },
    loading: false,
    error: null,
};

const updateOneNote = (state, action) => {
    let notesList = state.notesList;

    const index = notesList.findIndex((note) => note.id === action.data?.id);

    notesList[index] = action.data;

    return { ...state, notesList: notesList, error: null };
};

export default function notesReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_NOTES:
            return { ...state, loading: action.loading };
        case GET_NOTES_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                notesList: action.data[0],
                notesInfos: action.data[1],
                error: null,
            };
        case GET_NOTES_ERROR:
            return { ...state, loading: action.loading, error: action.error };
        case CHANGE_NOTES_FILTER:
            return { ...state, filters: action.filters };
        case UPDATE_ONE_NOTE:
            return updateOneNote(state, action);
        case DISCONNECT || NOT_CONNECTED:
            return { ...INIT_STATE };
        default:
            return state;
    }
}
