import { useState } from "react";
import DisplayModeProjectInfos from "./DisplayModeInfos";
import EditionModeProjectInfos from "./EditionModeInfos";

export const ProjectHeaderInfos = ({project, updateProject}) => {
    const [editionMode, setEditionMode] = useState(false);

    if (!editionMode) {
        return ( <DisplayModeProjectInfos project={project} setEditionMode={setEditionMode} /> );
    } else {
        return ( <EditionModeProjectInfos project={project} setEditionMode={setEditionMode} submit={updateProject} /> );
    }
};