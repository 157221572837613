import { Box, styled } from "@mui/system";

export const SidebarContainer = styled(Box)`
    width: ${(props) => (props.popoverVersion ? "70px" : "350px")};
    flex-shrink: 0;
    height: 100%;
    overflow: hidden;
    transition: 1s;
    display: flex;
    flex-direction: column;
    border-inline: ${(props) => `1px solid ${props.theme.palette.borderColor.dark}`};
    background-color: ${(props) => props.theme.palette.background.paper};
    border-top: ${(props) =>
        !props.popoverVersion && `1px solid ${props.theme.palette.borderColor.dark}`};
    ${(props) => props.theme.breakpoints.down("sm")} {
        position: absolute;
        width: ${(props) => (props.popoverVersion ? "70px" : "300px")};
        right: 0;
        transform: ${(props) =>
            props.isCollapse && `translate(${props.popoverVersion ? "70px" : "350px"})`};
`;
