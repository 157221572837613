import getDocumentsIconsType from "services/DocumentsType/getDocumentsIconsType";

export const DisplayFile = ({
    path = '',
    fileType = '',
    clickMedia,
    downloadFile,
    popoverVersion
}) => {
    const mediaWidth = popoverVersion ? 150 : 300;

    switch (true) {
        case fileType === 'Image':
            return (
                <img
                    src={path}
                    alt={'message File'}
                    width={mediaWidth}
                    onClick={() => clickMedia(path, fileType)}
                />
            );
        case fileType === 'Video':
            return (
                <video
                    controls
                    width={mediaWidth}
                    onClick={() => clickMedia(path, fileType)}
                >
                    <source src={path} type={fileType} />
                    Votre Navigateur ne supporte pas ce type de fichier vidéo
                </video>
            );
        case fileType === 'Audio':
            return (
                <audio controls width={mediaWidth}>
                    <source src={path} type={fileType} />
                    Votre Navigateur ne supporte pas ce type de fichier audio
                </audio>
            );
        default:
            const src = getDocumentsIconsType(fileType);
            return (
                <img
                    src={`/images/icons/${src}`}
                    alt={src}
                    width={100}
                    onClick={() => downloadFile(path, fileType)}
                />
            );
    }
};