import { Box, Button, CardContent, Hidden, ListSubheader, MenuItem, Select } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CakeIcon from '@mui/icons-material/Cake';
import WorkIcon from '@mui/icons-material/Work';
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import { CmtDatePicker } from "Components/CmtDatePicker/CmtDatePicker";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { IconView, TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function EditionInfosCard ({profile, submit, positionType, setEditionMode}) {
    const limitedate = () => {
        let date = new Date();

        date.setFullYear(date.getFullYear() - 18);

        return date;
    };

    const formatDate = (birthday) => {
        const date = birthday._d || birthday;

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return (`${year}-${(month < 10 ? '0' : '') + month}-${day}`);
    };

    const checkError = (values) => {
        const errors = {};

        const birthday = values.birthday._d || values.birthday;

        if (!values.birthday) {
            errors.birthday = 'Veuillez indiquer votre date de naissance';
        } else if (birthday.getTime() > limitedate()) {
            errors.birthday = 'Vous devez avoir plus de 18 ans pour pouvoir vous inscrire';
        }

        return errors;
    };

    return (
        <CmtCard>
            <TileTitle>Identité</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{birthday: new Date(profile?.profile?.birthday), positionType: profile?.profile?.positionType?.id || 0}}
                    validate={values => checkError(values)}
                    onSubmit={(values) => {
                        submit({...values, birthday: formatDate(values.birthday), positionType: values.positionType || null});
                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }} >
                                <IconView>
                                    <CakeIcon />
                                </IconView>
                                <Box ml={5}>
                                    <Box component="span" fontSize={12} color="primary" >Date de naissance</Box>
                                    <Box fontSize={16}>
                                        <CmtDatePicker 
                                            disableFuture
                                            initialFocusedDate={moment().subtract(18, 'year')}
                                            openToYear
                                            value={values.birthday}
                                            setValue={(value) => setFieldValue('birthday', value)}
                                            onTouched={setFieldTouched}
                                            name="birthday"
                                            error={touched.birthday && errors.birthday}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }} >
                                <IconView>
                                    <WorkIcon />
                                </IconView>
                                <Box ml={5}>
                                    <Box component="span" fontSize={12} color="primary">Travail</Box>
                                    <Box fontSize={16} width={200}>
                                        <Select
                                            fullWidth
                                            labelId="LabelPositionType"
                                            variant='standard'
                                            value={values.positionType}
                                            name="positionType"
                                            onChange={handleChange}
                                            error={touched.positionType && Boolean(errors.positionType)}
                                            onBlur={handleBlur}
                                            id='roleSelect'
                                        >
                                            <MenuItem value={0} key={`positionType 0`}>Travail</MenuItem>
                                            {positionType?.map((item, index) => ([
                                                <ListSubheader color='primary' key={`positionGroup ${index}`} >{item.name}</ListSubheader>,
                                                item.values.map((it, ind) => (
                                                    <MenuItem value={it.id} key={`positionType ${ind}`} id={`positionType${ind}`}>{it.name}</MenuItem>
                                                    ))
                                                ]))}
                                        </Select>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={10} width='100%' display='flex' justifyContent='flex-end' height={30}>
                                <Box mr={2}>
                                    <Button type='button' onClick={() => setEditionMode(false)} variant='contained' color='secondary' >
                                        <Hidden xsDown> Annuler </Hidden>
                                        <Hidden smUp> <CloseIcon /> </Hidden>
                                    </Button>
                                </Box>

                                <Box ml={2}>
                                    <Button type='submit' variant='contained' color='primary'  id='submitInfosCard'>
                                        <Hidden xsDown> Valider </Hidden>
                                        <Hidden smUp> <CheckIcon /> </Hidden>
                                    </Button>
                                </Box>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    );
};