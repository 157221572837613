import moment from "moment";
import axios from "./config";

const companies = {
    createCompagnie: async (data) => {
        try {
            let formData = new FormData();

            formData.append("name", data.name);
            formData.append("position", data.position || "");
            formData.append("siret", data.siret);
            formData.append("description", data.description);
            formData.append("address1", data.address1);
            formData.append("address2", data.address2);
            formData.append("zipCode", data.zipCode);
            formData.append("city", data.city);
            formData.append("employeeRange", data.size);

            const types = data?.compagniesTypes?.map((element) => element?.id);

            types?.forEach((element, index) => {
                formData.append(`compagniesTypes[${index}]`, element);
            });

            await axios.post("/user/compagnies/create", formData);

            return { result: true, status: 201 };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    updateCompagnie: async (data) => {
        try {
            let formData = new FormData();

            formData.append("name", data.name);

            const types = data?.compagniesTypes?.map((element) => element?.id);

            types?.forEach((element, index) => {
                formData.append(`compagniesTypes[${index}]`, element);
            });

            formData.append("creationDate", moment(data.creationDate).format("YYYY-MM-DD"));
            formData.append("position", data.position);
            formData.append("siret", data.siret);

            if (data?.description) {
                formData.append("description", data.description);
            }

            formData.append("address1", data.address1);

            if (data?.address2) {
                formData.append("address2", data.address2);
            }

            formData.append("zipCode", data.zipCode);
            formData.append("city", data.city);

            if (data?.coverFileName) {
                formData.append("coverFile[file]", data.coverFileName);
            }

            if (data?.logoFileName) {
                formData.append("logoFile[file]", data.logoFileName);
            }

            formData.append("employeeRange", data.employeeRange);

            if (data?.contactEmail) {
                formData.append(`contactEmail`, data?.contactEmail);
            }

            if (data?.phoneNumber) {
                formData.append(`phoneNumber`, data?.phoneNumber);
            }

            if (data?.website) {
                formData.append(`website`, data?.website);
            }

            data?.socials?.forEach((item, index) => {
                formData.append(`socials[${index}][socialType]`, item.typeId);
                formData.append(`socials[${index}][value]`, item.value);
            });

            const result = await axios.post(`/user/compagnies/${data.id}/update`, formData);

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getOneCompagnie: async (id) => {
        try {
            const result = await axios.get("/user/compagnies/" + id);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getPositionType: async () => {
        try {
            const result = await axios.get("/user/position-types");

            const groupNames = [];
            let lastId = "";

            result.data.forEach((d) => {
                if (d.groupName !== lastId) {
                    lastId = d.groupName;
                    groupNames.push(d.groupName);
                }
            });

            const tab = [];

            groupNames.forEach((d) => {
                tab.push({
                    name: d,
                    values: result.data.filter((l) => l.groupName === d),
                });
            });

            return { result: true, data: tab };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getCompaniesType: async () => {
        try {
            const result = await axios.get("/user/compagny-types");

            const groupNames = [];
            let lastId = "";

            result.data.forEach((d) => {
                if (d.groupName !== lastId) {
                    lastId = d.groupName;
                    groupNames.push(d.groupName);
                }
            });

            const tab = [];

            groupNames.forEach((d) => {
                tab.push({
                    name: d,
                    values: result.data.filter((l) => l.groupName === d),
                });
            });

            return { result: true, data: tab };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    deleteCompany: async (id) => {
        try {
            await axios.delete(`/user/compagnies/${id}`);

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getCompaniesList: async (name) => {
        try {
            const result = await axios.get(`/user/compagnies-insee/${name}`);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    inviteMember: async (data) => {
        try {
            let formData = new FormData();

            formData.append("email", data.email);
            formData.append("position", data.position);
            formData.append("accessRights", data.accessRight);

            const result = await axios.post(
                `/user/compagny-users/${data.compagnyId}/create`,
                formData
            );

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    updateMember: async (data) => {
        try {
            let formData = new FormData();

            formData.append("position", data.position);
            formData.append("accessRights", data.accessRights);

            const result = await axios.post(
                `/user/compagny-users/${data.compagnyUserId}/update`,
                formData
            );

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    deleteMember: async (id) => {
        try {
            await axios.delete(`/user/compagny-users/${id}`);

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    answerInvite: async (data) => {
        try {
            const result = await axios.post(
                `/user/compagny-users/${data.compagnyUserId}/answer/${
                    data.answerStatus ? "1" : "0"
                }`
            );

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },
};

export default companies;
