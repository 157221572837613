import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";
import { COMPANIES_ACCESS_RIGHT_LIST } from "Constant";
import { useState } from "react";

export const SendedInvite = ({ profile, invitations, deleteInvite, accessRight }) => {
    const [deletePopine, setDeletePopine] = useState(null);

    return (
        <>
            {invitations.map((item, index) => (
                <Grid
                    container
                    key={index}
                    width="100%"
                    m={0}
                    p={5}
                    borderTop="1px solid rgba(211, 211, 211, 0.4)"
                    spacing={0}
                >
                    <Grid
                        item
                        xs={2}
                        sm={1}
                        pb={{ xs: 2, sm: 0 }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <AvatarComponent
                            src={item?.user?.profileFileName || profile?.defaultAvatarPath}
                            size={50}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                        xs={10}
                        pb={{ xs: 2, sm: 0 }}
                        px={{ xs: 0, sm: 1 }}
                        display="flex"
                        alignItems="center"
                    >
                        <Typography component="p" variant="subtitle1">
                            {item?.user?.firstName} {item?.user?.lastName}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={2}
                        xs={12}
                        pb={{ xs: 2, sm: 0 }}
                        px={{ xs: 0, sm: 1 }}
                        display="flex"
                        alignItems="center"
                    >
                        <Typography component="p">{item?.user?.email || item?.email}</Typography>
                    </Grid>

                    <Grid
                        item
                        sm={2}
                        xs={6}
                        pb={{ xs: 2, sm: 0 }}
                        px={{ xs: 0, sm: 1 }}
                        display="flex"
                        alignItems="center"
                    >
                        <Typography component="p" ml={4}>
                            {item?.position}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        sm={2}
                        xs={6}
                        pb={{ xs: 2, sm: 0 }}
                        px={{ xs: 0, sm: 1 }}
                        display="flex"
                        alignItems="center"
                    >
                        <Typography component="p" ml={4}>
                            {
                                COMPANIES_ACCESS_RIGHT_LIST.find(
                                    (elem) => elem.accessRight === item.accessRights
                                )?.label
                            }
                        </Typography>
                    </Grid>

                    {accessRight && (
                        <Grid
                            item
                            sm={3}
                            xs={12}
                            px={{ xs: 0, sm: 2 }}
                            pt={{ xs: 2, sm: 0 }}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setDeletePopine({ id: item?.id })}
                            >
                                Supprimer
                            </Button>
                        </Grid>
                    )}
                </Grid>
            ))}

            <CmtDeleteDialog
                open={Boolean(deletePopine)}
                onCancel={() => setDeletePopine(false)}
                onDelete={() => {
                    deleteInvite(deletePopine.id);
                    setDeletePopine(false);
                }}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        Êtes-vous sûr de vouloir supprimer cette invitation ?
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>
        </>
    );
};
