export const UPDATE_FILTER = "Update_filter";
export const UPDATE_STATUS = "Update_status";
export const SET_CURRENT_USER = "Set_current_user";
export const ADD_CONVERSATION = "Add_Conversation";
export const SEND_MESSAGE = "Send_Message";
export const SEND_MEDIA_MESSAGE = "Send_Media_Message";
export const RECEIVE_MESSAGE = "Receive_Message";
export const GET_PROFIL_DATA = 'Get_Profil_Data';


export const REQUEST_PROFIL = 'Request_Profil';
export const REQUEST_PROFIL_SUCCESS = 'Request_Profil_Success';
export const REQUEST_PROFIL_ERROR = 'Request_Profil_Error';


export const REQUEST_USERS = 'Request_Users';
export const REQUEST_USERS_SUCCESS = 'Request_Users_Success';
export const REQUEST_USERS_ERROR = 'Request_Users_Error';


export const REQUEST_CONVERSATION = 'Request_Conversation';
export const REQUEST_CONVERSATION_SUCCESS = 'Request_Conversation_Success';
export const REQUEST_CONVERSATION_ERROR = 'Request_Conversation_Error';


export const REQUEST_SEND_MESSAGE = 'REQUEST_SEND_MESSAGE';
export const REQUEST_SEND_MESSAGE_SUCCESS = 'REQUEST_SEND_MESSAGE_SUCCESS';
export const REQUEST_SEND_MESSAGE_ERROR = 'REQUEST_SEND_MESSAGE_ERROR';

export const ONLINE_STATUS = 'En ligne';
export const BUSY_STATUS = 'Occupé';
export const DONT_DISTURB_STATUS = 'Ne pas déranger';
export const OFFLINE_STATUS = 'Hors ligne';

export const REQUEST_TO_REAL_TIME = 'REQUEST_TO_REAL_TIME';
export const REQUEST_TO_REAL_TIME_SUCCESS = 'REQUEST_TO_REAL_TIME_SUCCESS';
export const REQUEST_TO_REAL_TIME_ERROR = 'REQUEST_TO_REAL_TIME_ERROR';

export const REQUEST_DELETE_MESSAGE_SUCCESS = 'REQUEST_DELETE_MESSAGE_SUCCESS';
export const REQUEST_DELETE_MESSAGE_ERROR = 'REQUEST_DELETE_MESSAGE_ERROR';


export const SUBSCRIPTION_REAL_TIME = 'SUBSCRIPTION_REAL_TIME';

export const CHANGE_IS_TYPING = 'CHANGE_IS_TYPING';

export const DELETE_MESSAGE = 'DELETE_MESSAGE';

export const READED_MESSAGE = 'READED_MESSAGE';