import { Box, Chip, Hidden, Typography, useTheme } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import ExploreIcon from "@mui/icons-material/Explore";
import { GRID_MODE, MAP_MODE, NETWORK_SORT_TABLE } from "Constant";
import { NetworkOptions } from "./NetworkOptions";
import { SortingFilters } from "Components/CmtFilters/SortingFilters";

export default function NetworkFilter({
    filters,
    changeFilters,
    companiesTypes,
    resultNumber,
    displayMode,
    setDisplayMode,
    handleLocalisation,
    handleSearchLocalisation,
}) {
    const theme = useTheme();

    const DisplayModeChip = () => {
        return (
            <Chip
                label={
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box component="div" px={1}>
                            <ExploreIcon
                                color={displayMode === MAP_MODE ? "primary" : "secondary"}
                                fontSize="medium"
                                onClick={() => setDisplayMode(MAP_MODE)}
                                className="pointer"
                            />
                        </Box>
                        <Box component="div" px={1}>
                            <AppsIcon
                                color={displayMode === GRID_MODE ? "primary" : "secondary"}
                                fontSize="medium"
                                onClick={() => setDisplayMode(GRID_MODE)}
                                className="pointer"
                            />
                        </Box>
                    </Box>
                }
            />
        );
    };

    return (
        <>
            <Hidden smDown>
                <Box display="flex" alignItems="center" height={70}>
                    <Box my={2} pr={2} borderRight={`1px solid ${theme.palette.borderColor.dark}`}>
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                    </Box>

                    <Box p={3} flexGrow={1} display="flex" justifyContent="flex-start">
                        <NetworkOptions
                            filters={filters}
                            changeFilters={changeFilters}
                            companiesTypes={companiesTypes}
                            displayMode={displayMode}
                            handleLocalisation={handleLocalisation}
                            handleSearchLocalisation={handleSearchLocalisation}
                        />
                    </Box>

                    <Box my={2} pl={2} borderLeft={`1px solid ${theme.palette.borderColor.dark}`}>
                        <DisplayModeChip />
                    </Box>
                </Box>
            </Hidden>

            <Hidden mdUp>
                <Box display="flex" flexDirection="column">
                    <Box p={3} pl={0} display="flex" justifyContent="space-between">
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                        <DisplayModeChip />
                    </Box>

                    <Box
                        p={3}
                        pl={0}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <Typography component="p" variant="h5">
                            Filtrer par
                        </Typography>
                        <Box mt={3} display="flex" flexWrap="wrap">
                            <NetworkOptions
                                filters={filters}
                                changeFilters={changeFilters}
                                companiesTypes={companiesTypes}
                                displayMode={displayMode}
                                handleLocalisation={handleLocalisation}
                                handleSearchLocalisation={handleSearchLocalisation}
                            />
                        </Box>
                    </Box>

                    {displayMode === GRID_MODE && (
                        <Box
                            p={3}
                            pl={0}
                            flexGrow={1}
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-start"
                        >
                            <Typography component="p" variant="h5" mb={3}>
                                Trier par
                            </Typography>
                            <SortingFilters
                                filters={filters}
                                changeFilters={changeFilters}
                                sortingList={NETWORK_SORT_TABLE}
                            />
                        </Box>
                    )}
                </Box>
            </Hidden>
        </>
    );
}
