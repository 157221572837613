import { CHANGE_NOTES_FILTER, GET_NOTES, GET_NOTES_ERROR, GET_NOTES_SUCCESS, UPDATE_ONE_NOTE } from "redux/constants/notesConstants";
import auth from "services/Api/auth";
import notes from "services/Api/notes";
import { notConnectedAction } from "./authActions";

const requestNotes = () => ({
    type: GET_NOTES,
    loading: true,
});

const requestNotesSuccess = (data) => ({
    type: GET_NOTES_SUCCESS,
    loading: false,
    data: data,
});

const requestNotesError = (error) => ({
    type: GET_NOTES_ERROR,
    loading: false,
    error: error,
});

export const getNotesDataAction = () => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(requestNotes());


        notes.getNotesList()
        .then(result => {
            if (result?.result) {
                dispatch(requestNotesSuccess(result?.data));
            } else {
                dispatch(requestNotesError(result?.error));
            }

            return result;
        });
    };
};

export const createNoteAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = notes.createNote(data)
        .then(async result => {
            if (result?.result) {
                for (let i = 0; i < data?.todoList?.length; i++) {
                    await notes.createCheckbox({description: data?.todoList[i]?.description.trim(), noteId: result?.data?.id})
                    .then(res => {
                        if (res?.result && data?.todoList[i].isDone) {
                            notes.validationCheckbox(res?.data?.id);
                        }
                    });
                }

                dispatch(getNotesDataAction());
            }

            return result;
        });

        return result;
    };
};

export const updateNoteAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = notes.updateNote(data)
        .then(result => {
            if (result?.result) {
                dispatch(updateOneNoteAction(result?.data));
            }

            return result;
        });

        return result;
    };
};

export const deleteNoteAction = (noteId) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = notes.deleteNote(noteId)
        .then(result => {
            if (result?.result) {
                dispatch(getNotesDataAction());
            }

            return result;
        });

        return result;
    };
};

export const updateOneNoteAction = (data) => ({
    type: UPDATE_ONE_NOTE,
    data: data,
});

export const updateFiltersAction = (filters) => ({
    type: CHANGE_NOTES_FILTER,
    filters: filters,
});

export const changeNoteFiltersAction = (filters) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(updateFiltersAction(filters));

        sessionStorage.setItem('NotesFilterCategory', filters.category);
        sessionStorage.setItem('NotesFilterSorter', filters.sorter);

        notes.getNotesList(filters)
        .then(result => {
            if (result?.result) {
                dispatch(requestNotesSuccess(result?.data));
            } else {
                dispatch(requestNotesError(result?.error));
            }

            return result;
        });
    };
};