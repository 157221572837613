import { Box, Button, CardContent, Grid, Hidden, MenuItem, Select } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import WorkIcon from "@mui/icons-material/Work";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import auth from "services/Api/auth";
import { notConnectedAction } from "redux/actions/authActions";
import project from "services/Api/project";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import history from "services/History/history";
import { PROJECT_PATH, REDIRECTION_TIME } from "Constant";
import { NotificationManager } from "react-notifications";
import { TileTitle, IconView } from "Components/CmtProfileContainer/sc.ProfileContainer";
import EventIcon from "@mui/icons-material/Event";
import { CmtDatePicker } from "Components/CmtDatePicker/CmtDatePicker";
import moment from "moment";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export default function EditionProjectInfosCard({ projectData, submit, setEditionMode }) {
    const dispatch = useDispatch();

    const [projectTypeList, setProjectTypeList] = useState(null);

    const checkError = (values) => {
        const errors = {};

        if (!values.projectType) {
            errors.projectType = "Veuillez renseigner le type de votre project";
        }

        if (
            values.endDate &&
            values.startDate &&
            moment(values.startDate).isAfter(values.endDate)
        ) {
            errors.startDate = "La date de début ne peux pas être supérieure à la date de fin";
        }

        if (
            values.startDate &&
            values.endDate &&
            moment(values.endDate).isBefore(values.startDate)
        ) {
            errors.endDate = "La date de fin ne peux pas être inférieure à la date de début";
        }

        return errors;
    };

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                dispatch(notConnectedAction());
            }

            const result = await project.getProjectType();

            if (result.result) {
                setProjectTypeList(result.data);
            } else {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );

                history.push(PROJECT_PATH);
            }
        };

        fetchData();
    }, [dispatch]);

    return (
        <CmtCard>
            <TileTitle>Informations</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{
                        projectType: projectData?.projectType?.id || "",
                        startDate: projectData?.startDate || "",
                        endDate: projectData?.endDate || "",
                        performancesNb: projectData?.performancesNb || "",
                    }}
                    validate={(values) => checkError(values)}
                    onSubmit={(values) => {
                        submit({
                            ...values,
                            startDate: values.startDate
                                ? moment(values.startDate).format("YYYY-MM-DD")
                                : "",
                            endDate: values.endDate
                                ? moment(values.endDate).format("YYYY-MM-DD")
                                : "",
                        });

                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldTouched,
                        setFieldValue,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                        <IconView>
                                            <WorkIcon />
                                        </IconView>
                                        <Box ml={5} width="100%">
                                            <Box component="span" fontSize={12} color="primary">
                                                Type de projet
                                            </Box>
                                            <Box fontSize={16}>
                                                <Select
                                                    fullWidth
                                                    variant="standard"
                                                    labelId="LabelProjectType"
                                                    value={values.projectType}
                                                    name="projectType"
                                                    onChange={handleChange}
                                                    error={
                                                        touched.projectType && errors.projectType
                                                    }
                                                    onBlur={handleBlur}
                                                    id="roleSelect"
                                                >
                                                    {projectTypeList?.map((item, index) => (
                                                        <MenuItem
                                                            value={item.id}
                                                            key={`projectType ${index}`}
                                                            id={`projectType${index}`}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                        <IconView>
                                            <EventIcon />
                                        </IconView>
                                        <Box ml={5} width="100%">
                                            <Box component="span" fontSize={12} color="primary">
                                                Date de début estimée
                                            </Box>
                                            <CmtDatePicker
                                                fullWidth
                                                value={values.startDate}
                                                disablePast
                                                onTouched={setFieldTouched}
                                                name="startDate"
                                                id="infosStartDateInput"
                                                setValue={(value) => {
                                                    setFieldValue(
                                                        "startDate",
                                                        moment(value).format("YYYY-MM-DD")
                                                    );
                                                }}
                                                error={touched.startDate && errors.startDate}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                        <IconView>
                                            <EventIcon />
                                        </IconView>
                                        <Box ml={5} width="100%">
                                            <Box component="span" fontSize={12} color="primary">
                                                Date de fin estimée
                                            </Box>
                                            <CmtDatePicker
                                                fullWidth
                                                value={values.endDate}
                                                disablePast
                                                id="infosEndDateInput"
                                                onTouched={setFieldTouched}
                                                name="endDate"
                                                setValue={(value) =>
                                                    setFieldValue(
                                                        "endDate",
                                                        moment(value).format("YYYY-MM-DD")
                                                    )
                                                }
                                                error={touched.endDate && errors.endDate}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                        <IconView>
                                            <ConfirmationNumberIcon />
                                        </IconView>
                                        <Box ml={5} width="100%">
                                            <Box component="span" fontSize={12} color="primary">
                                                Nombre de représentation prévues
                                            </Box>
                                            <FormInput
                                                name="performancesNb"
                                                id="performancesNbInput"
                                                value={values.performancesNb}
                                                margin="none"
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;

                                                    if (
                                                        e.target.value === "" ||
                                                        re.test(e.target.value)
                                                    ) {
                                                        setFieldValue(
                                                            "performancesNb",
                                                            e.target.value
                                                        );
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box display="flex" justifyContent="flex-end" height={30} mt={5}>
                                <Box mr={2}>
                                    <Button
                                        type="button"
                                        onClick={() => setEditionMode(false)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <Hidden xsDown> Annuler </Hidden>
                                        <Hidden smUp>
                                            <CloseIcon />
                                        </Hidden>
                                    </Button>
                                </Box>

                                <Box ml={2}>
                                    <Button
                                        type="submit"
                                        id="submitInfosCard"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <Hidden xsDown> Valider </Hidden>
                                        <Hidden smUp>
                                            <CheckIcon />
                                        </Hidden>
                                    </Button>
                                </Box>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    );
}
