import { connect } from "react-redux";
import {authSelector} from 'redux/selectors/authSelector';
import AuthWrapper from '../AuthWrapper/AuthWrapper.jsx';
import ForgetPasswordForm from './ForgetPasswordForm.jsx';
import { useEffect, useState } from "react";
import history from 'services/History/history';
import auth from "services/Api/auth.js";
import { REDIRECTION_TIME, SIGNIN_PATH } from "Constant.js";
import CmtLoading from "Components/CmtLoading/CmtLoading.jsx";
import { NotificationManager } from 'react-notifications';
import { HOME_PATH } from 'Constant';

export const ForgetPasswordApp = ({ isAuth, children }) => {
    const [result, setResult] = useState(null);
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (isAuth.connected) {
            history.push(HOME_PATH);
        }
    });

    const handleSubmit = async (data) => {
        if (submit) {
            return;
        }

        if (!data || !data.email) {
            return;
        }

        setSubmit(true);
        setResult(null);

        const res = await auth.onForgetPassword(data);

        setResult(res);

        if (res.result) {
            NotificationManager.success('Votre demande à bien été prise en compte, vous allez être rédirigé vers la page de connexion', 'Succès', REDIRECTION_TIME);

            setTimeout(() => {history.push(SIGNIN_PATH)}, REDIRECTION_TIME);
        } else {
            NotificationManager.error(result?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);
            
            setSubmit(false);
        }
    };


    return (
        <AuthWrapper>
            {submit && <CmtLoading/>}
            <ForgetPasswordForm onForget={handleSubmit} />
            {children}
        </AuthWrapper>
    );
};

export const ForgetPasswordAppStore = connect(
    (state) => ({
        isAuth: authSelector(state),
    })
) (ForgetPasswordApp);
