import { Box, Tab, Tabs, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import moment from "moment";
import { DEFAULT_PRICE_LIST } from "Constant";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { SwiperSlide } from "swiper/react";
import CmtImage from "Components/CmtImage/CmtImage";
import { AnnouncementInfos } from "./AnnouncementInfos";
import { useState } from "react";
import { AnnouncementComments } from "./comments/AnnouncementComments";
import "swiper/swiper.scss";
import "./navigation.scss";
import { useTheme } from "@emotion/react";
import { SwiperContainer } from "./sc.AnnouncementDetail";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";

const DETAIL_MODE = "detail_mode";
const COMMENTS_MODE = "COMMENTS_MODE";
const SIMILARY_MODE = "SIMILARY_MODE";

export const AnnouncementContent = ({
    announcementData,
    priceList = DEFAULT_PRICE_LIST,
    changeFavorite,
    profileId,
    comments,
    addComment,
    deleteComment,
    updateComment,
}) => {
    const theme = useTheme();

    const [shareOpen, setShareOpen] = useState(null);

    const [tabValue, setTabValue] = useState(DETAIL_MODE);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box>
            <Box px={5}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography component="p" variant="h5">
                        {announcementData?.announcementType?.name}
                    </Typography>
                    <Box>
                        <Box component="span" mr={4}>
                            {announcementData?.isFavorite ? (
                                <FavoriteIcon
                                    fontSize="large"
                                    color="primary"
                                    className="pointer"
                                    onClick={changeFavorite}
                                />
                            ) : (
                                <FavoriteBorderIcon
                                    fontSize="large"
                                    color="primary"
                                    className="pointer"
                                    onClick={changeFavorite}
                                />
                            )}
                        </Box>
                        <ShareIcon
                            fontSize="large"
                            color="primary"
                            className="pointer"
                            onClick={(e) => setShareOpen(e.currentTarget)}
                        />
                    </Box>
                </Box>

                <Typography component="p" variant="h5">
                    Publiée le {moment(announcementData?.publicationDate).format("DD/MM/YY")}
                </Typography>

                <SwiperContainer navigation={true}>
                    {announcementData?.announcementPhotos?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <CmtImage src={item.photoFileName} />
                        </SwiperSlide>
                    ))}
                </SwiperContainer>
            </Box>
            <Box borderBottom={`1px solid ${theme?.palette?.borderColor?.dark}`}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab value={DETAIL_MODE} label="Détail de l'annonce" />
                    <Tab value={COMMENTS_MODE} label="Commentaires" />
                    <Tab value={SIMILARY_MODE} label="Annonces semblables" />
                </Tabs>
            </Box>

            {tabValue === DETAIL_MODE && (
                <AnnouncementInfos announcementData={announcementData} priceList={priceList} />
            )}
            {tabValue === COMMENTS_MODE && (
                <AnnouncementComments
                    comments={comments}
                    addComment={addComment}
                    deleteComment={deleteComment}
                    updateComment={updateComment}
                    profileId={profileId}
                />
            )}

            {Boolean(shareOpen) && (
                <CmtPopover
                    open={Boolean(shareOpen)}
                    anchorEl={shareOpen}
                    onClose={() => setShareOpen(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Box p={5}>
                        <Typography compponent="p" mb={3} variant="subtitle1">
                            {" "}
                            Partager sur{" "}
                        </Typography>
                        <Box display="flex">
                            <Box
                                mr={2}
                                component="a"
                                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}
                            >
                                <CmtImage
                                    size={50}
                                    src="/images/icons/facebook.jpg"
                                    className="pointer"
                                />
                            </Box>
                            <Box
                                mx={2}
                                component="a"
                                href={`https://twitter.com/intent/tweet?url=${window.location}`}
                            >
                                <CmtImage
                                    size={50}
                                    src="/images/icons/twitter.jpg"
                                    className="pointer"
                                />
                            </Box>
                            <Box mx={2} component="a" href={`mailto:?body=${window.location}`}>
                                <CmtImage
                                    size={50}
                                    src="/images/icons/email.jpg"
                                    className="pointer"
                                />
                            </Box>
                            <Box
                                mx={2}
                                component="a"
                                href={`https://www.instagram.com/?url=${window.location}`}
                            >
                                <CmtImage
                                    size={50}
                                    src="/images/icons/instagram.jpg"
                                    className="pointer"
                                />
                            </Box>
                        </Box>
                    </Box>
                </CmtPopover>
            )}
        </Box>
    );
};
