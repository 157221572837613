import { useTheme } from "@emotion/react";
import { Box, Container, Grid } from "@mui/material";
import { PublicMenu } from "Components/Public/Menu/PublicMenu";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";
import { RatingStar } from "../Home/sc.Home";
import { Footer } from "../Partial/Footer";
import { ParagraphText } from "../Partial/ParagraphText";
import { Round } from "../Partial/Round";
import { TestimonyElem } from "../Partial/TestimonyElem";
import { TitleText } from "../Partial/TitleText";

export const Testimony = () => {
    const theme = useTheme();

    return (
        <Box backgroundColor={theme.palette.primary.publicMain} pt={30}>
            <PublicMenu />

            <Container maxWidth="lg">
                <TitleText
                    position="relative"
                    fontSize={{
                        xs: theme.palette.publicTypo.h3Size,
                        md: theme.palette.publicTypo.h2Size,
                        lg: theme.palette.publicTypo.h1Size,
                    }}
                >
                    Avis & Témoignages
                    <ShapeImg src={"/images/Public/Shape/Shape4.png"} bottom={0} right={0} />
                </TitleText>
                <Grid container pt={10} px={5}>
                    <Grid item xs={12} sm={6}>
                        <Box p={5}>
                            <Box border={`1px dashed ${theme.palette.primary.main}`} px={10} py={3}>
                                <TitleText textAlign="center">Théâtre</TitleText>
                                <ParagraphText>
                                    As es quistem olorunt emporer spelignihit ad eum reicipsum unt.
                                </ParagraphText>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} position="relative">
                        <Box p={5}>
                            <Box border={`1px dashed ${theme.palette.primary.main}`} px={10} py={3}>
                                <TitleText textAlign="center">Compagnies</TitleText>
                                <ParagraphText>
                                    As es quistem olorunt emporer spelignihit ad eum reicipsum unt.
                                </ParagraphText>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} position="relative">
                        <Box p={5}>
                            <Box border={`1px dashed ${theme.palette.primary.main}`} px={10} py={3}>
                                <TitleText textAlign="center">Techniciens</TitleText>
                                <ParagraphText>
                                    As es quistem olorunt emporer spelignihit ad eum reicipsum unt.
                                </ParagraphText>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box p={5}>
                            <Box border={`1px dashed ${theme.palette.primary.main}`} px={10} py={3}>
                                <TitleText textAlign="center">Administrateurs</TitleText>
                                <ParagraphText>
                                    As es quistem olorunt emporer spelignihit ad eum reicipsum unt.
                                </ParagraphText>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <TitleText py={10}>Quelques chiffres</TitleText>
                <Grid container>
                    {[...Array(3)].map((item, index) => (
                        <Grid item xs={12} md={6} lg={4} position="relative">
                            <Box px={10} pb={10} display="flex" justifyContent={"center"}>
                                <Round
                                    zIndex={100}
                                    size={{ xs: "10rem", sm: "15rem", lg: "20rem" }}
                                >
                                    <TitleText fontSize={theme.palette.publicTypo.h2Size} pb={5}>
                                        8,5/10
                                    </TitleText>
                                    <RatingStar name="read-only" value={4} readOnly size="3em" />
                                    <ParagraphText
                                        family={theme.palette.publicTypo.pFamily}
                                        size={theme.palette.publicTypo.p2Size}
                                        fontWeight="300"
                                        color="primary"
                                    >
                                        (168 votes)
                                    </ParagraphText>
                                </Round>
                            </Box>
                            <ShapeImg src={"/images/Public/Shape/Shape1.png"} top={0} left={50} />
                            <ShapeImg
                                src={"/images/Public/Shape/Shape18.png"}
                                bottom={0}
                                right={75}
                            />
                        </Grid>
                    ))}
                </Grid>

                <TitleText pt={15}>Avis des utilisateurs</TitleText>

                <Grid container spacing={4} pt={10} pb={30} mb={30} position="relative">
                    {[...Array(8)].map((item, index) => (
                        <Grid item xs={12} md={6}>
                            <TestimonyElem
                                imgSrc={"/images/Public/Images/P1032127.jpg"}
                                rating={4}
                                text="« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »"
                            />
                        </Grid>
                    ))}
                    <ShapeImg src={"/images/Public/Shape/Shape2.png"} bottom={0} right={0} />
                </Grid>
            </Container>

            <Footer />
        </Box>
    );
};
