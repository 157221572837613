import { Box, IconButton, InputAdornment } from "@mui/material";
import { ABOUT_PATH, FUNCTIONNALITY_PATH, PRICES_PATH, TESTIMONY_PATH } from "Constant";
import history from "services/History/history";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import {
    MenuAppBar,
    MenuBurgerButton,
    MenuButtonLink,
    MenuContainer,
    MenuSearchBar,
} from "./sc.Menu";

export const PublicMenu = ({ floatingMode = false }) => {
    const [open, setOpen] = useState(floatingMode ? false : true);

    const menuList = [
        { label: "Fonctionnalités", link: FUNCTIONNALITY_PATH },
        { label: "Tarifs", link: PRICES_PATH },
        { label: "Témoignages", link: TESTIMONY_PATH },
        { label: "A propos", link: ABOUT_PATH },
    ];

    return (
        <MenuContainer pl={5}>
            {floatingMode && (
                <MenuBurgerButton open={open} color="primary" onClick={() => setOpen(!open)}>
                    <MoreVertIcon sx={{ fontSize: "1.5em" }} />
                </MenuBurgerButton>
            )}
            <MenuAppBar open={open}>
                {menuList.map((item, index) => (
                    <MenuButtonLink
                        key={index}
                        sx={{ my: 2, color: "white", display: "block" }}
                        onClick={() => history.push(item.link)}
                    >
                        {item.label}
                    </MenuButtonLink>
                ))}
                <Box display="flex" alignItems="center">
                    <MenuSearchBar
                        variant="standard"
                        placeholder="Rechercher"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                        }}
                    />
                </Box>
            </MenuAppBar>
        </MenuContainer>
    );
};
