import { Box } from "@mui/system";
import { ProfileContainer } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { PerfectScroll } from "Components/PerfectScroll/sc.PerfectScroll";
import {
    MEMBERSTAB,
    PROFILETAB,
    PROFILE_PATH,
    PROJECTSTAB,
    REDIRECTION_TIME,
    TECHNICALINFOSTAB,
} from "Constant";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { notConnectedAction } from "redux/actions/authActions";
import { getProfileAction } from "redux/actions/profileActions";
import { profileSelector } from "redux/selectors/profileSelector";
import auth from "services/Api/auth";
import intermittent from "services/Api/intermittent";
import companies from "services/Api/companies";
import entities from "services/Api/entities";
import profileUser from "services/Api/profileUser";
import history from "services/History/history";
import { ProfileTab } from "./ProfileTab/ProfileTab";
import StructureHeader from "./StructureHeader/StructureHeader";
import { ProjectTab } from "./ProjectTab/ProjectTab";
import { CmtTabs } from "Components/CmtTabs/CmtTabs";
import { MembersTab } from "./MembersTab/MembersTab";
import { useMemo } from "react";

export const StructureApp = ({ id }) => {
    const [companiesTypes, setCompaniesTypes] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [socialType, setSocialType] = useState(null);

    const [tabValue, setTabValue] = useState(PROFILETAB);

    const profile = useSelector(profileSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                dispatch(notConnectedAction());

                return;
            }

            companies.getCompaniesType().then((result) => {
                if (!result.result) {
                    history.push(PROFILE_PATH);

                    return;
                } else {
                    setCompaniesTypes(result.data);
                }
            });

            profileUser.getSocialType().then((result) => {
                if (!result.result) {
                    history.push(PROFILE_PATH);

                    return;
                } else {
                    setSocialType(result.data);
                }
            });

            entities.getOneEntity(id).then((result) => {
                if (!result.result) {
                    history.push(PROFILE_PATH);

                    return;
                } else {
                    setCompanyData(result.data);
                }
            });
        };

        fetchData();
    }, [id, dispatch]);

    const handleUpdateCompany = async (data) => {
        const position = data?.position
            ? data?.position
            : companyData?.compagnyUsers?.find(
                  (element) => element?.user?.id === profile?.profile?.id
              )?.position;

        companies
            .updateCompagnie({ ...companyData, ...data, position: position, id: id })
            .then((result) => {
                if (!result.result) {
                    NotificationManager.error(
                        result?.error?.message || "Une Erreur s'est produite",
                        "Erreur",
                        REDIRECTION_TIME
                    );
                } else {
                    setCompanyData(result?.data);

                    dispatch(getProfileAction());
                }
            });
    };

    const handleUpdateIntermittent = async (data) => {
        intermittent.updateIntermittent({ ...companyData, ...data }).then((result) => {
            if (!result.result) {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            } else {
                setCompanyData(result?.data);

                dispatch(getProfileAction());
            }
        });
    };

    const handleUpdate = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        if (companyData?.audiensNb) {
            handleUpdateIntermittent(data);
        } else {
            handleUpdateCompany(data);
        }
    };

    const handleInviteMember = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = companies
            .inviteMember({ ...data, compagnyId: companyData?.id })
            .then((result) => {
                if (result.result) {
                    let newData = companyData;

                    newData.compagnyUsers.push(result?.data);

                    setCompanyData({ ...newData });

                    NotificationManager.success(
                        "Votre invitation à bien été envoyé",
                        "Succès",
                        REDIRECTION_TIME
                    );
                } else {
                    NotificationManager.error(
                        result?.error?.message || "Une Erreur s'est produite",
                        "Erreur",
                        REDIRECTION_TIME
                    );
                }

                return result;
            });

        return result;
    };

    const handleAnswerInvite = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = companies.answerInvite(data).then((result) => {
            if (result.result) {
                let newData = companyData;

                newData.compagnyUsers[
                    newData.compagnyUsers.findIndex((elem) => elem.id === data.compagnyUserId)
                ] = result?.data;

                setCompanyData({ ...newData });
            } else {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }

            return result;
        });

        return result;
    };

    const handleDeleteInvite = async (id) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = companies.deleteMember(id).then((result) => {
            if (result?.result) {
                let newData = companyData;

                newData.compagnyUsers = companyData.compagnyUsers.filter(
                    (element) => element.id !== id
                );

                setCompanyData({ ...newData });

                NotificationManager.success(
                    "Le membre à bien été supprimé de la structure",
                    "Succès",
                    REDIRECTION_TIME
                );
            } else {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }
            return result;
        });

        return result;
    };

    const handleUpdateMember = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = companies.updateMember(data).then((result) => {
            if (result.result) {
                let newData = companyData;

                newData.compagnyUsers[
                    newData.compagnyUsers.findIndex((elem) => (elem.id = data.compagnyUserId))
                ] = result?.data;

                setCompanyData({ ...newData });

                NotificationManager.success(
                    "Le status de l'utilisateur à été mis à jour",
                    "Succès",
                    REDIRECTION_TIME
                );
            } else {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }

            return result;
        });

        return result;
    };

    const isIntermittent = Boolean(companyData?.audiensNb);

    const isAdmin = useMemo(() => {
        if (!companyData) {
            return null;
        }

        if (isIntermittent) {
            return companyData.user.id === profile?.profile?.id;
        } else {
            return (
                profile?.profile?.compagnyUserActives?.find(
                    (elem) => elem?.compagny?.id === companyData?.id
                )?.accessRights === 0
            );
        }
    }, [companyData, profile.profile?.compagnyUserActives, profile?.profile?.id, isIntermittent]);

    if (!companyData) {
        return <></>;
    }

    return (
        <PerfectScroll>
            <ProfileContainer>
                <StructureHeader
                    isIntermittent={isIntermittent}
                    companyData={companyData}
                    profile={profile}
                    update={handleUpdate}
                    isAdmin={isAdmin}
                />
                <Box display="flex" justifyContent="flex-end" mb={5}>
                    <CmtTabs
                        value={tabValue}
                        setValue={setTabValue}
                        list={[
                            { value: PROFILETAB, label: "Profil" },
                            { value: PROJECTSTAB, label: "Projets" },
                            { value: TECHNICALINFOSTAB, label: "Informations techniques" },
                            { value: MEMBERSTAB, label: "Membres", blocked: isIntermittent },
                        ]}
                    />
                </Box>

                {tabValue === PROFILETAB && (
                    <ProfileTab
                        profile={profile}
                        companyData={companyData}
                        isIntermittent={isIntermittent}
                        companiesTypes={companiesTypes}
                        updateProfile={handleUpdate}
                        socialType={socialType}
                        isAdmin={isAdmin}
                    />
                )}

                {tabValue === PROJECTSTAB && (
                    <ProjectTab
                        profile={profile}
                        companyData={companyData}
                        isIntermittent={isIntermittent}
                    />
                )}

                {tabValue === MEMBERSTAB && !isIntermittent && (
                    <MembersTab
                        companyData={companyData}
                        isIntermittent={isIntermittent}
                        profile={profile}
                        handleUpdate={handleUpdateMember}
                        handleInviteMember={handleInviteMember}
                        handleDeleteInvite={handleDeleteInvite}
                        submitAnswer={handleAnswerInvite}
                    />
                )}
            </ProfileContainer>
        </PerfectScroll>
    );
};
