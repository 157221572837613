import { Box } from "@mui/material";
import ProfileHeaderInfos from "./ProfileHeaderInfos/ProfileHeaderInfos";
import CmtUploadImage from "Components/CmtUploadImage/CmtUploadImage";
import { ProfileHeaderRoot } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function ProfileHeader({ profile, update }) {
    if (!profile?.profile) {
        return <></>;
    }

    return (
        <ProfileHeaderRoot display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box
                position="absolute"
                left={0}
                top={0}
                width="100%"
                height="100%"
                minHeight={370}
                sx={{ backgroundColor: "rgba(211, 211, 211, 1)" }}
            >
                <CmtUploadImage
                    filename={profile?.profile?.coverFileName || "/images/profile-bg-img.png"}
                    submit={(image) => update({ coverFileName: image })}
                />
            </Box>
            <Box position="relative" display="flex" width="fit-content" zIndex={3}>
                <Box
                    display="flex"
                    flexDirection={{ xs: "row", sm: "row" }}
                    alignItems="center"
                    marginRight={3}
                    height={80}
                    width={80}
                    borderRadius="50%"
                    overflow="hidden"
                >
                    <CmtUploadImage
                        filename={profile?.profile?.profileFileName || profile?.defaultAvatarPath}
                        submit={(image) => update({ profileFileName: image })}
                        isAvatar={true}
                        size={80}
                    />
                </Box>
                <ProfileHeaderInfos profile={profile} update={update} />
            </Box>
        </ProfileHeaderRoot>
    );
}
