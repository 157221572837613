import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { Formik } from "formik";

export const EditMemberInfos = ({ profile, setEditMode, item, update, index }) => {
    const checkError = (values) => {
        let errors = {};

        if (!values?.position) {
            errors.position = "Veuillez préciser la position";
        }

        return errors;
    };

    return (
        <Formik
            initialValues={{
                position: item?.position || "",
                accessRights: item?.accessRights || "0",
            }}
            validate={(values) => checkError(values)}
            onSubmit={async (values) => {
                update({ ...values, compagnyUserId: item.id }).then((result) => {
                    if (result?.result) {
                        setEditMode(false);
                    }
                });
            }}
        >
            {({ values, errors, touched, handleChange, handleSubmit, handleBlur }) => (
                <CmtForm onSubmit={handleSubmit}>
                    <Grid
                        container
                        width="100%"
                        m={0}
                        p={5}
                        spacing={0}
                        borderTop="1px solid rgba(211, 211, 211, 0.4)"
                    >
                        <Grid
                            item
                            xs={2}
                            sm={1}
                            pb={{ xs: 2, sm: 0 }}
                            display="flex"
                            alignItems="center"
                        >
                            <AvatarComponent
                                src={item?.user?.profileFileName || profile?.defaultAvatarPath}
                                size={50}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={2}
                            xs={10}
                            pb={{ xs: 2, sm: 0 }}
                            px={{ xs: 0, sm: 1 }}
                            display="flex"
                            alignItems="center"
                        >
                            <Typography component="p" variant="subtitle1">
                                {item?.user?.firstName} {item?.user?.lastName}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            sm={2}
                            xs={12}
                            pb={{ xs: 2, sm: 0 }}
                            px={{ xs: 0, sm: 1 }}
                            display="flex"
                            alignItems="center"
                        >
                            <Typography component="p">{item?.user?.email}</Typography>
                        </Grid>

                        <Grid
                            item
                            sm={2}
                            xs={12}
                            pb={{ xs: 2, sm: 0 }}
                            px={{ xs: 0, sm: 1 }}
                            display="flex"
                            alignItems="center"
                        >
                            <FormInput
                                value={values.position}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={touched.position && errors.position}
                                variant="outlined"
                                name="position"
                                size="small"
                                label={"Fonction"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={2}
                            xs={12}
                            pb={{ xs: 2, sm: 0 }}
                            px={{ xs: 0, sm: 1 }}
                            display="flex"
                            alignItems="center"
                        >
                            <FormControl fullWidth variant="outlined" size="small" margin="normal">
                                <InputLabel id={`labelAccessRights ${index}`}>
                                    Droits d'accès
                                </InputLabel>
                                <Select
                                    labelId={`labelAccessRights ${index}`}
                                    value={values.accessRights}
                                    name="accessRights"
                                    onChange={handleChange}
                                    label="Droits d'accès"
                                >
                                    <MenuItem value={0}>Administateur</MenuItem>
                                    <MenuItem value={1}>Utilisateur</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            px={{ xs: 0, sm: 2 }}
                            xs={12}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Button
                                sx={{ mr: 2 }}
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Modifier
                            </Button>

                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setEditMode(false)}
                            >
                                Annuler
                            </Button>
                        </Grid>
                    </Grid>
                </CmtForm>
            )}
        </Formik>
    );
};
