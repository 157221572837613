import { DisplayNoteElement } from "./DisplayNoteElement";
import Masonry from "@mui/lab/Masonry";
import { NoteSkeletonElement } from "./DisplayNoteElement";

export const DisplayNoteList = ({
    notesData,
    notesTypes,
    setEditNoteMode,
    filters,
    changeFilters,
}) => {
    return (
        <Masonry columns={{ xs: 1, md: 2, lg: 4 }} spacing={4} sx={{ alignContent: "flex-start" }}>
            {notesData?.notesList?.map((item, index) => (
                <DisplayNoteElement
                    key={index}
                    item={item}
                    index={index}
                    notesTypes={notesTypes}
                    setEditMode={setEditNoteMode}
                    filters={filters}
                    changeFilters={changeFilters}
                />
            ))}
            {notesData?.loading &&
                !notesData?.error &&
                [...Array(5)].map((_, index) => <NoteSkeletonElement key={index} />)}
        </Masonry>
    );
};
