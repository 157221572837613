import { Box, Chip, Typography } from "@mui/material";
import { CmtDatePicker } from "Components/CmtDatePicker/CmtDatePicker";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import moment from "moment";
import { useState } from "react";
import DateRangeIcon from '@mui/icons-material/DateRange';

export const AnnouncementDateOption = ({
    filters,
    changeFilters
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const type = () => {
        let displayFromDate = `${filters?.fromDate ? `${filters?.toDate ? 'Du ' : 'A partir du '}${moment(filters?.fromDate).format('DD MM')}` : ''}`;
        let displayToDate = `${filters?.toDate ? `${filters?.fromDate ? ' au ' : 'Jusqu\'au '}${moment(filters?.toDate).format('DD MM')}` : ''}`;

        return (`${displayFromDate}${displayToDate}`);
    }

    const handleChangeFromDate = (newValue) => {
        if (filters.toDate && newValue && moment(newValue).isAfter(filters.toDate)) {
            changeFilters({
                ...filters,
                fromDate: moment(newValue).format('YYYY-MM-DD'),
                toDate: moment(newValue).format('YYYY-MM-DD'),
            });
        } else {
            changeFilters({
                ...filters,
                fromDate: moment(newValue).format('YYYY-MM-DD'),
            });
        }
    };

    const handleChangeToDate = (newValue) => {
        if (newValue && filters.fromDate && moment(newValue).isBefore(filters.fromDate)) {
            changeFilters({
                ...filters,
                fromDate: moment(newValue).format('YYYY-MM-DD'),
                toDate: moment(newValue).format('YYYY-MM-DD'),
            });
        } else {
            changeFilters({
                ...filters,
                toDate: moment(newValue).format('YYYY-MM-DD'),
            });
        }
    };

    return (
        <Box mx={1} py={2}>
            <Box display='flex' alignItems='center' justifyContent='center' className='fullHeight'>
                <Chip
                    variant={type() ? 'default' : 'outlined'}
                    icon={<DateRangeIcon/>}
                    size='medium'
                    label={type() || "Dates"}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    onDelete={type() ? (() => changeFilters({...filters, fromDate: '', toDate: ''})) : null}
                />
            </Box>

            <CmtPopover
                anchorEl={anchorEl}
                closePopover={() => setAnchorEl(null)}
            >
                <Box pt={5} pr={5} pl={5}>
                    <Typography component='p'>Annonce qui démarre à partir du</Typography>
                </Box>
                <Box p={5} >
                    <CmtDatePicker
                        fullWidth
                        value={filters.fromDate || null}
                        setValue={(value) => handleChangeFromDate(value)}
                    />
                </Box>

                <Box pt={5} pr={5} pl={5}>
                    <Typography component='p'>Annonce qui termine le</Typography>
                </Box>

                <Box p={5} >
                    <CmtDatePicker
                        fullWidth
                        value={filters.toDate || null}
                        setValue={(value) => handleChangeToDate(value)}
                    />
                </Box>

            </CmtPopover>
        </Box>
    );
};