import { Box } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import { Formik } from "formik";
import { CancelIconButton, DescriptionInput, TitleInput, ValidateIconButton } from "./sc.TodoList";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export const AddTodo = ({ projectData, updateProject, setOpenAdd }) => {
    const checkErrors = (values) => {
        let errors = {};

        if (!values.title) {
            errors.title = "Veuillez renseigner un titre";
        }

        return errors;
    };

    return (
        <Formik
            initialValues={{ title: "", description: "" }}
            validate={(values) => checkErrors(values)}
            onSubmit={(values) => {
                updateProject({
                    projectChecks: [
                        ...projectData.projectChecks,
                        {
                            ...values,
                            position: projectData.projectChecks?.length + 1,
                            isDone: false,
                        },
                    ],
                }).then((result) => {
                    if (result?.result) {
                        setOpenAdd(false);
                    }
                });
            }}
        >
            {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
                <CmtForm onSubmit={handleSubmit}>
                    <Box backgroundColor="rgba(231, 231, 231, 1)" sx={{ px: 5, py: 5 }}>
                        <Box display="flex" width="100%">
                            <TitleInput
                                name="title"
                                id="checkTitle"
                                value={values.title}
                                placeholder="Titre"
                                variant="standard"
                                margin="none"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.title}
                            />
                            <CancelIconButton
                                color="error"
                                size="small"
                                onClick={() => setOpenAdd(false)}
                                sx={{ mx: 2, backgroundColor: "white" }}
                            >
                                <CloseIcon />
                            </CancelIconButton>
                            <ValidateIconButton
                                color="primary"
                                id="createTaskButton"
                                size="small"
                                type="submit"
                                sx={{ backgroundColor: "white" }}
                            >
                                <CheckIcon />
                            </ValidateIconButton>
                        </Box>
                        <DescriptionInput
                            name="description"
                            placeholder="Description"
                            id="checkDescription"
                            margin="none"
                            value={values.description}
                            variant="standard"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.description && errors.description}
                        />
                    </Box>
                </CmtForm>
            )}
        </Formik>
    );
};
