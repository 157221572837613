import { Box, Button, CardContent, Grid, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { IconView, TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import TitleIcon from "@mui/icons-material/Title";
import { useState } from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { DEFAULT_PUBLIC_TYPE_LIST, DEFAULT_TYPE_LIST } from "Constant";

export const DisplaySpectacleGeneralInfos = ({ projectData, setEditionMode }) => {
    const [hover, setHover] = useState(false);

    return (
        <Box p={2}>
            <CmtCard
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                id="generalInfosCardCmtCard"
            >
                <TileTitle>Infos Générale</TileTitle>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <TitleIcon />
                                </IconView>
                                <Box ml={5}>
                                    <Typography component="span" fontSize={12}>
                                        Titre du spectacle
                                    </Typography>
                                    <Typography fontSize={16}>
                                        {projectData?.spectacle?.title || "Titre non renseigné"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <TitleIcon />
                                </IconView>
                                <Box ml={5}>
                                    <Typography component="span" fontSize={12}>
                                        Type de spectacle
                                    </Typography>
                                    <Typography fontSize={16}>
                                        {DEFAULT_TYPE_LIST.find(
                                            (elem) => projectData?.spectacle?.category === elem.id
                                        )?.name || "Type de spectacle non renseigné"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <TitleIcon />
                                </IconView>
                                <Box ml={5}>
                                    <Typography component="span" fontSize={12}>
                                        Type de public
                                    </Typography>
                                    <Typography fontSize={16}>
                                        {DEFAULT_PUBLIC_TYPE_LIST.find(
                                            (elem) => projectData?.spectacle?.publicType === elem.id
                                        )?.name || "Type de public non renseigné"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <TitleIcon />
                                </IconView>
                                <Box ml={5}>
                                    <Typography component="span" fontSize={12}>
                                        Durée du spectacle
                                    </Typography>
                                    <Typography fontSize={16}>
                                        {projectData?.spectacle?.duration
                                            ? `${projectData?.spectacle?.duration.replace(
                                                  ":",
                                                  " H "
                                              )} min`
                                            : "Durée non renseigné"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <TitleIcon />
                                </IconView>
                                <Box ml={5}>
                                    <Typography component="span" fontSize={12}>
                                        Status
                                    </Typography>
                                    <Typography fontSize={16}>
                                        {projectData?.spectacle?.status
                                            ? "En tourné"
                                            : projectData?.spectacle?.status === false
                                            ? "Archivé"
                                            : "Status non renseigné"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box display="flex" justifyContent="flex-end" height={30}>
                        {hover && (
                            <Button
                                onClick={() => {
                                    setEditionMode(true);
                                }}
                                variant="contained"
                                color="secondary"
                                id="descriptionCardUpdateButton"
                            >
                                <Hidden xsDown> Modifier </Hidden>
                                <Hidden smUp>
                                    <CreateOutlinedIcon className="pointer" />
                                </Hidden>
                            </Button>
                        )}
                    </Box>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
