import { useTheme } from "@emotion/react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtImage from "Components/CmtImage/CmtImage";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";
import { TextBlock } from "../Partial/TextBlock";
import { PublicTypo } from "../sc.Public";

const FunctionalityTableElement = ({ title, text, ...rest }) => {
    const theme = useTheme();

    return (
        <Grid item xs={12} sm={6} lg={4} pl={10} {...rest}>
            <CmtCard sx={{ height: "100%", px: 5, py: 5 }}>
                <PublicTypo
                    family={theme.palette.publicTypo.hFamily}
                    variant="h1"
                    fontWeight="300"
                    color="#f2b90c"
                >
                    {title}
                </PublicTypo>
                <PublicTypo family={theme.palette.publicTypo.pFamily} fontWeight="light" pt={10}>
                    {text}
                </PublicTypo>
            </CmtCard>
        </Grid>
    );
};

export const FunctionalityBlock = () => {
    const theme = useTheme();

    return (
        <>
            <Container maxWidth="lg">
                <Grid container py={30} px={10}>
                    <Grid
                        item
                        xs={11}
                        lg={5}
                        position="relative"
                        display={{ xs: "none", md: "block" }}
                    >
                        <CmtImage src="/images/Public/Home/Fichier2.png" width="100%" />
                        <ShapeImg src="/images/Public/Shape/Shape12.png" top="20px" right="-50px" />
                        <ShapeImg src="/images/Public/Shape/Shape5.png" bottom="0" left={"25px"} />
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={12} lg={5} position="relative" pt={{ xs: 0, md: 50 }}>
                        <ShapeImg
                            src="/images/Public/Shape/Shape5.png"
                            rotate="90deg"
                            top={{ xs: 0, md: "100px" }}
                            right={"100px"}
                        />
                        <TextBlock
                            title={"Annuaire"}
                            text="Dénichez les structures les plus proches
                        de vous et invitez-les dans votre projet.
                        Construisez votre réseau
                        en dénichant les meilleurs experts dont
                        vous avez besoin. Théâtre, costumier,
                        producteur, diffuseur...
                        Tous les métiers du spectacle vivant les
                        plus proches de chez vous !"
                        />
                    </Grid>
                    <Grid item xs={12} lg={5} position="relative">
                        <TextBlock
                            title={"Annonces"}
                            text="Dénichez les structures les plus proches
                        de vous et invitez-les dans votre projet.
                        Construisez votre réseau
                        en dénichant les meilleurs experts dont
                        vous avez besoin. Théâtre, costumier,
                        producteur, diffuseur...
                        Tous les métiers du spectacle vivant les
                        plus proches de chez vous !"
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>

                    <Grid
                        item
                        xs={12}
                        lg={5}
                        position="relative"
                        display={{ xs: "none", lg: "block" }}
                    >
                        <ShapeImg src="/images/Public/Shape/Shape11.png" top="40%" left={"10%"} />

                        <ShapeImg src="/images/Public/Shape/Shape7.png" top="20%" right={"10%"} />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        lg={5}
                        position="relative"
                        pt={50}
                        display={{ xs: "none", lg: "block" }}
                    >
                        <CmtImage src="/images/Public/Home/Fichier3.png" width="100%" />
                        <ShapeImg src="/images/Public/Shape/Shape20.png" top="10%" right={"20%"} />
                    </Grid>
                    <Grid item xs={2}></Grid>

                    <Grid item xs={12} lg={5}>
                        <Box position="relative">
                            <ShapeImg
                                src="/images/Public/Shape/Shape19.png"
                                top="-20px"
                                right="10%"
                            />
                            <ShapeImg
                                src="/images/Public/Shape/Shape13.png"
                                bottom="-100px"
                                left={"80px"}
                                height={50}
                            />

                            <TextBlock
                                title={"Projets"}
                                text="Dénichez les structures les plus proches
                        de vous et invitez-les dans votre projet.
                        Construisez votre réseau
                        en dénichant les meilleurs experts dont
                        vous avez besoin. Théâtre, costumier,
                        producteur, diffuseur...
                        Tous les métiers du spectacle vivant les
                        plus proches de chez vous !"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box backgroundColor="#312d2e" py={15} width="100%">
                <Container maxWidth="lg">
                    <Box display="flex" justifyContent={"center"}>
                        <PublicTypo
                            family={theme.palette.publicTypo.hFamily}
                            size={theme.palette.publicTypo.h2Size}
                            fontWeight="300"
                            color="#f2b90c"
                        >
                            Fonctionnalités
                        </PublicTypo>
                    </Box>

                    <Grid container pt={5} spacing={4}>
                        <FunctionalityTableElement
                            title="Annonces"
                            text="Trouvez ce qui manque à la bonne conduite de vos projets."
                        />
                        <FunctionalityTableElement
                            title="Annuaire"
                            text="Intégrez une troupe et réalisez vos spectacles."
                        />
                        <FunctionalityTableElement
                            title="Projet"
                            text="Collaborez, échangez, évoluez, apprendre..."
                        />
                        <FunctionalityTableElement
                            title="Agenda"
                            text="Gérez votre planning et ceux de votre équipe en toute simplicité."
                        />
                        <FunctionalityTableElement
                            title="Bons plans"
                            text="Chinez et trouvez les bons plans du spectacle vivant."
                        />
                        <FunctionalityTableElement
                            title="Chat"
                            text="Attention au cat cat (Miaou !) Bordel de miaou"
                        />
                    </Grid>
                </Container>
            </Box>
        </>
    );
};
