import { Box, Button, CardContent, Grid, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useState } from "react";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import CmtImage from "Components/CmtImage/CmtImage";
import { IconView, TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function DisplayContact ({profile, setEditionMode}) {
    const [hover, setHover] = useState(false);

    if (!profile?.profile) {
        return (<></>);
    }
    
    return (
        <CmtCard onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} id='contactCmtCard'>
            <TileTitle>Contact</TileTitle>
            <CardContent>
                <Grid container>
                    <Grid item container sm={4} xs={12} mb={{ xs: 4, sm: 6 }} display='flex' justifyContent='center' my={5}>
                        <IconView marginRight={5}>
                            <EmailIcon />
                        </IconView>
                        <Box>
                            <Box component="span" fontSize={12} color="primary">Email de contact</Box>
                            {profile.profile.contactEmail ? <Typography component="a" display='block' color='secondary' href={`mailto:${profile.profile.contactEmail}`}>
                                {profile.profile.contactEmail}
                            </Typography> : <Typography component='p' display='block' color='secondary'>Non renseigné</Typography>}
                        </Box>
                    </Grid>

                    <Grid container item sm={4} xs={12} mb={{ xs: 4, sm: 6 }}  display='flex' justifyContent='center' my={5}>
                        <IconView>
                            <PhoneIcon />
                        </IconView>
                        <Box ml={5}>
                            <Box component="span" fontSize={12} color="primary">Téléphone</Box>
                            {profile?.profile?.phoneNumber ? <Typography component="a" display='block' color='secondary' href={`tel:${profile.profile.phoneNumber}`}>
                                {profile.profile.phoneNumber.replace(/\B(?=(\d{2})+(?!\d))/g, '.')}
                            </Typography> : <Typography omponent='p' display='block' color='secondary'>Non renseigné</Typography>}
                        </Box> 
                    </Grid>

                    <Grid container item sm={4} xs={12} mb={{ xs: 4, sm: 6 }} display='flex' justifyContent='center' my={5}>
                        <IconView>
                            <LanguageIcon />
                        </IconView>
                        <Box ml={5}>
                            <Box component="span" fontSize={12} color="primary">Site Web</Box>
                            {profile?.profile?.website ? <Typography component="a" display='block' color='secondary' href={profile.profile.website}>
                                {profile.profile.website.replace(/(^\w+:|^)\/\//, '')}
                            </Typography> : <Typography omponent='p' display='block' color='secondary'>Non renseigné</Typography>}
                        </Box>
                    </Grid>


                </Grid>
                
                <Grid container mt={10}>
                    {profile?.profile?.socials?.map((item, index) => {
                        return (
                            <Grid container item lg={6} xs={12} display='flex' justifyContent='center' my={5} key={`socialNetwork ${index}`}>
                                <CmtImage src={item?.iconPath} height={40} width={40}/>
                                <Box ml={5}>
                                    <Box component="span" fontSize={12} color="primary">{item?.name}</Box>
                                    <Typography component="a" display='block' color='secondary' href={item.value}>
                                        {item.value.replace(/(^\w+:|^)\/\//, '')}
                                    </Typography>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
                <Box width='100%' display='flex' justifyContent='flex-end' height={30}>
                    {hover &&
                        <Button onClick={() => {setEditionMode(true)}} variant='contained' color='secondary' id="contactUpdateButton">
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp> <CreateOutlinedIcon className='pointer' /> </Hidden>
                        </Button>
                    }
                </Box>
            </CardContent>
        </CmtCard>
    );
};