import { Box, Button, Hidden, Typography } from "@mui/material";

export const StepperLine = ({
    onCancel = null,
    stepper = null,
    nextButtonText = 'Suivant',
    step,
}) => {

    const Stepper = stepper;

    return (
        <Box display='flex' mb={5} flexWrap='wrap'>
            {onCancel && 
                <Hidden smDown>
                    <Box mr={5}>
                        <Button
                            id='cancelButton'
                            variant="outlined"
                            color="secondary"
                            size='small'
                            onClick={onCancel}
                        >
                            <Typography>Précédent</Typography>
                        </Button>
                    </Box>
                </Hidden>
            }

            <Box mb={3} mr={5} flexGrow={1} position='relative'>
                <Stepper />
                <Typography component='p' variant='subtitle2' color='textSecondary' fontWeight={500}> {step}</Typography>
            </Box>

            <Box display='flex' justifyContent='space-between'>
                {onCancel && 
                    <Hidden smUp>
                        <Box>
                            <Button
                                id='cancelButton'
                                variant="outlined"
                                color="secondary"
                                size='small'
                                onClick={onCancel}
                            >
                                <Typography>Précédent</Typography>
                            </Button>
                        </Box>
                    </Hidden>
                }

                <Box ml='auto'>
                    <Button
                        id='submitButton'
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size='small'
                    >
                        <Typography>{nextButtonText}</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};