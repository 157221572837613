import { Box } from '@mui/material';
import CmtSearch from 'Components/CmtSearch/CmtSearch';

export const SideBarHeader = ({
    filter,
    setFilter
}) => {
    return (
        <Box pt={4} pr={4} pb={2}>
            <CmtSearch
                placeholder={"Rechercher un contact"}
                value={filter}
                setValue={setFilter}
                border={false}
                collapse={false}
            />
        </Box>
    );
};