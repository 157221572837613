import { Tooltip } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { useState } from "react";
import { ChatAppStore } from "Apps/Chat/ChatApp";
import {
    NotificationCircle,
    PopoverContainer,
    PopoverIconButton,
} from "../../sc.ConnectedContainer";

export default function Messages({ chatData }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const onOpenPopOver = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onClosePopOver = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const unread = chatData.users?.find((elem) => elem?.lastMessage?.messageRead === false);

    return (
        <>
            <Tooltip title="Chat">
                <PopoverIconButton
                    open={open}
                    onClick={(event) => {
                        onOpenPopOver(event);
                    }}
                    color="primary"
                    size="large"
                >
                    <MessageIcon />
                    {unread && <NotificationCircle opened={open} />}
                </PopoverIconButton>
            </Tooltip>

            <PopoverContainer
                open={open}
                onClose={onClosePopOver}
                id={"simple-popover"}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <ChatAppStore popoverVersion={true} />
            </PopoverContainer>
        </>
    );
}
