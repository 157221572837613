import axios from "./config";

const profileUser = {
    getAuthUser: async () => {
        try {
            const result = await axios.get("/user/profile");

            return { result: true, data: result.data };
        } catch (err) {
            return { result: false, err: err?.response?.data };
        }
    },

    markNotificationAsRead: async (id) => {
        try {
            const result = await axios.post(`/user/notifications/${id}/markAsRead`);

            return { result: true, data: result?.data };
        } catch (err) {
            return { result: false, err: err?.response?.data };
        }
    },

    updateProfile: async (data) => {
        try {
            let formData = new FormData();

            if ("firstName" in data) {
                formData.append("firstName", data.firstName);
            }

            if ("lastName" in data) {
                formData.append("lastName", data.lastName);
            }

            if ("email" in data) {
                formData.append("email", data.email);
            }

            if ("plainPassword" in data) {
                formData.append("plainPassword", data.plainPassword);
            }

            if ("birthday" in data) {
                formData.append("birthday", data.birthday);
            }

            if ("positionType" in data) {
                formData.append("positionType", data.positionType);
            }

            if ("description" in data) {
                formData.append("description", data.description);
            }

            if ("contactEmail" in data) {
                formData.append("contactEmail", data.contactEmail);
            }

            if ("phoneNumber" in data) {
                formData.append("phoneNumber", data.phoneNumber);
            }

            if ("website" in data) {
                formData.append("website", data.website);
            }

            if ("profileFileName" in data) {
                formData.append("profileFile[file]", data.profileFileName);
            }

            if ("coverFileName" in data) {
                formData.append("coverFile[file]", data.coverFileName);
            }

            data?.socials?.forEach((item, index) => {
                formData.append(`socials[${index}][socialType]`, item.typeId);
                formData.append(`socials[${index}][value]`, item.value);
            });

            const result = await axios.post("/user/profile/edit", formData);

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    deleteProfile: async () => {
        try {
            await axios.delete("/user/profile");

            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("time_token");

            axios.defaults.headers.common["Authorization"] = "";

            return { result: true };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getSocialType: async () => {
        try {
            const result = await axios.get("/user/social-types");

            return { result: true, data: result.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getMercureToken: async () => {
        try {
            const result = await axios.get("/user/token");

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getColors: async () => {
        try {
            const result = await axios.get("/user/colors");

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },

    getNotifications: async (data) => {
        try {
            const params = {};

            params.limit = data.limit || 10;
            params.lastId = data.lastId;

            const result = await axios.get(`/user/notifications`, { params: params });

            return { result: true, data: result?.data };
        } catch (error) {
            return { result: false, error: error?.response?.data };
        }
    },
};

export default profileUser;
