import axios from "./config";

const notes = {
    getNotesList: async (filters) => {
        try {
            let params = {};

            if (filters?.category) {
                params = { ...params, "filters[category]": filters.category };
            }

            if (filters?.sorter) {
                const sorter = filters?.sorter.split(" ");

                params = { ...params, "filters[sortField]": sorter[0] };
                params = { ...params, "filters[sortOrder]": sorter[1] };
            }

            const result = await axios.get(`/user/notes`, { params: params });

            return { result: true, data: result.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    createNote: async (data) => {
        try {
            let formData = new FormData();

            formData.append("title", data?.title);

            if (data?.description) {
                formData.append("description", data?.description);
            }

            if (data?.imageFile) {
                formData.append("imageFile[file]", data?.imageFile);
            }

            data?.notesTypes?.forEach((element, index) => {
                formData.append(`noteTypes[${index}]`, element);
            });

            if (data?.reminderDate) {
                formData.append("reminderDate", data?.reminderDate);
            }

            const result = await axios.post(`/user/notes/create`, formData);

            return { result: true, data: result.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    updateNote: async (data) => {
        try {
            let formData = new FormData();

            if (data?.title) {
                formData.append("title", data?.title);
            }

            if (data.description) {
                formData.append("description", data?.description);
            }

            if (data?.imageFile) {
                formData.append("imageFile[file]", data?.imageFile);
            }

            data?.noteTypes?.forEach((element, index) => {
                formData.append(`noteTypes[${index}]`, element);
            });

            if (data?.reminderDate) {
                formData.append("reminderDate", data?.reminderDate);
            }

            const result = await axios.post(`/user/notes/${data?.id}/update`, formData);

            return { result: true, data: result.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    deleteNote: async (id) => {
        try {
            await axios.delete(`/user/notes/${id}`);

            return { result: true };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    createCheckbox: async (data) => {
        try {
            let formData = new FormData();

            formData.append("description", data?.description);

            const result = await axios.post(`/user/note-checks/${data?.noteId}/create`, formData);

            return { result: true, data: result.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    updateCheckbox: async (data) => {
        try {
            let formData = new FormData();

            formData.append("description", data?.description);

            const result = await axios.post(
                `/user/note-checks/${data?.noteCheckId}/update`,
                formData
            );

            return { result: true, data: result.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    moveCheckbox: async (data) => {
        try {
            const result = await axios.post(
                `/user/note-checks/${data?.noteCheckId}/move/${data?.position}`
            );

            return { result: true, data: result.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    validationCheckbox: async (noteCheckId) => {
        try {
            const result = await axios.post(`/user/note-checks/${noteCheckId}/check`);

            return { result: true, data: result?.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    deleteCheckbox: async (noteCheckId) => {
        try {
            await axios.delete(`/user/note-checks/${noteCheckId}`);

            return { result: true };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    getNotesTypeList: async () => {
        try {
            const result = await axios.get(`/user/note-types`);

            return { result: true, data: result?.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    createNotesType: async (data) => {
        try {
            let formData = new FormData();

            formData.append("name", data?.name);

            const result = await axios.post(`/user/note-types/create`, formData);

            return { result: true, data: result?.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    updateNotesType: async (data) => {
        try {
            let formData = new FormData();

            formData.append("name", data?.name);
            formData.append("color", String(data?.color).toUpperCase());

            const result = await axios.post(`/user/note-types/${data?.id}/update`, formData);

            return { result: true, data: result?.data };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },

    deleteNotesType: async (noteTypeId) => {
        try {
            await axios.delete(`/user/note-types/${noteTypeId}`);

            return { result: true };
        } catch (err) {
            return { result: false, error: err?.response?.data };
        }
    },
};

export default notes;
