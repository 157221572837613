import { alpha, Box, styled } from "@mui/system";

export const MessageItem = styled(Box)`
    transition: all .3s ease;
    position: relative;
    &:not(:first-child) {
        border-top: ${props => `solid 1px ${props.theme.palette.borderColor.dark}`};
    };
    &:hover {
        background-color: ${props => alpha(props.theme.palette.common.dark, 0.04)};
        & .commentRoot {
            visibility: visible;
        };
    };
`;