import { Box, Hidden } from "@mui/material";
import { AnnouncementPriceOption } from "./AnnouncementPriceOption";
import { AnnouncementDateOption } from "./AnnouncementDateOption";
import { ANNOUNCEMENT_SORT_TABLE } from "Constant";
import { CategoriesFilters } from "Components/CmtFilters/CategoriesFilters";
import { LocationFilters } from "Components/CmtFilters/LocationFilters";
import { SortingFilters } from "Components/CmtFilters/SortingFilters";
import { AnnouncementFavoriteOption } from "./AnnouncementFavoriteOption";

export const AnnouncementFilters = ({
    categoriesList,
    filters,
    changeFilters,
    locationMode,
    setLocationMode,
}) => {
    return (
        <>
            <CategoriesFilters
                filters={filters}
                categoriesList={categoriesList}
                changeFilters={changeFilters}
            />

            <LocationFilters
                filters={filters}
                changeFilters={changeFilters}
                locationMode={locationMode}
                setLocationMode={setLocationMode}
            />

            <AnnouncementPriceOption
                filters={filters}
                changeFilters={changeFilters}
            />

            <AnnouncementDateOption
                filters={filters}
                changeFilters={changeFilters}
            />

            <AnnouncementFavoriteOption
                filters={filters}
                changeFilters={changeFilters}
            />


            <Hidden mdDown>
                <Box ml='auto' display='flex' alignItems='center'>
                    <SortingFilters
                        filters={filters}
                        changeFilters={changeFilters}
                        sortingList={ANNOUNCEMENT_SORT_TABLE}
                    />
                </Box>
            </Hidden>
        </>
    );
};