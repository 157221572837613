import { useState } from "react";
import DisplayInfosCard from "./DisplayInfosCard";
import EditionInfosCard from "./EditionInfosCard";

export const ProfileInfosCard = ({ companyData, companiesTypes, update, isAdmin }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionInfosCard
                companyData={companyData}
                setEditionMode={setEditionMode}
                companiesTypes={companiesTypes}
                submit={update}
            />
        );
    } else {
        return (
            <DisplayInfosCard
                companyData={companyData}
                setEditionMode={setEditionMode}
                isAdmin={isAdmin}
            />
        );
    }
};
