import { Box } from "@mui/material";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import { CmtNavMenu } from "Components/CmtNavMenu/CmtNavMenu";
import { NOTE_PATH, SETTING_PATH } from "Constant";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { notConnectedAction } from "redux/actions/authActions";
import { NoteTypeParameters } from "./NoteTypesParameters/NoteTypeParameters";

export const ParametersApp = ({disconnect}) => {
    const NavMenu = () => {
        return (
            <CmtNavMenu
                navList={[
                    {label: 'Paramètres généraux', value: SETTING_PATH},
                    {label: 'Types de notes', value: `${SETTING_PATH}${NOTE_PATH}`},
                ]}
            />
        );
    };

    return (
        <>
            <NavMenu />
            <Box
                display='flex'
                height='100%'
                flexDirection='column'
                flexWrap='nowrap'
                p={5}
            >
                <Switch>
                    <Route exact path={SETTING_PATH}>
                        <Box pb={5}>
                            <CmtAppHeader title='Paramètres'/>
                        </Box>
                    </Route>


                    <Route exact path={`${SETTING_PATH}${NOTE_PATH}`}>
                        <Box pb={5}>
                            <CmtAppHeader title='Types de notes' />
                            <NoteTypeParameters />
                        </Box>
                    </Route>
                </Switch>
            </Box>
        </>
    );
};


export const ParametersAppStore = connect(
    (state) => ({
    }),
    (dispatch) => ({
        disconnect: () => dispatch(notConnectedAction()),
    })
) (ParametersApp);