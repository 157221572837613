import { Box, Button, CardContent, Grid, Hidden } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import WorkIcon from "@mui/icons-material/Work";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useState } from "react";
import { TileTitle, IconView } from "Components/CmtProfileContainer/sc.ProfileContainer";
import EventIcon from "@mui/icons-material/Event";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import moment from "moment";

export default function DisplayProjectInfosCard({ projectData, setEditionMode }) {
    const [hover, setHover] = useState(false);

    return (
        <CmtCard
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            id="infosCardCmtCard"
        >
            <TileTitle>Informations</TileTitle>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                            <IconView>
                                <WorkIcon />
                            </IconView>
                            <Box ml={5}>
                                <Box component="span" fontSize={12} color="primary">
                                    Type de projet
                                </Box>
                                <Box fontSize={16}>
                                    <Box>{projectData?.projectType?.name}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                            <IconView>
                                <EventIcon />
                            </IconView>
                            <Box ml={5}>
                                <Box component="span" fontSize={12} color="primary">
                                    Date de début estimée
                                </Box>
                                <Box fontSize={16}>
                                    <Box>
                                        {projectData?.startDate
                                            ? moment(projectData?.startDate).format("DD MM YYYY")
                                            : "Non renseigné"}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                            <IconView>
                                <EventIcon />
                            </IconView>
                            <Box ml={5}>
                                <Box component="span" fontSize={12} color="primary">
                                    Date de fin estimée
                                </Box>
                                <Box fontSize={16}>
                                    <Box>
                                        {projectData?.endDate
                                            ? moment(projectData?.endDate).format("DD MM YYYY")
                                            : "Non renseigné"}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                            <IconView>
                                <ConfirmationNumberIcon />
                            </IconView>
                            <Box ml={5}>
                                <Box component="span" fontSize={12} color="primary">
                                    Nombre de représentation prévues
                                </Box>
                                <Box fontSize={16}>
                                    <Box>{projectData?.performancesNb || "Non renseigné"}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" height={30}>
                    {hover && (
                        <Button
                            onClick={() => {
                                setEditionMode(true);
                            }}
                            variant="contained"
                            color="secondary"
                            id="infosCardUpdateButton"
                        >
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp>
                                <CreateOutlinedIcon className="pointer" />
                            </Hidden>
                        </Button>
                    )}
                </Box>
            </CardContent>
        </CmtCard>
    );
}
