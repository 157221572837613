import { Avatar, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { RootAvatarGroup } from "./sc.AvatarGroup";

const CmtAvatarGroup = ({
    items,
    max,
    srcKey,
    titleKey,
    phCharLength,
    avatarSize,
    onItemClick,
    onMoreClick,
    spacing,
    expandable,
    renderItemSummary,
    renderMore,
    moreVisibleOn,
    itemStyle,
    activeItemStyle,
    tooltipProps,
    ...rest
}) => {
    const totalRecords = useMemo(() => items.length, [items]);
    const visibleItems = useMemo(() => items.slice(0, max < totalRecords ? max - 1 : max), [items, max, totalRecords]);
    const [itemTooltipContent, setItemTooltipContent] = useState('');
    const [moreTooltipContent, setMoreTooltipContent] = useState('');
    const [moreTooltip, setMoreTooltip] = useState(false);


    const handleItemMouseOver = (item, index) => {
        if (renderItemSummary) {
            setItemTooltipContent(renderItemSummary(item, index));
        }
    };
  
    const handleItemMouseLeave = () => {
        setItemTooltipContent('');
    };
  
    const handleMoreMouseOver = () => {
        if (!onMoreClick && moreVisibleOn === 'hover' && renderMore) {
            const restItems = items.slice(max - 1, totalRecords);
            
            setMoreTooltipContent(renderMore(restItems));
            
            setMoreTooltip(true);
        }
    };
  
    const handleMoreMouseLeave = () => {
        if (!onMoreClick && moreVisibleOn === 'hover' && renderMore) {
            setMoreTooltip(false);
            
            setTimeout(() => {
                setMoreTooltipContent('');
            }, 100);
        }
    };
  
    const handleItemClick = (item, index) => {
        if (onItemClick) {
            onItemClick(item, index);
        }
    };
  
    const handleMoreClick = () => {
        const restItems = items.slice(max - 1, totalRecords);

        if (onMoreClick) {
            onMoreClick(restItems);
        } else if (moreVisibleOn === 'click' && renderMore) {
            if (moreTooltipContent) {
                setMoreTooltip(false);
                setTimeout(() => {
                    setMoreTooltipContent('');
                }, 100);
            } else {
                setMoreTooltipContent(renderMore(restItems));
                setMoreTooltip(true);
            }
        }
    };
  
    const getPlaceholderChar = str => {
        if (str && phCharLength > 0) {
            return str.substr(0, phCharLength).toUpperCase();
        }
    };
  
    return (
        <RootAvatarGroup className='Cmt-root-avatar-group' max={max} {...rest}>
            {visibleItems.map((item, index) => (
                <Tooltip title={itemTooltipContent} key={index} {...tooltipProps}>
                    <Avatar
                        onMouseEnter={() => handleItemMouseOver(item, index)}
                        onMouseLeave={handleItemMouseLeave}
                        className={expandable ? 'Cmt-hoverEffect' : ''}
                        alt={item[titleKey]}
                        src={item[srcKey]}
                        size={avatarSize}
                        onClick={() => handleItemClick(item, index)}
                        style={{ cursor: 'pointer', zIndex: index, height: avatarSize, width: avatarSize }}
                    >
                        {getPlaceholderChar(item[titleKey])}
                    </Avatar>
                </Tooltip>
            ))}

            {max < totalRecords && (
                <Tooltip title={moreTooltipContent} open={moreTooltip}>
                    <Avatar
                        onMouseEnter={handleMoreMouseOver}
                        onMouseLeave={handleMoreMouseLeave}
                        className={`Cmt-avatar-more ${expandable ? 'Cmt-hoverEffect' : ''}`}
                        alt={(totalRecords - max + 1).toString()}
                        size={avatarSize}
                        style={{ cursor: 'pointer', zIndex: max }}
                        onClick={handleMoreClick}
                    >
                        +{totalRecords - max + 1}
                    </Avatar>
                </Tooltip>
            )}
        </RootAvatarGroup>
    );
};
  
CmtAvatarGroup.prototype = {
    items: PropTypes.array.isRequired,
    max: PropTypes.number,
    spacing: PropTypes.number,
    srcKey: PropTypes.string,
    titleKey: PropTypes.string,
    phCharLength: PropTypes.number,
    avatarSize: PropTypes.oneOfType([PropTypes.oneOf(['small', 'medium', 'large']), PropTypes.number]),
    onItemClick: PropTypes.func,
    onMoreClick: PropTypes.func,
    expandable: PropTypes.bool,
    renderItemSummary: PropTypes.func,
    renderMore: PropTypes.func,
    moreVisibleOn: PropTypes.oneOf(['hover', 'click']),
    itemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object,
    tooltipProps: PropTypes.object,
};
  
CmtAvatarGroup.defaultProps = {
    items: [],
    max: 5,
    spacing: 8,
    srcKey: 'src',
    titleKey: 'title',
    phCharLength: 1,
    avatarSize: 'medium',
    expandable: false,
    moreVisibleOn: 'hover',
    itemStyle: { outlineColor: '#fafafa', outlineThickness: 2 },
    activeItemStyle: { outlineColor: '', outlineThickness: 2, elevation: 1 },
};
  
export default React.memo(CmtAvatarGroup);
