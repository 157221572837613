import { useState } from "react";
import { DisplaySpectaclePoster } from "./DisplaySpectaclePoster";
import { EditionSpectaclePoster } from "./EditionSpectaclePoster";

export const SpectaclePoster = ({ projectData, updateProject }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionSpectaclePoster
                projectData={projectData}
                setEditionMode={setEditionMode}
                submit={updateProject}
            />
        );
    } else {
        return <DisplaySpectaclePoster projectData={projectData} setEditionMode={setEditionMode} />;
    }
};
