import { styled } from '@mui/system';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const ChatPerfectScroll = styled(PerfectScrollbar)`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s ease;
    position: relative;
    touchAction: auto;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
    & .ps__rail-y, & .ps__rail-x {
        position: absolute;
    };
`;