import { Box, Button, Hidden, Typography, alpha } from "@mui/material";
import FormInput from '../../../Components/CmtFormInput/CmtFormInput.jsx';
import { FORGET_PATH, SIGNUP_PATH } from 'Constant';
import CmtImage from "Components/CmtImage/CmtImage.jsx";
import CmtForm from "Components/CmtForm/CmtForm.jsx";
import { NavLink } from 'react-router-dom';
import { Formik } from "formik";
import { useTheme } from "@emotion/react";


export default function LoginForm ({onLogin}) {
    const theme = useTheme();

    const checkError = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Veuillez renseigner une addresse Email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Adresse email invalide';
        }

        if (!values.password) {
            errors.password = 'Veuillez renseigner un mot de passe';
        }

        return errors;
    };

    return (
        <>
            <Box p={8} width='100%' onClick={(e) => e.stopPropagation()}>
                <Box mb={7}>
                    <CmtImage src={'/images/logo.svg'} height={50}/>
                </Box>
                <Typography component="div" variant="h1" color='primary'>Se Connecter</Typography>
                <Formik
                    initialValues={{email: '', password: ''}}
                    validate={values => checkError(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        onLogin(values);
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        isSubmitting,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <FormInput 
                                type="email"
                                name="email"
                                label="Email"
                                variant='standard'
                                id='loginEmail'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email} 
                                error={touched.email && errors.email}
                            />
                            <FormInput 
                                type="password" 
                                name="password" 
                                variant='standard'
                                label="Mot de passe"
                                id='loginPassword'
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.password && errors.password}
                            />
                            
                            <Box display="flex" alignItems="center" justifyContent="flex-end" mb={5}>
                                <Box component="div" fontSize={{xs: 12, sm: 16}}>
                                    <NavLink to={FORGET_PATH}>
                                        <Typography component='p'>Mot de passe oublié ?</Typography>
                                    </NavLink>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                                <Button id='loginButton' type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                    <Typography>Se connecter</Typography>
                                </Button>
                                <NavLink to={SIGNUP_PATH}>
                                    <Typography component='p'>Créer un compte</Typography>
                                </NavLink>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>

            </Box>
            <Hidden smDown>
                <Box
                    p={5}
                    width='50%'
                    flexShrink={0}
                    backgroundColor={alpha(theme.palette.primary.main, 0.12)}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <CmtImage src="/images/Auth/login-img.png" />
                </Box>
            </Hidden>
        </>
    );
};
