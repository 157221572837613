import { Draggable } from "react-beautiful-dnd";
import { TaskItemBox } from "./sc.TodoList";
import { Box, Checkbox, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { Formik } from "formik";
import { CancelIconButton, DescriptionInput, TitleInput, ValidateIconButton } from "./sc.TodoList";
import CmtForm from "Components/CmtForm/CmtForm";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export const TodoElem = ({ item, index, checkItem, deleteLine, updateTask }) => {
    const [editMode, setEditMode] = useState(false);
    const [hover, setHover] = useState(false);

    const checkError = (values) => {
        let errors = {};

        if (!values.title) {
            errors.title = "Veuillez renseigner un titre";
        }

        return errors;
    };

    if (editMode) {
        return (
            <Formik
                initialValues={{ title: item.title, description: item.description }}
                validate={(values) => checkError(values)}
                onSubmit={(values) => {
                    updateTask({ ...item, ...values }).then((result) => {
                        if (result?.result) {
                            setEditMode(false);
                        }
                    });
                }}
            >
                {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
                    <CmtForm onSubmit={handleSubmit}>
                        <Box sx={{ px: 5, py: 5 }}>
                            <Box display="flex" width="100%">
                                <TitleInput
                                    name="title"
                                    id="checkTitle"
                                    value={values.title}
                                    placeholder="Titre"
                                    variant="standard"
                                    margin="none"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.title}
                                />
                                <CancelIconButton
                                    color="error"
                                    size="small"
                                    sx={{ mx: 2, backgroundColor: "white" }}
                                    onClick={() => setEditMode(false)}
                                >
                                    <CloseIcon />
                                </CancelIconButton>
                                <ValidateIconButton
                                    color="primary"
                                    size="small"
                                    id={`validateEditTaskButton`}
                                    type="submit"
                                    sx={{ backgroundColor: "white" }}
                                >
                                    <CheckIcon />
                                </ValidateIconButton>
                            </Box>
                            <DescriptionInput
                                name="description"
                                placeholder="Description"
                                id="checkDescription"
                                margin="none"
                                value={values.description}
                                variant="standard"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.description && errors.description}
                            />
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        );
    }

    return (
        <Draggable draggableId={item?.id?.toString()} index={index} key={item?.id}>
            {(provided) => (
                <TaskItemBox
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    id={`taskItemBox-${index}`}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Checkbox
                        checked={item?.isDone}
                        onChange={(e) => checkItem(e.target.checked)}
                    />
                    <Box ml={3}>
                        <Typography component="p" variant="subtitle1">
                            {item?.title}
                        </Typography>
                        <Typography component="p" variant="body2">
                            {item?.description}
                        </Typography>
                    </Box>

                    {hover && (
                        <Box
                            display="flex"
                            alignItems="center"
                            ml="auto"
                            height="100%"
                            id="iconBox"
                        >
                            <IconButton
                                id={`editTaskButton-${index}`}
                                color="primary"
                                size="small"
                                onClick={() => setEditMode(true)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                id={`deleteTaskButton-${index}`}
                                color="secondary"
                                size="small"
                                onClick={deleteLine}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    )}
                </TaskItemBox>
            )}
        </Draggable>
    );
};
