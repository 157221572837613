import { Box, Grid, Typography } from "@mui/material";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import { RatingStar } from "../Home/sc.Home";

export const TestimonyElem = ({ imgSrc, rating, text }) => {
    return (
        <Grid item lg={12} md={4} xs={12}>
            <CmtCard>
                <Box display="flex">
                    <Box p={3}>
                        <AvatarComponent src={imgSrc} size={100} />
                    </Box>

                    <Box p={3} display="flex" flexDirection="column" flexGrow={1}>
                        <RatingStar name="read-only" value={rating} readOnly size="1.5em" />
                        <Typography pt={5} component="p">
                            {text}
                        </Typography>
                    </Box>
                </Box>
            </CmtCard>
        </Grid>
    );
};
