import { DATE_LAST_TWO_DAYS, DATE_LAST_WEEK } from "Constant";
import { SORT_BY_CATEGORIES, SORT_BY_DATE } from "redux/constants/announcementsConstants";

export const announcementsListSelector = ({announcements}) => {
    if (!announcements.announcementsList || !announcements.myAnnouncementsList) {
        return (announcements);
    }

    let announce = announcements;

    if (announce.dateFilters) {
        announce = dateFilteredAnnouncementsListSelector({announcements: announce});
    }

    if (announce.categoryFilters) {
        announce = categoryFilteredAnnouncementsListSelector({announcements: announce});
    }

    if (announce.sortBy) {
        announce = sortedAnnouncementListSelector({announcements: announce});
    }

    if (announce.searchText) {
        announce = searchedAnouncementListSelector({announcements: announce});
    }

    return (announce);
};


export const searchedAnouncementListSelector = ({announcements}) => {
    if (!announcements?.searchText) {
        return (announcements);
    }

    let list = [];

    announcements.announcementsList.forEach(item => {
        if (item?.title?.includes(announcements.searchText)) {
            list.push(item);
        }
    });


    let myList = [];

    announcements.myAnnouncementsList.forEach(item => {
        if (item?.title?.includes(announcements.searchText)) {
            myList.push(item);
        }
    });

    return {...announcements, announcementsList: list, myAnnouncementsList: myList};
};


export const dateFilteredAnnouncementsListSelector = ({announcements}) => {
    if (!announcements.dateFilters) {
        return (announcements);
    }

    let date = new Date();

    if (announcements.dateFilters === DATE_LAST_TWO_DAYS) {
        date.setDate(date.getDate() - 2);
    } else if (announcements.dateFilters === DATE_LAST_WEEK) {
        date.setDate(date.getDate() - date.getDay() + 1);
    } else {
        date.setDate(1);
    }

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    const actualTime = date.getTime();

    let list = [];

    announcements.announcementsList.forEach(item => {
        const publicationDate = new Date(item.publicationDate);

        if (publicationDate.getTime() >= actualTime) {
            list.push(item);
        }
    });


    let myList = [];

    announcements.myAnnouncementsList.forEach(item => {
        const publicationDate = new Date(item.publicationDate);

        if (publicationDate.getTime() >= actualTime) {
            myList.push(item);
        }
    });

    return {...announcements, announcementsList: list, myAnnouncementsList: myList};
};



export const categoryFilteredAnnouncementsListSelector = ({announcements}) => {
    if (!announcements.categoryFilters) {
        return (announcements);
    }
    
    let list = [];
    let myList = [];
    
    announcements.announcementsList.forEach(item => {
        if (item.announcementType.id === announcements.categoryFilters) {
            list.push(item);
        }
    });

    announcements.myAnnouncementsList.forEach(item => {
        if (item.announcementType.id === announcements.categoryFilters) {
            myList.push(item);
        }
    });
    
    return {...announcements, announcementsList: list, myAnnouncementsList: myList};
};




export const sortedAnnouncementListSelector = ({announcements}) => {
    let list = announcements.announcementsList;
    let myList = announcements.myAnnouncementsList;

    if (!list || !myList || !announcements.sortBy) {
        return (announcements);
    }

    list.sort((a, b) => {
        if (announcements.sortBy === SORT_BY_CATEGORIES) {
            return a.announcementType.name > b.announcementType.name;
        } else if (announcements.sortBy === SORT_BY_DATE) {
            return a.publicationDate < b.publicationDate;
        }

        return (a);
    });

    myList.sort((a, b) => {
        if (announcements.sortBy === SORT_BY_CATEGORIES) {
            return a.announcementType.name > b.announcementType.name;
        } else if (announcements.sortBy === SORT_BY_DATE) {
            return a.publicationDate < b.publicationDate;
        }

        return (a);
    });

    return {...announcements, announcementsList: list, myAnnouncementsList: myList};
};