import { Box } from '@mui/material';
import ContentFooter from './ContentFooter/ContentFooter.jsx';
import ContentHeader from './ContentHeader/ContentHeader.jsx';
import ContentMessages from './ContentMessages/ContentMessages.jsx';

export function ChatContainer ({
    profile,
    chat,
    sendMessage,
    sendMediaMessage,
    getMessages,
    deleteMessage,
    sendIsTyping,
    readedMessage,
    popoverVersion,
    setIsCollapse
}) {
    if (chat?.currentUser) {
        return (
            <Box
                flexGrow={1}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
            >
                <ContentHeader setIsCollapse={setIsCollapse} user={chat?.currentUser} profileId={profile?.profile?.id} />
                
                {chat?.conversation?.length > 0 &&
                    <ContentMessages
                        chat={chat}
                        profileId={profile?.profile?.id}
                        getMessages={getMessages}
                        deleteMessage={deleteMessage}
                        readedMessage={readedMessage}
                        popoverVersion={popoverVersion}
                    />
                }
                
                <ContentFooter
                    sendMessage={sendMessage}
                    sendMediaMessage={sendMediaMessage}
                    sendIsTyping={sendIsTyping}
                    currentUser={chat?.currentUser}
                    popoverVersion={popoverVersion}
                />
            </Box>
        );
    } else {
        return (
            <Box height='100%' width='100%' />
        );
    }
};