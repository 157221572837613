import { Box } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { NoBorderCheckbox } from "../sc.Notes";

export const NoteCheckElem = ({
    item,
    index,
    handleValidateCheckbox,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleDeleteCheck,
    remove,
    handleCreateCheck,
    handleUpdateCheck
}) => {
    if (!item.id) {
        return (
            <Box
                key={index}
                display='flex'
                alignItems='flex-start'
            >
                <Box pt='2px' >
                    <DragIndicatorIcon color='disabled' />
                </Box>
                <Box pr={2} pt='5px'>
                    <NoBorderCheckbox
                        size='small'
                        disabled
                    />
                </Box>
                <FormInput
                    name={`todoList.${index}.description`}
                    placeholder='Ajouter une tâche'
                    value={item?.description}
                    margin='none'
                    onChange={handleChange}
                    onBlur={async (e) => {
                        handleCreateCheck(e.target.value)
                        .then(result => {
                            if (result?.result) {
                                setFieldValue(`todoList.${index}.id`, result?.data?.id);
                            }
                        });

                        handleBlur(e);
                    }}
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    error={touched.todoList && touched.todoList[index] && errors.todoList && errors.todoList[index]}
                />
                <Box pl={2} pb={0} pt='2px' className='pointer'>
                    <DeleteIcon
                        color='primary'
                        onClick={() => {
                            if (item?.id) {
                                handleDeleteCheck(item?.id);
                            }
                            
                            remove(index);
                        }}
                        />
                </Box>
            </Box>

        );
    }

    return (
        <Draggable draggableId={item?.id.toString()} index={index} key={item.id}>
            {(provided) => (
                <Box
                    key={index}
                    display='flex'
                    alignItems='flex-start'
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Box pt='2px'>
                        <DragIndicatorIcon />
                    </Box>
                    <Box pr={2} pt='5px'>
                        <NoBorderCheckbox
                            checked={item?.isDone}
                            size='small'
                            onChange={(e) => {
                                handleValidateCheckbox(item?.id);
                                setFieldValue(`todoList.${index}.isDone`, e.target.checked)
                            }}
                        />
                    </Box>
                    <FormInput
                        name={`todoList.${index}.description`}
                        placeholder='Ajouter une tâche'
                        value={item?.description}
                        margin='none'
                        onChange={handleChange}
                        onBlur={(e) => {
                            handleUpdateCheck(item?.id, e.target.value);
                            handleBlur(e);
                        }}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        error={touched.todoList && touched.todoList[index] && errors.todoList && errors.todoList[index]}
                    />
                    <Box pl={2} pb={0} pt='2px' className='pointer'>
                        <DeleteIcon
                            color='primary'
                            onClick={() => {
                                if (item?.id) {
                                    handleDeleteCheck(item?.id);
                                }
                                
                                remove(index);
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Draggable>
    );
};