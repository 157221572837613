export const GET_CALENDARS = 'GET_CALENDARS';
export const GET_CALENDARS_SUCCESS = 'GET_CALENDARS_SUCCESS';
export const GET_CALENDARS_ERROR = 'GET_CALENDARS_ERROR';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const SET_SELECTED_CALENDAR = 'SET_SELECTED_CALENDAR';

export const CREATE_CALENDARS_SUCCESS = 'CREATE_CALENDARS_SUCCESS';
export const CREATE_CALENDARS_ERROR = 'CREATE_CALENDARS_ERROR';

export const UPDATE_EVENT_ERROR = 'UPDATE_CALENDARS_ERROR';


export const DELETE_CALENDAR_SUCCESS = 'DELETE_CALENDARS_SUCCESS';
export const DELETE_CALENDAR_ERROR = 'DELETE_CALENDARS_ERROR';

export const UPDATE_CALENDAR_SUCCESS = 'UPDATE_CALENDARS_SUCCESS';
export const UPDATE_CALENDAR_ERROR = 'UPDATE_CALENDARS_ERROR';

export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';

export const DELETE_EVENT_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENTS_ERROR';


export const CHANGE_PLANNING_FILTERS = 'CHANGE_PLANNING_FILTERS';

