import { Box, Chip, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { useState } from "react";
import FolderIcon from '@mui/icons-material/Folder';

export const CategoriesFilters = ({
    filters,
    categoriesList,
    changeFilters,
}) => {
    const type = filters.category ? categoriesList?.find(elem => elem.id === filters.category)?.name : null;

    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <Box mx={1} py={2}>
            <Box display='flex' alignItems='center' justifyContent='center' className='fullHeight'>
                <Chip
                    variant={type ? 'default' : 'outlined'}
                    icon={<FolderIcon/>}
                    size='medium'
                    label={type || "Catégories"}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    onDelete={type ? (() => changeFilters({...filters, category: ''})) : null}

                />
            </Box>

            <CmtPopover
                anchorEl={anchorEl}
                closePopover={() => setAnchorEl(null)}
            >
                <Box p={5} width={300}>
                    <Typography component='p' variant='subtitle1'> Selectionner une catégorie</Typography>
                    <FormControl fullWidth variant='standard'>
                        <InputLabel id="categoriesFilterType">Catégories</InputLabel>
                        <Select
                            labelId="categoriesFilterType"
                            value={filters?.category}
                            fullWidth
                            label={"Catégories"}
                            onChange={(e) => changeFilters({...filters, category: e.target.value})}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                        >
                            <MenuItem value={""} id={`categoryTypeItem-0`}>Aucune</MenuItem>
                            {categoriesList?.map((item, index) => (
                                <MenuItem value={item.id} key={`categoryType ${index}`} id={`announcementTypeItem-${index}`}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> 
                </Box>
            </CmtPopover>
        </Box>
    );
};