import { useTheme } from "@emotion/react";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtImage from "Components/CmtImage/CmtImage";
import { TESTIMONY_PATH } from "Constant";
import history from "services/History/history";
import { Round } from "../Partial/Round";
import { TestimonyElem } from "../Partial/TestimonyElem";
import { PublicButton, PublicTypo } from "../sc.Public";
import { RatingStar } from "./sc.Home";

export const TestimonyBlock = () => {
    const theme = useTheme();

    return (
        <Container maxWidth="lg">
            <Grid container py={22}>
                <Grid item xs={11} lg={6}>
                    <PublicTypo
                        family={theme.palette.publicTypo.hFamily}
                        fontSize={{
                            xs: theme.palette.publicTypo.h3Size,
                            md: theme.palette.publicTypo.h2Size,
                        }}
                        color="primary"
                    >
                        Impul'Scène pour tous !
                    </PublicTypo>

                    <PublicTypo
                        mt={{ xs: 2, md: 15 }}
                        mb={{ xs: 10, md: 0 }}
                        width="100%"
                        family={theme.palette.publicTypo.pFamily}
                        size={theme.palette.publicTypo.p2Size}
                        fontWeight="200"
                    >
                        « Endis simet doloreptat volupta namin eceri a sperum liat ea idelistru
                        voluptas tate magnist iore edis et mil loreptat volupt incidebit. »
                    </PublicTypo>

                    <Box
                        pt={50}
                        position="relative"
                        pb={{ xs: 10, lg: 0 }}
                        px={{ xs: 10, lg: 0 }}
                        display={{ xs: "none", md: "block" }}
                    >
                        <CmtImage src="/images/Public/Home/Fichier4.png" width="100%" />
                        <Round
                            size="20rem"
                            sx={{ translate: "-10% 50%" }}
                            position="absolute"
                            top={0}
                            right={0}
                        >
                            <PublicTypo
                                family={theme.palette.publicTypo.hFamily}
                                size={theme.palette.publicTypo.h2Size}
                                color="primary"
                                pb={5}
                            >
                                8,5/10
                            </PublicTypo>
                            <RatingStar name="read-only" value={4} readOnly size="3em" />
                            <PublicTypo
                                family={theme.palette.publicTypo.pFamily}
                                size={theme.palette.publicTypo.p2Size}
                                fontWeight="300"
                                color="primary"
                            >
                                (168 votes)
                            </PublicTypo>
                        </Round>
                    </Box>
                </Grid>

                <Grid item xs={1}></Grid>

                <Grid height="100%" item container xs={12} lg={5} spacing={8}>
                    <TestimonyElem
                        imgSrc={"/images/Public/Images/P1032127.jpg"}
                        rating={4}
                        text="« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »"
                    />

                    <TestimonyElem
                        imgSrc={"/images/Public/Images/P1032127.jpg"}
                        rating={4}
                        text="« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »"
                    />

                    <TestimonyElem
                        imgSrc={"/images/Public/Images/P1032127.jpg"}
                        rating={4}
                        text="« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »"
                    />

                    <TestimonyElem
                        imgSrc={"/images/Public/Images/P1032127.jpg"}
                        rating={4}
                        text="« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »"
                    />

                    <TestimonyElem
                        imgSrc={"/images/Public/Images/P1032127.jpg"}
                        rating={4}
                        text="« Cus re dent dis sit, odi aut quam ito limpuiti bea consequ issitae voloriatiur .Eceri asperum liat ea idelistruvoluptas pra cullo quiatem. »"
                    />

                    <Grid item xs={12} display="flex" justifyContent="center">
                        <PublicButton
                            variant="contained"
                            color="error"
                            onClick={() => history.push(TESTIMONY_PATH)}
                        >
                            En savoir plus
                        </PublicButton>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
