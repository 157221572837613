import { Box, Button, CardContent, Hidden } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CakeIcon from '@mui/icons-material/Cake';
import WorkIcon from '@mui/icons-material/Work';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useState } from "react";
import { TileTitle, IconView } from "Components/CmtProfileContainer/sc.ProfileContainer";

const monthName = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

export default function DisplayInfosCard ({profile, setEditionMode}) {
    const [hover, setHover] = useState(false);

    const birthday = new Date(profile?.profile?.birthday);

    return (
        <CmtCard onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} id='infosCardCmtCard'>
            <TileTitle>Identité</TileTitle>
            <CardContent>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                    <IconView>
                        <CakeIcon />
                    </IconView>
                    <Box ml={5}>
                        <Box component="span" fontSize={12} color="primary">Date de naissance</Box>
                        <Box fontSize={16}>
                            <Box>{birthday.getDate()} {monthName[birthday.getMonth()]} {birthday.getFullYear()}</Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }} >
                    <IconView>
                        <WorkIcon />
                    </IconView>
                    <Box ml={5}>
                        <Box component="span" fontSize={12} color="primary">Travail</Box>
                        <Box fontSize={16}>
                            <Box>{profile?.profile?.positionType ? profile.profile.positionType.name : "Non renseigné"}</Box>
                        </Box>
                    </Box>
                </Box>
                <Box width='100%' display='flex' justifyContent='flex-end' height={30}>
                    {hover &&
                        <Button onClick={() => {setEditionMode(true)}} variant='contained' color='secondary' id='infosCardUpdateButton'>
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp> <CreateOutlinedIcon className='pointer'/> </Hidden>
                        </Button>
                    }
                </Box>
            </CardContent>
        </CmtCard>
    );
};