import { connect } from "react-redux"
import { authSelector } from 'redux/selectors/authSelector';
import { loginAction } from 'redux/actions/authActions';
import history from 'services/History/history';
import { useEffect, useState } from 'react';
import LoginForm from './LoginForm.jsx'
import AuthWrapper from "../AuthWrapper/AuthWrapper.jsx";
import { deleteErrorAction } from "../../../redux/actions/authActions.js";
import CmtLoading from "Components/CmtLoading/CmtLoading.jsx";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { REDIRECTION_TIME } from "Constant.js";

export const LoginApp = ({auth, login, onLoadingPage, children}) => {
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (auth.error) {
            NotificationManager.error(auth?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);

            onLoadingPage();
        } else if (auth.connected) {
            history.push("/");
        }
    });

    const handleSubmit = (data) => {
        if (submit) {
            return;
        }

        setSubmit(true);

        login(data);

        setSubmit(false);
    };

    return (
        <AuthWrapper>
            {auth?.loading && <CmtLoading/>}
            <LoginForm onLogin={handleSubmit} />
            {children}
        </AuthWrapper>
    );
};


export const LoginAppStore = connect(
    (state) => ({
        auth: authSelector(state),
    }),
    (dispatch) => ({
        login: infos => dispatch(loginAction(infos)),
        onLoadingPage: () => dispatch(deleteErrorAction()),
    })
) (LoginApp);