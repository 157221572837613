import {
    REQUEST_LOGIN,
    REQUEST_LOGIN_ERROR,
    REQUEST_LOGIN_SUCCESS,
    REQUEST_SIGNUP,
    REQUEST_SIGNUP_SUCCESS,
    REQUEST_SIGNUP_ERROR,
    DISCONNECT,
    NOT_CONNECTED,
    REQUEST_CONNEXION,
    REQUEST_CONNEXION_SUCCESS,
    REQUEST_CONNEXION_ERROR,
} from "redux/constants/authConstants";
import auth from "services/Api/auth";
import { DELETE_ERROR } from "../constants/authConstants";


export const deleteErrorAction = () => ({
    type: DELETE_ERROR
});

export const disconnectAction = () => ({
    type: DISCONNECT,
});

export const notConnectedAction = () => ({
    type: NOT_CONNECTED,
});



/* --------------------------Get Connexion-------------------------- */

export const requestConnexion = () => ({
    type: REQUEST_CONNEXION,
    loading: true,
});

export const requestConnexionSuccess = () => ({
    type: REQUEST_CONNEXION_SUCCESS,
    loading: false,
});

export const requestConnexionError = () => ({
    type: REQUEST_CONNEXION_ERROR,
    loading: false,
});


export const getConnectionAction = () => {
    return async (dispatch) => {
        const result = await auth.checkIsAuth();

        if (result) {
            dispatch(requestConnexionSuccess());
        } else {
            dispatch(requestConnexionError());
        }

        return (result);
    };
};

/* --------------------------Login-------------------------- */

export const requestLogin = () => ({
    type: REQUEST_LOGIN,
    loading: true
});

export const requestLoginSuccess = () => ({
    type: REQUEST_LOGIN_SUCCESS,
    loading: false
});

export const requestLoginError = (data) => ({
    type: REQUEST_LOGIN_ERROR,
    loading: false,
    error: data
});

export const loginAction = (datas) => {
    return async (dispatch) => {
        dispatch(requestLogin());

        const result = await auth.onLogin(datas);

        if (result.result) {
            dispatch(requestLoginSuccess());
        } else {
            dispatch(requestLoginError(result.error));
        }
    };
};




/* --------------------------Sign Up-------------------------- */


export const requestSignUp = () => ({
    type: REQUEST_SIGNUP,
    loading: true
});

export const requestSignUpSuccess = () => ({
    type: REQUEST_SIGNUP_SUCCESS,
    loading: false
});

export const requestSignUpError = (result) => ({
    type: REQUEST_SIGNUP_ERROR,
    loading: false,
    error: result,
});

export const signUpAction = (datas) => {
    return async (dispatch) => {
        dispatch(requestSignUp());

        const result = await auth.onRegister(datas);

        if (result.result) {
            dispatch(requestSignUpSuccess());
        } else {
            dispatch(requestSignUpError(result.error));
        }

        return result;
    };
};