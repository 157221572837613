export const imageCropperStyle = theme => ({
    cropper: {
        maxHeight: '80vh',
        maxWidth: '80vw',
        borderRadius: 8,
        zIndex: 30,
    },

    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 100,
    },

    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        marginRight: '-50%',
        background: theme.palette.primary.main,
        overflow: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        borderRadius: 8,
        outline: 'none',
        paddingBottom: 32,
    },

    validateButton: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        padding: 0,
        '-webkit-transform': 'transform(-50%)',
        transform: 'translate(-50%)',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        width: '100%',
        textAlign: 'center',
        color: 'rgba(255, 255, 255, 1)',
    },
    
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: 'rgba(255, 255, 255, 1)',
        '& .MuiSvgIcon-root': {
            fontSize: '2rem',
        }

    }

})