import { Box, FormHelperText, Grid } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import { Formik } from "formik";
import { MAPBOX_TOKEN, MAPBOX_STYLE, ZOOM_MIN } from "Constant";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapBoxGl, { ZoomControl } from "react-mapbox-gl";
import { useRef } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MapContext, MapControl } from "./MapControl";
import "./mapbox-gl-geocoder.css";
import { CmtMarker } from "Components/CmtMapBoxElements/CmtMarker/CmtMarker";
import { StepperLine } from "../StepperLine";

const MapBox = ReactMapBoxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: true,
    minZoom: ZOOM_MIN,
});

const mapStyle = {
    minHeight: "400px",
    height: "100%",
    width: "100%",
};

export const Step5 = ({ stepper, cancel, submit, data }) => {
    const mapRef = useRef(null);

    const checkError = (values) => {
        const errors = {};

        if (!values.fullAddress) {
            errors.fullAddress = "Veuillez selectionner une adresse";
        }

        return errors;
    };

    return (
        <Grid item container xs={12} sm={5}>
            <Formik
                initialValues={{
                    fullAddress: data.fullAddress || "",
                    longitude: data.longitude || "",
                    latitude: data.latitude || "",
                }}
                validate={(values) => checkError(values)}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({ errors, values, touched, handleSubmit, setFieldValue }) => (
                    <CmtForm onSubmit={handleSubmit} className="fullWidth">
                        <CmtCard className="fullWidth">
                            <Box p={5}>
                                <MapBox
                                    // eslint-disable-next-line
                                    style={MAPBOX_STYLE}
                                    containerStyle={mapStyle}
                                    ref={mapRef}
                                    center={[2.209667, 46.8]}
                                    zoom={[ZOOM_MIN]}
                                    flyToOptions={{ speed: 0.8 }}
                                >
                                    <MapContext.Provider value={{ mapRef }}>
                                        <MapControl
                                            control={
                                                new MapboxGeocoder({
                                                    accessToken: MAPBOX_TOKEN,
                                                    countries: "fr",
                                                    marker: false,
                                                })
                                            }
                                            onSelect={(e) => {
                                                setFieldValue(
                                                    "fullAddress",
                                                    e.result?.place_name_fr
                                                );
                                                setFieldValue(
                                                    "longitude",
                                                    e.result?.geometry?.coordinates[0]
                                                );
                                                setFieldValue(
                                                    "latitude",
                                                    e.result?.geometry?.coordinates[1]
                                                );
                                            }}
                                            initValue={values.fullAddress}
                                        />
                                    </MapContext.Provider>
                                    {values?.longitude && values?.latitude && (
                                        <CmtMarker
                                            coordinates={[values.longitude, values.latitude]}
                                        />
                                    )}
                                    <ZoomControl />
                                </MapBox>
                                <FormHelperText error>
                                    {touched.fullAddress && errors.fullAddress}
                                </FormHelperText>
                            </Box>
                        </CmtCard>

                        <Box mt={4}>
                            <StepperLine
                                onCancel={cancel}
                                stepper={stepper}
                                step="Etape 5 : La localisation de l'annonce"
                            />
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        </Grid>
    );
};
