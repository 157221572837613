import { Box, Checkbox, Skeleton, TableCell } from "@mui/material";
import CmtImage from "Components/CmtImage/CmtImage";
import { DOCUMENTS_PATH } from "Constant";
import history from "services/History/history";
import formatFileSize from "../../utils/formatFileSize";
import getDocumentsIconsType from "../../utils/getDocumentsIconsType";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useState } from "react";
import { EditFolder } from "../Folder/EditFolder";
import { EditFile } from "../File/EditFile";
import {
    RowTableContainer,
    TranslatedIcon,
    HiddenIcon,
    SkeletonTableContainer,
} from "Components/CmtTableElements/sc.TableElements";
import { EnterFolderIcon } from "../sc.DisplayDocuments";

export const DocumentSkeletonLine = () => {
    return (
        <SkeletonTableContainer>
            <TableCell>
                <Box display="flex" justifyContent="center">
                    <Skeleton variant="rectangular" width={20} height={20} />
                </Box>
            </TableCell>

            <TableCell>
                <Skeleton variant="rectangular" width={30} height={40} />
            </TableCell>

            <TableCell>
                <Skeleton variant="text" width="80%" />
            </TableCell>

            <TableCell>
                <Skeleton variant="text" width="50%" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" width="60%" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" width="20%" />
            </TableCell>
            <TableCell>
                <Box display="flex" justifyContent="center" mr={3}>
                    <Skeleton variant="circular" width={40} height={40} />
                </Box>
            </TableCell>
        </SkeletonTableContainer>
    );
};

export const DocumentTableLine = ({
    item,
    checkedList,
    handleCheckClick,
    clickOnDetail,
    deleteFileFolder,
    updateFileFolder,
}) => {
    const [openEdit, setOpenEdit] = useState(null);

    let timer = 0;
    let delay = 200;
    let prevent = false;

    const onSingleClickHandler = () => {
        timer = setTimeout(() => {
            if (!prevent) {
                clickOnDetail(item?.id);
            }
        }, delay);
    };

    const onDoubleClickHandler = () => {
        clearTimeout(timer);
        prevent = true;

        if (item?.type === "Dossier") {
            history.push(`${DOCUMENTS_PATH}/${item?.id}`);
        } else {
            window.open(item.documentFileName, "_blank");
        }

        setTimeout(() => {
            prevent = false;
        }, delay);
    };

    return (
        <RowTableContainer onClick={onSingleClickHandler} onDoubleClick={onDoubleClickHandler}>
            <TableCell>
                {!item?.project && (
                    <Checkbox
                        checked={checkedList.includes(item?.id)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            handleCheckClick(e.target.checked, item?.id);
                        }}
                    />
                )}
            </TableCell>

            <TableCell>
                <CmtImage
                    src={`/images/icons/${getDocumentsIconsType(
                        item?.project ? "ProjectFolder" : item?.type
                    )}`}
                    height={40}
                />
            </TableCell>

            <TableCell>{item?.name}</TableCell>

            <TableCell>{item?.type}</TableCell>

            <TableCell>
                {item?.createdBy.firstName} {item?.createdBy.lastName}
            </TableCell>

            <TableCell>{item?.documentSize ? formatFileSize(item?.documentSize) : "--"}</TableCell>

            <TableCell>
                <Box display="flex" onClick={(e) => e.stopPropagation()} overflow="hidden">
                    <TranslatedIcon
                        color="primary"
                        onClick={onDoubleClickHandler}
                        className={`${!item?.project && "action-option "}icons`}
                    >
                        {item?.isFolder ? <EnterFolderIcon /> : <GetAppIcon />}
                    </TranslatedIcon>

                    {!item?.project && (
                        <>
                            <HiddenIcon
                                color="primary"
                                className="action-option icons"
                                onClick={(e) => setOpenEdit(e.currentTarget)}
                            >
                                <EditIcon />
                            </HiddenIcon>

                            <HiddenIcon
                                color="primary"
                                onClick={() => deleteFileFolder([item?.id])}
                                className="action-option icons"
                            >
                                <DeleteIcon />
                            </HiddenIcon>
                        </>
                    )}

                    {Boolean(openEdit) && item?.isFolder && (
                        <EditFolder
                            anchorEl={openEdit}
                            closePopover={() => setOpenEdit(null)}
                            folderData={item}
                            updateFolder={updateFileFolder}
                        />
                    )}

                    {Boolean(openEdit) && !item?.isFolder && (
                        <EditFile
                            anchorEl={openEdit}
                            closePopover={() => setOpenEdit(null)}
                            fileData={item}
                            updateFile={updateFileFolder}
                        />
                    )}
                </Box>
            </TableCell>
        </RowTableContainer>
    );
};
