import axios from './config';

const documents = {
    getDocumentsList: async (filters, folderId = '') => {
        try {
            let params = {};

            if (filters?.category) {
                params = {...params, 'filters[category]': filters.category};
            }

            if (filters?.sorter) {
                const sorter = filters?.sorter.split(' ');

                params = {...params, 'filters[sortField]': sorter[0]};
                params = {...params, 'filters[sortOrder]': sorter[1]};
            }

            params = {...params, 'filters[limit]': 30};


            const result = await axios.get(`/user/documents${folderId}`, {
                params : params,
            });

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    createFileFolder: async (data) => {
        try {
            let formData = new FormData();

            formData.append('name', data?.name);
            formData.append('isFolder', data?.isFolder ? 1 : 0);

            if ('documentFile' in data) {
                formData.append('documentFile[file]', data?.documentFile);
            }

            const result = await axios.post(`/user/documents/create${data?.folderId}`, formData);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    getFileFolderDetail: async (id) => {
        try {
            const result = await axios.get(`/user/documents/${id}/detail`);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    deleteFileFolder: async (id) => {
        try {
            await axios.delete(`/user/documents/${id}`);

            return {result: true};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    moveFileFolder: async (fileFolderId, newPath) => {
        try {
            await axios.post(`/user/documents/${fileFolderId}/move${newPath}`);

            return {result: true};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    getMimesTypes: async () => {
        try {
            const result = await axios.get(`/user/mimes/categories`);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    updateFileFolder: async (data) => {
        try {
            let formData = new FormData();

            formData.append('name', data?.name);
            formData.append('isFolder', data?.isFolder ? 1 : 0);

            if ('documentFile' in data) {
                formData.append('documentFile[file]', data?.documentFile);
            }

            const result = await axios.post(`/user/documents/${data?.folderId}/update`, formData);

            return {result: true, data: result.data};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    }
};

export default documents;