import LabelIcon from '@mui/icons-material/Label';
import { NoteLabelChip } from "./sc.Notes";

export const LabelChip = ({
    type,
    notesTypes,
    variant = 'outlined',
    ...rest
}) => {
    return (
        <NoteLabelChip
            variant={variant}
            typeColor={type?.color}
            icon={<LabelIcon/>}
            size="small"
            label={notesTypes?.find(elem => elem.id === type?.id)?.name}
            {...rest}
        />
    );
};