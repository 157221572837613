import { Typography } from "@mui/material";
import {
    ANNOUNCEMENTS_PATH,
    DASHBOARD_PATH,
    DOCUMENTS_PATH,
    GOOD_PLAN_PATH,
    MY_PROJECT_PATH,
    NETWORK_PATH,
    NOTE_PATH,
    PLANNING_PATH,
    PROFILE_PATH,
    PROJECT_PATH,
} from "Constant";
import CmtMenuItem from "../components/CmtMenuItem/CmtMenuItem";
import history from "services/History/history";
import ExploreIcon from "@mui/icons-material/Explore";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FolderIcon from "@mui/icons-material/Folder";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TodayIcon from "@mui/icons-material/Today";
import FeedbackIcon from "@mui/icons-material/Feedback";
import DescriptionIcon from "@mui/icons-material/Description";
import NotesIcon from "@mui/icons-material/Notes";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import { MenuTitle, SideBarMenuContainer, FeedBackContainer } from "./sc.SideBarMenu";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import { useEffect, useState } from "react";
import { FeedbackStore } from "Apps/Feedback/Feedback";

export default function SideBarMenu({ profile, setCollapse }) {
    const [path, setPath] = useState(window.location.pathname);
    const [openFeedBack, setOpenFeedBack] = useState(false);

    const handlePath = (destination) => {
        setCollapse(true);

        history.push(destination);
    };

    useEffect(() => {
        const listen = history.listen(() => {
            setPath(window.location.pathname);
        });

        return () => {
            listen();
        };
    }, []);

    return (
        <>
            <SideBarMenuContainer>
                <CmtMenuItem destination={PROFILE_PATH} path={path} setPath={handlePath}>
                    <AvatarComponent
                        src={profile?.profile?.profileFileName || profile?.defaultAvatarPath}
                        size={25}
                    />
                    <Typography>
                        {profile?.profile?.firstName} {profile?.profile?.lastName}
                    </Typography>
                </CmtMenuItem>
                <CmtMenuItem destination={DASHBOARD_PATH} path={path} setPath={handlePath}>
                    <DashboardIcon /> <Typography> Tableau de bord</Typography>
                </CmtMenuItem>

                <MenuTitle component="p" variant="subtitle2" color="textSecondary">
                    LE RESEAU
                </MenuTitle>
                <CmtMenuItem destination={GOOD_PLAN_PATH} path={path} setPath={handlePath}>
                    <LocalActivityOutlinedIcon />
                    <Typography> Bons Plans</Typography>
                </CmtMenuItem>
                <CmtMenuItem destination={NETWORK_PATH} path={path} setPath={handlePath}>
                    <ExploreIcon />
                    <Typography> Annuaire</Typography>
                </CmtMenuItem>
                <CmtMenuItem destination={ANNOUNCEMENTS_PATH} path={path} setPath={handlePath}>
                    <AssignmentIcon />
                    <Typography> Annonces </Typography>
                </CmtMenuItem>
                <CmtMenuItem destination={PROJECT_PATH} path={path} setPath={handlePath}>
                    <FolderIcon />
                    <Typography>Projets</Typography>
                </CmtMenuItem>

                <MenuTitle component="p" variant="subtitle2" color="textSecondary">
                    PROJETS
                </MenuTitle>
                <CmtMenuItem destination={MY_PROJECT_PATH} path={path} setPath={handlePath}>
                    <FolderIcon />
                    <Typography> Mes Projets </Typography>
                </CmtMenuItem>
                <CmtMenuItem destination={PLANNING_PATH} path={path} setPath={handlePath}>
                    <TodayIcon />
                    <Typography> Planning </Typography>
                </CmtMenuItem>
                <CmtMenuItem destination={DOCUMENTS_PATH} path={path} setPath={handlePath}>
                    <DescriptionIcon />
                    <Typography> Documents </Typography>
                </CmtMenuItem>
                <CmtMenuItem destination={NOTE_PATH} path={path} setPath={handlePath}>
                    <NotesIcon />
                    <Typography> Notes </Typography>
                </CmtMenuItem>
            </SideBarMenuContainer>
            <FeedBackContainer onClick={() => setOpenFeedBack(true)}>
                <FeedbackIcon fontSize="small" />
                <Typography component="p" ml={4}>
                    Feedback
                </Typography>
            </FeedBackContainer>

            <FeedbackStore open={openFeedBack} onClose={() => setOpenFeedBack(false)} />
        </>
    );
}
