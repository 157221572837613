import { Box, CardContent } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { MAPBOX_TOKEN, MAPBOX_STYLE, ZOOM_MIN } from "Constant";
import ReactMapBoxGl, { ZoomControl } from "react-mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useRef } from "react";
import { MapContext, MapControl } from "./MapControl";
import { CmtMarker } from "Components/CmtMapBoxElements/CmtMarker/CmtMarker";
import "./mapbox-gl-geocoder.css";

const MapBox = ReactMapBoxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: true,
    minZoom: ZOOM_MIN,
});

const mapStyle = {
    minHeight: "400px",
    height: "100%",
    width: "100%",
};

export const CreateProjectLocation = ({ setFieldValue, values }) => {
    const mapRef = useRef(null);

    return (
        <Box p={2}>
            <CmtCard>
                <TileTitle> Localisation </TileTitle>
                <CardContent>
                    <MapBox
                        // eslint-disable-next-line
                        style={MAPBOX_STYLE}
                        containerStyle={mapStyle}
                        ref={mapRef}
                        center={[2.209667, 46.8]}
                        zoom={[ZOOM_MIN]}
                        flyToOptions={{ speed: 0.8 }}
                    >
                        <MapContext.Provider value={{ mapRef }}>
                            <MapControl
                                control={
                                    new MapboxGeocoder({
                                        accessToken: MAPBOX_TOKEN,
                                        countries: "fr",
                                        marker: false,
                                    })
                                }
                                onSelect={(value) => {
                                    setFieldValue("fullAddress", value.result?.place_name_fr);
                                    setFieldValue("lng", value.result?.geometry?.coordinates[0]);
                                    setFieldValue("lat", value.result?.geometry?.coordinates[1]);
                                }}
                                initValue={values.fullAddress}
                            />
                        </MapContext.Provider>
                        {values?.lng && values?.lat && (
                            <CmtMarker
                                style={{ zIndex: 1 }}
                                coordinates={[values.lng, values.lat]}
                            />
                        )}
                        <ZoomControl />
                    </MapBox>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
