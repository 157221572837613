import {
    CHANGE_NETWORK_FILTER,
    REQUEST_ALL_COMPANY,
    REQUEST_ALL_COMPANY_ERROR,
    REQUEST_ALL_COMPANY_SUCCESS,
    REQUEST_PAGED_COMPANY_ERROR,
    REQUEST_PAGED_COMPANY_SUCCESS
} from "redux/constants/companyConstants";
import auth from "services/Api/auth";
import companies from "services/Api/companies";
import entities from "services/Api/entities";
import { notConnectedAction } from "./authActions";
import { getProfileAction } from "./profileActions";

export const requestAllCompany = () => ({
    type: REQUEST_ALL_COMPANY,
    loading: true,
});

const requestAllCompaniesSuccess = (data) => ({
    type: REQUEST_ALL_COMPANY_SUCCESS,
    loading: false,
    data: data,
});

const requestAllCompaniesError = (error) => ({
    type: REQUEST_ALL_COMPANY_ERROR,
    loading: false,
    error: error,
});

export const getAllCompaniesAction = (filters = null) => {
    return async (dispatch, getState) => {
        dispatch(requestAllCompany());

        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const state = filters || getState().company.filters;
        
        entities.getAllEntities({...state, page: 0})
        .then(result => {
            if (!result.result) {
                dispatch(requestAllCompaniesError(result.err));
            } else {
                dispatch(requestAllCompaniesSuccess(result.data));
            }
        })
    };
};


/* ----------------------- Filters -------------------- */

export const updateNetworkFiltersAction = (filters) => ({
    type: CHANGE_NETWORK_FILTER,
    filters: filters,
});

export const changeNetworkFiltersAction = (filters) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        sessionStorage.setItem('NetworkFiltersCategory', filters.category);
        sessionStorage.setItem('NetworkFiltersDistance', filters.distance);
        sessionStorage.setItem('NetworkFiltersSorter', filters.sorter);
        sessionStorage.setItem('NetworkFiltersLocation', filters.location);
        sessionStorage.setItem('NetworkFiltersLng', filters.lng);
        sessionStorage.setItem('NetworkFiltersLat', filters.lat);
        sessionStorage.setItem('NetworkFiltersSize', filters.size);

        filters.page = 1;

        dispatch(updateNetworkFiltersAction(filters));

        dispatch(getAllCompaniesAction(filters));
        dispatch(getPagedEntitiesAction(filters));
    };
};


export const createCompagnyAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await companies.createCompagnie(data);

        if (result.result) {
            dispatch(getProfileAction);
        }
    }
};

const requestPagedEntitiesSuccess = (data) => ({
    type: REQUEST_PAGED_COMPANY_SUCCESS,
    loading: false,
    data: data,
});

const requestPagedEntitiesError = (error) => ({
    type: REQUEST_PAGED_COMPANY_ERROR,
    loading: false,
    error: error,
});

export const getPagedEntitiesAction = (filters = null) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();
        
        if (!check) {
            dispatch(notConnectedAction());
            
            return;
        }

        const state = filters || getState().company.networkFilters;

        entities.getAllEntities(state)
        .then(result => {
            if (result.result) {
                dispatch(requestPagedEntitiesSuccess(result.data));
            } else {
                dispatch(requestPagedEntitiesError(result.error));
            }
        });
    };
};



export const loadMoreEntitiesAction = (filters) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();
        
        if (!check) {
            dispatch(notConnectedAction());
            
            return;
        }

        dispatch(updateNetworkFiltersAction(filters));

        dispatch(getPagedEntitiesAction(filters));

    };
};

