import axios from './config'

const auth = {
    onRegister: async (data) => {
        try {
            let formData = new FormData();

            formData.append('email', data.email);
            formData.append('plainPassword', data.password);
            formData.append('firstName', data.firstName);
            formData.append('birthday', data.birthday);

            await axios.post('/register', formData);

            return {result: true};
        } catch (error) {
            return {result: false, error: error?.response?.data};
        };
    },

    onLogin: async (data) => {
        try {
            const result = await axios.post('/login_check', {
                username: data.email,
                password: data.password,
            });

            localStorage.setItem('token', result.data.token);
            localStorage.setItem('refresh_token', result.data.refresh_token);
            localStorage.setItem('time_token', Date.now());

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;

            return {result: true};
        } catch (error) {
            return {result: false, error: error?.response?.data};
        };
    },

    onForgetPassword: async (data) => {
        try {
            let formData = new FormData();

            formData.append('login', data.email);

            await axios.post('/forgot-password', {
                login: data.email,
            });

            return {result: true};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    onLogout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        axios.defaults.headers.common['Authorization'] = '';
    },


    refreshConnexionToken: async (refreshToken = null) => {
        const refresh_token = refreshToken || localStorage.getItem('refresh_token');

        if (!refresh_token) {
            return false;
        }

        try {
            const formData = new FormData();

            formData.append('refresh_token', refresh_token);

            const result = await axios.post('/token_refresh', formData);

            localStorage.setItem('token', result.data.token);
            localStorage.setItem('refresh_token', result.data.refresh_token);
            localStorage.setItem('time_token', Date.now());

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token;

            return true;
        } catch {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('time_token');
            
            axios.defaults.headers.common['Authorization'] = '';

            return false;
        };
    },

    validEmail: async (email, token) => {
        try {
            await axios.post(`/valid-email?email=${email}&token=${token}`);
            
            return {result: true};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    resetPassword: async (data) => {
        try {
            let formData = new FormData();

            formData.append('login', data.email);
            formData.append('token', data.token);
            formData.append('newPassword', data.newPassword);

            await axios.post('/reset-password', {
                login: data.email,
                token: data.token,
                newPassword: data.newPassword,
            });

            return {result: true};
        } catch (err) {
            return {result: false, error: err?.response?.data};
        };
    },

    checkIsAuth: async () => {
        const timeToken = localStorage.getItem('time_token');
        const token = localStorage.getItem('token');
        const refresh_token = localStorage.getItem('refresh_token');
        
        if (!refresh_token) {
            localStorage.removeItem('token');
            localStorage.removeItem('time_token');
            
            return false;
        }
        
        const limitDate = new Date(parseInt(timeToken) + (1*60*60*1000));

        if (!token || Date.now() > limitDate) {
            return await auth.refreshConnexionToken(refresh_token);
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        return true;
    },
};

export default auth;