import moment from "moment";
import {
    CHANGE_PLANNING_FILTERS,
    CREATE_CALENDARS_ERROR,
    CREATE_CALENDARS_SUCCESS,
    CREATE_EVENT_ERROR,
    CREATE_EVENT_SUCCESS,
    DELETE_CALENDAR_SUCCESS,
    DELETE_EVENT_ERROR,
    DELETE_EVENT_SUCCESS,
    GET_CALENDARS,
    GET_CALENDARS_ERROR,
    GET_CALENDARS_SUCCESS,
    GET_EVENTS,
    GET_EVENTS_ERROR,
    GET_EVENTS_SUCCESS,
    SET_SELECTED_CALENDAR,
    UPDATE_CALENDAR_ERROR,
    UPDATE_CALENDAR_SUCCESS,
    UPDATE_EVENT_ERROR
} from "redux/constants/planningConstants";

const getSelected = () => {
    let list = sessionStorage.getItem('PlanningSelectedCalendars')?.split(',');

    if (!list) {
        return [];
    }

    let result = [];

    list.forEach(element => result?.push(parseInt(element)));
    
    return result;
};

const INIT_STATE = {
    calendarList: null,
    eventList: null,
    filters: {
        mode: 'month',
        firstDay: moment().startOf('month').startOf('week').format('YYYY-MM-DD'),
        lastDay: moment().endOf('month').endOf('week').format('YYYY-MM-DD')
    },
    selectedCalendars: getSelected(),
    loading: false,
    eventsLoading: false,
    error: null,
};

const addCalendar = (state, action) => {
    let calendars = state.calendarList.owner;

    if (action.data.isDefault) {
        calendars.forEach(element => {
            element.isDefault = false;
        });
    }

    calendars = [...calendars, action.data];

    return ({
        ...state,
        loading: action.loading,
        calendarList: {
            ...state.calendarList,
            owner: calendars,
        }
    });
};

const deleteCalendar = (state, action) => {
    let owner = state?.calendarList?.owner?.filter(item => item.id !== action.id);

    return owner;
};

const updateCalendar = (state, action) => {
    let owner = state?.calendarList?.owner;

    if (action.data.isDefault) {
        for (let i = 0; i < owner.length; i++) {
            if (owner[i].id === action.data.id) {
                owner[i] = action.data;
            } else {
                owner[i].isDefault = false;
            }
        }
    } else {
        let index = owner.findIndex(item => item.id === action.data.id);

        if (index === -1) {
            return owner;
        }

        owner[index] = action.data;
    }

    return owner;
};

export default function planningReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_CALENDARS:
            return {...state, loading: action.loading};
        case GET_CALENDARS_SUCCESS:
            return {...state, loading: action.loading, calendarList: action.data, error: null};
        case GET_CALENDARS_ERROR:
            return {...state, loading: action.loading, error: action.error};
        case GET_EVENTS:
            return {...state, eventsLoading: action.loading};
        case GET_EVENTS_SUCCESS:
            return {...state, eventsLoading: action.loading, eventList: action.data, error: null};
        case GET_EVENTS_ERROR:
            return {...state, eventsLoading: action.loading, error: action.error};
        case SET_SELECTED_CALENDAR:
            return {...state, selectedCalendars: action.data};
        case CREATE_CALENDARS_SUCCESS:
            return addCalendar(state, action);
        case CREATE_CALENDARS_ERROR:
            return {...state, loading: action.loading, error: action.error};
        case CREATE_EVENT_SUCCESS:
            return {...state, loading: action.loading, eventList: [...state.eventList, action.data]};
        case CREATE_EVENT_ERROR:
            return {...state, loading: action.loading, error: action.error};
        case UPDATE_EVENT_ERROR:
            return {...state, loading: action.loading, error: action.error};
        case DELETE_CALENDAR_SUCCESS:
            return {...state, loading: action.loading, calendarList: {...state.calendarList, owner: deleteCalendar(state, action)}};
        case UPDATE_CALENDAR_SUCCESS:
            return {...state, loading: action.loading, calendarList: {...state.calendarList, owner: updateCalendar(state, action)}};
        case UPDATE_CALENDAR_ERROR:
            return {...state, loading: action.loading, error: action.error};
        case CHANGE_PLANNING_FILTERS:
            return {...state, filters: action.filters};
        case DELETE_EVENT_SUCCESS:
            return {...state, loading: action.loading, eventList: state.eventList?.filter(elem => elem.id !== action?.id)};
        case DELETE_EVENT_ERROR:
            return {...state, loading: action.loading, error: action.error};
        default:
            return state;
    }
}