import { useEffect, useState } from "react";
import { announcements } from "services/Api/announcements";
import auth from "services/Api/auth";
import { AnnouncementDetailHeader } from "./AnnouncementDetailHeader";
import { useDispatch } from "react-redux";
import { notConnectedAction } from "redux/actions/authActions";
import { AnnouncementContent } from "./AnnouncementContent/AnnouncementContent";
import { favoriteAnnouncementAction } from "redux/actions/announcementsActions";
import { NotificationManager } from "react-notifications";
import { REDIRECTION_TIME } from "Constant";
import { PerfectScroll } from "Components/PerfectScroll/sc.PerfectScroll";
import { AnnouncementContact } from "./AnnouncementContent/AnnouncementContact";
import { Box } from "@mui/system";
import { DisplayAnnouncementContact } from "./AnnouncementContent/AnnouncementContact";
import { Hidden } from "@mui/material";

export const AnnouncementDetail = ({
    profileId,
    id,
    deleteAnnouncement,
    basicPath,
}) => {
    const dispatch = useDispatch();

    const [announcementData, setAnnouncementData] = useState(null);
    const [comments, setComments] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                dispatch(notConnectedAction());
                
                return;
            }

            const result = await announcements.getOneAnnouncements(id);

            if (!result.result) {
                return;
            }

            setAnnouncementData(result.data);

            const commentsResult = await announcements.getComments(id);

            if (!commentsResult.result) {
                return;
            }

            setComments(commentsResult?.data);
        };

        fetchData();
    }, [dispatch, id]);

    const handleClickFavorite = () => {
        dispatch(favoriteAnnouncementAction(announcementData.id));

        setAnnouncementData({...announcementData, isFavorite: !announcementData.isFavorite});
    };

    const handleAddComment = async (message) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());
        }

        const result = await announcements.createComment({id: announcementData.id, content: message});

        if (!result.result) {
            NotificationManager.error(result?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);
        } else {
            setComments([...comments, result.data]);
        }

        return result;
    };

    const handleDeleteComment = async (commentId) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());
        }

        const result = await announcements.deleteComment({idAnnouncement: announcementData.id, idComment: commentId});

        if (!result.result) {
            NotificationManager.error(result?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);
        } else {
            let newComments = comments;

            newComments.splice(newComments.findIndex(item => {
                return (item.id === commentId);
            }), 1);

            setComments([...newComments]);
        }

        return result;
    };


    const handleUpdateComment = async (commentId, message) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());
        }

        const data = {
            idAnnouncement: announcementData.id,
            idComment: commentId,
            content: message,
        };

        const result = await announcements.updateComment(data);

        if (!result.result) {
            NotificationManager.error(result?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);
        } else {
            let newComments = comments;

            const commentIndex = newComments.findIndex(item => {
                return (item.id === commentId);
            });

            newComments[commentIndex] = result.data;

            setComments([...newComments]);
        }

        return (result);
    };

    if (!announcementData) {
        return (<></>);
    }

    return (
        <Box display='flex' overflow='hidden'>
            <PerfectScroll>
                <Box
                    display='flex'
                    flexDirection='column'
                    flexWrap='nowrap'
                    position='relative'
                    height='100%'
                    flexGrow={1}
                >
                    <AnnouncementDetailHeader
                        title={announcementData.title}
                        isMyAnnouncement={profileId === announcementData?.createdBy?.id}
                        id={announcementData.id}
                        basicPath={basicPath}
                        deleteAnnouncement={deleteAnnouncement}
                    />

                    <Hidden smUp>
                        <DisplayAnnouncementContact announcementData={announcementData} isMyAnnouncement={profileId === announcementData?.createdBy?.id}/>
                    </Hidden>

                    <AnnouncementContent
                        announcementData={announcementData}
                        changeFavorite={handleClickFavorite}
                        comments={comments}
                        addComment={handleAddComment}
                        deleteComment={handleDeleteComment}
                        updateComment={handleUpdateComment}
                        profileId={profileId}
                        isMyAnnouncement={profileId === announcementData?.createdBy?.id}
                    />
                </Box>
            </PerfectScroll>
            <Hidden smDown>
                <AnnouncementContact announcementData={announcementData} isMyAnnouncement={profileId === announcementData?.createdBy?.id}/>
            </Hidden>
        </Box>
    );
};