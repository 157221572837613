import { RestrictedRoute } from "Components/ResctrictedRoute/RestrictedRoute";
import { UnauthorizedPage } from "Components/UnauthorizedPage/UnauthorizedPage";
import { CREATE_PATH, EDIT_PATH, PLANNING_PATH, UNOTHAURIZED_PATH } from "Constant";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { notConnectedAction } from "redux/actions/authActions";
import {
    changePlanningFiltersAction,
    createCalendarAction,
    createEventAction,
    deleteCalendarAction,
    deleteEventAction,
    getCalendarsAction,
    getEventsAction,
    setSelectedCalendarAction,
    updateCalendarAction,
    updateEventAction,
} from "redux/actions/planningActions";
import { getProjectListAction } from "redux/actions/projectActions";
import { allPlanningSelector, planningSelector } from "redux/selectors/planningSelector";
import { profileSelector } from "redux/selectors/profileSelector";
import { projectSelector } from "redux/selectors/projectSelector";
import auth from "services/Api/auth";
import profileUser from "services/Api/profileUser";
import { CreateEvent } from "./CreateEvent/CreateEvent";
import { DisplayPlanning } from "./DisplayPlanning/DisplayPlanning";
import { UpdateEvent } from "./UpdateEvent/UpdateEvent";

export const PlanningApp = ({
    planning,
    getCalendars,
    profileData,
    getEvents,
    changeFilters,
    setSelectedCalendar,
    createCalendar,
    createEvent,
    deleteCalendar,
    updateCalendar,
    disconnect,
    deleteEvent,
    project,
    getProject,
    updateEvent,
}) => {
    const [colorList, setColorList] = useState(null);

    const allEventList = useSelector(allPlanningSelector).eventList;

    const getColors = useCallback(async () => {
        if (colorList) {
            return;
        }

        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();

            return;
        }

        profileUser.getColors().then((result) => {
            if (result.result) {
                setColorList(result?.data);
            }
        });
    }, [colorList, setColorList, disconnect]);

    useEffect(() => {
        if (!planning.calendarList && !planning.loading && !planning.error) {
            getCalendars();
        }
    }, [planning?.calendarList, planning?.loading, getCalendars, planning.error]);

    useEffect(() => {
        if (!planning.eventList && !planning?.eventsLoading && !planning.error) {
            getEvents(planning.filters);
        }
    }, [
        planning?.eventList,
        planning?.eventsLoading,
        planning?.error,
        planning?.filters,
        getEvents,
    ]);

    useEffect(() => {
        if (project.error) {
            return;
        }

        if (!project?.projectList && !project.loading) {
            getProject();
        }
    }, [project, getProject]);

    useEffect(() => {
        getColors();
    }, [getColors]);

    return (
        <Switch>
            <Route path={`${PLANNING_PATH}${UNOTHAURIZED_PATH}`}>
                <UnauthorizedPage />
            </Route>

            <Route exact path={PLANNING_PATH}>
                <RestrictedRoute profile={profileData} baseUrl={PLANNING_PATH}>
                    <DisplayPlanning
                        planningData={planning}
                        profileData={profileData}
                        setSelectedCalendar={setSelectedCalendar}
                        handleCreateCalendar={createCalendar}
                        getEvents={getEvents}
                        changeFilters={changeFilters}
                        deleteCalendar={deleteCalendar}
                        updateCalendar={updateCalendar}
                        colorList={colorList}
                        deleteEvent={deleteEvent}
                    />
                </RestrictedRoute>
            </Route>

            <Route exact path={`${PLANNING_PATH}/evenement${CREATE_PATH}`}>
                <RestrictedRoute profile={profileData} baseUrl={PLANNING_PATH}>
                    <CreateEvent
                        planningData={planning}
                        projectList={project.projectList?.current}
                        handleCreateEvent={createEvent}
                    />
                </RestrictedRoute>
            </Route>

            <Route
                exact
                path={`${PLANNING_PATH}/evenement/:id${EDIT_PATH}`}
                children={({ match }) => (
                    <RestrictedRoute profile={profileData} baseUrl={PLANNING_PATH}>
                        <UpdateEvent
                            item={allEventList?.find(
                                (elem) => elem?.id === parseInt(match?.params?.id)
                            )}
                            planningData={{ ...planning, eventList: allEventList }}
                            projectList={project.projectList?.current}
                            handleUpdateEvent={updateEvent}
                        />
                    </RestrictedRoute>
                )}
            />
        </Switch>
    );
};

export const PlanningAppStore = connect(
    (state) => ({
        planning: planningSelector(state),
        project: projectSelector(state),
        profileData: profileSelector(state),
    }),
    (dispatch) => ({
        disconnect: () => dispatch(notConnectedAction()),
        getCalendars: () => dispatch(getCalendarsAction()),
        getEvents: (filters) => dispatch(getEventsAction(filters)),
        setSelectedCalendar: (data) => dispatch(setSelectedCalendarAction(data)),
        createCalendar: (data) => dispatch(createCalendarAction(data)),
        createEvent: (data) => dispatch(createEventAction(data)),
        changeFilters: (data) => dispatch(changePlanningFiltersAction(data)),
        deleteCalendar: (data) => dispatch(deleteCalendarAction(data)),
        updateCalendar: (data) => dispatch(updateCalendarAction(data)),
        deleteEvent: (data) => dispatch(deleteEventAction(data)),
        getProject: () => dispatch(getProjectListAction()),
        updateEvent: (data) => dispatch(updateEventAction(data)),
    })
)(PlanningApp);
