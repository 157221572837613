import { ZOOM_MIN, ZOOM_MAX, MAP_FIRST_MAX, MAP_SECOND_MAX, STRUCTURE_PATH } from "Constant";
import { Box, ClickAwayListener, Hidden, Typography } from "@mui/material";
import { MAPBOX_TOKEN, MAPBOX_STYLE } from "Constant";
import ReactMapBoxGl, { ZoomControl, Marker, Cluster } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { LocationButton, MapboxPopup } from "./sc.Network";
import {
    MyLocationDot,
    ClusterOne,
    ClusterTwo,
    ClusterThree,
    Dot,
} from "Components/CmtMapBoxElements/sc.MapBoxElements";
import { useState } from "react";
import { RestrictedModale } from "Components/RestrictedModale/RestrictedModale";
import history from "services/History/history";

const MapBox = ReactMapBoxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: true,
    minZoom: ZOOM_MIN,
});

const mapStyle = {
    height: "100%",
    width: "100%",
};

export default function NetworkMap({
    company,
    selected,
    setSelected,
    viewport,
    setViewport,
    handleLocalisation,
    profile,
    defaultProfileFileName,
}) {
    const [restrictedModale, setResctrictedModale] = useState(false);

    const handleClick = (item) => {
        if (item.id === selected?.id) {
            setSelected(null);

            return;
        }

        setViewport({
            ...viewport,
            zoom: [ZOOM_MAX],
            center: [item.lng, item.lat],
        });
        setSelected(item);
    };

    return (
        <>
            <MapBox
                // eslint-disable-next-line
                style={MAPBOX_STYLE}
                containerStyle={mapStyle}
                center={viewport.center || [2.209667, 46.8]}
                zoom={viewport.zoom}
                flyToOptions={{ speed: 0.8 }}
            >
                <LocationButton onClick={handleLocalisation}>
                    <MyLocationIcon />
                    <Hidden xsDown>Me localiser</Hidden>
                </LocationButton>
                {viewport.location && (
                    <Marker coordinates={viewport.location}>
                        Ma Position <MyLocationDot />
                    </Marker>
                )}
                <Cluster
                    zoomOnClick={true}
                    ClusterMarkerFactory={(coordinates, pointCount) => {
                        if (pointCount <= MAP_FIRST_MAX) {
                            return (
                                <ClusterOne
                                    coordinates={coordinates}
                                    key={`Cluster Marker ${coordinates}`}
                                >
                                    {pointCount}
                                </ClusterOne>
                            );
                        } else if (pointCount <= MAP_SECOND_MAX) {
                            return (
                                <ClusterTwo
                                    coordinates={coordinates}
                                    key={`Cluster Marker ${coordinates}`}
                                >
                                    {pointCount}
                                </ClusterTwo>
                            );
                        } else {
                            return (
                                <ClusterThree
                                    coordinates={coordinates}
                                    key={`Cluster Marker ${coordinates}`}
                                >
                                    {pointCount}
                                </ClusterThree>
                            );
                        }
                    }}
                >
                    {company?.companies[0]?.map((item, index) => {
                        return (
                            <Marker
                                coordinates={[item.lng, item.lat]}
                                properties={{ name: item.name }}
                                key={`Marker ${index}`}
                                onClick={() => handleClick(item)}
                            >
                                <Dot key={`Marker Box ${index}`} />
                            </Marker>
                        );
                    })}
                </Cluster>
                {selected && (
                    <ClickAwayListener onClickAway={() => setSelected(null)}>
                        <MapboxPopup
                            coordinates={[selected.lng, selected.lat]}
                            anchor={"bottom-left"}
                            onClick={() => {
                                if (!profile?.profile?.isCompleted) {
                                    setResctrictedModale(true);
                                } else {
                                    history.push(`${STRUCTURE_PATH}/${selected.id}`);
                                }
                            }}
                        >
                            <Box display="flex">
                                <Box p={3}>
                                    <AvatarComponent
                                        src={selected?.logoFileName || defaultProfileFileName}
                                        size={100}
                                    />
                                </Box>
                                <Box p={3} display="flex" flexDirection="column" flexGrow={1}>
                                    <Box display="flex">
                                        <Typography component="div" variant="subtitle1">
                                            {selected.name}{" "}
                                        </Typography>
                                        <Box ml="auto">
                                            <Typography component="i" variant="h6">
                                                A {selected?.distance} km
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography
                                        component="div"
                                        variant="subtitle2"
                                        color="textSecondary"
                                    >
                                        {selected.city}
                                    </Typography>
                                </Box>
                            </Box>
                        </MapboxPopup>
                    </ClickAwayListener>
                )}
                <ZoomControl />
            </MapBox>
            {restrictedModale && (
                <RestrictedModale
                    close={(e) => {
                        e.stopPropagation();
                        setResctrictedModale(false);
                    }}
                />
            )}
        </>
    );
}
