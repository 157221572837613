import { alpha, Box, styled } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Rating } from "@mui/material";

export const Popine = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 100;
    background-color: ${(props) => alpha(props.theme.palette.common.black, 0.7)};
`;

export const CloseIcon = styled(CloseOutlinedIcon)`
    position: absolute;
    font-size: 40px;
    color: ${(props) => props.theme.palette.primary.main};
    margin: 10px;
    cursor: pointer;
    right: 0;
`;

export const RatingStar = styled(Rating)`
    font-size: ${(props) => props.size && props.size};
    color: ${(props) => props.color || props.theme.palette.primary.main};
    & .MuiRating-iconEmpty {
        color: ${(props) => props.color || props.theme.palette.primary.main};
    }
`;
