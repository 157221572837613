import { Box, Fab } from "@mui/material";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import AddIcon from "@mui/icons-material/Add";
import history from "services/History/history";
import { ProjectListItem } from "./ProjectListItem/ProjectListItem";
import { PageContainer } from "./PageContainer/PageContainer";
import { ProjectSkeletonListItem } from "./ProjectListItem/ProjectListItem";
import { CREATE_PATH, GRID_MODE, MY_PROJECT_PATH, PROJECT_PATH, REDIRECTION_TIME } from "Constant";
import { useState } from "react";
import { useEffect } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { ProjectOptions } from "./ProjectOptions/ProjectOptions";
import auth from "services/Api/auth";
import { NotificationManager } from "react-notifications";
import project from "services/Api/project";

export const ProjectPage = ({ projectData, changeFilter, disconnect }) => {
    const [displayMode, setDisplayMode] = useState(GRID_MODE);

    const [projectType, setProjectType] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                disconnect();

                return;
            }

            project.getProjectType().then((result) => {
                if (result?.result) {
                    setProjectType(result?.data);
                } else {
                    NotificationManager.error(
                        result?.error?.message || "Une Erreur s'est produite",
                        "Erreur",
                        REDIRECTION_TIME
                    );
                }
            });
        };

        fetchData();
    }, [disconnect]);

    return (
        <Box p={5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <CmtAppHeader title="Liste des projets" />
                <Box display="flex">
                    <Fab
                        variant="extended"
                        sx={{ ml: 5, px: 5 }}
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={() => history.push(`${PROJECT_PATH}${CREATE_PATH}`)}
                    >
                        <AddIcon sx={{ mr: 2 }} />
                        Créer un projet
                    </Fab>

                    <Fab
                        variant="extended"
                        sx={{ ml: 5, px: 5 }}
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={() => history.push(`${MY_PROJECT_PATH}`)}
                    >
                        <SettingsIcon sx={{ mr: 2 }} />
                        Mes projets
                    </Fab>
                </Box>
            </Box>

            <ProjectOptions
                filters={projectData?.filters}
                resultNumber={projectData?.projectList?.length || 0}
                changeFilters={changeFilter}
                displayMode={displayMode}
                categoriesList={projectType}
                setDisplayMode={setDisplayMode}
            />
            <PageContainer>
                {projectData?.projectList?.map((item, index) => (
                    <ProjectListItem projectData={item} key={index} index={index} />
                ))}
                {projectData?.loading &&
                    !projectData.error &&
                    [...Array(3)].map((_, index) => <ProjectSkeletonListItem key={index} />)}
            </PageContainer>
        </Box>
    );
};
