import Card from "@mui/material/Card";
import { styled } from "@mui/system";

const CardContainer = styled(Card)`
    position: relative;
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
`;

const CmtCard = ({ children, ...rest }) => {
    return <CardContainer {...rest}>{children}</CardContainer>;
};

export default CmtCard;
