import { MobileStepper } from "@mui/material";
import { styled } from "@mui/system";

export const ProgessBar = styled(MobileStepper)`
    position: relative;
    z-index: 1;
    height: 30px;
    border-radius: 4px;
    margin-right: 20px;
    background-color: rgba(211, 211, 211, 0.4);
    width: 100%;
    padding: 10px;
    & .MuiLinearProgress-barColorPrimary {
        background-color: ${props => props.theme.palette.secondary.light};
    };

    & .MuiMobileStepper-progress {
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
    };
`;