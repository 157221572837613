import { Box, styled } from "@mui/system";

export const MenuItemBox = styled(Box)`
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 40px;
    border-radius: 0 30px 30px 0;
    color: ${props => props.active ? props.theme.palette.primary.dark : props.theme.palette.primary.main};
    background-color: ${props => props.active ? props.theme.palette.primary.light : ''};
    &:hover {
        background-color: ${props => !props.active ? props.theme.palette.primary.light: ''};
        color: ${props => !props.active ? props.theme.palette.primary.dark : ''},
    };
    padding-Block: 10px;
    padding-left: 20px;
    & .MuiTypography-root {
        margin-left: 20px;
    };
`;