import { DISCONNECT, NOT_CONNECTED } from "redux/constants/authConstants";
import {
    CHANGE_PROJECT_FILTER,
    CREATE_PROJECT_ERROR,
    CREATE_PROJECT_SUCCESS,
    DELETE_PROJECT_SUCCESS,
    GET_MY_PROJECT_LIST_ERROR,
    GET_MY_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST_ERROR,
    GET_PROJECT_LIST_SUCCESS,
    MY_PROJECT_LOADING,
    PROJECT_LOADING,
} from "redux/constants/projectConstants";

const INIT_STATE = {
    projectList: null,
    myProjectList: null,
    loading: false,
    myLoading: false,
    filters: {
        projectType: parseInt(sessionStorage.getItem("ProjectFiltersType")) || "",
        fromDate: sessionStorage.getItem("ProjectFiltersFromDate") || "",
        toDate: sessionStorage.getItem("ProjectFiltersToDate") || "",
        location: sessionStorage.getItem("ProjectFiltersLocation") || "",
        lng: sessionStorage.getItem("ProjectFiltersLng") || "",
        lat: sessionStorage.getItem("ProjectFiltersLat") || "",
        distance: sessionStorage.getItem("ProjectFiltersDistance") || 100,
        sorter: sessionStorage.getItem("ProjectFiltersSorter") || "fromDate ASC",
    },
    error: null,
};

const deleteProject = (state, action) => {
    let projectList = state?.projectList;

    let projectListIndex = projectList.findIndex((element) => {
        return element.id === parseInt(action.projectId);
    });

    projectList.splice(projectListIndex, 1);

    return { ...state, projectList: [...projectList], loading: action.loading, error: null };
};

export default function projectReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case PROJECT_LOADING:
            return { ...state, loading: action.loading };
        case GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projectList: action.projectList,
                loading: action.loading,
                error: null,
            };
        case GET_PROJECT_LIST_ERROR:
            return { ...state, error: action.error, loading: action.loading };
        case MY_PROJECT_LOADING:
            return { ...state, myLoading: action.loading };
        case GET_MY_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                myProjectList: action.projectList,
                myLoading: action.loading,
                error: null,
            };
        case GET_MY_PROJECT_LIST_ERROR:
            return { ...state, error: action.error, myLoading: action.loading };
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                myProjectList: {
                    ...state.myProjectList,
                    current: [...state.myProjectList?.current, action.project],
                },
                loading: action.loading,
                error: null,
            };
        case CREATE_PROJECT_ERROR:
            return { ...state, error: action.error, loading: action.loading };
        case DELETE_PROJECT_SUCCESS:
            return deleteProject(state, action);
        case CHANGE_PROJECT_FILTER:
            return { ...state, filters: { ...action.filters } };
        case DISCONNECT || NOT_CONNECTED:
            return { ...INIT_STATE };
        default:
            return state;
    }
}
