import { Box, Fab } from "@mui/material";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    changeNoteFiltersAction,
    createNoteAction,
    deleteNoteAction,
    getNotesDataAction,
    updateNoteAction,
    updateOneNoteAction,
} from "redux/actions/notesActions";
import { notesListSelector } from "redux/selectors/notesSelector";
import { CreateNote } from "./CreateNote/CreateNote";
import auth from "services/Api/auth";
import { notConnectedAction } from "redux/actions/authActions";
import notes from "services/Api/notes";
import { NotificationManager } from "react-notifications";
import { NOTE_PATH, REDIRECTION_TIME, SETTING_PATH, UNOTHAURIZED_PATH } from "Constant";
import { DisplayNoteList } from "./DisplayNotes/DisplayNoteList";
import { EditNote } from "./EditNote/EditNote";
import SettingsIcon from "@mui/icons-material/Settings";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { NotesOptions } from "./NotesOptions";
import history from "services/History/history";
import { profileSelector } from "redux/selectors/profileSelector";
import { Route, Switch } from "react-router";
import { UnauthorizedPage } from "Components/UnauthorizedPage/UnauthorizedPage";
import { RestrictedRoute } from "Components/ResctrictedRoute/RestrictedRoute";

export const NotesApp = ({
    notesData,
    profileData,
    getNotesData,
    createNote,
    disconnect,
    deleteNote,
    updateOneNote,
    updateNote,
    changeFilters,
}) => {
    const [openNewNoteForm, setOpenNewNoteForm] = useState(false);
    const [editNoteMode, setEditNoteMode] = useState(null);

    const [notesTypes, setNotesTypes] = useState(null);

    const getNotesTypes = useCallback(async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();
        }

        notes.getNotesTypeList().then((result) => {
            if (result?.result) {
                setNotesTypes(result?.data);
            }
        });
    }, [disconnect]);

    const handleCreateLabel = async (valueLabel) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();
        }

        const result = notes.createNotesType({ name: valueLabel }).then((result) => {
            if (result?.result) {
                setNotesTypes([...notesTypes, result?.data]);
            } else {
                NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
            }

            return result;
        });

        return result;
    };

    const handleDeleteNote = async (id) => {
        deleteNote(id).then((result) => {
            if (result?.result) {
                setEditNoteMode(null);
            }
        });
    };

    useEffect(() => {
        if (!notesTypes) {
            getNotesTypes();
        }
    }, [notesTypes, getNotesTypes]);

    useEffect(() => {
        if (!notesData.notesList && !notesData?.error) {
            getNotesData();
        }
    }, [notesData.notesList, getNotesData, notesData.error]);

    return (
        <Switch>
            <Route path={`${NOTE_PATH}${UNOTHAURIZED_PATH}`}>
                <UnauthorizedPage />
            </Route>
            <Route path={NOTE_PATH}>
                <RestrictedRoute profile={profileData} baseUrl={NOTE_PATH}>
                    <Box
                        display="flex"
                        height="100%"
                        flexDirection="column"
                        flexWrap="nowrap"
                        p={5}
                    >
                        <Box display="flex" justifyContent="space-between" pb={3}>
                            <CmtAppHeader title="Notes" />
                            <Box display="flex">
                                <Fab
                                    variant="extended"
                                    sx={{ ml: 5, px: 5 }}
                                    size="small"
                                    color="primary"
                                    aria-label="add"
                                    id="openCreateNoteButton"
                                    onClick={() => setOpenNewNoteForm(true)}
                                >
                                    <PostAddIcon sx={{ mr: 2 }} />
                                    Ecrire une note
                                </Fab>

                                <Fab
                                    variant="extended"
                                    sx={{ ml: 5, px: 5 }}
                                    size="small"
                                    color="primary"
                                    aria-label="add"
                                    onClick={() => history.push(`${SETTING_PATH}${NOTE_PATH}`)}
                                >
                                    <SettingsIcon sx={{ mr: 2 }} />
                                    Paramètres
                                </Fab>
                            </Box>
                        </Box>

                        <NotesOptions
                            resultNumber={notesData?.notesInfos?.total}
                            filters={notesData?.filters}
                            categoriesList={notesTypes}
                            changeFilters={changeFilters}
                        />

                        <Box display="flex" justifyContent="flex-end" pb={5}>
                            <CreateNote
                                submit={createNote}
                                notesTypes={notesTypes}
                                createLabel={handleCreateLabel}
                                openNewNoteForm={openNewNoteForm}
                                setOpenNewNoteForm={setOpenNewNoteForm}
                            />
                        </Box>

                        <DisplayNoteList
                            notesData={notesData}
                            notesTypes={notesTypes}
                            setEditNoteMode={setEditNoteMode}
                            filters={notesData?.filters}
                            changeFilters={changeFilters}
                        />

                        {editNoteMode && (
                            <EditNote
                                noteDetail={notesData?.notesList?.find(
                                    (elem) => elem.id === editNoteMode
                                )}
                                notesTypes={notesTypes}
                                createLabel={handleCreateLabel}
                                onClose={() => setEditNoteMode(null)}
                                getNotesList={getNotesData}
                                deleteNote={handleDeleteNote}
                                disconnect={disconnect}
                                updateOneNote={updateOneNote}
                                submit={(data) => updateNote(data)}
                            />
                        )}
                    </Box>
                </RestrictedRoute>
            </Route>
        </Switch>
    );
};

export const NotesAppStore = connect(
    (state) => ({
        notesData: notesListSelector(state),
        profileData: profileSelector(state),
    }),
    (dispatch) => ({
        getNotesData: () => dispatch(getNotesDataAction()),
        createNote: (data) => dispatch(createNoteAction(data)),
        disconnect: () => dispatch(notConnectedAction()),
        deleteNote: (id) => dispatch(deleteNoteAction(id)),
        updateOneNote: (data) => dispatch(updateOneNoteAction(data)),
        updateNote: (data) => dispatch(updateNoteAction(data)),
        changeFilters: (filters) => dispatch(changeNoteFiltersAction(filters)),
    })
)(NotesApp);
