import { Box, Button } from "@mui/material";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";


export default function EditCommentForm ({content, cancel, submit}) {

    const [message, setMessage] = useState(content);

    return (
        <Box
            mt='auto'
            width='100%'
            py={3}
            px={7}
        >
            <FormInput
                multiline
                variant='standard'
                rows={3}
                value={message}
                onChange={e => setMessage(e.target.value)}
                margin="normal"
                placeholder="Envoyer un commentaire"
                InputProps={{ disableUnderline: true }}
            />

            <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box pr={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => cancel()}
                        >
                        <CancelIcon /> Annuler
                    </Button>
                </Box>

                <Box pl={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => submit(message)}
                        disabled={!message}
                    >
                        <SendIcon /> Envoyer
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};