export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_ERROR = "GET_PROJECT_LIST_ERROR";

export const PROJECT_LOADING = "PROJECT_LOADING";

export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR";

export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR";

export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR";

export const PROJECT_UPDATED = "PROJECT_UPDATED";
export const PROJECT_DELETED = "PROJECT_DELETED";

export const PROJECTS_INVITATIONS_SUCCESS = "PROJECTS_INVITATIONS_SUCCESS";
export const PROJECTS_INVITATIONS_ERROR = "PROJECTS_INVITATIONS_ERROR";

export const MY_PROJECT_LOADING = "MY_PROJECT_LOADING";

export const GET_MY_PROJECT_LIST_SUCCESS = "GET_MY_PROJECT_LIST_SUCCESS";
export const GET_MY_PROJECT_LIST_ERROR = "GET_MY_PROJECT_LIST_ERROR";

export const CHANGE_PROJECT_FILTER = "CHANGE_PROJECT_FILTER";
