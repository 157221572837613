import { Box, Button, CardContent, Hidden, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { useState } from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { MAPBOX_TOKEN, MAPBOX_STYLE, ZOOM_BEGIN } from "Constant";
import ReactMapBoxGl, { ZoomControl } from "react-mapbox-gl";
import { CmtMarker } from "Components/CmtMapBoxElements/CmtMarker/CmtMarker";
import { useEffect } from "react";
import { useRef } from "react";
import { useCallback } from "react";

const MapBox = ReactMapBoxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: true,
    minZoom: ZOOM_BEGIN,
});

export default function DisplayAddress({ companyData, setEditionMode, isAdmin }) {
    const [hover, setHover] = useState(false);
    const cardRef = useRef(null);
    const [mapStyle, setMapStyle] = useState({
        height: "100%",
        width: "100%",
    });

    const resizeMap = useCallback(() => {
        if (cardRef?.current) {
            setMapStyle({
                width: cardRef?.current?.offsetWidth,
                height: cardRef?.current?.offsetWidth,
            });
        }
    }, [cardRef]);

    useEffect(() => {
        resizeMap();

        window.addEventListener("resize", resizeMap);

        return () => {
            window.removeEventListener("resize", resizeMap);
        };
    }, [resizeMap]);

    return (
        <CmtCard
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            id="contactCmtCard"
            className="fullHeight"
        >
            <TileTitle>Adresse</TileTitle>
            <CardContent>
                <Box py={2}>
                    <Typography component="p" fontSize={12}>
                        Adresse
                    </Typography>
                    <Typography component="p" fontSize={12}>
                        {companyData?.address1} {companyData?.address2}, {companyData.zipCode},{" "}
                        {companyData.city}
                    </Typography>
                </Box>

                <Box py={5} width="100%" flexShrink={0} ref={cardRef}>
                    <MapBox
                        // eslint-disable-next-line
                        style={MAPBOX_STYLE}
                        containerStyle={mapStyle}
                        onStyleLoad={(map) => map.resize()}
                        center={[companyData?.lng, companyData?.lat]}
                        zoom={[ZOOM_BEGIN]}
                        flyToOptions={{ speed: 0.8 }}
                    >
                        <CmtMarker coordinates={[companyData?.lng, companyData?.lat]} />
                        <ZoomControl />
                    </MapBox>
                </Box>

                <Box width="100%" display="flex" justifyContent="flex-end" height={30}>
                    {hover && isAdmin && (
                        <Button
                            onClick={() => {
                                setEditionMode(true);
                            }}
                            variant="contained"
                            color="secondary"
                            id="contactUpdateButton"
                        >
                            <Hidden xsDown> Modifier </Hidden>
                            <Hidden smUp>
                                {" "}
                                <CreateOutlinedIcon className="pointer" />{" "}
                            </Hidden>
                        </Button>
                    )}
                </Box>
            </CardContent>
        </CmtCard>
    );
}
