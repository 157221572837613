export const REQUEST_FEEDBACK = "REQUEST_FEEDBACK";
export const REQUEST_FEEDBACK_SUCCESS = "REQUEST_FEEDBACK_SUCCESS";
export const REQUEST_FEEDBACK_ERROR = "REQUEST_FEEDBACK_ERROR";

export const REQUEST_SEND_FEEDBACK_MESSAGE_SUCCESS = "REQUEST_SEND_FEEDBACK_MESSAGE_SUCCESS";
export const REQUEST_SEND_FEEDBACK_MESSAGE_ERROR = "REQUEST_SEND_FEEDBACK_MESSAGE_ERROR";

export const REQUEST_DELETE_FEEDBACK_MESSAGE_SUCCESS = "REQUEST_DELETE_FEEDBACK_MESSAGE_SUCCESS";
export const REQUEST_DELETE_FEEDBACK_MESSAGE_ERROR = "REQUEST_DELETE_FEEDBACK_MESSAGE_ERROR";

export const RECEIVE_FEEDBACK_MESSAGE = "RECEIVE_FEEDBACK_MESSAGE";

export const READED_FEEDBACK_MESSAGE = "READED_FEEDBACK_MESSAGE";
