import { Box, styled } from "@mui/system";
import { IconButton, TableRow } from "@mui/material";

export const RowTableContainer = styled(TableRow)`
    cursor: pointer;
    transition: 1s;
    position: relative;
    &:hover {
        background-color: ${(props) => props.theme.palette.primary.light};
        & .action-option {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }

        & .icons {
            background-color: #fff;
        }
    }

    &:nth-of-type(odd) {
        background-color: rgba(211, 211, 211, 0.4);
        &:hover {
            background-color: ${(props) => props.theme.palette.primary.light};
        }
    }
`;

export const SkeletonTableContainer = styled(TableRow)`
    background-color: rgba(211, 211, 211, 0.4);
`;

export const TranslatedIcon = styled(IconButton)`
    transition: all 0.3s ease;
    transform: translateX(100%);
`;

export const HiddenIcon = styled(IconButton)`
    margin-left: 10px;
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    transform: translateX(100%);
`;

export const GridElem = styled(Box)`
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
        & .action-option {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
`;
