import { Box, Checkbox, IconButton, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';

export const DocumentsTableHeader = ({
    filters,
    isAllChecked = false,
    handleHeaderCheck,
    changeFilters,
    checkedList,
    deleteFileFolder,
    moveFileFolder,
}) => {
    const field = filters?.sorter ? filters.sorter.split(' ')[0] : '';
    const order = filters.sorter ? filters.sorter.split(' ')[1] : '';

    const handleSortClick = (newField) => {
        let newOrder = '';

        if (field === newField) {
            newOrder = order === 'ASC' ? 'DESC' : 'ASC';
        } else {
            newOrder = order;
        }

        changeFilters({...filters, sorter: `${newField} ${newOrder}`});
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell width={50}>
                    <Box display='flex' alignItems='center'>
                        <Checkbox checked={checkedList.length > 0 && isAllChecked} onChange={(e) => handleHeaderCheck(e.target.checked)} />
                    </Box>
                </TableCell>

                {checkedList.length === 0 ?
                    <>
                        <TableCell width={80}/>

                        <TableCell align='justify'>
                            <TableSortLabel
                                active={field === 'name'}
                                direction={order.toLowerCase()}
                                onClick={() => handleSortClick('name')}
                            >
                                Nom
                            </TableSortLabel>
                        </TableCell>
                        
                        <TableCell align='justify'>
                            <TableSortLabel
                                active={field === 'type'}
                                direction={order.toLowerCase()}
                                onClick={() => handleSortClick('type')}
                            >
                                Type
                            </TableSortLabel>
                        </TableCell>
                        
                        <TableCell align='justify'>
                            <TableSortLabel
                                active={field === 'author'}
                                direction={order.toLowerCase()}
                                onClick={() => handleSortClick('author')}
                            >
                                Auteur
                            </TableSortLabel>
                        </TableCell>

                        <TableCell align='justify'>
                            <TableSortLabel
                                active={field === 'size'}
                                direction={order.toLowerCase()}
                                onClick={() => handleSortClick('size')}
                            >
                                Taille
                            </TableSortLabel>
                        </TableCell>

                        <TableCell width={80}/>
                    </>
                :
                    <>
                        <TableCell align='right' padding='none' width={80}>
                            <Box pr={9} display='flex' justifyContent='flex-end'>
                                <Box pr={1}>
                                    <IconButton size='medium' color='primary' onClick={(e) => moveFileFolder(e.currentTarget)}>
                                        <OpenWithIcon/>
                                    </IconButton>
                                </Box>

                                <Box pl={1}>
                                    <IconButton size='medium' color='primary' onClick={() => deleteFileFolder(checkedList)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell/>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell width={80}/>
                    </>
                }
            </TableRow>
        </TableHead>
    );
};