import { useState } from "react";
import DisplayAddress from "./DisplayAddress";
import EditionAddress from "./EditionAddress";

export const ProfileAddress = ({ companyData, update, isAdmin }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionAddress
                companyData={companyData}
                setEditionMode={setEditionMode}
                submit={update}
            />
        );
    } else {
        return (
            <DisplayAddress
                companyData={companyData}
                setEditionMode={setEditionMode}
                isAdmin={isAdmin}
            />
        );
    }
};
