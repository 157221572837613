export const CONNECT_SUCCESS = 'CONNECT_SUCCESS';
export const NOT_CONNECTED = 'NOT_CONNECTED';
export const DISCONNECT = 'DISCONNECT';
export const DELETE_ERROR = "DELETE_ERROR";


export const REQUEST_CONNEXION = 'REQUEST_CONNEXION';
export const REQUEST_CONNEXION_SUCCESS = 'REQUEST_CONNEXION_SUCCESS';
export const REQUEST_CONNEXION_ERROR = 'REQUEST_CONNEXION_ERROR';


export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_ERROR = 'REQUEST_LOGIN_ERROR';


export const REQUEST_SIGNUP = 'REQUEST_SIGNUP';
export const REQUEST_SIGNUP_SUCCESS = 'REQUEST_SIGNUP_SUCCESS';
export const REQUEST_SIGNUP_ERROR = 'REQUEST_SIGNUP_ERROR';