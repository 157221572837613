import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/Attachment";
import { TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { ALL_FILE_SUPPORTED, REDIRECTION_TIME } from "Constant";
import { NotificationManager } from "react-notifications";

export default function ContentFooter({
    sendMessage,
    sendMediaMessage,
    sendIsTyping,
    currentUser,
}) {
    const [message, setMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);

    const [currentUserId, setCurrentUserId] = useState(currentUser?.id);

    const handleSendMessage = (e) => {
        e.preventDefault();

        sendMessage(message);

        setMessage("");

        sendIsTyping(false);

        setIsTyping(false);
    };

    useEffect(() => {
        if (currentUserId !== currentUser?.id) {
            setMessage("");

            setCurrentUserId(currentUser?.id);
        }
    }, [currentUser, currentUserId]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: ALL_FILE_SUPPORTED,
        multiple: false,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections?.length > 0) {
                NotificationManager.error(
                    "Votre fichier ne peut pas être ajouté",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }

            if (acceptedFiles[0]) {
                sendMediaMessage(acceptedFiles[0]);
            }
        },
    });

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey && message) {
            handleSendMessage(event);
        }
    };

    const handleSetMessage = async (message) => {
        setMessage(message);

        if (isTyping !== Boolean(message.length > 0)) {
            sendIsTyping(!isTyping);

            setIsTyping(!isTyping);
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center" py={2} px={3}>
                <input {...getInputProps()} />

                <IconButton {...getRootProps()}>
                    <AttachFileIcon />
                </IconButton>

                <Box px={3} width="100%">
                    <TextField
                        id="chat-textarea"
                        value={message}
                        onChange={(e) => handleSetMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Tapez un message..."
                        variant="outlined"
                        multiline
                        fullWidth={true}
                    />
                </Box>

                <IconButton onClick={handleSendMessage}>
                    <SendIcon />
                </IconButton>
            </Box>
        </>
    );
}
