import { Box, styled } from "@mui/system";
import { Marker } from "react-mapbox-gl";

export const MyLocationDot = styled(Box)`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: rgb(0, 150, 0);
`;

export const Dot = styled(Box)`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.cluster.color.one};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
`;

export const ClusterOne = styled(Marker)`
    height: ${(props) => props.theme.cluster.size.one};
    width: ${(props) => props.theme.cluster.size.one};
    border-radius: 50%;
    background-color: ${(props) => props.theme.cluster.color.one};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
`;

export const ClusterTwo = styled(Marker)`
    height: ${(props) => props.theme.cluster.size.one};
    width: ${(props) => props.theme.cluster.size.one};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    background-color: ${(props) => props.theme.cluster.color.one};
`;

export const ClusterThree = styled(Marker)`
    height: ${(props) => props.theme.cluster.size.one};
    width: ${(props) => props.theme.cluster.size.one};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    background-color: ${(props) => props.theme.cluster.color.one};
`;
