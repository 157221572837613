import { useState } from "react";
import DisplayModeInfos from "./DisplayModeInfos";
import EditionModeInfos from "./EditionModeInfos";


export default function ProfileHeaderInfos ({profile, update}) {
    const [editionMode, setEditionMode] = useState(false);

    if (!editionMode) {
        return ( <DisplayModeInfos profile={profile} setEditionMode={setEditionMode} /> );
    } else {
        return ( <EditionModeInfos profile={profile} setEditionMode={setEditionMode} submit={update} /> );
    }
};