import { Button, Typography } from "@mui/material"
import CmtCard from "Components/CmtCard/CmtCard"
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { Box } from "@mui/system";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";
import { useState } from "react";

export const DeleteAccount = ({deleteMyAccount}) => {
    const [deletePopine, setDeletePopine] = useState(false);

    return (
        <>
            <CmtCard>
                <Box display='flex' alignItems='center' flexDirection='column'>
                    <TileTitle color='red'>Supprimer mon Compte</TileTitle>
                    <Box my={5}>
                        <Button type="button" variant="contained" color='error' onClick={() => setDeletePopine(true)}> Supprimer mon compte </Button>
                    </Box>
                </Box>
            </CmtCard>

            <CmtDeleteDialog
                open={deletePopine}
                onCancel={() => setDeletePopine(false)}
                onDelete={() => {
                    deleteMyAccount();
                    setDeletePopine(false);
                }}
            >
                <Box textAlign='center' py={3}>
                    <Typography component='p'>
                        Êtes-vous sûr de vouloir supprimer votre compte ?
                    </Typography>

                    <Typography component='p'>
                        Cette action est irréversible.
                    </Typography>
                </Box>
            </CmtDeleteDialog>
        </>
    );
};