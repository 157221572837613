import { ClickAwayListener, Hidden } from "@mui/material";
import SideBarMenu from "./SideBarMenu/SideBarMenu";
import { SidebarContainer } from "./sc.SideBar";

export const SideBar = ({ profile, isCollapse, setCollapse, closeSearch }) => {
    return (
        <>
            <Hidden smUp>
                <ClickAwayListener
                    onClickAway={(e) => {
                        if (!isCollapse) {
                            e.preventDefault();
                            setCollapse(true);
                        }
                    }}
                >
                    <SidebarContainer isCollapse={isCollapse} onClick={closeSearch}>
                        <SideBarMenu
                            setCollapse={setCollapse}
                            closeSearch={closeSearch}
                            profile={profile}
                        />
                    </SidebarContainer>
                </ClickAwayListener>
            </Hidden>

            <Hidden smDown>
                <SidebarContainer onClick={closeSearch}>
                    <SideBarMenu
                        setCollapse={setCollapse}
                        closeSearch={closeSearch}
                        profile={profile}
                    />
                </SidebarContainer>
            </Hidden>
        </>
    );
};
