import { Box, Button, Checkbox, FormControl, FormHelperText, Grid, InputLabel, ListSubheader, MenuItem, Select, Typography } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { Formik } from "formik";

const INIT_VALUE = {
    positionType: '',
    audiensNb: '',
    address1: '',
    address2: '',
    zipCode: '',
    city: '',
    sectorType: [],
};

export const CreateIntermittentForm = ({submit, disabled, sectorTypes}) => {
    const checkError = (values) => {
        let errors = {};

        if (!values.audiensNb) {
            errors.audiensNb = "Veuillez indiquer votre numéro Audiens";
        } else if (values.audiensNb.length !== 10) {
            errors.audiensNb = "Votre numéros audiens doit comporter 10 caractères";
        }

        if (!values.address1) {
            errors.address1 = "Veuillez renseigner votre adresse";
        }

        if (!values.zipCode) {
            errors.zipCode = "Veuillez renseigner votre code postal";
        } else if (!/^[0-9]/i.test(values.zipCode)) {
            errors.zipCode = "Votre code postale est incorrect";
        }

        if (!values.city) {
            errors.city = "Veuillez renseigner votre ville";
        }

        if (values.sectorType.length === 0) {
            errors.sectorType = "Veuillez renseigner le type de votre activité";
        }

        return errors;
    };

    return (
        <Box style={{filter: disabled ? 'blur(4px)': "blur(0px)"}}>
            <Typography component="div" variant="h1">Enregistrer mon statut d'intermittent</Typography>
            <Formik
                initialValues={INIT_VALUE}
                validate={values => checkError(values)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);

                    const result = await submit(values);

                    if (result?.result) {
                        resetForm();
                    }

                    setTimeout(() => {
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                }) => (
                    <CmtForm onSubmit={handleSubmit} >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    type="text"
                                    name="audiensNb"
                                    label="Numéro audiens"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.audiensNb}
                                    error={touched.audiensNb && errors.audiensNb}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant='standard' margin='normal'>
                                    <InputLabel id="labelSector">Secteur d'activité</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="labelSector"
                                        variant='standard'
                                        value={values.sectorType}
                                        name="sectorType"
                                        onChange={handleChange}
                                        error={touched.sectorType && Boolean(errors.sectorType)}
                                        onBlur={handleBlur}
                                        renderValue={() => {
                                            const result = values?.sectorType?.map(element => element?.name).join(', ');

                                            return result;
                                        }}
                                        id='roleSelect'
                                        multiple
                                    >
                                        {sectorTypes?.map((item, index) => ([
                                            <ListSubheader color='primary' key={`positionGroup ${index}`} >{item.name}</ListSubheader>,
                                            item.values.map((it, ind) => (
                                                <MenuItem value={it} key={`positionType ${ind}`} id={`positionType${ind}`}>
                                                    <Checkbox checked={Boolean(values?.sectorType?.find(element => element.id === it.id))} />
                                                    {it.name}
                                                </MenuItem>
                                            ))
                                        ]))}
                                    </Select>
                                    <FormHelperText error>{touched.sectorType && errors.sectorType}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInput
                                    type="text"
                                    name="address1"
                                    label="Adresse 1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address1}
                                    error={touched.address1 && errors.address1}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormInput
                                    type="text"
                                    name="address2"
                                    label="Adresse 2"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address2}
                                    error={touched.address2 && errors.address2}
                                    disabled={disabled}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={4} lg={3}>
                                <FormInput
                                    type="text"
                                    name="zipCode"
                                    label="Code Postal"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.zipCode}
                                    error={touched.zipCode && errors.zipCode}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} md={8} lg={9} >
                                <FormInput
                                    type="text"
                                    name="city"
                                    label="Ville"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.city}
                                    error={touched.city && errors.city}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} display='flex' justifyContent='flex-end' mt={5}>
                                <Button
                                    id='loginButton'
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting || disabled}
                                >
                                    <Typography>Enregistrer</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </CmtForm>
                )}
            </Formik>
        </Box>
    );
};