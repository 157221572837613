import { Box, Button, CardContent } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { Formik } from "formik";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";



export const ChangePassword = ({submit}) => {
    const checkError = (values) => {
        const errors = {};

        if (!values.actualPassword) {
            errors.actualPassword = 'Veuillez renseigner votre mot de passe actuel';
        }

        if (!values.newPassword) {
            errors.newPassword = 'Veuillez renseigner votre nouveau mot de passe';
        }

        if (!values.confirmNewPassword) {
            errors.confirmNewPassword = 'Veuillez confirmer votre nouveau mot de passe';
        } else if (values.confirmNewPassword !== values.newPassword) {
            errors.confirmNewPassword = 'Votre saisie ne correspond pas à votre nouveau mot de passe';
        }

        return errors;
    }

    return (
        <CmtCard>
            <TileTitle> Modifier mon mot de passe</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{
                        actualPassword: '',
                        newPassword: '',
                        confirmNewPassword: ''
                    }}
                    validate={values => checkError(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        submit({plainPassword: values.newPassword});
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                    }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        isSubmitting,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <FormInput 
                                type="password"
                                name="actualPassword"
                                label="Mot de passe actuel"
                                id='changePasswordActual'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email} 
                                error={touched.actualPassword && errors.actualPassword}
                                />
                            <FormInput 
                                type="password" 
                                name="newPassword"
                                label="Nouveau mot de passe"
                                id='changeNewPassword'
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.newPassword && errors.newPassword}
                                />
                            <FormInput 
                                type="password" 
                                name="confirmNewPassword" 
                                label="Nouveau mot de passe"
                                id='changeNewPasswordConfirm'
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.confirmNewPassword && errors.confirmNewPassword}
                                />
                            
                            <Box width='100%' display='flex' justifyContent='flex-end' mt={7}>
                                <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                                    Modifier
                                </Button>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    )

}