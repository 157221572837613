import { Box, Button, Typography } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import CmtLoading from "Components/CmtLoading/CmtLoading";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { useState } from "react";

export const CreateFolder = ({
    anchorEl,
    closePopover,
    createFolder,
    folderId,
    loading,

}) => {
    const [value, setValue] = useState('');

    return (
        <CmtPopover
            anchorEl={anchorEl}
            closePopover={() => closePopover()}
        >
            {loading && 
                <CmtLoading />
            }
            <Box p={5} minWidth={350}>
                <CmtForm 
                    onSubmit={(e) => {
                        e.preventDefault();

                        createFolder({
                            name: value,
                            folderId: folderId,
                            isFolder: true,
                        })
                    }}
                >
                    <Typography component='p' variant='h1'>Créer un dossier</Typography>
                    <FormInput label='Nom du dossier' value={value} onChange={(e) => setValue(e.target.value)} />
                    <Box display='flex' pt={4} justifyContent='flex-end'>
                        <Button
                            color='primary'
                            variant='contained'
                            type='submit'
                            disabled={!value}
                        >
                            Créer
                        </Button>
                    </Box>
                </CmtForm>
            </Box>
        </CmtPopover>
    );
};