import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import AddCommentForm from "./AddCommentForm";
import EditCommentForm from "./EditCommentForm";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { MessageItem } from "./sc.Comments";

const AnnouncementCommentsElem = ({item, profileId, deleteComment, updateComment}) => {
    const [editMode, setEditMode] = useState(false);

    const handleUpdateComment = async (commentId, message) => {
        updateComment(commentId, message)
        .then(result => {
            if (result?.result) {
                setEditMode(false);
            }
        });
    };

    return (
        <MessageItem p={8} minHeight={50}>
            {profileId === item?.createdBy?.id && !editMode &&
                <Box
                    position='absolute'
                    right={30}
                    top={20}
                    zIndex={1}
                    visibility='hidden'
                    className='commentRoot'
                >
                    <IconButton color='primary' onClick={() => setEditMode(true)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color='primary' onClick={() => deleteComment(item.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            }
            
            {editMode ?
                <EditCommentForm
                    content={item.content}
                    cancel={() => setEditMode(false)}
                    submit={(message) => handleUpdateComment(item.id, message)}
                />
            :
                <Box
                    mb={8}
                    component="p"
                    dangerouslySetInnerHTML={{
                        __html: item?.content?.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                    }}
                />
            }
        </MessageItem>
    );
};

export const AnnouncementComments = ({
    comments,
    addComment,
    deleteComment,
    updateComment,
    profileId
}) => {

    return (
        <>
            {comments?.map((item, index) => (
                <AnnouncementCommentsElem
                    item={item}
                    key={index}
                    deleteComment={deleteComment}
                    profileId={profileId}
                    updateComment={updateComment}
                />
            ))}
            <AddCommentForm
                submit={addComment}
            />
        </>
    );
};