import { Fab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PROFILE_PATH } from "Constant";
import { useSelector } from "react-redux";
import { profileSelector } from "redux/selectors/profileSelector";
import history from "services/History/history";

export const IncompleteProfile = ({
    title = "",
    text = "",
    secondText = "",
    callToAction = null,
}) => {
    const profile = useSelector(profileSelector);

    if (!profile?.profile) {
        return <></>;
    }

    const { lastName, birthday, positionType, description } = profile?.profile;

    return (
        <Box>
            <Typography component="p" variant="h1" textAlign="center">
                {title || "Profil incomplet"}
            </Typography>

            <Typography component="p" mt={5}>
                {text ||
                    "Vous devez compléter votre profil pour accéder à l'ensemble de l'application."}
            </Typography>

            <Typography component="p" mb={5}>
                {secondText}
            </Typography>

            {!lastName && (
                <Typography component="p" color="primary">
                    - Votre nom de famille
                </Typography>
            )}

            {!birthday && (
                <Typography component="p" color="primary">
                    - Votre date de naissance
                </Typography>
            )}

            {!positionType && (
                <Typography component="p" color="primary">
                    - Votre profession
                </Typography>
            )}

            {!description && (
                <Typography component="p" color="primary">
                    - Une description
                </Typography>
            )}

            <Box display="flex" justifyContent="center" mt={10}>
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{ margin: "auto" }}
                    onClick={() => {
                        if (callToAction) {
                            callToAction();
                        }
                        history.push(PROFILE_PATH);
                    }}
                >
                    Compléter mon profil
                </Fab>
            </Box>
        </Box>
    );
};
