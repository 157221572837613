import { useTheme } from "@emotion/react";
import { Box, CardContent, Container, Grid } from "@mui/material";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";
import { PresentationBox, PublicTypo } from "../sc.Public";

const Block = ({ title, text, children, ...rest }) => {
    const theme = useTheme();

    return (
        <Grid item px={13} xs={12} md={6} lg={4} py={10}>
            <PresentationBox {...rest}>
                <PublicTypo
                    family={theme.palette.publicTypo.hFamily}
                    variant="h1"
                    pl={4}
                    pt={5}
                    color="primary"
                >
                    {title}
                </PublicTypo>

                <CardContent>
                    <PublicTypo family={theme.palette.publicTypo.pFamily} pt={3}>
                        {text}
                    </PublicTypo>
                </CardContent>
                {children}
            </PresentationBox>
        </Grid>
    );
};

export const PresentationBlock = () => {
    return (
        <Box backgroundColor="#0A7AA6">
            <Container maxWidth="lg">
                <Grid container width="100%" py={20} px={10}>
                    <Block
                        title="Rencontrez"
                        text="Théatre, musique, danse etc... consultez les projets en cours et intégrez des
                groupes qui partagent vos passions. Avec Impul’Scène, faites de vos
                ambitions une réalité. Enrichissez votre réseau, ayez une meilleure
                connaissance du milieu."
                    >
                        <ShapeImg src="/images/Public/Shape/Shape8.png" top="-50px" left="-50px" />

                        <ShapeImg
                            src="/images/Public/Shape/Shape6.png"
                            rotate="90deg"
                            bottom="-50px"
                            right="-50px"
                        />
                    </Block>

                    <Block
                        zIndex={2}
                        title="Échangez"
                        text="Avoir une idée, recherchez des acteurs pour la concrétiser. Être à l’écoute, prendre les
                décisions... Grâce à Impul’Scène, dénichez de nouveaux talents, montez votre équipe
                de toute pièce et travaillez de concert avec les profils que vous aurez choisis pour
                mener à bien votre spectacle."
                    >
                        <ShapeImg
                            src="/images/Public/Shape/Shape9.png"
                            top="-50px"
                            left={0}
                            height="50px"
                        />
                        <ShapeImg
                            src="/images/Public/Shape/Shape10.png"
                            bottom="-50px"
                            right="-50px"
                        />
                    </Block>

                    <Block
                        title="Créez"
                        text="Éxprimez vos idées et concrétiser les... Réservez aux acteurs du spectacle vivant
                Impul’Scène vous donne un coup de pouce pour réalisez vos projets. Représentez votre
                collectifs sur Impul’scène et mettez le en avant pour attirer de nouveaux
                visiteurs de la scène artistique."
                    >
                        <ShapeImg
                            zIndex={1}
                            src="/images/Public/Shape/Shape2.png"
                            top="-50px"
                            left="-50px"
                        />
                        <ShapeImg
                            zIndex={1}
                            src="/images/Public/Shape/Shape7.png"
                            bottom="-50px"
                            right="-50px"
                        />
                    </Block>
                </Grid>
            </Container>
        </Box>
    );
};
