import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import auth from "services/Api/auth";
import notes from "services/Api/notes";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { CirclePicker } from "react-color";
import { NoteTypeLabelColor } from "../sc.Parameters";

const NoteTypesLine = ({
    item,
    setDisplayEditNoteType,
    setDisplayDeleteNoteType,
}) => {
    return (
        <TableRow>
            <TableCell>
                <Typography component='p' variant='h5'>{item?.name}</Typography>
            </TableCell>
            <TableCell>
                <Typography component='p' variant='h5'>
                    {item?.color ? <NoteTypeLabelColor iconColor={item?.color} fontSize='large'/> : 'Aucune couleur défini'}
                </Typography>
            </TableCell>
            <TableCell align='right'>
                <IconButton color='primary' onClick={() => setDisplayEditNoteType(item)}>
                    <EditIcon/>
                </IconButton>

                <IconButton color='primary' onClick={() => setDisplayDeleteNoteType(item)}>
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export const NoteTypeParameters = ({disconnect}) => {
    const [notesTypes, setNotesTypes] = useState(null);

    const [displayEditNoteType, setDisplayEditNoteType] = useState(null);
    const [displayDeleteNoteType, setDisplayDeleteNoteType] = useState(null);

    const getNotesTypes = useCallback(async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();
        }

        notes.getNotesTypeList()
        .then(result => {
            if (result?.result) {
                setNotesTypes(result?.data);
            }
        });
    }, [disconnect]);

    const checkError = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = 'Veuillez renseigner un nom pour votre label';
        }

        if (!values.color) {
            errors.color = 'Veuillez indiquer une couleur pour votre label';
        }

        return errors;
    };

    const handleEditType = async (values) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();
        }

        notes.updateNotesType(values)
        .then(result => {
            if (result?.result) {
                let newTab = notesTypes;

                newTab[newTab.findIndex(type => type.id === result?.data?.id)] = result?.data;

                setNotesTypes(newTab);

                setDisplayEditNoteType(null);
            }
        });
    };

    const handleDeleteLabel = async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();
        }

        notes.deleteNotesType(displayDeleteNoteType.id)
        .then(result => {
            if (result?.result) {
                setNotesTypes(notesTypes?.filter(type => type.id !== displayDeleteNoteType?.id));

                setDisplayDeleteNoteType(null);
                setDisplayEditNoteType(null);
            }
        });
    };

    useEffect(() => {
        if (!notesTypes) {
            getNotesTypes();
        }
    }, [notesTypes, getNotesTypes]);

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell align='justify'> Nom</TableCell>
                        <TableCell align='justify'> Couleur</TableCell>
                        <TableCell />
                    </TableHead>
                    <TableBody>
                        {notesTypes?.map((item, index) => (
                            <NoteTypesLine
                                item={item}
                                key={index}
                                setDisplayDeleteNoteType={setDisplayDeleteNoteType}
                                setDisplayEditNoteType={setDisplayEditNoteType}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            <Dialog
                open={Boolean(displayEditNoteType)}
                onClose={() => setDisplayEditNoteType(null)}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>
                    <Typography component='p' variant='h5'>Modifier un label</Typography>
                </DialogTitle>
                
                <Formik
                    initialValues={{
                        id: displayEditNoteType?.id,
                        name: displayEditNoteType?.name,
                        color: displayEditNoteType?.color,
                    }}
                    validate={values => checkError(values)}
                    onSubmit={values => handleEditType(values)}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <FormInput
                                    name='name'
                                    value={values.name}
                                    onChange={handleChange}
                                    error={touched.name && errors.name}
                                    onBlur={handleBlur}
                                    label='Nom'
                                />

                                <Box py={2}>
                                    <Box pb={2}>
                                        <Typography component='p' variant='h6'>Couleur</Typography>
                                    </Box>
                                    <CirclePicker
                                        width='100%'
                                        onChange={newValue => setFieldValue('color', newValue.hex)}
                                        value={values.color}
                                        name='color'
                                        circleSpacing={8}
                                        onBlur={handleBlur}
                                    />
                                    <FormHelperText error>{touched.color && errors.color}</FormHelperText>
                                </Box>
                            </DialogContent>


                            <DialogActions>
                                <Box display='flex' justifyContent='space-between' width='100%'>
                                    <Button color='secondary' onClick={() => setDisplayEditNoteType(null)}>Annuler</Button>
                                    <Button color='primary' type='submit'>Enregistrer</Button>
                                </Box>
                            </DialogActions>
                        </CmtForm>
                    )}
                </Formik>
            </Dialog>

            <Dialog
                open={Boolean(displayDeleteNoteType)}
                onClose={() => setDisplayDeleteNoteType(null)}
            >
                <DialogContent dividers>
                    <Typography component='p' variant='h5'>Voulez-vous vraiment supprimer ce label ?</Typography>
                    <Typography component='p' variant='h5'>Attention, cette action est irréversible.</Typography>
                </DialogContent>

                <DialogActions>
                    <Box display='flex' justifyContent='space-between' width='100%'>
                        <Button color='secondary' onClick={() => setDisplayDeleteNoteType(null)}>Annuler</Button>
                        <Button color='primary' onClick={handleDeleteLabel}>Supprimer</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    )
};