import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function CmtEditor ({
    value,
    onChange,
    className,
    ...rest
}) {
    return (
        <CKEditor
            editor={ ClassicEditor }
            data={value}
            onChange={(event, editor) => {
                const data = editor.getData();

                onChange(data);
            }}
            {...rest}
        />
    );
};