import { Box, Button, Typography } from "@mui/material";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from '@mui/icons-material/Check';
import { TextFieldRoot } from "Components/CmtProfileContainer/sc.ProfileContainer";


export default function EditionModeInfos ({profile, setEditionMode, submit}) {
    const checkError = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = 'Veuillez renseigner votre prénom';
        }

        return errors;
    };

    return (
        <Box 
            display='flex'
            alignItems='center'
        >
            <Box >
                <Formik
                    initialValues={{
                        firstName: profile?.profile?.firstName || '',
                        lastName: profile?.profile?.lastName || ''
                    }}
                    validate={values => checkError(values)}
                    onSubmit={(values) => {
                        submit(values);
                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <Box display='flex' alignItems='flex-end' justifyContent='center'>
                                <Box mr={4}>
                                    <TextFieldRoot
                                        type="text"
                                        name="firstName"
                                        label="Prénom"
                                        id='profileFirstName'
                                        fullWidth={false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName} 
                                        error={touched.firstName && errors.firstName}
                                    />
                                </Box>
                                <TextFieldRoot
                                    type="text"
                                    name="lastName"
                                    label="Nom"
                                    fullWidth={false}
                                    id='profileLastName'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName} 
                                    error={touched.lastName && errors.lastName}
                                />
                                <Button type='submit' >
                                    <Box color='white'>
                                        <CheckIcon/>
                                    </Box>
                                </Button>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
                <Typography color='whitesmoke' > {profile?.profile?.email}</Typography>
            </Box>
            
        </Box>
    );
};