import { UNOTHAURIZED_PATH } from "Constant";
import { useEffect } from "react";
import history from "services/History/history";

export const RestrictedRoute = ({ profile, children, baseUrl }) => {
    useEffect(() => {
        if (profile?.profile && !profile.profile.isCompleted) {
            history.push(`${baseUrl}${UNOTHAURIZED_PATH}`);
        }
    }, [profile?.profile, baseUrl]);

    if ((profile?.profile && !profile?.profile?.isCompleted) || !profile?.profile) {
        return <></>;
    }

    return children;
};
