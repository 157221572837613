import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
} from "@mui/material";
import { Box } from "@mui/system";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtForm from "Components/CmtForm/CmtForm";
import { DEFAULT_PRICE_LIST } from "Constant";
import { Formik } from "formik";
import { StepperLine } from "../StepperLine";

export const Step4 = ({stepper, cancel, submit, data}) => {
    const priceList = DEFAULT_PRICE_LIST;

    const checkError = (values) => {
        const errors = {};

        if (!values.price && values?.price !== 0) {
            errors.price = 'Veuillez ajouter une fourchette de prix à votre annonce';
        }

        return (errors);
    };

    return (
        <Grid item container xs={12} sm={5}>
            <Formik
                initialValues={{price: !data.price && data.price !== 0 ? '' : data.price}}
                validate={values => checkError(values)}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                }) => (
                    <CmtForm onSubmit={handleSubmit} className='fullWidth'>
                        <CmtCard>
                            <Box p={5}>
                                <FormControl variant='standard' fullWidth>
                                    <InputLabel id="priceFilterType">Prix</InputLabel>
                                    <Select
                                        value={values.price}
                                        onChange={handleChange}
                                        name='price'
                                        label={"Prix"}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                        }}
                                        fullWidth
                                    >
                                        {priceList?.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>{touched.price && errors.price}</FormHelperText>
                                </FormControl>
                            </Box>
                            
                        </CmtCard>

                        <Box mt={4}>
                            <StepperLine
                                onCancel={cancel}
                                stepper={stepper}
                                step="Etape 4 : Prix Proposé"
                            />
                        </Box>
                    </CmtForm>
                )}
            </Formik>
        </Grid>
    );
};