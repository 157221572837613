import {
    REQUEST_FEEDBACK,
    REQUEST_FEEDBACK_ERROR,
    REQUEST_FEEDBACK_SUCCESS,
    REQUEST_SEND_FEEDBACK_MESSAGE_ERROR,
    REQUEST_SEND_FEEDBACK_MESSAGE_SUCCESS,
} from "redux/constants/feedbackConstants";
import auth from "services/Api/auth";
import feedback from "services/Api/feedback";
import { notConnectedAction } from "./authActions";

const requestFeedBack = () => ({
    type: REQUEST_FEEDBACK,
    loading: true,
});

const requestFeedBackSuccess = (data) => ({
    type: REQUEST_FEEDBACK_SUCCESS,
    loading: false,
    data: data,
});

const requestFeedBackError = (error) => ({
    type: REQUEST_FEEDBACK_ERROR,
    loading: false,
    error: error,
});

export const getFeedBackAction = () => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(requestFeedBack());

        const result = feedback.getConversation().then((result) => {
            if (result?.result) {
                dispatch(requestFeedBackSuccess(result?.data));
            } else {
                dispatch(requestFeedBackError(result?.error));
            }

            return result;
        });

        return result;
    };
};

/* --------------------------Send Message-------------------------- */

export const requestSendMessageSuccess = (message) => ({
    type: REQUEST_SEND_FEEDBACK_MESSAGE_SUCCESS,
    message: message,
    loading: false,
});

export const requestSendMessageError = (error) => ({
    type: REQUEST_SEND_FEEDBACK_MESSAGE_ERROR,
    loading: false,
    error: error,
});

export const sendMessageAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await feedback.createMessage(data);

        if (result.result) {
            dispatch(requestSendMessageSuccess(result?.data));
        } else {
            dispatch(requestSendMessageError(result.error));
        }
    };
};
