import { Box, Skeleton, Typography, useMediaQuery } from "@mui/material";
import getDocumentsIconsType from "Apps/Documents/utils/getDocumentsIconsType";
import CmtCard from "Components/CmtCard/CmtCard";
import CmtImage from "Components/CmtImage/CmtImage";
import { DOCUMENTS_PATH } from "Constant";
import history from "services/History/history";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { EditFolder } from "../Folder/EditFolder";
import { EditFile } from "../File/EditFile";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { MoveFileFolder } from "../MoveFileFolder/MoveFileFolder";
import { CmtContextualMenu } from "Components/CmtContextualMenu/CmtContextualMenu";
import { GridElem } from "Components/CmtTableElements/sc.TableElements";
import { GridViewContainer } from "../sc.DisplayDocuments";
import { useTheme } from "@emotion/react";

const DocumentsGridElem = ({ item, setFileFolderDetail, setOpenContextual, smBreakPoint }) => {
    let timer = 0;
    let delay = 200;
    let prevent = false;

    const onSingleClickHandler = (id) => {
        timer = setTimeout(() => {
            if (!prevent) {
                setFileFolderDetail(id);
            }
        }, delay);
    };

    const onDoubleClickHandler = (fileFolder) => {
        clearTimeout(timer);
        prevent = true;

        if (fileFolder?.type === "Dossier") {
            history.push(`${DOCUMENTS_PATH}/${fileFolder?.id}`);
        } else {
            window.open(fileFolder.documentFileName, "_blank");
        }

        setTimeout(() => {
            prevent = false;
        }, delay);
    };

    return (
        <>
            <GridElem
                width={smBreakPoint ? "50%" : 150}
                height={150}
                flexShrink={0}
                p={2}
                onClick={() => onSingleClickHandler(item?.id)}
                onDoubleClick={() => onDoubleClickHandler(item)}
                onContextMenu={(e) => {
                    if (item?.project) {
                        return;
                    }

                    e.preventDefault();

                    setOpenContextual({
                        open: true,
                        anchorEl: e.currentTarget,
                        positionX: e.pageX,
                        positionY: e.pageY,
                        item: item,
                    });
                }}
            >
                <CmtCard className="fullHeight">
                    <Box display="flex" justifyContent="center" p={2}>
                        <CmtImage
                            src={`/images/icons/${getDocumentsIconsType(
                                item?.project ? "ProjectFolder" : item?.type
                            )}`}
                            height={80}
                        />
                    </Box>
                    <Box pb={2} px={2} display="flex" justifyContent="center">
                        <Typography component="p" variant="body2">
                            {item?.name}
                        </Typography>
                    </Box>
                </CmtCard>
            </GridElem>
        </>
    );
};

export const DocumentsGrid = ({
    documentsData,
    handleDeleteFileFolder,
    moveFileFolder,
    updateFileFolder,
    setFileFolderDetail,
    createLoading,
    createFileFolder,
    path,
}) => {
    const [openEdit, setOpenEdit] = useState(null);
    const [openMoveFileFolder, setOpenMoveFileFolder] = useState(null);

    const theme = useTheme();
    const smBreakPoint = useMediaQuery(theme?.breakpoints.down("sm"));

    const [openContextual, setOpenContextual] = useState({
        open: false,
        anchorEl: null,
        positionY: 0,
        positionX: 0,
    });

    const loading = documentsData?.loading;

    return (
        <GridViewContainer>
            {documentsData.documentsList?.map((item, index) => (
                <DocumentsGridElem
                    item={item}
                    key={index}
                    smBreakPoint={smBreakPoint}
                    path={path}
                    setFileFolderDetail={setFileFolderDetail}
                    handleDeleteFileFolder={handleDeleteFileFolder}
                    updateFileFolder={updateFileFolder}
                    moveFileFolder={moveFileFolder}
                    createFileFolder={createFileFolder}
                    createLoading={createLoading}
                    setOpenContextual={setOpenContextual}
                />
            ))}

            {loading && [...Array(10)].map((_, index) => <DocumentSkeletonGrid key={index} />)}

            <CmtContextualMenu
                open={openContextual?.open}
                positionY={openContextual?.positionY}
                positionX={openContextual?.positionX}
                anchorEl={openContextual?.anchorEl}
                zIndex={100}
                onClose={() =>
                    setOpenContextual({
                        open: false,
                        positionY: 0,
                        positionX: 0,
                    })
                }
            >
                <Box
                    display="flex"
                    alignItems="center"
                    p={3}
                    className="pointer"
                    onClick={() => {
                        setOpenEdit(openContextual?.anchorEl);
                        setOpenContextual({ ...openContextual, open: false });
                    }}
                >
                    <EditIcon color="primary" />
                    <Box pl={3}>
                        <Typography component="p" variant="h5" className="fontWeight-500">
                            Modifier
                        </Typography>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    p={3}
                    className="pointer"
                    onClick={() => {
                        setOpenMoveFileFolder(openContextual?.anchorEl);
                        setOpenContextual({ ...openContextual, open: false });
                    }}
                >
                    <OpenWithIcon color="primary" />
                    <Box pl={3}>
                        <Typography component="p" variant="h5" className="fontWeight-500">
                            Déplacer
                        </Typography>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    p={3}
                    className="pointer"
                    onClick={() => {
                        handleDeleteFileFolder([openContextual?.item?.id]);
                        setOpenContextual({ ...openContextual, open: false });
                    }}
                >
                    <DeleteIcon color="primary" />
                    <Box pl={3}>
                        <Typography component="p" variant="h5" className="fontWeight-500">
                            Supprimer
                        </Typography>
                    </Box>
                </Box>
            </CmtContextualMenu>

            {Boolean(openEdit) && openContextual?.item?.isFolder && (
                <EditFolder
                    anchorEl={openEdit}
                    closePopover={() => setOpenEdit(null)}
                    folderData={openContextual.item}
                    updateFolder={updateFileFolder}
                />
            )}

            {Boolean(openEdit) && !openContextual?.item?.isFolder && (
                <EditFile
                    anchorEl={openEdit}
                    closePopover={() => setOpenEdit(null)}
                    fileData={openContextual?.item}
                    updateFile={updateFileFolder}
                />
            )}

            {Boolean(openMoveFileFolder) && (
                <MoveFileFolder
                    anchorEl={openMoveFileFolder}
                    closePopover={() => setOpenMoveFileFolder(null)}
                    path={path}
                    fileFolderList={[openContextual?.item?.id]}
                    loading={createLoading}
                    moveFileFolder={(newPath) => {
                        moveFileFolder([openContextual?.item?.id], newPath);
                    }}
                    createFolder={createFileFolder}
                />
            )}
        </GridViewContainer>
    );
};

const DocumentSkeletonGrid = () => {
    return (
        <GridElem width={150} height={150} flexShrink={0} p={2}>
            <CmtCard className="fullHeight">
                <Box display="flex" justifyContent="center" p={2}>
                    <Skeleton variant="rectangular" width={60} height={80} />
                </Box>
                <Box pb={2} px={2} display="flex" justifyContent="center">
                    <Skeleton variant="text" width="80%" />
                </Box>
            </CmtCard>
        </GridElem>
    );
};
