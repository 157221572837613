import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Input,
    InputLabel,
    Typography,
    useMediaQuery,
} from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { FieldArray, Formik } from "formik";
import { useState } from "react";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import moment from "moment";
import { REDIRECTION_TIME } from "Constant";
import { NotificationManager } from "react-notifications";
import EventIcon from "@mui/icons-material/Event";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddIcon from "@mui/icons-material/PostAdd";
import LabelIcon from "@mui/icons-material/Label";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AddIcon from "@mui/icons-material/Add";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { useRef } from "react";
import { LabelChip } from "../LabelChip";
import { NoBorderCheckbox, NoteImage } from "../sc.Notes";
import { CmtButtonDateTimePicker } from "Components/CmtDatePicker/CmtButtonDateTimePicker";
import { useTheme } from "@emotion/react";

export const CreateNote = ({
    submit,
    notesTypes,
    createLabel,
    openNewNoteForm,
    setOpenNewNoteForm,
}) => {
    const [labelAnchorEl, setLabelAnchorEl] = useState(null);
    const [dateTimeOpen, setDateTimeOpen] = useState(false);
    const labelRef = useRef(null);
    const reminderRef = useRef(null);

    const theme = useTheme();
    const smBreakPoint = useMediaQuery(theme?.breakpoints.down("sm"));

    const [newLabelValue, setNewLabelValue] = useState("");

    const checkError = (values) => {
        let errors = {};

        if (!values.title) {
            errors.title = "Veuillez renseigner un titre pour votre note";
        }

        values.todoList.forEach((item, index) => {
            if (!item?.description?.trim()) {
                errors = {
                    ...errors,
                    todoList: {
                        ...errors.todoList,
                        [index]: "Veuillez renseigner un nom pour votre tâche",
                    },
                };
            }
        });

        return errors;
    };

    const handleCreate = async (values) => {
        submit(values).then((result) => {
            if (result?.result) {
                NotificationManager.success(
                    "Votre note à été crée correctement",
                    "Succès",
                    REDIRECTION_TIME
                );

                setOpenNewNoteForm(false);
            } else {
                NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
            }
        });
    };

    const handleCreateLabel = async () => {
        createLabel(newLabelValue).then((result) => {
            if (result?.result) {
                setNewLabelValue("");
            }
        });
    };

    return (
        <>
            <Dialog
                open={openNewNoteForm}
                onClose={() => setOpenNewNoteForm(false)}
                maxWidth={"md"}
                fullWidth
            >
                <Formik
                    initialValues={{
                        title: "",
                        description: "",
                        imageFile: null,
                        notesTypes: [],
                        reminderDate: "",
                        todoList: [],
                    }}
                    validate={(values) => checkError(values)}
                    onSubmit={(values) => {
                        handleCreate(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldTouched,
                        setFieldValue,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <DialogTitle>
                                <FormInput
                                    name="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                    label="Titre"
                                    error={touched.title && errors.title}
                                />
                            </DialogTitle>

                            {values.imageFile && (
                                <Box display="flex" justifyContent="center">
                                    <Box position="relative" className="pointer" maxWidth="100%">
                                        <NoteImage src={URL.createObjectURL(values.imageFile)} />
                                        <Box position="absolute" right={24} top={24}>
                                            <DeleteIcon
                                                color="primary"
                                                onClick={() => setFieldValue("imageFile", null)}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                            <DialogContent>
                                <FormInput
                                    name="description"
                                    onChange={handleChange}
                                    multiline
                                    rows={3}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    label="Description"
                                />

                                <Box py={5} display="flex" flexWrap="wrap">
                                    {values.reminderDate && (
                                        <Box pr={2}>
                                            <Chip
                                                variant="outlined"
                                                icon={<EventIcon />}
                                                size="small"
                                                label={values.reminderDate}
                                                onClick={() =>
                                                    setDateTimeOpen(reminderRef?.current)
                                                }
                                            />
                                        </Box>
                                    )}

                                    {values.notesTypes.map((item, index) => (
                                        <Box pr={2} key={index}>
                                            <LabelChip
                                                type={notesTypes?.find((elem) => elem.id === item)}
                                                notesTypes={notesTypes}
                                                onClick={() => setLabelAnchorEl(labelRef?.current)}
                                            />
                                        </Box>
                                    ))}
                                </Box>

                                {values.todoList.length > 0 && (
                                    <FieldArray name="todoList">
                                        {({ remove, push }) => (
                                            <>
                                                <Typography
                                                    component="p"
                                                    variant="h5"
                                                    color="textSecondary"
                                                    fontSize={12}
                                                >
                                                    Tâches
                                                </Typography>
                                                {values?.todoList?.map((item, index) => (
                                                    <Box
                                                        key={index}
                                                        display="flex"
                                                        alignItems="flex-start"
                                                    >
                                                        <Box pt="4px">
                                                            <NoBorderCheckbox
                                                                checked={item?.isDone}
                                                                onChange={(e) =>
                                                                    setFieldValue(
                                                                        `todoList.${index}.isDone`,
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                        <FormInput
                                                            name={`todoList.${index}.description`}
                                                            margin="none"
                                                            value={item?.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            InputProps={{ disableUnderline: true }}
                                                            placeholder="Ajouter une tâche"
                                                            error={
                                                                touched.todoList &&
                                                                touched.todoList[index] &&
                                                                errors.todoList &&
                                                                errors.todoList[index]
                                                            }
                                                        />
                                                        <Box
                                                            pl={2}
                                                            pb={1}
                                                            pt="2px"
                                                            className="pointer"
                                                        >
                                                            <DeleteIcon
                                                                color="primary"
                                                                onClick={() => remove(index)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                ))}
                                                <Box pt={2} pl={8}>
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => {
                                                            push({ title: "", isDone: false });
                                                        }}
                                                    >
                                                        <PostAddIcon />
                                                        <Typography component="p" variant="h6">
                                                            Ajouter une tâche
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </>
                                        )}
                                    </FieldArray>
                                )}
                            </DialogContent>

                            <DialogActions>
                                <Box
                                    pt={2}
                                    pb={4}
                                    px={4}
                                    display="flex"
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems={!smBreakPoint && "center"}
                                    flexDirection={smBreakPoint && "column"}
                                    flexWrap="wrap"
                                >
                                    <Box display="flex">
                                        <IconButton
                                            color="primary"
                                            ref={reminderRef}
                                            onClick={(e) => setDateTimeOpen(e.currentTarget)}
                                        >
                                            <AddAlertIcon />
                                        </IconButton>

                                        <InputLabel htmlFor="uploadImageNote">
                                            <Input
                                                accept="image/*"
                                                id="uploadImageNote"
                                                type="file"
                                                className="hideElement"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.files[0].type.split("/")[0] ===
                                                        "image"
                                                    ) {
                                                        setFieldValue(
                                                            "imageFile",
                                                            e.target.files[0]
                                                        );
                                                    }
                                                }}
                                            />
                                            <IconButton color="primary" component="span">
                                                <ImageIcon />
                                            </IconButton>
                                        </InputLabel>

                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                if (values.todoList.length === 0) {
                                                    setFieldValue("todoList", [
                                                        { description: "", isDone: false },
                                                    ]);
                                                } else {
                                                    const joinedValue = values?.todoList
                                                        .map((todo) => todo.description)
                                                        .join("");

                                                    if (!joinedValue) {
                                                        setFieldValue("todoList", []);
                                                    }
                                                }
                                            }}
                                        >
                                            <PlaylistAddCheckIcon />
                                        </IconButton>

                                        <IconButton
                                            color="primary"
                                            ref={labelRef}
                                            onClick={(e) => {
                                                setLabelAnchorEl(e.currentTarget);
                                            }}
                                        >
                                            <LabelIcon />
                                        </IconButton>
                                    </Box>

                                    <Box
                                        display="flex"
                                        pl={2}
                                        width={smBreakPoint ? "100%" : "fit-content"}
                                        justifyContent={smBreakPoint && "space-between"}
                                        alignItems="center"
                                    >
                                        <Button
                                            color="secondary"
                                            type="button"
                                            onClick={() => setOpenNewNoteForm(false)}
                                            variant="outlined"
                                            sx={{ mr: 2 }}
                                        >
                                            Annuler
                                        </Button>
                                        <Button color="primary" type="submit" variant="contained">
                                            Créer
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogActions>

                            <CmtPopover
                                anchorEl={labelAnchorEl}
                                closePopover={() => setLabelAnchorEl(null)}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <Box p={5} pl={3}>
                                    {notesTypes?.map((item, index) => (
                                        <Box display="flex" alignItems="center" key={index}>
                                            <Checkbox
                                                size="small"
                                                checked={values?.notesTypes.includes(item?.id)}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "notesTypes",
                                                        e.target.checked
                                                            ? values.notesTypes.concat(item?.id)
                                                            : values.notesTypes.filter(
                                                                  (typesId) => typesId !== item?.id
                                                              )
                                                    );
                                                }}
                                            />
                                            <Typography component="p" variant="h5">
                                                {item?.name}
                                            </Typography>
                                        </Box>
                                    ))}
                                    <Box display="flex" alignItems="flex-end" pl={2}>
                                        <FormInput
                                            size="small"
                                            value={newLabelValue}
                                            onChange={(e) => setNewLabelValue(e.target.value)}
                                            label="Ajouter un nouveau label"
                                        />
                                        <Box pb={2}>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={handleCreateLabel}
                                                disabled={!newLabelValue}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </CmtPopover>
                            <CmtButtonDateTimePicker
                                disablePast
                                value={values.reminderDate}
                                setValue={(value) =>
                                    setFieldValue(
                                        "reminderDate",
                                        value ? moment(value).format("YYYY-MM-DD HH:mm") : ""
                                    )
                                }
                                onTouched={setFieldTouched}
                                name="toDate"
                                open={dateTimeOpen}
                                setOpen={setDateTimeOpen}
                                error={touched.toDate && errors.toDate}
                            />
                        </CmtForm>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};
