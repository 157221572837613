import { Box, Hidden, Typography } from "@mui/material";
import { AvatarComponent }  from "Components/AvatarComponent/AvatarComponent";
import { getColorStatus } from "Apps/Chat/getColorStatus";
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@emotion/react';

export default function ContentHeader({user, setIsCollapse, profileId}) {
    const theme = useTheme();


    return (
        <Box
            py={3}
            width='100%'
            borderBottom={`1px solid ${theme.palette.borderColor.dark}`}
            display='flex'
            alignItems='center'
            pl='1em'
        >
            <AvatarComponent src={user?.profileFileName} size={50} />
            <Box ml='1em' >
                <Typography component="p" variant='h4' >{user.firstName} {user.lastName}</Typography>
                <Box display='flex' alignItems='center'>
                    <Box
                        borderRadius='50%'
                        width={10}
                        height={10}
                        mr='0.5em'
                        backgroundColor={getColorStatus(profileId === user.id || user.mercureId)}
                    />
                    <Typography component="span" fontSize='0.7em' variant='subtitle2' color='textSecondary'>{profileId === user.id || user.mercureId ? "Connecté" : "Déconnecté"}</Typography>
                </Box>
            </Box>
            <Hidden smUp>
                <Box ml='auto' mr={5} onClick={(e) => e.stopPropagation()}>
                    <MenuIcon color='primary' onClick={() => setIsCollapse(false)}/>
                </Box>
            </Hidden>
        </Box>
    );
};