import { connect } from "react-redux"
import { signUpAction } from "redux/actions/authActions"
import {authSelector} from 'redux/selectors/authSelector';
import AuthWrapper from '../AuthWrapper/AuthWrapper.jsx';
import SignUpForm from './SignUpForm.jsx';
import { useEffect, useState } from "react";
import history from 'services/History/history';
import { deleteErrorAction } from "../../../redux/actions/authActions.js";
import CmtLoading from "Components/CmtLoading/CmtLoading.jsx";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { REDIRECTION_TIME, SIGNIN_PATH } from "Constant.js";
import { HOME_PATH } from 'Constant';

export const SignUpApp = ({auth, signUp, onLoadingPage, children}) => {
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (auth.error) {
            onLoadingPage();
        } else if (auth.connected) {
            history.push(HOME_PATH);
        }
    });

    const handleSubmit = async (data) => {
        if (submit) {
            return;
        }
        
        setSubmit(true);
        
        const result = signUp(data).then(result => {
            setSubmit(false);

            if (result.result) {
                NotificationManager.success('Votre compte à bien été crée, veuillez vérifier vos emails', 'Succès', REDIRECTION_TIME);

                history.push(SIGNIN_PATH);
            } else {
                NotificationManager.error(result?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);
            }

            return result;
        })
        
        return result;
    };

    return (
        <AuthWrapper>
            {auth?.loading && <CmtLoading/>}
            <SignUpForm onRegister={handleSubmit} />
            {children}
        </AuthWrapper>
    );
};

export const SignUpAppStore = connect(
    (state) => ({
        auth: authSelector(state),
    }),
    (dispatch) => ({
        signUp: infos => dispatch(signUpAction(infos)),
        onLoadingPage: () => dispatch(deleteErrorAction()),
    })
) (SignUpApp);