import { Box, Hidden } from "@mui/material";
import { ProjectDateOption } from "./ProjectDateOption";
import { ANNOUNCEMENT_SORT_TABLE } from "Constant";
import { CategoriesFilters } from "./CategoriesFilters";
import { LocationFilters } from "Components/CmtFilters/LocationFilters";
import { SortingFilters } from "Components/CmtFilters/SortingFilters";

export const ProjectFilters = ({
    categoriesList,
    filters,
    changeFilters,
    locationMode,
    setLocationMode,
}) => {
    return (
        <>
            <CategoriesFilters
                filters={filters}
                categoriesList={categoriesList}
                changeFilters={changeFilters}
            />

            <LocationFilters
                filters={filters}
                changeFilters={changeFilters}
                locationMode={locationMode}
                setLocationMode={setLocationMode}
            />

            <ProjectDateOption filters={filters} changeFilters={changeFilters} />

            <Hidden mdDown>
                <Box ml="auto" display="flex" alignItems="center">
                    <SortingFilters
                        filters={filters}
                        changeFilters={changeFilters}
                        sortingList={ANNOUNCEMENT_SORT_TABLE}
                    />
                </Box>
            </Hidden>
        </>
    );
};
