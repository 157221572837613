import { Box, Button, CardContent, Checkbox, Hidden, ListSubheader, MenuItem, Select } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import CakeIcon from '@mui/icons-material/Cake';
import WorkIcon from '@mui/icons-material/Work';
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import { CmtDatePicker } from "Components/CmtDatePicker/CmtDatePicker";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { IconView, TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";

export default function EditionInfosCard ({companyData, submit, companiesTypes, setEditionMode}) {
    return (
        <CmtCard>
            <TileTitle>Identité</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{
                        creationDate: companyData?.creationDate ? moment(companyData?.creationDate) : null,
                        compagniesTypes: companyData?.compagniesTypes || []
                    }}
                    onSubmit={(values) => {
                        submit({...values});
                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }} width='100%'>
                                <IconView>
                                    <CakeIcon />
                                </IconView>
                                <Box ml={5} width='100%'>
                                    <Box component="span" fontSize={12} color="primary" >Date de création</Box>
                                    <CmtDatePicker 
                                        disableFuture
                                        openToYear
                                        fullWidth
                                        value={values.creationDate}
                                        setValue={(value) => setFieldValue('creationDate', value)}
                                        onTouched={setFieldTouched}
                                        name="creationDate"
                                        error={touched.creationDate && errors.creationDate}
                                    />
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }} overflow='hidden'>
                                <IconView>
                                    <WorkIcon />
                                </IconView>
                                <Box ml={5} width='100%'>
                                    <Box component="span" fontSize={12} color="primary">Secteur d'activité</Box>
                                    <Select
                                        fullWidth
                                        labelId="LabelPositionType"
                                        variant='standard'
                                        value={values.compagniesTypes}
                                        name="compagniesTypes"
                                        onChange={handleChange}
                                        error={touched.compagniesTypes && Boolean(errors.compagniesTypes)}
                                        onBlur={handleBlur}
                                        renderValue={() => {
                                            const result = values?.compagniesTypes?.map(element => element?.name).join(', ');

                                            return result;
                                        }}
                                        id='roleSelect'
                                        multiple
                                    >
                                        {companiesTypes?.map((item, index) => ([
                                            <ListSubheader color='primary' key={`positionGroup ${index}`} >{item.name}</ListSubheader>,
                                            item.values.map((it, ind) => (
                                                <MenuItem value={it} key={`positionType ${ind}`} id={`positionType${ind}`}>
                                                    <Checkbox checked={Boolean(values?.compagniesTypes?.find(element => element.id === it.id))} />
                                                    {it.name}
                                                </MenuItem>
                                            ))
                                        ]))}
                                    </Select>
                                </Box>
                            </Box>
                            <Box mt={10} width='100%' display='flex' justifyContent='flex-end' height={30}>
                                <Box mr={2}>
                                    <Button type='button' onClick={() => setEditionMode(false)} variant='contained' color='secondary' >
                                        <Hidden xsDown> Annuler </Hidden>
                                        <Hidden smUp> <CloseIcon /> </Hidden>
                                    </Button>
                                </Box>

                                <Box ml={2}>
                                    <Button type='submit' variant='contained' color='primary'  id='submitInfosCard'>
                                        <Hidden xsDown> Valider </Hidden>
                                        <Hidden smUp> <CheckIcon /> </Hidden>
                                    </Button>
                                </Box>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    );
};