import { Box, Button, Typography, alpha, Hidden } from "@mui/material";
import FormInput from '../../../Components/CmtFormInput/CmtFormInput.jsx';
import CmtImage from "Components/CmtImage/CmtImage.jsx";
import CmtForm from "Components/CmtForm/CmtForm.jsx";
import { Formik } from "formik";
import { SIGNIN_PATH } from "Constant.js";
import { NavLink } from "react-router-dom";
import { useTheme } from "@emotion/react";



export default function ForgetPasswordForm ({onForget}) {
    const theme = useTheme();

    const checkError = (values) => {
        let errors = {};

        if (!values.email) {
            errors.email = 'Veuillez renseigner votre adresse email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Addresse email invalide';
        }

        return errors;
    };

    return (
        <>
            <Box p={8} width='100%' onClick={(e) => e.stopPropagation()}>
                <Box mb={7}>
                    <CmtImage src={'/images/logo.svg'} height={50} />
                </Box>
                <Typography component="div" variant="h1" color='primary'>Mot de passe oublié</Typography>
                <Typography component="p" pt={2}>Veuillez saisir votre adresse email. Un lien unique vous sera envoyé pour réinitialiser votre mot de passe.</Typography>
                <Formik
                    initialValues={{email: '', password: ''}}
                    validate={values => checkError(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        onForget(values);
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        isSubmitting,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <FormInput 
                                name="email" 
                                label="Email" 
                                id="forgetEmail"
                                value={values.email}
                                variant='standard'
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                error={touched.email && errors.email}
                            />
                            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={10} mb={5}>
                                <Button id='forgetButton' type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                    <Typography component='p'>Envoyer</Typography>
                                </Button>
                                <NavLink to={SIGNIN_PATH} >
                                    <Typography component='p'>Annuler</Typography>
                                </NavLink>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </Box>
            <Hidden smDown>
                <Box
                    p={5}
                    width='50%'
                    flexShrink={0}
                    backgroundColor={alpha(theme.palette.primary.main, 0.12)}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <CmtImage src="/images/Auth/forgot-img.png" />
                </Box>
            </Hidden>
        </>
    );
};


