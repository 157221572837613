import { Box, Chip, Hidden, Typography, useTheme } from "@mui/material";
import { DOCUMENTS_SORT_TABLE, GRID_MODE, LIST_MODE } from "Constant";
import ListIcon from "@mui/icons-material/List";
import AppsIcon from "@mui/icons-material/Apps";
import { DocumentsOptions } from "./DocumentsOptions";
import auth from "services/Api/auth";
import { useDispatch } from "react-redux";
import { notConnectedAction } from "redux/actions/authActions";
import { useEffect, useState } from "react";
import documents from "services/Api/documents";
import { useCallback } from "react";
import { SortingFilters } from "Components/CmtFilters/SortingFilters";

export const DocumentsFilters = ({
    filters,
    changeFilters,
    resultNumber,
    displayMode,
    setDisplayMode,
}) => {
    const theme = useTheme();

    const dispatch = useDispatch();

    const [categoriesList, setCategoriesList] = useState(null);

    const fetchData = useCallback(async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());
        }

        documents.getMimesTypes().then((result) => {
            if (result?.result) {
                setCategoriesList(result?.data);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const DisplayModeChip = () => {
        return (
            <Chip
                label={
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box component="div" px={1}>
                            <ListIcon
                                color={displayMode === LIST_MODE ? "primary" : "secondary"}
                                fontSize="medium"
                                onClick={() => setDisplayMode(LIST_MODE)}
                                className="pointer"
                            />
                        </Box>
                        <Box component="div" px={1}>
                            <AppsIcon
                                color={displayMode === GRID_MODE ? "primary" : "secondary"}
                                fontSize="medium"
                                onClick={() => setDisplayMode(GRID_MODE)}
                                className="pointer"
                            />
                        </Box>
                    </Box>
                }
            />
        );
    };

    return (
        <>
            <Hidden mdDown>
                <Box display="flex" alignItems="center" height={70} pb={3}>
                    <Box
                        my={3}
                        pr={3}
                        ml={0}
                        borderRight={`1px solid ${theme.palette.borderColor.dark}`}
                    >
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                    </Box>

                    <Box m={3} flexGrow={1} display="flex" justifyContent="flex-start">
                        <DocumentsOptions
                            filters={filters}
                            changeFilters={changeFilters}
                            categoriesList={categoriesList}
                            displayMode={displayMode}
                        />
                        {displayMode === GRID_MODE && (
                            <Box display="flex" alignItems="center" ml="auto">
                                <SortingFilters
                                    filters={filters}
                                    changeFilters={changeFilters}
                                    sortingList={DOCUMENTS_SORT_TABLE}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box
                        my={3}
                        pl={3}
                        mr={0}
                        borderLeft={`1px solid ${theme.palette.borderColor.dark}`}
                    >
                        <DisplayModeChip />
                    </Box>
                </Box>
            </Hidden>

            <Hidden mdUp>
                <Box display="flex" flexDirection="column">
                    <Box p={3} pl={0} display="flex" justifyContent="space-between">
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                    </Box>

                    <Box
                        p={3}
                        pl={0}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <Typography component="p" variant="h5">
                            Filtrer par
                        </Typography>
                        <Box mt={1} display="flex" flexWrap="wrap">
                            <DocumentsOptions
                                filters={filters}
                                changeFilters={changeFilters}
                                categoriesList={categoriesList}
                            />
                        </Box>
                    </Box>

                    <Box
                        p={3}
                        pl={0}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <Typography component="p" variant="h5" mb={1}>
                            Trier par
                        </Typography>
                        <SortingFilters
                            filters={filters}
                            changeFilters={changeFilters}
                            sortingList={DOCUMENTS_SORT_TABLE}
                        />
                    </Box>
                </Box>
            </Hidden>
        </>
    );
};
