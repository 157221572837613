import { Box, Button, CardContent, FormHelperText, Hidden } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CmtEditor from "Components/CmtEditor/CmtEditor";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { ProjectEditor } from "../../../sc.CreateProject";

export default function EditionProjectDescriptionCard({ projectData, submit, setEditionMode }) {
    const checkError = (values) => {
        const errors = {};

        if (!values.description.replace(/<[^>]+>/g, "").replace(/&nbsp/g, "")) {
            errors.description = "Veuillez ajouter une description à votre projet";
        }

        return errors;
    };

    return (
        <CmtCard>
            <TileTitle>Déscription</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{
                        description: projectData.description || "",
                    }}
                    validate={(values) => checkError(values)}
                    onSubmit={(values) => {
                        submit(values);

                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        setFieldTouched,
                        setFieldValue,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <ProjectEditor>
                                <CmtEditor
                                    labelId="description"
                                    value={values.description}
                                    onBlur={() => setFieldTouched("description", true, false)}
                                    onChange={(val) => {
                                        setFieldValue("description", val);
                                    }}
                                    id="descriptionEditor"
                                />
                                <FormHelperText error>
                                    {touched.description && errors.description}
                                </FormHelperText>
                            </ProjectEditor>
                            <Box display="flex" justifyContent="flex-end" height={30} mt={5}>
                                <Box mr={2}>
                                    <Button
                                        type="button"
                                        onClick={() => setEditionMode(false)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <Hidden xsDown> Annuler </Hidden>
                                        <Hidden smUp>
                                            <CloseIcon />
                                        </Hidden>
                                    </Button>
                                </Box>

                                <Box ml={2}>
                                    <Button
                                        type="submit"
                                        id="submitDescriptionCard"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <Hidden xsDown> Valider </Hidden>
                                        <Hidden smUp>
                                            <CheckIcon />
                                        </Hidden>
                                    </Button>
                                </Box>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    );
}
