import { Box, Button, CardContent, Grid, Hidden, MenuItem, Select, FormHelperText } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { FieldArray, Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TileTitle, DeleteIconButton, IconView } from "Components/CmtProfileContainer/sc.ProfileContainer";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import CmtImage from "Components/CmtImage/CmtImage";
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';

export default function EditionContact ({companyData, submit, setEditionMode, socialType}) {
    const checkError = (values) => {
        const errors = {};

        if (values.contactEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.contactEmail)) {
            errors.contactEmail = 'Adresse email invalide';
        }

        if (values.phoneNumber && !/^0[1-9][0-9]{8}/i.test(values.phoneNumber)) {
            errors.phoneNumber = 'Numéro de téléphone incorrect';
        }

        if (values.website && !/^(www|http:|https:)+[^\s]+[\w]/i.test(values.website)) {
            errors.website = 'Veuillez renseigner une url valide (www. / http / https)';
        }

        values.socials.forEach((item, index) => {
            let error = null;

            if (item.typeId === 0) {
                error = {
                    ...error,
                    id: 'Veuillez indiquer le type de média',
                };
            }

            if (!item.value) {
                error = {
                    ...error,
                    value: 'Veuillez renseigner un lien vers votre réseau social',
                };
            } else if (!/^(www|http:|https:)+[^\s]+[\w]/i.test(item.value)) {
                error = {
                    ...error,
                    value: 'Veuillez renseigner une url valide (www. / http / https)',
                };
            }

            if (error) {
                errors.socials = {...errors.socials, [index]: error};
            }
        });

        return errors;
    };

    const getSocialList = () => {
        let list = [];

        companyData?.socials?.forEach((item) => {
            list.push({
                typeId: socialType.find((it) => it.name === item.name).id,
                iconPath: item.iconPath,
                value: item.value,
            });
        });

        return list;
    };

    if (!socialType) {
        return (<></>);
    }

    return (
        <CmtCard>
            <TileTitle>Contact</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{
                        contactEmail: companyData?.contactEmail || '',
                        phoneNumber: companyData?.phoneNumber || '',
                        website: companyData?.website || '',
                        socials: getSocialList(),
                    }}
                    validate={values => checkError(values)}
                    onSubmit={(values) => {
                        submit(values);
                        setEditionMode(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid item lg={4} xs={12} mb={{ xs: 4, sm: 6 }} display='flex' alignItems='center' my={5}>
                                    <IconView mr={5} mt={4}>
                                        <EmailIcon />
                                    </IconView>
                                    <FormInput
                                        type="email"
                                        label="Email de contact"
                                        name="contactEmail"
                                        id='contactEmail'
                                        placeholder='monNom@monsite.com'
                                        fullWidth={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.contactEmail} 
                                        error={touched.contactEmail && errors.contactEmail}
                                    />
                                </Grid>

                                <Grid item lg={4} xs={12} mb={{ xs: 4, sm: 6 }} display='flex' alignItems='center' my={5}>
                                    <IconView mr={5} mt={4}>
                                        <PhoneIcon />
                                    </IconView>
                                    <FormInput
                                        type="tel"
                                        label="Numéro de téléphone"
                                        name="phoneNumber"
                                        id='contactPhoneNumber'
                                        placeholder='0123456789'
                                        fullWidth={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phoneNumber} 
                                        error={touched.phoneNumber && errors.phoneNumber}
                                    />
                                </Grid>

                                <Grid item lg={4} xs={12} mb={{ xs: 4, sm: 6 }} display='flex' alignItems='center' my={5}>
                                    <IconView mr={5} mt={4}>
                                        <LanguageIcon />
                                    </IconView>
                                    <FormInput
                                        type="text"
                                        label="Site web"
                                        name="website"
                                        id='contactWebsite'
                                        placeholder="https://monsite.com"
                                        fullWidth={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.website} 
                                        error={touched.website && errors.website}
                                    />
                                </Grid>

                                <FieldArray name='socials'>
                                    {({ remove, push }) => (
                                        <>
                                            {values.socials.length > 0 && values.socials.map((item, index) => (
                                                <Grid
                                                    item
                                                    lg={6} xs={12} mb={{ xs: 4, sm: 6 }}
                                                    display='flex' flexWrap='nowrap'
                                                    justifyContent='center'
                                                    alignItems='flex-start'
                                                    my={5}
                                                    key={`mediaLine ${index}`}
                                                >
                                                    <Box key={`mediaSelect ${index}`} width={150}>
                                                        <Select
                                                            labelId="LabelPositionType"
                                                            value={item.typeId}
                                                            name={`socials.${index}.typeId`}
                                                            variant='standard'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            error={
                                                                touched.socials && touched.socials[index]?.typeId &&
                                                                errors.socials && errors.socials[index]?.typeId
                                                            }
                                                            
                                                        >
                                                            <MenuItem disabled value={0}>Type de média</MenuItem>
                                                            {socialType.map((it, ind) => (
                                                                <MenuItem value={it.id} key={`socialType ${ind}`} >
                                                                    <CmtImage src={it.iconPath} width={15} height={15}/>
                                                                    <Box ml={2} component='span'>
                                                                        {it.name}
                                                                    </Box>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText error>
                                                            {
                                                                touched.socials && touched.socials[index]?.id &&
                                                                errors.socials && errors.socials[index]?.id
                                                            }
                                                        </FormHelperText>
                                                    </Box>
                                                    <Box width='50%' mx={5}>
                                                        <FormInput
                                                            type="text"
                                                            name={`socials.${index}.value`}
                                                            key={`mediaLink ${index}`}
                                                            margin='none'
                                                            placeholder="Lien"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={item.value}
                                                            fullWidth
                                                            error={
                                                                touched.socials && touched.socials[index]?.value &&
                                                                errors.socials && errors.socials[index]?.value
                                                            }
                                                            
                                                        />
                                                    </Box>
                                                    <DeleteIconButton key={`mediaDeleteButton ${index}`} onClick={() => remove(index)}><CloseIcon/></DeleteIconButton>
                                                </Grid>
                                            ))}
                                            <Grid item container sm={12} xs={12} mb={{ xs: 4, sm: 6 }} display='flex' justifyContent='flex-end' alignItems='center' my={8}>
                                                <Button onClick={() => push({id: 0, value: ''})} color='primary'><AddIcon/> Ajouter un réseau social</Button>
                                            </Grid>
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>

                            <Box width='100%' display='flex' justifyContent='flex-end' height={30}>
                                <Box mr={2}>
                                    <Button type='button' onClick={() => setEditionMode(false)} variant='contained' color='secondary'>
                                        <Hidden xsDown> Annuler </Hidden>
                                        <Hidden smUp> <CloseIcon /> </Hidden>
                                    </Button>
                                </Box>

                                <Box ml={2}>
                                    <Button type='submit' color='primary' variant='contained' id='contactSubmitButton'>
                                        <Hidden xsDown> Valider </Hidden>
                                        <Hidden smUp> <CheckIcon /> </Hidden>
                                    </Button>
                                </Box>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    );
};