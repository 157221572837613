import { Box, Typography, useTheme } from "@mui/material";
import { SwiperSlide } from 'swiper/react';
import CmtCard from "Components/CmtCard/CmtCard";
import { ANNOUNCEMENTS_PATH } from "Constant";
import 'swiper/swiper.scss';
import './navigation.scss';
import { ImageSearchResult, ResultSlider } from "../sc.ConnectedContainer";

export const DisplayAnnouncementResult = ({results, handleClick}) => {
    const theme = useTheme();

    return (
        <Box pt={5}>
            <Box pl={11}>
                <Typography component='p' variant='h4' color='whitesmoke'> Annonces </Typography>
            </Box>
            <ResultSlider navigation={true} spaceBetween={10} slidesPerView={'auto'} pagination={true}>
                {results?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Box width={200} className='pointer' onClick={() => handleClick(`${ANNOUNCEMENTS_PATH}/${item?.id}`)}>
                            <CmtCard>
                                <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                    <ImageSearchResult  src={item.announcementPhotos[0]?.photoFileName} />
                                    <Box width='100%' p={2} pl={2} borderTop={`1px solid ${theme.palette.borderColor.dark}`}>
                                        <Typography component='p' variant='subtitle1'>{item?.title}</Typography>
                                    </Box>
                                </Box>
                            </CmtCard>
                        </Box>
                    </SwiperSlide>
                ))}
            </ResultSlider>
        </Box>
    );
};