import { Box, Chip, Hidden, Typography, useTheme } from "@mui/material";
import { CategoriesFilters } from "Components/CmtFilters/CategoriesFilters";
import { SortingFilters } from "Components/CmtFilters/SortingFilters";
import { NOTES_SORT_TABLE } from "Constant";

export const NotesOptions = ({ resultNumber, filters, changeFilters, categoriesList }) => {
    const theme = useTheme();

    return (
        <>
            <Hidden mdDown>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box
                        my={3}
                        ml={0}
                        pr={3}
                        borderRight={`1px solid ${theme.palette.borderColor.dark}`}
                    >
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                    </Box>

                    <Box
                        pl={2}
                        flexGrow={1}
                        display="flex"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <CategoriesFilters
                            filters={filters}
                            categoriesList={categoriesList}
                            changeFilters={changeFilters}
                        />

                        <SortingFilters
                            filters={filters}
                            changeFilters={changeFilters}
                            sortingList={NOTES_SORT_TABLE}
                        />
                    </Box>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box display="flex" flexDirection="column" flexWrap="wrap">
                    <Box p={3} pl={0} className="fullWidth">
                        <Chip label={`${resultNumber} résultat${resultNumber > 1 ? "s" : ""}`} />
                    </Box>

                    <Box p={3} pl={0} flexGrow={1} display="flex" flexDirection="column">
                        <Typography component="p" variant="h5">
                            Filtrer par
                        </Typography>
                        <Box mt={1} display="flex" flexWrap="wrap" justifyContent="space-between">
                            <CategoriesFilters
                                filters={filters}
                                categoriesList={categoriesList}
                                changeFilters={changeFilters}
                            />
                        </Box>
                    </Box>

                    <Box
                        p={3}
                        pl={0}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <Typography component="p" variant="h5" mb={3}>
                            Trier par
                        </Typography>
                        <SortingFilters
                            filters={filters}
                            changeFilters={changeFilters}
                            sortingList={NOTES_SORT_TABLE}
                        />
                    </Box>
                </Box>
            </Hidden>
        </>
    );
};
