import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Step1 } from "./CreationStep/Step1/Step1";
import { Step2 } from "./CreationStep/Step2/Step2";
import { Step3 } from "./CreationStep/Step3/Step3";
import { Step4 } from "./CreationStep/Step4/Step4";
import { Step5 } from "./CreationStep/Step5/Step5";
import { Step6 } from "./CreationStep/Step6/Step6";
import { Step7 } from "./CreationStep/Step7/Step7";
import auth from "services/Api/auth";
import { announcements } from "services/Api/announcements";
import history from "services/History/history";
import { ANNOUNCEMENTS_PATH, REDIRECTION_TIME } from "Constant";
import { NotificationManager } from "react-notifications";
import { ProgessBar } from "./sc.ProcessAnnouncement";

const components = [
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
];

export const EditAnnouncementsApp = ({announcementId, profile, cancel, submit, disconnect}) => {
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState(null);

    const SpecificStep = components[step];

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                disconnect();

                return;
            }

            const result = await announcements.getOneAnnouncements(announcementId);

            if (result.result) {
                setFormData({
                    title: result?.data?.title,
                    announcementType: result?.data?.announcementType?.id,
                    creatorEntity: result?.data?.createdAs?.id,
                    fromDate: result?.data?.fromDate,
                    toDate: result?.data?.toDate,
                    description: result?.data?.content,
                    price: result?.data?.priceRange,
                    fullAddress: result?.data?.fullAddress,
                    longitude: result?.data?.lng,
                    latitude: result?.data?.lat,
                    pictures: result?.data?.announcementPhotos,
                    email: result?.data?.email,
                    phoneNumber: result?.data?.phone,
                });
            } else {
                NotificationManager.error('Une erreurs est survenu', 'ERREUR', REDIRECTION_TIME);

                history.push(ANNOUNCEMENTS_PATH);
            }
        };

        fetchData();
    }, [announcementId, disconnect]);


    const Stepper = () => {
        return (
            <ProgessBar
                variant="progress"
                steps={7}
                activeStep={step}
            />
        );
    }

    if (!formData) {
        return (<></>);
    }

    return (
        <Box display='flex' alignItems='center' justifyContent='center' className='fullHeight'>
            <SpecificStep 
                profile={profile}
                data={formData}
                stepper={Stepper}
                cancel={() => {
                    if (step > 0) {
                        setStep(step - 1);
                    } else {
                        cancel();
                    }
                }}
                submit={(values) => {
                    const data = {...formData, ...values};
                    
                    if (step < 6) {
                        setStep(step + 1);
                    } else {
                        submit(data);
                    }
                    
                    setFormData(data);
                }}
            />
        </Box>
    );
};