import { Box, Typography } from "@mui/material";

export const ProjectItemHeader = ({title}) => {
    return (
        <Box display='flex' flexDirection='column' position='relative' zIndex={1} p={6}>
            {title && (
                <Typography> {title} </Typography>
            )}
        </Box>
    );
};