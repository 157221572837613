import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

export default function DisplayModeInfos({ companyData, isIntermittent, setEditionMode, isAdmin }) {
    const [hover, setHover] = useState(false);

    return (
        <Box height="80px" display="flex" alignItems="center">
            <Box
                display="flex"
                position="relative"
                pr={10}
                alignItems="center"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => {
                    if (isAdmin) {
                        setEditionMode(true);
                    }
                }}
            >
                <Typography component="p" variant="h1" color="white">
                    {companyData.name}
                </Typography>
                {hover && !isIntermittent && isAdmin && (
                    <Box
                        position="absolute"
                        right={0}
                        ml={5}
                        component="span"
                        height="100%"
                        color="white"
                        className="pointer"
                    >
                        <CreateOutlinedIcon />
                    </Box>
                )}
            </Box>
        </Box>
    );
}
