import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import moment from 'moment';
import { AvatarComponent }  from "Components/AvatarComponent/AvatarComponent";
import linkify from 'services/Linkify/linkify';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { DisplayFile } from "../DisplayFile/DisplayFile";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { motion } from 'framer-motion';
import { useEffect } from "react";
import { useTheme } from '@emotion/react';


export const Message = ({
    message,
    lastMessage,
    userPic,
    profileId,
    deleteMessage,
    readedMessage,
    popoverVersion,
}) => {
    const theme = useTheme();

    const [hover, setHover] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(null);

    const [media, setMedia] = useState(null);

    const [unreadMessage, setUnreadMessage] = useState(lastMessage?.id === message?.id && !lastMessage?.messageRead);

    const handleOpenMedia = (path, type) => {
        setMedia({
            src: path,
            type: type
        });
    };

    const onShowMoreOptions = event => {
        setShowMoreOptions(event.currentTarget);
    };

    const onHideMoreOptions = () => {
        setShowMoreOptions(null);
    };

    const handleDeleteMessage = () => {
        deleteMessage({
            messageId: message.id,
        });

        onHideMoreOptions();
    };

    useEffect(() => {
        setTimeout(() => {
            setUnreadMessage(false);
            
            readedMessage();
        }, 3000);
    }, [readedMessage]);

    return (
        <motion.div
            exit={{
                x: profileId === message?.userFrom?.id ? '160vw' : '-60vw',
                scale: [1, 0],
                transition: { duration: 0.5 },
                backgroundColor: "rgba(255,0,0,1)",
            }}
            key={message.id}
        >
            <Box
                width='100%'
                display='flex'
                overflow='hidden'
                flexDirection='column'
                my='1em'
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    width='fit-content'
                    maxWidth='100%'
                    height='fit-content'
                    ml={profileId === message?.userTo?.id ? '2em' : 'auto'}
                    mr='2em'
                >
                    { message?.userTo === profileId && <AvatarComponent src={userPic} size={40}/>}
                    { message?.messageText && message?.messageText !== 'undefined' &&
                        <Box
                            color={profileId === message?.userTo?.id && theme.palette.text.white}
                            backgroundColor={
                                profileId === message?.userTo?.id ?
                                theme.palette.secondary.main :
                                theme.palette.chat.message.sended.backgroundColor
                            }
                            p={2}
                            my={0}
                            borderRadius='5px'
                            component="p"
                            style={{fontWeight: unreadMessage ? 'bold' : 'normal'}}
                            dangerouslySetInnerHTML={{
                                __html: linkify(message?.messageText?.replace(/(?:\r\n|\r|\n)/g, '<br />')),
                            }}
                        />
                    }
                    {message.messageFileName && 
                        <Box>
                            <DisplayFile 
                                path={message.messageFileName}
                                fileType={message.messageFileType}
                                clickMedia={handleOpenMedia}
                                downloadFile={(path) => window.open(path, '_blank')}
                                popoverVersion={popoverVersion}
                            />
                        </Box>
                    }

                    {hover && profileId === message?.userFrom?.id &&
                        <Box position='absolute' right={0} onClick={(e) => e.stopPropagation()} >
                            <Tooltip title="More Options">
                                <IconButton size="small" onClick={onShowMoreOptions} aria-controls="messageMenu">
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    <Menu
                        anchorEl={showMoreOptions}
                        open={Boolean(showMoreOptions)}
                        onClose={onHideMoreOptions}
                        id="messageMenu"
                    >
                        <MenuItem onClick={handleDeleteMessage}>
                            <DeleteIcon />
                            <Box component="span" ml={4}>
                                Supprimer
                            </Box>
                        </MenuItem>
                    </Menu>
                </Box>
                <Box
                    ml={profileId === message?.userTo?.id ? '2em' : 'auto'}
                    mr='2em'
                    mt={0}
                >
                    {moment(message.messageDate).format('HH:MM:SS')}
                </Box>
            </Box>
            
            {media &&    /* Affiche l'image en plein écran */
                <Box
                    position='fixed'
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    backgroundColor={'rgb(48, 48, 48)'}
                    zIndex={20}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Box position='fixed' top={10} left={10}>
                        <CloseIcon onClick={() => setMedia(null)} color='primary' fontSize='large' className='pointer'/>
                    </Box>
                    <img src={media.src} alt="img"/>
                </Box>
            }
        </motion.div>
    );
};