import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cmtUploadImageStyle } from './cmtUploadImageStyle';
import ImageCropper from 'Components/ImageCropper/ImageCropper';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import clsx from 'clsx';
import { useCallback } from 'react';
import { NotificationManager } from "react-notifications";
import { REDIRECTION_TIME } from 'Constant';

const useStyle = makeStyles(cmtUploadImageStyle);

const format = 'image/png, image/jpg, image/jpeg';

const CmtUploadImage = ({
    filename,
    isAvatar = false,
    submit,
    imageClassName = '',
    size = '',
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isCropperOpen, setIsCropperOpen] = useState(false);
    const [fileName, setFileName] = useState(filename);
    const [fileNameForCropper, setFileNameForCropper] = useState('');

    const [hover, setHover] = useState(false);
    
    const classes = useStyle({hover: hover, isAvatar: isAvatar});

    const onDrop = useCallback ((acceptedFiles, fileRejections) => {
        if (fileRejections?.length > 0) {
            let errorMessage = '';

            if (fileRejections[0]?.errors[0]?.code === 'file-invalid-type') {
                errorMessage = `Votre fichier doit ếtre dans l'un des formats suivant: ${format}`;
            }

            NotificationManager.error(errorMessage || 'Votre image ne peut pas être ajouté', 'Erreur', REDIRECTION_TIME);
        }

        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => setIsDisabled(false);

            reader.onerror = test => {
                setIsDisabled(false);
            };

            reader.onprogress = () => setIsDisabled(true);

            reader.onload = () => {
                setIsDisabled(false);

                const file = acceptedFiles[0];

                setFileNameForCropper(URL.createObjectURL(file));

                setIsCropperOpen(true);
            };

            reader.readAsArrayBuffer(file);
        });
    }, []);

    const onValidateCrop = (canvas) => {
        setFileName(canvas.toDataURL());
        canvas.toBlob(blob => {
            const file = new File([blob], `avatarImage${Date.now()}.${blob.type.split('/')[1]}`);
            
            submit(file);
        });
    };
    
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: isDisabled,
        accept: 'image/png, image/jpg, image/jpeg',
        multiple: false,
    });

    return (
        <Box
            display='flex' alignItems='flex-start'
            flexDirection='column' flexGrow={1} position='relative'
            width='100%' height='100%'
        >
            <ImageCropper
                isOpen={isCropperOpen}
                imageURL={fileNameForCropper}
                onClose={() => setIsCropperOpen(false)}
                onValidate={onValidateCrop}
                isAvatar={isAvatar}
            />

            <Box
                style={{background: `center / cover no-repeat url(${fileName}`}}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                {...getRootProps()}
                className={clsx(classes.imageBox, imageClassName)}
            >
                {hover && <PhotoCameraIcon className={classes.photoIcon} />}
                <input type='image' alt="image" style={{display: 'none'}} {...getInputProps()} />
            </Box>
        </Box>
    );
};

export default CmtUploadImage;
