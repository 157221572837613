import TextField from '@mui/material/TextField';

export default function FormInput ({
    name,
    label,
    onChange,
    value,
    type = "text",
    className = "",
    error = null,
    onBlur,
    id,
    fullWidth = true,
    variant = 'standard',
    ...rest
}) {
    return (
        <TextField
            label={label}
            name={name}
            variant={variant}
            type={type}
            fullWidth={fullWidth}
            onChange={onChange}
            value={value}
            margin="normal"
            className={className}
            helperText={error !== null && error}
            error={Boolean(error)}
            onBlur={onBlur}
            id={id}
            {...rest}
        />
    );
};