import { Box } from "@mui/material";
import CmtUploadImage from "Components/CmtUploadImage/CmtUploadImage";
import { ProfileHeaderRoot } from "Components/CmtProfileContainer/sc.ProfileContainer";
import StructureHeaderInfos from "./StructureHeaderInfos/StructureHeaderInfos";

export default function StructureHeader({ companyData, isIntermittent, profile, update, isAdmin }) {
    if (!companyData || !profile?.defaultAvatarPath) {
        return <></>;
    }

    return (
        <ProfileHeaderRoot display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box position="absolute" left={0} top={0} width="100%" height="100%" minHeight={370}>
                {isIntermittent || !isAdmin ? (
                    <Box
                        height="100%"
                        width="100%"
                        sx={{
                            background: `center / cover no-repeat url(${
                                companyData?.coverFileName || "/images/profile-bg-img.png"
                            })`,
                        }}
                    />
                ) : (
                    <CmtUploadImage
                        filename={companyData?.coverFileName || "/images/profile-bg-img.png"}
                        submit={(image) => update({ coverFileName: image })}
                    />
                )}
            </Box>
            <Box position="relative" display="flex" width="fit-content" zIndex={3}>
                <Box
                    display="flex"
                    flexDirection={{ xs: "row", sm: "row" }}
                    alignItems="center"
                    marginRight={3}
                    height={80}
                    width={80}
                    borderRadius="50%"
                    overflow="hidden"
                >
                    {isIntermittent || !isAdmin ? (
                        <Box
                            height="100%"
                            width="100%"
                            sx={{
                                background: `center / cover no-repeat url(${
                                    companyData?.logoFileName || profile?.defaultAvatarPath
                                })`,
                            }}
                        />
                    ) : (
                        <CmtUploadImage
                            filename={companyData?.logoFileName || profile?.defaultAvatarPath}
                            submit={(image) => update({ profileFileName: image })}
                            isAvatar={true}
                            size={80}
                        />
                    )}
                </Box>
                <StructureHeaderInfos
                    isIntermittent={isIntermittent}
                    companyData={companyData}
                    profile={profile}
                    update={update}
                    isAdmin={isAdmin}
                />
            </Box>
        </ProfileHeaderRoot>
    );
}
