import { Box, Button, IconButton, Typography } from "@mui/material";
import CmtImage from "Components/CmtImage/CmtImage";
import { DOCUMENTS_PATH, REDIRECTION_TIME } from "Constant";
import { useState } from "react";
import { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { notConnectedAction } from "redux/actions/authActions";
import auth from "services/Api/auth";
import documents from "services/Api/documents";
import history from "services/History/history";
import formatFileSize from "../../utils/formatFileSize";
import getDocumentsIconsType from "../../utils/getDocumentsIconsType";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { useCallback } from "react";
import { DetailContainer, PreviewImage } from "../sc.DisplayDocuments";

export const DisplayDetail = ({id, closeDetail, emplacement}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState(null);

    const fetchData = useCallback(async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        documents.getFileFolderDetail(id)
        .then(result => {
            if (result?.result) {
                setData(result?.data);
            } else {
                NotificationManager.error(result?.error?.message || "Une Erreur s'est produite", 'Erreur', REDIRECTION_TIME);
            }
        });
    }, [dispatch, id]);

    const handleClick = () => {
        if (data.isFolder) {
            history.push(`${DOCUMENTS_PATH}/${data?.id}`);
        } else {
            window.open(data?.documentFileName, '_blank')
        }
    };

    useEffect(() => {
        if (data?.id === id) {
            return;
        }

        fetchData();
    }, [data?.id, id, fetchData]);


    useEffect(() => {
        const listen = history.listen(() => {
            closeDetail();
        });

        return (() => {
            listen();
        })
    }, [closeDetail]);

    if (!data) {
        return (<></>);
    }

    const Preview = () => {
        switch (true) {
            case data?.type === 'Image':
                return ( <PreviewImage src={data?.documentFileName}/> );
            case data?.type === 'Vidéo':
                return (
                    <Box>
                        <video
                            controls
                            width={250}
                        >
                            <source src={data?.documentFileName} />
                            Votre Navigateur ne supporte pas ce type de fichier vidéo
                        </video>
                    </Box>
                );
            default:
                return ( <CmtImage src={`/images/icons/${getDocumentsIconsType(data?.project ? 'ProjectFolder' : data?.type)}`} height={100}/>);
        }
    };

    return (
        <DetailContainer p={5}>
            <Box display='flex' justifyContent='flex-end'>
                <IconButton onClick={closeDetail}> <CloseIcon /> </IconButton>
            </Box>
            <Typography component='p' variant='subtitle1'>{data?.name}</Typography>
            <Box pt={5} display='flex' justifyContent='center'>
                <Preview />
            </Box>
            <Box pt={5}>
                <Typography component='p' variant='subtitle1'>Informations :</Typography>
                <Box display='flex'>
                    <Box pt={5} overflow='hidden'>
                        <Box py={3} display='flex'>
                            <Box width={130}>
                                <Typography component='p' variant='h5' className='fontWeight-500'>Type</Typography>
                            </Box>
                            <Typography component='p' variant='h5'>{data?.type}</Typography>
                        </Box>

                        <Box py={3} display='flex'>
                            <Box width={130}>
                                <Typography component='p' variant='h5' className='fontWeight-500'>Taille</Typography>
                            </Box>
                            <Typography component='p' variant='h5' >{data?.documentSize ? formatFileSize(data?.documentSize) : '--'}</Typography>
                        </Box>


                        <Box py={3} display='flex'>
                            <Box width={130} flexShrink={0}>
                                <Typography component='p' variant='h5' className='fontWeight-500'>Emplacement</Typography>
                            </Box>
                            <Typography component='p' variant='body2'>
                                {emplacement?.path?.map(it => it.name).join(' / ')} / {data?.name}
                            </Typography>
                        </Box>

                        <Box py={3} display='flex'>
                            <Box width={130}>
                                <Typography component='p' variant='h5' className='fontWeight-500'>Auteur</Typography>
                            </Box>
                            <Typography component='div' >{data?.createdBy?.firstName} {data?.createdBy?.lastName}</Typography>
                        </Box>

                        <Box py={3} display='flex'>
                            <Box width={130}>
                                <Typography component='p' variant='h5' className='fontWeight-500'>Crée le</Typography>
                            </Box>
                            <Typography component='p' variant='h5'>{moment(data?.createdBy?.createdAt).format('LL')}</Typography>
                        </Box>
                    </Box>

                </Box>

                <Box display='flex' justifyContent='center' pt={10}>
                    <Button variant='contained' color='primary' onClick={handleClick}> {data?.isFolder ? 'Ouvrir le dossier' : 'Télécharger le fichier'}</Button>
                </Box>
            </Box>
        </DetailContainer>
    );
};