import { Box, Chip, FormControl, InputLabel, ListSubheader, MenuItem, Select, Typography } from "@mui/material";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import { useState } from "react";
import FolderIcon from '@mui/icons-material/Folder';

export const NetworkCategoryFilter = ({
    filters,
    changeFilters,
    categoriesList = []
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const type = filters?.category?.name;

    return (
        <Box mr={2}>
            <Box display='flex' alignItems='center' justifyContent='center' className='fullHeight'>
                <Chip
                    variant={type ? 'default' : 'outlined'}
                    icon={<FolderIcon/>}
                    size='medium'
                    label={type || "Catégories"}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    onDelete={type ? (() => changeFilters({...filters, category: ''})) : null}

                />
            </Box>

            <CmtPopover
                anchorEl={anchorEl}
                closePopover={() => setAnchorEl(null)}
            >
                <Box p={5} width={300}>
                    <Typography component='p' variant='subtitle1'> Selectionner une catégorie</Typography>
                    <FormControl fullWidth variant='standard'>
                        <InputLabel id="categoriesFilterType">Catégories</InputLabel>
                        <Select
                            labelId="categoriesFilterType"
                            value={filters?.category}
                            fullWidth
                            label={"Catégories"}
                            onChange={(e) => changeFilters({...filters, category: e.target.value})}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                        >
                            {categoriesList?.map((item, index) => ([
                                <ListSubheader key={`CompaniesGroup ${index}`} disabled>{item.name}</ListSubheader>,
                                item.values.map((it, ind) => (
                                    <MenuItem value={it} key={`companiesType ${ind}`}>{it.name}</MenuItem>
                                ))
                            ]))}
                        </Select>
                    </FormControl> 
                </Box>
            </CmtPopover>
        </Box>
    );
};