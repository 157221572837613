import { useTheme } from "@emotion/react";
import { PublicTypo } from "../sc.Public";

export const TitleText = ({ children, fontSize = null, ...rest }) => {
    const theme = useTheme();

    return (
        <PublicTypo
            family={theme.palette.publicTypo.hFamily}
            fontSize={
                fontSize || {
                    xs: theme.palette.publicTypo.h3Size,
                    md: theme.palette.publicTypo.h2Size,
                }
            }
            color="primary"
            {...rest}
        >
            {children}
        </PublicTypo>
    );
};
