import { Box, Typography, Button, IconButton, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import CmtLoading from "Components/CmtLoading/CmtLoading";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { notConnectedAction } from "redux/actions/authActions";
import auth from "services/Api/auth";
import documents from "services/Api/documents";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import getDocumentsIconsType from "../../utils/getDocumentsIconsType";
import CmtImage from "Components/CmtImage/CmtImage";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { CreateFolder } from "../Folder/CreateFolder";
import { NotificationManager } from "react-notifications";
import { REDIRECTION_TIME } from "Constant";
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from "react";
import { DisplayMoveFolder } from "../sc.DisplayDocuments";

const FolderLine = ({
    item,
    index,
    changeCurrentFolder,
}) => {
    const onClickHandler = () => {
        if (!item?.isFolder) {
            return;
        }

        changeCurrentFolder(`/${item?.id}`);
    };

    return (
        <DisplayMoveFolder
            key={index}
            onClick={onClickHandler}
            isFolder={item?.isFolder}
        >
            <Box pr={2}>
                <CmtImage src={`/images/icons/${getDocumentsIconsType(item?.project ? 'ProjectFolder' : item?.type)}`} height={30} />
            </Box>
            <Typography component='p' variant='h5' className='fontWeight-500'>
                {item?.name}
            </Typography>
        </DisplayMoveFolder>
    );
};


export const MoveFileFolder = ({
    anchorEl,
    closePopover,
    moveFileFolder,
    path,
    loading,
    createFolder,
    fileFolderList,
}) => {
    const dispatch = useDispatch();

    const [currentFolder, setCurrentFolder] = useState(null);

    const [openCreateFolder, setOpenCreateFolder] = useState(null);
    const [createLoading, setCreateLoading] = useState(false);

    const getCurrentFolder = useCallback(async (id) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());
        }

        documents.getDocumentsList(null, id)
        .then(result => {
            if (result?.result) {
                setCurrentFolder(result?.data);
            }
        });
    }, [dispatch]);

    const handleCreateFolder = async (data) => {
        setCreateLoading(true);

        createFolder(data)
        .then((result) => {
            setCreateLoading(false);
            setOpenCreateFolder(null);

            if (result?.result) {
                getCurrentFolder(path);
            } else {
                NotificationManager.error(result?.error?.message, 'Erreur', REDIRECTION_TIME);

                closePopover();
            }
        });
    };

    useEffect(() => {
        getCurrentFolder(path);
    }, [getCurrentFolder, path]);

    if (!currentFolder) {
        return (<></>);
    }

    return (
        <>
            <Dialog
                open={Boolean(anchorEl)}
                onClose={() => closePopover()}
            >
                <DialogTitle>
                    <Box display='flex' justifyContent='space-between'>
                        <Box display='flex' p={0} alignItems='center'>
                            {currentFolder[1]?.path?.length > 0 && 
                                <IconButton size='small' onClick={() => {
                                    if (currentFolder[1]?.path?.length > 1) {
                                        getCurrentFolder(`/${currentFolder[1]?.path?.at(-2)?.id}`);
                                    } else {
                                        getCurrentFolder('');
                                    }
                                }}>
                                    <ArrowBackIcon />
                                </IconButton>
                            }
                            <Typography component='p' variant='h1'>{currentFolder[1]?.path?.length > 0 ? currentFolder[1]?.path.at(-1).name : '/'}</Typography>
                        </Box>
                        <IconButton onClick={closePopover}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent dividers>
                    <Box minWidth={350}>
                        {loading && 
                            <CmtLoading />
                        }
                        {currentFolder[0]?.map((item, index) => {
                            if (!fileFolderList?.includes(item?.id)) {
                                return (
                                    <FolderLine
                                        item={item}
                                        index={index}
                                        changeCurrentFolder={getCurrentFolder}
                                    />
                                );
                            } else {
                                return (<></>);
                            }
                        })}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Box p={0} display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                        <IconButton color='primary' onClick={(e) => setOpenCreateFolder(e.currentTarget)}>
                            <CreateNewFolderIcon />
                        </IconButton>

                        <Button
                            variant='contained'
                            size='medium'
                            color='primary'
                            onClick={() => {
                                moveFileFolder(currentFolder[1]?.path?.length > 0 ? `/${currentFolder[1]?.path?.at(-1).id}` : '');

                                closePopover();
                            }}
                        >
                            Déplacer ici
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {Boolean(openCreateFolder) && 
                <CreateFolder
                    anchorEl={openCreateFolder}
                    closePopover={() => setOpenCreateFolder(null)}
                    createFolder={handleCreateFolder}
                    folderId={path}
                    loading={createLoading}
                />
            }
        </>
    );
};