import { Avatar } from '@mui/material';
import { styled } from '@mui/system';

const AvatarStyle = styled(Avatar)`
    height: ${props => `${props?.size}px`};
    width: ${props => `${props?.size}px`};
`;

export const AvatarComponent = ({src, size, ...rest}) => {
    return (
        <AvatarStyle src={src} size={size} {...rest}></AvatarStyle>
    );
};

/* Affiche l'image d'avatar */