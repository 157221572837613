import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Input,
    InputLabel,
    Typography,
    useMediaQuery,
} from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { Formik } from "formik";
import { useState } from "react";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import moment from "moment";
import EventIcon from "@mui/icons-material/Event";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import LabelIcon from "@mui/icons-material/Label";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AddIcon from "@mui/icons-material/Add";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import auth from "services/Api/auth";
import notes from "services/Api/notes";
import { DragDropContext } from "react-beautiful-dnd";
import { NoteCheckList } from "./NoteCheckList";
import { REDIRECTION_TIME } from "Constant";
import { NotificationManager } from "react-notifications";
import { useRef } from "react";
import { LabelChip } from "../LabelChip";
import { NoteImage } from "../sc.Notes";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";
import { CmtButtonDateTimePicker } from "Components/CmtDatePicker/CmtButtonDateTimePicker";
import { useTheme } from "@emotion/react";

export const EditNote = ({
    submit,
    notesTypes,
    createLabel,
    noteDetail,
    onClose,
    disconnect,
    deleteNote,
    updateOneNote,
}) => {
    const [deleteModale, setDeleteModale] = useState(false);
    const [labelAnchorEl, setLabelAnchorEl] = useState(null);
    const [dateTimeOpen, setDateTimeOpen] = useState(false);
    const labelRef = useRef(null);
    const reminderRef = useRef(null);

    const [newLabelValue, setNewLabelValue] = useState("");

    const theme = useTheme();
    const smBreakPoint = useMediaQuery(theme?.breakpoints.down("sm"));

    const checkError = (values) => {
        let errors = {};

        if (!values.title) {
            errors.title = "Veuillez renseigner un titre pour votre note";
        }

        values.todoList.forEach((item, index) => {
            if (!item?.description?.trim()) {
                errors = {
                    ...errors,
                    todoList: {
                        ...errors.todoList,
                        [index]: "Veuillez renseigner un nom pour votre tâche",
                    },
                };
            }
        });

        return errors;
    };

    const handleCreateLabel = async () => {
        createLabel(newLabelValue).then((result) => {
            if (result?.result) {
                setNewLabelValue("");
            }
        });
    };

    const handleCreateCheck = async (description) => {
        const result = auth.checkIsAuth().then((check) => {
            if (!check) {
                disconnect();

                return;
            }

            const result = notes
                .createCheckbox({
                    description: description,
                    noteId: noteDetail?.id,
                })
                .then((res) => {
                    if (res.result) {
                        updateOneNote({
                            ...noteDetail,
                            noteChecks: noteDetail?.noteChecks.concat(res.data),
                        });
                    }

                    return res;
                });

            return result;
        });

        return result;
    };

    const handleDeleteCheck = async (checkboxId) => {
        auth.checkIsAuth().then((check) => {
            if (!check) {
                disconnect();
            }

            notes.deleteCheckbox(checkboxId).then((result) => {
                if (result?.result) {
                    updateOneNote({
                        ...noteDetail,
                        noteChecks: noteDetail?.noteChecks.filter((elem) => elem.id !== checkboxId),
                    });
                }
            });
        });
    };

    const handleUpdateCheck = async (checkboxId, description) => {
        if (
            !description ||
            noteDetail?.noteChecks?.find((item) => item?.id === checkboxId).description ===
                description
        ) {
            return;
        }

        auth.checkIsAuth().then((check) => {
            if (!check) {
                disconnect();
            }

            notes
                .updateCheckbox({
                    noteCheckId: checkboxId,
                    description: description,
                })
                .then((result) => {
                    if (result.result) {
                        let list = noteDetail?.noteChecks;

                        list[list.findIndex((item) => item.id === checkboxId)] = result.data;

                        updateOneNote({ ...noteDetail, noteChecks: list });
                    }
                });
        });
    };

    const handleValidateCheckbox = async (checkboxId) => {
        auth.checkIsAuth().then((check) => {
            if (!check) {
                disconnect();
            }

            notes.validationCheckbox(checkboxId).then((res) => {
                if (res.result) {
                    let list = noteDetail?.noteChecks;

                    list[list.findIndex((item) => item.id === checkboxId)] = res.data;

                    updateOneNote({ ...noteDetail, noteChecks: list });
                }
            });
        });
    };

    const handleSubmit = async (values) => {
        let data = {
            id: noteDetail?.id,
        };

        if (values?.title !== noteDetail?.title) {
            data.title = values.title;
        }

        if (values?.description !== noteDetail?.description) {
            data.description = values?.description;
        }

        if (values?.imageFile !== noteDetail?.imageFileName) {
            data.imageFile = values?.imageFile;
        }

        data.noteTypes = values?.noteTypes;

        if (values?.reminderDate !== noteDetail?.reminderDate) {
            data.reminderDate = values?.reminderDate;
        }

        submit(data).then((result) => {
            if (result?.result) {
                NotificationManager.success(
                    "Votre note à bien été mise à jour",
                    "Succès",
                    REDIRECTION_TIME
                );

                onClose();
            } else {
                NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
            }
        });
    };

    const handleDragEnd = (result, values, setFieldValue) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination?.index === source?.index) {
            return;
        }

        const noteCheckId = parseInt(draggableId);

        let noteChecks = values?.todoList;

        noteChecks.splice(
            result?.destination.index,
            0,
            ...noteChecks.splice(result?.source?.index, 1)
        );

        setFieldValue("todoList", noteChecks);

        auth.checkIsAuth().then((check) => {
            if (!check) {
                disconnect();
            }

            notes
                .moveCheckbox({
                    noteCheckId,
                    position: result?.destination?.index + 1,
                })
                .then((res) => {
                    if (res.result) {
                        updateOneNote({
                            ...noteDetail,
                            notesChecks: noteChecks,
                        });
                    }
                });
        });
    };

    return (
        <>
            <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
                <Formik
                    initialValues={{
                        title: noteDetail.title || "",
                        description: noteDetail?.description || "",
                        imageFile: noteDetail?.imageFileName || null,
                        noteTypes: noteDetail?.noteTypes?.map((type) => type.id) || [],
                        reminderDate: noteDetail?.reminderDate
                            ? moment(noteDetail?.reminderDate).format("YYYY-MM-DD HH:mm")
                            : "",
                        todoList: noteDetail?.noteChecks || [],
                    }}
                    validate={(values) => checkError(values)}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldTouched,
                        setFieldValue,
                    }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <DialogTitle>
                                <FormInput
                                    name="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin="none"
                                    value={values.title}
                                    label="Titre"
                                    error={touched.title && errors.title}
                                />
                            </DialogTitle>

                            {values.imageFile && (
                                <Box display="flex" justifyContent="center">
                                    <Box position="relative" className="pointer">
                                        <NoteImage
                                            src={
                                                values?.imageFile === noteDetail?.imageFileName
                                                    ? values?.imageFile
                                                    : URL.createObjectURL(values.imageFile)
                                            }
                                            width="100%"
                                        />
                                        <Box position="absolute" right={24} top={24}>
                                            <DeleteIcon
                                                color="primary"
                                                onClick={() => setFieldValue("imageFile", null)}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                            <DialogContent>
                                <FormInput
                                    name="description"
                                    onChange={handleChange}
                                    multiline
                                    rows={3}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    label="Description"
                                />

                                {values.todoList.length > 0 && (
                                    <DragDropContext
                                        onDragEnd={(result) =>
                                            handleDragEnd(result, values, setFieldValue)
                                        }
                                    >
                                        <NoteCheckList
                                            values={values}
                                            handleValidateCheckbox={handleValidateCheckbox}
                                            setFieldValue={setFieldValue}
                                            handleDeleteCheck={handleDeleteCheck}
                                            touched={touched}
                                            errors={errors}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            handleCreateCheck={handleCreateCheck}
                                            handleUpdateCheck={handleUpdateCheck}
                                        />
                                    </DragDropContext>
                                )}

                                <Box pt={5} display="flex" flexWrap="wrap">
                                    {values.reminderDate && (
                                        <Box pr={2}>
                                            <Chip
                                                variant="outlined"
                                                icon={<EventIcon />}
                                                size="small"
                                                onClick={() => setDateTimeOpen(reminderRef.current)}
                                                label={values.reminderDate}
                                            />
                                        </Box>
                                    )}

                                    {values.noteTypes.map((item, index) => (
                                        <Box pr={2} key={index}>
                                            <LabelChip
                                                type={notesTypes?.find((elem) => elem.id === item)}
                                                notesTypes={notesTypes}
                                                onClick={() => setLabelAnchorEl(labelRef.current)}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </DialogContent>

                            <DialogActions>
                                <Box
                                    pt={2}
                                    pb={4}
                                    px={4}
                                    display="flex"
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems={!smBreakPoint && "center"}
                                    flexDirection={smBreakPoint && "column"}
                                    flexWrap="wrap"
                                >
                                    <Box display="flex">
                                        <IconButton
                                            color="primary"
                                            ref={reminderRef}
                                            onClick={(e) => setDateTimeOpen(e.currentTarget)}
                                        >
                                            <AddAlertIcon />
                                        </IconButton>

                                        <InputLabel htmlFor="uploadImageNote">
                                            <Input
                                                accept="image/*"
                                                id="uploadImageNote"
                                                type="file"
                                                className="hideElement"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.files[0].type.split("/")[0] ===
                                                        "image"
                                                    ) {
                                                        setFieldValue(
                                                            "imageFile",
                                                            e.target.files[0]
                                                        );
                                                    }
                                                }}
                                            />
                                            <IconButton color="primary" component="span">
                                                {" "}
                                                <ImageIcon />{" "}
                                            </IconButton>
                                        </InputLabel>

                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                if (values.todoList.length === 0) {
                                                    setFieldValue("todoList", [
                                                        {
                                                            description: "",
                                                            isDone: false,
                                                        },
                                                    ]);
                                                } else {
                                                    const joinedValue = values?.todoList
                                                        .map((todo) => todo.description)
                                                        .join("");

                                                    if (!joinedValue) {
                                                        setFieldValue("todoList", []);
                                                    }
                                                }
                                            }}
                                        >
                                            <PlaylistAddCheckIcon />
                                        </IconButton>

                                        <IconButton
                                            color="primary"
                                            ref={labelRef}
                                            onClick={(e) => {
                                                setLabelAnchorEl(e.currentTarget);
                                            }}
                                        >
                                            <LabelIcon />
                                        </IconButton>

                                        <IconButton
                                            color="primary"
                                            onClick={() => setDeleteModale(true)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>

                                    <Box
                                        display="flex"
                                        pl={2}
                                        width={smBreakPoint ? "100%" : "fit-content"}
                                        justifyContent={smBreakPoint && "space-between"}
                                        alignItems="center"
                                    >
                                        <Box mr={2}>
                                            <Button
                                                color="secondary"
                                                type="button"
                                                onClick={onClose}
                                                variant="outlined"
                                            >
                                                Annuler
                                            </Button>
                                        </Box>

                                        <Button color="primary" type="submit" variant="contained">
                                            Enregistrer
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogActions>

                            <CmtPopover
                                anchorEl={labelAnchorEl}
                                closePopover={() => setLabelAnchorEl(null)}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <Box p={5} pl={3}>
                                    {notesTypes?.map((item, index) => (
                                        <Box display="flex" alignItems="center" key={index}>
                                            <Checkbox
                                                size="small"
                                                checked={values?.noteTypes.includes(item?.id)}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "noteTypes",
                                                        e.target.checked
                                                            ? values.noteTypes.concat(item?.id)
                                                            : values.noteTypes.filter(
                                                                  (typesId) => typesId !== item?.id
                                                              )
                                                    );
                                                }}
                                            />
                                            <Typography component="p" variant="h5">
                                                {item?.name}
                                            </Typography>
                                        </Box>
                                    ))}
                                    <Box display="flex" alignItems="flex-end" pl={2}>
                                        <FormInput
                                            size="small"
                                            value={newLabelValue}
                                            onChange={(e) => setNewLabelValue(e.target.value)}
                                            label="Ajouter un nouveau label"
                                        />
                                        <Box pb={2}>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={handleCreateLabel}
                                                disabled={!newLabelValue}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </CmtPopover>

                            <CmtButtonDateTimePicker
                                disablePast
                                value={values.reminderDate}
                                setValue={(value) =>
                                    setFieldValue(
                                        "reminderDate",
                                        value ? moment(value).format("YYYY-MM-DD HH:mm") : ""
                                    )
                                }
                                onTouched={setFieldTouched}
                                name="toDate"
                                open={dateTimeOpen}
                                setOpen={setDateTimeOpen}
                                error={touched.toDate && errors.toDate}
                            />
                        </CmtForm>
                    )}
                </Formik>
            </Dialog>

            <CmtDeleteDialog
                open={deleteModale}
                onCancel={() => setDeleteModale(false)}
                onDelete={() => deleteNote(noteDetail?.id)}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        Êtes-vous sûr de vouloir supprimer cette note ?
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>
        </>
    );
};
