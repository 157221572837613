import { Box, Fab, Hidden, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { DOCUMENTS_PATH, LIST_MODE, REDIRECTION_TIME } from "Constant";
import { CreateFolder } from "./Folder/CreateFolder";
import { NotificationManager } from "react-notifications";
import { AddFile } from "./File/AddFile";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import { DisplayDetail } from "./Detail/DisplayDetail";
import { DocumentsFilters } from "../DocumentsFilters/DocumentsFilters";
import history from "services/History/history";
import HomeIcon from "@mui/icons-material/Home";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { DocumentsList } from "./List/DocumentsList";
import { DocumentsGrid } from "./Grid/DocumentsGrid";
import { DisplayDocumentsContainer } from "./sc.DisplayDocuments";
import { CmtDeleteDialog } from "Components/CmtDeleteDialog/CmtDeleteDialog";

export const DisplayDocuments = ({
    path,
    documentsData,
    getDocumentsData,
    createFileFolder,
    changeFilters,
    deleteFileFolder,
    moveFileFolder,
    updateFileFolder,
}) => {
    const [fileFolderDetail, setFileFolderDetail] = useState(null);
    const [deletePopine, setDeletePopine] = useState(null);

    const [createLoading, setCreateLoading] = useState(false);

    const [openCreateFolder, setOpenCreateFolder] = useState(null);
    const [openAddFile, setOpenAddFile] = useState(null);

    const [displayMode, setDisplayMode] = useState(
        sessionStorage.getItem("documentsDisplayMode") || LIST_MODE
    );

    const handleCreateFileFolder = async (data) => {
        setCreateLoading(true);

        createFileFolder(data).then((result) => {
            setCreateLoading(false);

            if (result?.result) {
                const str = data?.isFolder
                    ? "Votre dossier à été crée avec succès"
                    : "Votre fichier à été ajouté avec succès";

                NotificationManager.success(str, "Succès", REDIRECTION_TIME);

                setOpenCreateFolder(null);
                setOpenAddFile(null);
            } else {
                NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
            }
        });
    };

    useEffect(() => {
        const listen = history.listen(() => {
            setOpenCreateFolder(null);
            setOpenAddFile(null);
        });

        return () => {
            listen();
        };
    }, []);

    useEffect(() => {
        if (documentsData?.path !== path && !documentsData?.loading) {
            getDocumentsData(path);

            return;
        }

        if (!documentsData?.documentsList && !documentsData?.loading && !documentsData?.error) {
            getDocumentsData(path);
        }
    }, [
        path,
        documentsData.documentsList,
        documentsData.loading,
        documentsData?.path,
        documentsData.error,
        getDocumentsData,
    ]);

    return (
        <>
            <Box display="flex" height="100%" flexShrink={0} overflow="auto">
                <DisplayDocumentsContainer detail={Boolean(fileFolderDetail)}>
                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                        <CmtAppHeader title="Mes documents" />
                        <Box display="flex">
                            <Fab
                                variant="extended"
                                sx={{ ml: 5, px: 5 }}
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={(e) => setOpenCreateFolder(e.currentTarget)}
                            >
                                <CreateNewFolderIcon sx={{ mr: 2 }} />
                                Créer un dossier
                            </Fab>

                            <Fab
                                variant="extended"
                                sx={{ ml: 5, px: 5 }}
                                size="small"
                                color="secondary"
                                aria-label="add"
                                onClick={(e) => setOpenAddFile(e.currentTarget)}
                            >
                                <NoteAddIcon sx={{ mr: 2 }} />
                                Ajouter un fichier
                            </Fab>
                        </Box>
                    </Box>
                    <DocumentsFilters
                        filters={documentsData?.filters}
                        changeFilters={changeFilters}
                        resultNumber={documentsData?.documentsInfos?.total || 0}
                        displayMode={displayMode}
                        setDisplayMode={(value) => {
                            setDisplayMode(value);

                            sessionStorage.setItem("documentsDisplayMode", value);
                        }}
                    />
                    <Box display="flex" alignItems="center">
                        <HomeIcon
                            fontSize="medium"
                            onClick={() => history.push(DOCUMENTS_PATH)}
                            className="pointer"
                        />
                        {documentsData?.documentsInfos?.path?.map((item, index) => (
                            <Box pl={2} key={index} display="flex" alignItems="center">
                                <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                                <Box
                                    pl={2}
                                    onClick={() => history.push(`${DOCUMENTS_PATH}/${item?.id}`)}
                                    className="pointer"
                                >
                                    <Typography component="p" variant="h5">
                                        {" "}
                                        {item?.name}{" "}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    {displayMode === LIST_MODE ? (
                        <>
                            <Hidden mdDown>
                                <Box display="flex" overflow="auto">
                                    <DocumentsList
                                        changeFilters={changeFilters}
                                        documentsData={documentsData}
                                        handleDeleteFileFolder={setDeletePopine}
                                        moveFileFolder={moveFileFolder}
                                        updateFileFolder={updateFileFolder}
                                        setFileFolderDetail={setFileFolderDetail}
                                        fileFolderDetail={fileFolderDetail}
                                        path={path}
                                        createLoading={createLoading}
                                        createFileFolder={createFileFolder}
                                    />
                                </Box>
                            </Hidden>
                            <Hidden mdUp>
                                <DocumentsGrid
                                    changeFilters={changeFilters}
                                    documentsData={documentsData}
                                    handleDeleteFileFolder={setDeletePopine}
                                    moveFileFolder={moveFileFolder}
                                    updateFileFolder={updateFileFolder}
                                    setFileFolderDetail={setFileFolderDetail}
                                    fileFolderDetail={fileFolderDetail}
                                    path={path}
                                    createLoading={createLoading}
                                    createFileFolder={createFileFolder}
                                />
                            </Hidden>
                        </>
                    ) : (
                        <DocumentsGrid
                            changeFilters={changeFilters}
                            documentsData={documentsData}
                            handleDeleteFileFolder={setDeletePopine}
                            moveFileFolder={moveFileFolder}
                            updateFileFolder={updateFileFolder}
                            setFileFolderDetail={setFileFolderDetail}
                            fileFolderDetail={fileFolderDetail}
                            path={path}
                            createLoading={createLoading}
                            createFileFolder={createFileFolder}
                        />
                    )}
                </DisplayDocumentsContainer>

                {fileFolderDetail && (
                    <DisplayDetail
                        id={fileFolderDetail}
                        closeDetail={() => setFileFolderDetail(null)}
                        emplacement={documentsData?.documentsInfos}
                    />
                )}
            </Box>

            {Boolean(openCreateFolder) && (
                <CreateFolder
                    anchorEl={openCreateFolder}
                    closePopover={() => setOpenCreateFolder(null)}
                    createFolder={handleCreateFileFolder}
                    folderId={path}
                    loading={createLoading}
                />
            )}

            {Boolean(openAddFile) && (
                <AddFile
                    anchorEl={openAddFile}
                    closePopover={() => setOpenAddFile(null)}
                    addFile={handleCreateFileFolder}
                    folderId={path}
                    loading={createLoading}
                />
            )}

            <CmtDeleteDialog
                open={Boolean(deletePopine)}
                onCancel={() => setDeletePopine(null)}
                onDelete={() => {
                    deleteFileFolder(deletePopine);
                    setDeletePopine(null);
                }}
            >
                <Box textAlign="center" py={3}>
                    <Typography component="p">
                        {deletePopine?.length > 1
                            ? `Voulez vous supprimer ces ${deletePopine?.length} éléments ?`
                            : "Voulez vous supprimer cet élément ?"}
                    </Typography>

                    <Typography component="p">Cette action est irréversible.</Typography>
                </Box>
            </CmtDeleteDialog>
        </>
    );
};
