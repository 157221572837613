import {
    CHANGE_PLANNING_FILTERS,
    CREATE_CALENDARS_ERROR,
    CREATE_CALENDARS_SUCCESS,
    CREATE_EVENT_ERROR,
    DELETE_CALENDAR_ERROR,
    DELETE_CALENDAR_SUCCESS,
    DELETE_EVENT_ERROR,
    DELETE_EVENT_SUCCESS,
    GET_CALENDARS,
    GET_CALENDARS_ERROR,
    GET_CALENDARS_SUCCESS,
    GET_EVENTS,
    GET_EVENTS_ERROR,
    GET_EVENTS_SUCCESS,
    SET_SELECTED_CALENDAR,
    UPDATE_CALENDAR_ERROR,
    UPDATE_CALENDAR_SUCCESS,
    UPDATE_EVENT_ERROR
} from "redux/constants/planningConstants";
import auth from "services/Api/auth";
import planning from "services/Api/planning";
import { notConnectedAction } from "./authActions";

const requestCalendars = () => ({
    type: GET_CALENDARS,
    loading: true,
});

const requestCalendarsSuccess = (data) => ({
    type: GET_CALENDARS_SUCCESS,
    loading: false,
    data: data,
});

const requestCalendarsError = (error) => ({
    type: GET_CALENDARS_ERROR,
    loading: false,
    error: error,
});


export const getCalendarsAction = () => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(requestCalendars());


        planning.getAllCalendars()
        .then(result => {
            if (result?.result) {
                dispatch(requestCalendarsSuccess(result?.data));
            } else {
                dispatch(requestCalendarsError(result?.error));
            }

            return result;
        });
    };
};

const requestEvents = () => ({
    type: GET_EVENTS,
    loading: true,
});

const requestEventsSuccess = (data) => ({
    type: GET_EVENTS_SUCCESS,
    loading: false,
    data: data,
});

const requestEventsError = (error) => ({
    type: GET_EVENTS_ERROR,
    loading: false,
    error: error,
});

export const getEventsAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(requestEvents());

        planning.getAllEvents(data)
        .then(result => {
            if (result?.result) {
                dispatch(requestEventsSuccess(result?.data));
            } else {
                dispatch(requestEventsError(result?.error));
            }

            return result;
        });
    };
};

export const setSelectedCalendarAction = (data) => ({
    type: SET_SELECTED_CALENDAR,
    data: data,
});

const createCalendarSuccess = (data) => ({
    type: CREATE_CALENDARS_SUCCESS,
    loading: false,
    data: data,
});

const createCalendarError = (error) => ({
    type: CREATE_CALENDARS_ERROR,
    loading: false,
    error: error,
});

export const createCalendarAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = planning.createCalendar(data)
        .then(result => {
            if (result?.result) {
                dispatch(createCalendarSuccess(result?.data));
            } else {
                dispatch(createCalendarError(result?.error));
            }

            return result;
        });

        return result;
    };
};

const updateCalendarSuccess = (data) => ({
    type: UPDATE_CALENDAR_SUCCESS,
    loading: false,
    data: data,
});

const updateCalendarError = (error) => ({
    type: UPDATE_CALENDAR_ERROR,
    loading: false,
    error: error,
});

export const updateCalendarAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = planning.updateCalendar(data)
        .then(result => {
            if (result?.result) {
                dispatch(updateCalendarSuccess(result?.data));
            } else {
                dispatch(updateCalendarError(result?.error));
            }

            return result;
        });

        return result;
    };
};

const deleteCalendarSuccess = (id) => ({
    type: DELETE_CALENDAR_SUCCESS,
    loading: false,
    id: id,
});

const deleteCalendarError = (error) => ({
    type: DELETE_CALENDAR_ERROR,
    loading: false,
    error: error,
});

export const deleteCalendarAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = planning.deleteCalendar(data)
        .then(result => {
            if (result?.result) {
                dispatch(deleteCalendarSuccess(data.id));
            } else {
                dispatch(deleteCalendarError(result?.error));
            }

            return result;
        });

        return result;
    };
};

const createEventError = (error) => ({
    type: CREATE_EVENT_ERROR,
    loading: false,
    error: error,
});

export const createEventAction = (data) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = planning.createEvent(data)
        .then(result => {
            if (result?.result) {
                const filters = getState().planning.filters;

                dispatch(getEventsAction(filters));
            } else {
                dispatch(createEventError(result?.error));
            }

            return result;
        });

        return result;
    };
};

const updateEventError = (error) => ({
    type: UPDATE_EVENT_ERROR,
    loading: false,
    error: error,
});

export const updateEventAction = (data) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = planning.updateEvent(data)
        .then(result => {
            if (result?.result) {
                const filters = getState().planning.filters;

                dispatch(getEventsAction(filters));
            } else {
                dispatch(updateEventError(result?.error));
            }

            return result;
        });

        return result;
    };
};

const deleteEventSuccess = (id) => ({
    type: DELETE_EVENT_SUCCESS,
    loading: false,
    id: id,
});

const deleteEventError = (error) => ({
    type: DELETE_EVENT_ERROR,
    loading: false,
    error: error,
});

export const deleteEventAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = planning.deleteEvent(data)
        .then(result => {
            if (result?.result) {
                dispatch(deleteEventSuccess(data.calendarEventId));
            } else {
                dispatch(deleteEventError(result?.error));
            }

            return result;
        });

        return result;
    };
};

export const updatePlanningFiltersAction = (filters) => ({
    type: CHANGE_PLANNING_FILTERS,
    filters: filters,
});

export const changePlanningFiltersAction = (filters) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(updatePlanningFiltersAction(filters));

        dispatch(getEventsAction(filters));
    };
};



