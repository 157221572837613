import { Button, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { DEFAULT_COMPANIES_PIC } from "Constant";

export const CompagnyInvitation = ({ list, submitResponse }) => {
    return (
        <CmtCard>
            <Box mb={3}>
                <TileTitle>Invitations reçues</TileTitle>
            </Box>

            <CardContent>
                <Grid container spacing={8}>
                    {list?.map((item, index) => (
                        <Grid item xs={12} sm={3} key={index}>
                            <CmtCard>
                                <Box p={5}>
                                    <Box display="flex" alignItems="center" mb={4}>
                                        <AvatarComponent
                                            src={
                                                item?.compagny?.logoFileName ||
                                                DEFAULT_COMPANIES_PIC
                                            }
                                            size={50}
                                        />

                                        <Typography
                                            component="p"
                                            variant="subtitle1"
                                            color="primary"
                                            lineHeight={1}
                                            ml={5}
                                        >
                                            {item?.compagny?.name}
                                        </Typography>
                                    </Box>

                                    <Typography component="span" variant="subtitle2">
                                        La structure{" "}
                                        <Typography
                                            component="span"
                                            variant="subtitle2"
                                            color="primary"
                                            fontWeight="bold"
                                        >
                                            {item?.compagny?.name}
                                        </Typography>{" "}
                                        vous invite à la rejoindre
                                        {item?.position && (
                                            <>
                                                {" "}
                                                en tant que{" "}
                                                <Typography
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="primary"
                                                    fontWeight="bold"
                                                >
                                                    {item?.position}
                                                </Typography>
                                            </>
                                        )}
                                        .
                                    </Typography>

                                    <Box display="flex" justifyContent="space-between" mt={5}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>
                                                submitResponse({
                                                    compagnyUserId: item?.id,
                                                    answerStatus: false,
                                                })
                                            }
                                        >
                                            Refuser
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                submitResponse({
                                                    compagnyUserId: item?.id,
                                                    answerStatus: true,
                                                })
                                            }
                                        >
                                            Accepter
                                        </Button>
                                    </Box>
                                </Box>
                            </CmtCard>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </CmtCard>
    );
};
