export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const REQUEST_PROFILE_SUCCESS = "REQUEST_PROFILE_SUCCESS";
export const REQUEST_PROFILE_ERROR = "REQUEST_PROFILE_ERROR";

export const REQUEST_UPDATE_PROFILE = "REQUEST_UPDATE_PROFILE";
export const REQUEST_UPDATE_PROFILE_SUCCESS = "REQUEST_UPDATE_PROFILE_SUCESS";
export const REQUEST_UPDATE_PROFILE_ERROR = "REQUEST_UPDATE_PROFILE_ERROR";

export const REQUEST_MERCURE = "REQUEST_MERCURE";
export const REQUEST_MERCURE_SUCCESS = "REQUEST_MERCURE_SUCCESS";
export const REQUEST_MERCURE_ERROR = "REQUEST_MERCURE_ERROR";

export const REQUEST_NOTIFICATION_READ_SUCCESS = "REQUEST_NOTIFICATION_READ_SUCCESS";
export const REQUEST_NOTIFICATION_READ_ERROR = "REQUEST_NOTIFICATION_READ_ERROR";

export const REQUEST_NOTIFICATION = "REQUEST_NOTIFICATION";
export const REQUEST_NOTIFICATION_SUCCESS = "REQUEST_NOTIFICATION_SUCCESS";
export const REQUEST_NOTIFICATION_ERROR = "REQUEST_NOTIFICATION_ERROR";

export const REQUEST_ANSWER_INVITE_SUCCESS = "REQUEST_ANSWER_INVITE_SUCCESS";
export const REQUEST_ANSWER_INVITE_ERROR = "REQUEST_ANSWER_INVITE_ERROR";

export const REQUEST_DELETE_INVITE_SUCCESS = "REQUEST_DELETE_INVITE_SUCCESS";
export const REQUEST_DELETE_INVITE_ERROR = "REQUEST_DELETE_INVITE_ERROR";

export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION";

export const REQUEST_REAL_TIME_SUCCESS = "REQUEST_REAL_TIME_SUCCESS";
export const REQUEST_REAL_TIME_ERROR = "REQUEST_REAL_TIME_ERROR";
