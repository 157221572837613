import { DISCONNECT, NOT_CONNECTED } from "redux/constants/authConstants";
import {
    REQUEST_PROFILE,
    REQUEST_PROFILE_SUCCESS,
    REQUEST_PROFILE_ERROR,
    REQUEST_UPDATE_PROFILE_ERROR,
    REQUEST_UPDATE_PROFILE_SUCCESS,
    REQUEST_NOTIFICATION_READ_SUCCESS,
    REQUEST_NOTIFICATION_SUCCESS,
    REQUEST_NOTIFICATION_ERROR,
    REQUEST_NOTIFICATION,
    REQUEST_ANSWER_INVITE_SUCCESS,
    REQUEST_ANSWER_INVITE_ERROR,
    REQUEST_DELETE_INVITE_SUCCESS,
    REQUEST_DELETE_INVITE_ERROR,
    ADD_NEW_NOTIFICATION,
    REQUEST_REAL_TIME_SUCCESS,
    REQUEST_REAL_TIME_ERROR,
} from "redux/constants/profileConstants";

const INIT_STATE = {
    profile: null,
    defaultAvatarPath: "",
    mercureToken: "",
    loading: false,
    mercureTokenLoading: false,
    notificationLimit: 10,
    canLoadNotifications: true,
    notificationLoading: false,
    notificationMercure: null,
    error: null,
};

const setProfile = (state, action) => {
    let profile = {
        ...state,
        loading: action.loading,
        error: null,
        profile: action.data,
        notificationLoading: false,
        canLoadNotifications: action.data.notifications.length >= 10,
    };

    if (state?.defaultAvatarPath) {
        return profile;
    }

    const randomIndex = Math.floor(Math.random() * 24) + 1;

    const defaultAvatarPath = `/images/Avatars/${
        randomIndex < 10 ? "0" : ""
    }${randomIndex.toString()}.jpg`;

    return {
        ...profile,
        defaultAvatarPath: defaultAvatarPath,
    };
};

export default function profileReducer(state = INIT_STATE, action) {
    let profile = state.profile;

    switch (action.type) {
        case REQUEST_PROFILE:
            return { ...state, loading: action.loading, error: null };
        case REQUEST_PROFILE_SUCCESS:
            return setProfile(state, action);
        case REQUEST_PROFILE_ERROR:
            return { ...state, loading: action.loading, error: action.error };
        case NOT_CONNECTED:
            return { ...state, loading: false, error: null };
        case REQUEST_UPDATE_PROFILE_ERROR:
            return { ...state, loading: action.loading, error: action.error };
        case REQUEST_UPDATE_PROFILE_SUCCESS:
            return { ...state, loading: action.loading, profile: action.data };
        case REQUEST_NOTIFICATION_READ_SUCCESS:
            profile.notifications[
                profile.notifications?.findIndex((element) => element.id === action.notification.id)
            ] = action.notification;

            return {
                ...state,
                profile: profile,
            };
        case REQUEST_NOTIFICATION:
            return { ...state, notificationLoading: true };

        case REQUEST_NOTIFICATION_SUCCESS:
            profile.notifications = [...profile.notifications, ...action.notifications];

            return {
                ...state,
                loading: false,
                profile: profile,
                canLoadNotifications: action.notifications.length >= state.limit,
                notificationLoading: false,
            };
        case REQUEST_NOTIFICATION_ERROR:
            return { ...state, loading: action.loading, error: action.error };
        case REQUEST_ANSWER_INVITE_SUCCESS:
            profile.compagnyUserActives[
                profile.compagnyUserActives.findIndex((elem) => elem.id === action.invite.id)
            ] = action.invite;

            return { ...state, profile: profile };
        case REQUEST_ANSWER_INVITE_ERROR:
            return { ...state, error: action.error };
        case REQUEST_DELETE_INVITE_SUCCESS:
            profile.compagnyUserActives = profile?.compagnyUserActives?.filter(
                (elem) => elem.id !== action.id
            );

            return { ...state, profile: profile };
        case REQUEST_DELETE_INVITE_ERROR:
            return { ...state, error: action.error };
        case REQUEST_REAL_TIME_SUCCESS:
            return { ...state, notificationMercure: action.eventSource };
        case REQUEST_REAL_TIME_ERROR:
            return { ...state, error: action.error };
        case ADD_NEW_NOTIFICATION:
            profile.notifications = [action.notification, ...profile?.notifications];

            return { ...state, profile: profile };
        case DISCONNECT || NOT_CONNECTED:
            state.notificationMercure.close();
            return { ...INIT_STATE };
        default:
            return state;
    }
}
