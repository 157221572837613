import { Table, TableBody, TableContainer } from "@mui/material";
import { useState } from "react";
import { MoveFileFolder } from "../MoveFileFolder/MoveFileFolder";
import { DocumentsTableHeader } from "./DocumentsTableHeader";
import { DocumentSkeletonLine } from "./DocumentTableLine";
import { DocumentTableLine } from "./DocumentTableLine";

export const DocumentsList = ({
    changeFilters,
    documentsData,
    handleDeleteFileFolder,
    moveFileFolder,
    updateFileFolder,
    setFileFolderDetail,
    fileFolderDetail,
    createLoading,
    createFileFolder,
    path,
}) => {
    const [checkedFileFolder, setCheckedFileFolder] = useState([]);

    const [openMoveFileFolder, setOpenMoveFileFolder] = useState(null);

    const handleCheckbox = (isChecked, id) => {
        if (isChecked) {
            setCheckedFileFolder(checkedFileFolder.concat(id));
        } else {
            setCheckedFileFolder(checkedFileFolder.filter((fileFolderId) => fileFolderId !== id));
        }
    };

    const handleHeaderCheckBox = (isChecked) => {
        if (!documentsData.documentsList) {
            return;
        }

        if (isChecked) {
            let checkedList = [];

            documentsData?.documentsList?.forEach((fileFolder) => {
                if (!fileFolder.project) {
                    checkedList.push(fileFolder.id);
                }
            });

            setCheckedFileFolder(checkedList);
        } else {
            setCheckedFileFolder([]);
        }
    };

    const loading = documentsData.loading && !documentsData?.error;
    return (
        <>
            <TableContainer>
                <Table>
                    <DocumentsTableHeader
                        filters={documentsData?.filters}
                        changeFilters={changeFilters}
                        isAllChecked={
                            checkedFileFolder?.length ===
                            documentsData?.documentsList?.filter((elem) => elem.project === null)
                                .length
                        }
                        checkedList={checkedFileFolder}
                        deleteFileFolder={handleDeleteFileFolder}
                        handleHeaderCheck={handleHeaderCheckBox}
                        moveFileFolder={(currentTarget) => setOpenMoveFileFolder(currentTarget)}
                    />

                    <TableBody>
                        {documentsData.documentsList?.map((item, index) => (
                            <DocumentTableLine
                                item={item}
                                key={index}
                                checkedList={checkedFileFolder}
                                handleCheckClick={handleCheckbox}
                                updateFileFolder={updateFileFolder}
                                clickOnDetail={(id) => {
                                    setFileFolderDetail(id === fileFolderDetail ? null : id);
                                }}
                                deleteFileFolder={handleDeleteFileFolder}
                            />
                        ))}

                        {loading &&
                            [...Array(3)].map((_, index) => <DocumentSkeletonLine key={index} />)}
                    </TableBody>
                </Table>
            </TableContainer>

            {Boolean(openMoveFileFolder) && (
                <MoveFileFolder
                    anchorEl={openMoveFileFolder}
                    closePopover={() => setOpenMoveFileFolder(null)}
                    path={path}
                    loading={createLoading}
                    moveFileFolder={(newPath) => {
                        moveFileFolder(checkedFileFolder, newPath);

                        setCheckedFileFolder([]);
                    }}
                    createFolder={createFileFolder}
                    fileFolderList={checkedFileFolder}
                />
            )}
        </>
    );
};
