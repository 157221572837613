import { Box, Chip, Skeleton, Typography } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import EventIcon from "@mui/icons-material/Event";
import moment from "moment";
import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { LabelChip } from "../LabelChip";
import { NoBorderCheckbox, NoteImage, NoteTaskDescriptionTypo } from "../sc.Notes";

export const DisplayNoteElement = ({ item, notesTypes, setEditMode, filters, changeFilters }) => {
    const [displayAllCheck, setDisplayAllCheck] = useState(false);

    return (
        <Box height="fit-content" p="1px" width="100%">
            <CmtCard className="fullWidth fullHeight pointer" onClick={() => setEditMode(item?.id)}>
                <Box p={4}>
                    <Typography component="p" variant="subtitle1">
                        {item?.title}
                    </Typography>
                </Box>

                {item?.imageFileName && (
                    <Box display="flex" justifyContent="center">
                        <NoteImage src={item.imageFileName} width="100%" />
                    </Box>
                )}

                <Box p={4}>
                    <Typography component="p" variant="body2">
                        {item?.description}
                    </Typography>
                </Box>

                {item?.noteChecks?.map((check, checkIndex) => (
                    <Box
                        key={checkIndex}
                        display={!displayAllCheck && checkIndex > 3 ? "none" : "flex"}
                        px={4}
                        py={0}
                        alignItems="center"
                    >
                        <NoBorderCheckbox checked={check?.isDone} size="small" disabled />
                        <NoteTaskDescriptionTypo component="p" isDone={check?.isDone}>
                            {check?.description}{" "}
                        </NoteTaskDescriptionTypo>
                    </Box>
                ))}
                {item?.noteChecks?.length > 3 && !displayAllCheck && (
                    <Box
                        pl={4}
                        className="pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            setDisplayAllCheck(true);
                        }}
                    >
                        <MoreHorizIcon color="primary" />
                    </Box>
                )}

                <Box p={4} display="flex" flexWrap="wrap">
                    {item.reminderDate && (
                        <Box pr={2} py={1}>
                            <Chip
                                variant="outlined"
                                icon={<EventIcon />}
                                size="small"
                                label={moment(item.reminderDate).format("YYYY-MM-DD HH:mm")}
                            />
                        </Box>
                    )}
                    {notesTypes &&
                        item?.noteTypes?.map((type, noteIndex) => (
                            <Box
                                pr={2}
                                py={1}
                                onClick={(e) => e.stopPropagation()}
                                className="pointer"
                            >
                                <LabelChip
                                    type={type}
                                    noteIndex={noteIndex}
                                    notesTypes={notesTypes}
                                    variant={
                                        filters?.category === type?.id ? "default" : "outlined"
                                    }
                                    onClick={() =>
                                        changeFilters({ ...filters, category: type?.id })
                                    }
                                />
                            </Box>
                        ))}
                </Box>
            </CmtCard>
        </Box>
    );
};

export const NoteSkeletonElement = () => {
    return (
        <Box height="fit-content" p={1}>
            <CmtCard className="fullWidth fullHeight pointer">
                <Box p={4}>
                    <Skeleton variant="text" width="40%" />
                </Box>

                <Box p={4}>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                </Box>

                <Box px={4} py={5} display="flex" justifyContent="space-between">
                    <Skeleton width="45%" height="30px" />
                    <Skeleton width="45%" height="30px" />
                </Box>
            </CmtCard>
        </Box>
    );
};
