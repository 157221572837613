import { Box, Typography, useTheme } from "@mui/material";
import { SwiperSlide } from 'swiper/react';
import CmtCard from "Components/CmtCard/CmtCard";
import { EDIT_PATH, PROJECT_PATH } from "Constant";
import 'swiper/swiper.scss';
import './navigation.scss';
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";
import { ResultSlider } from "../sc.ConnectedContainer";

export const DisplayProjectResult = ({results, handleClick}) => {
    const theme = useTheme();

    return (
        <Box pt={5}>
            <Box pl={11}>
                <Typography component='p' variant='h4' color='whitesmoke'> Projets </Typography>
            </Box>
            <ResultSlider navigation={true} spaceBetween={10} slidesPerView={'auto'} pagination={true} >
                {results?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Box width={200} className='pointer' onClick={() => handleClick(`${PROJECT_PATH}/${item?.id}${EDIT_PATH}`)}>
                            <CmtCard>
                                <Box pt={2} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                    <AvatarComponent size={150} src={item.logoFileName} />
                                    <Box width='100%' mt={2} p={2} pl={2} borderTop={`1px solid ${theme.palette.borderColor.dark}`}>
                                        <Typography component='p' variant='subtitle1'>{item?.name}</Typography>
                                    </Box>
                                </Box>
                            </CmtCard>
                        </Box>
                    </SwiperSlide>
                ))}
            </ResultSlider>
        </Box>
    );
};