import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { IncompleteProfile } from "Components/IncompletProfile/IncompleteProfile";

export const RestrictedModale = ({ close }) => {
    return (
        <Dialog open={true} maxWidth="sm" fullWidth onClose={close}>
            <DialogTitle>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <CloseIcon color="primary" onClick={close} />
                </Box>

                <DialogContent>
                    <IncompleteProfile
                        text="Un profil incomplet vous empêchera d'accéder à certains
                        contenus. Pour compléter votre profil, assurez d'avoir renseigné
                        les informations suivantes :"
                    />
                </DialogContent>
            </DialogTitle>
        </Dialog>
    );
};
