import { alpha, Box, styled } from "@mui/system";
import { Button, Tab, Tabs, Typography } from "@mui/material";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { blue } from "@mui/material/colors";

export const ProfileContainer = styled(Box)`
    width: 100%;
    padding-inline: 88px;
    padding-top: 30px;
    ${(props) => props.theme.breakpoints.down("md")} {
        padding: 0px;
    } ;
`;

export const TabsList = styled(Tabs)`
    position: relative;
    min-height: 10px;
    & .MuiTabs-indicator {
        background-color: ${(props) => alpha(props.theme.palette.common.white, 0.4)};
    }
    & .Mui-selected {
        color: white !important;
        font-weight: 600;
    }
`;

export const TabItem = styled(Tab)`
    max-width: none;
    min-width: 10px;
    min-height: 10px;
    padding: 5px 10px;
    text-transform: capitalize;
    color: ${(props) => props.theme.palette.common.white};
    font-size: 14px;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;

export const ProfileHeaderRoot = styled(Box)`
    position: relative;
    margin: -30px -15px 0 -15px;
    padding-right: 15px;
    padding-left: 150px;
    padding-top: 30px;
    padding-bottom: 110px;
    ${(props) => props.theme.breakpoints.up("md")} {
        margin-inline: -88px;
        padding-inline: 88px;
    }
    ${(props) => props.theme.breakpoints.down("md")} {
        padding-left: 20px;
        padding-top: 40px;
        margin-right: 0;
    } ;
`;

export const TextFieldRoot = styled(FormInput)`
    &:hover .MuiInputBase-root:before,
    .MuiInputBase-root:before {
        border-color: ${(props) => props.theme.palette.common.white};
    }

    & .MuiInputBase-input {
        color: ${(props) => props.theme.palette.common.white};
    }

    & .MuiFormLabel-root {
        color: ${(props) => props.theme.palette.common.white};
    }
    width: ${(props) => (props.width ? props.width : "100px")};
`;

export const TileTitle = styled(Typography)`
    padding-left: 15px;
    padding-top: 20px;
    ${(props) => props.theme.breakpoints.down("sm")} {
        font-size: 13px;
    } ;
`;

export const IconView = styled(Box)`
    background-color: ${alpha(blue["500"], 0.1)};
    color: ${(props) => props.theme.palette.secondary.main};
    padding: 8px;
    border-radius: 4px;
    width: 40px;
    height: 40px;
`;

export const DeleteIconButton = styled(Button)`
    background-color: ${(props) => props.theme.palette.error.main};
    transition: 1s;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 0;
    color: white;
    &:hover {
        background-color: ${(props) => props.theme.palette.error.light};
    }
`;
