import { Box } from "@mui/material";
import linkify from "services/Linkify/linkify";
import { motion } from "framer-motion";

export const Message = ({ message }) => {
    return (
        <motion.div key={message.id}>
            <Box width="100%" display="flex" overflow="hidden" flexDirection="column" my="1em">
                <Box
                    display="flex"
                    alignItems="center"
                    width="fit-content"
                    maxWidth="100%"
                    height="fit-content"
                    mr="2em"
                >
                    <Box
                        p={2}
                        my={0}
                        borderRadius="5px"
                        component="p"
                        dangerouslySetInnerHTML={{
                            __html: linkify(message?.message?.replace(/(?:\r\n|\r|\n)/g, "<br />")),
                        }}
                    />
                </Box>
            </Box>
        </motion.div>
    );
};
