import { Grid } from "@mui/material";
import { SpectaclePoster } from "./SpectaclePoster/SpectaclePoster";
import { SpectacleGeneralInfos } from "./SpectacleGeneralInfos/SpectacleGeneralInfos";
import { SpectacleProdDiff } from "./SpectacleProdDiff/SpectacleProdDiff";
import { SpectacleAuthor } from "./SpectacleAuthor/SpectacleAuthor";

export const SpectacleTab = ({ projectData, updateProject }) => {
    return (
        <Grid container>
            <Grid item xs={12} lg={4}>
                <SpectaclePoster projectData={projectData} updateProject={updateProject} />
            </Grid>
            <Grid item xs={12} lg={8}>
                <SpectacleGeneralInfos projectData={projectData} updateProject={updateProject} />
                <SpectacleProdDiff projectData={projectData} updateProject={updateProject} />
                <SpectacleAuthor projectData={projectData} updateProject={updateProject} />
            </Grid>
        </Grid>
    );
};
