import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { TextField } from "@mui/material";

export default function SendingContent({ sendMessage }) {
    const [message, setMessage] = useState("");

    const handleSendMessage = (e) => {
        e.preventDefault();

        sendMessage(message);

        setMessage("");
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey && message) {
            handleSendMessage(event);
        }
    };

    return (
        <>
            <Box width="100%" py={2} px={3}>
                <Typography component="p" variant="h5" my={5}>
                    Nous sommes ravis de vous avoir sur Impul'scène ! Comment se passe votre
                    expérience ? Faites nous part de vos impressions et suggestions d'amélioration.
                </Typography>
                <Box px={0} width="100%" mt={10}>
                    <TextField
                        id="chat-textarea"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Tapez un message..."
                        variant="standard"
                        multiline
                        rows={5}
                        fullWidth={true}
                    />
                    <Box display="flex" justifyContent={"flex-end"} mt={4}>
                        <Button onClick={handleSendMessage} variant="contained">
                            Envoyer
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
