import { CHANGE_DOCUMENTS_FILTER, GET_DOCUMENTS, GET_DOCUMENTS_ERROR, GET_DOCUMENTS_SUCCESS } from "redux/constants/documentsConstants";
import auth from "services/Api/auth";
import documents from "services/Api/documents";
import { notConnectedAction } from "./authActions";

const requestDocuments = () => ({
    type: GET_DOCUMENTS,
    loading: true,
});

const requestDocumentsSuccess = (data, path) => ({
    type: GET_DOCUMENTS_SUCCESS,
    loading: false,
    data: data,
    path: path,
});

const requestDocumentsError = (error) => ({
    type: GET_DOCUMENTS_ERROR,
    loading: false,
    error: error,
});

const updateDocumentsFiltersAction = (filters) => ({
    type: CHANGE_DOCUMENTS_FILTER,
    filters: filters,
});

export const getDocumentsDataAction = (path) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        dispatch(requestDocuments());

        const state = getState().documents;

        documents.getDocumentsList(state.filters, path)
        .then(result => {
            if (result?.result) {
                dispatch(requestDocumentsSuccess(result?.data, path));
            } else {
                dispatch(requestDocumentsError(result?.error));
            }

            return result;
        });
    };
};


export const createFileFolderAction = (data) => {
    return async (dispatch) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = documents.createFileFolder(data)
        .then(result => {
            if (result?.result) {
                dispatch(getDocumentsDataAction(data.folderId));
            }

            return result;
        });

        return (result);
    };
};

export const changeDocumentsFiltersAction = (filters) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }
        
        dispatch(updateDocumentsFiltersAction(filters));

        const state = getState().documents;

        const result = documents.getDocumentsList(filters, state.path)
        .then(result => {
            if (result?.result) {
                dispatch(requestDocumentsSuccess(result?.data, state.path));
            } else {
                dispatch(requestDocumentsError(result?.error));
            }

            return result;
        });

        return result;
    };
};


export const deleteFileFolderAction = (list) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }
        
        const state = getState().documents;

        for (let a = 0; a < list.length; a++) {
            await documents.deleteFileFolder(list[a]);
        }
        
        dispatch(getDocumentsDataAction(state.path));
    };
};


export const moveFileFolderAction = (list, path) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        for (let a = 0; a < list.length; a++) {
            await documents.moveFileFolder(list[a], path);
        }
        
        const state = getState().documents;

        dispatch(getDocumentsDataAction(state.path));
    };
};

export const updateFileFolderAction = (data) => {
    return async (dispatch, getState) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = documents.updateFileFolder(data)
        .then(result => {
            if (result?.result) {
                const state = getState().documents;

                dispatch(getDocumentsDataAction(state.path));
            }

            return result;
        })
        
        return result;
    };
}