import { Box, Grid } from "@mui/material";
import CmtGridContainer from 'Components/CmtGridContainer/CmtGridContainer';
import { ProfileDescription } from "Apps/Profile/ProfileTab/ProfileDescription/ProfileDescription";
import { ProfileInfosCard } from "./ProfileInfos/ProfileInfosCard";
import { ProfileContact } from "./ProfileContact/ProfileContact";

export const ProfileTab = ({profile, updateProfile, positionType, socialType}) => {

    return (
        <CmtGridContainer spacing={0}>
            <Grid item xs={12} lg={4} >
                <Box p={2}>
                    <ProfileInfosCard positionType={positionType} profile={profile} update={updateProfile} />
                </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Box p={2}>
                    <ProfileDescription profile={profile} update={updateProfile} />
                </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Box p={2}>
                    <ProfileContact socialType={socialType} profile={profile} update={updateProfile} />
                </Box>
            </Grid>
        </CmtGridContainer>
    );
};