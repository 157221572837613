import { Checkbox, Chip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import CmtImage from "Components/CmtImage/CmtImage";

export const NoteLabelChip = styled(Chip)`
    color: ${(props) =>
        props.variant === "outlined" ? `${props.typeColor}` : "rgba(255, 255, 255, 1)"};
    border-color: ${(props) => props?.typeColor};
    background-color: ${(props) => props.variant === "default" && props.typeColor};
    & .MuiSvgIcon-root {
        color: ${(props) =>
            props.variant === "outlined" ? props.typeColor : "rgba(255, 255, 255, 1)"};
    }
`;

export const NoteImage = styled(CmtImage)`
    max-width: 400px;
    max-height: 500px;
    object-fit: contain;
`;

export const NoBorderCheckbox = styled(Checkbox)`
    padding: 0;
`;

export const NoteTaskDescriptionTypo = styled(Typography)`
    text-decoration: ${(props) => props.isDone && "line-through"};
    color: ${(props) => props.isDone && "rgba(0, 0, 0, 0.42)"};
`;
