import React from 'react';
import Grid from '@mui/material/Grid';

const GridContainer = ({ children, spacing = 6, ...rest }) => {
    return (
        <Grid container spacing={spacing} {...rest}>
            {children}
        </Grid>
    );
};

export default GridContainer;
