import { Box, styled } from "@mui/system";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

export const DisplayDocumentsContainer = styled(Box)`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    ${(props) => props.theme.breakpoints.up("lg")} {
        margin-right: ${(props) => props.detail && "350px"};
    } ;
`;

export const DisplayMoveFolder = styled(Box)`
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: ${(props) => (props.isFolder ? "pointer" : "not-allowed")};
    transition: 1s;
    position: relative;
    &:hover {
        background-color: ${(props) => props.isFolder && props.theme.palette.primary.light};
    }

    & .MuiTypography-root {
        color: ${(props) => !props.isFolder && "grey"};
    }
`;

export const EnterFolderIcon = styled(KeyboardReturnIcon)`
    transform: scaleX(-1);
`;

export const DocumentsGridElem = styled(Box)`
    transition: all 0.5s ease;
    &:hover {
        & .action-option {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
`;

export const GridViewContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    ${(props) => props.theme.breakpoints.down("lg")} {
        margin-right: 0px;
    } ;
`;

export const PreviewImage = styled((props) => (
    <img src={props.src} alt={props.alt || "picture"} {...props} />
))`
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
`;

export const DetailContainer = styled(Box)`
    width: 350px;
    background-color: #ffff;
    position: fixed;
    flex-shrink: 0;
    box-shadow: ${(props) => props.theme.palette.sidebar.shadow};
    top: 70px;
    right: 0;
    bottom: 0;
    overflow-y: auto;
`;
