import { Box, Button, CardContent, Hidden } from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import CmtForm from "Components/CmtForm/CmtForm";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import ReactMapBoxGl, { ZoomControl } from "react-mapbox-gl";
import { useRef } from "react";
import { MAPBOX_TOKEN, MAPBOX_STYLE, ZOOM_MIN } from "Constant";
import { MapContext, MapControl } from "./MapControl";
import { CmtMarker } from "Components/CmtMapBoxElements/CmtMarker/CmtMarker";

const MapBox = ReactMapBoxGl({
    accessToken: MAPBOX_TOKEN,
    scrollZoom: true,
    minZoom: ZOOM_MIN,
});

const mapStyle = {
    minHeight: "400px",
    height: "100%",
    width: "100%",
};

export const EditionProjectLocation = ({ projectData, setEditionMode, submit }) => {
    const mapRef = useRef(null);

    return (
        <CmtCard>
            <TileTitle>Adresse</TileTitle>
            <CardContent>
                <Formik
                    initialValues={{
                        fullAddress: projectData?.fullAddress || "",
                        lat: projectData?.lat || "",
                        lng: projectData?.lng || "",
                    }}
                    onSubmit={(values) => {
                        submit(values);
                        setEditionMode(false);
                    }}
                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        <CmtForm onSubmit={handleSubmit}>
                            <MapBox
                                // eslint-disable-next-line
                                style={MAPBOX_STYLE}
                                containerStyle={mapStyle}
                                ref={mapRef}
                                center={[2.209667, 46.8]}
                                zoom={[ZOOM_MIN]}
                                flyToOptions={{ speed: 0.8 }}
                            >
                                <MapContext.Provider value={{ mapRef }}>
                                    <MapControl
                                        control={
                                            new MapboxGeocoder({
                                                accessToken: MAPBOX_TOKEN,
                                                countries: "fr",
                                                marker: false,
                                            })
                                        }
                                        onSelect={(value) => {
                                            setFieldValue(
                                                "fullAddress",
                                                value.result?.place_name_fr
                                            );
                                            setFieldValue(
                                                "lng",
                                                value.result?.geometry?.coordinates[0]
                                            );
                                            setFieldValue(
                                                "lat",
                                                value.result?.geometry?.coordinates[1]
                                            );
                                        }}
                                        initValue={values.fullAddress}
                                    />
                                </MapContext.Provider>
                                {values?.lng && values?.lat && (
                                    <CmtMarker
                                        style={{ zIndex: 1 }}
                                        coordinates={[values.lng, values.lat]}
                                    />
                                )}
                                <ZoomControl />
                            </MapBox>
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="flex-end"
                                mt={3}
                                height={30}
                            >
                                <Box mr={2}>
                                    <Button
                                        type="button"
                                        onClick={() => setEditionMode(false)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <Hidden xsDown> Annuler </Hidden>
                                        <Hidden smUp>
                                            <CloseIcon />
                                        </Hidden>
                                    </Button>
                                </Box>

                                <Box ml={2}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        id="locationSubmitButton"
                                    >
                                        <Hidden xsDown> Valider </Hidden>
                                        <Hidden smUp>
                                            <CheckIcon />
                                        </Hidden>
                                    </Button>
                                </Box>
                            </Box>
                        </CmtForm>
                    )}
                </Formik>
            </CardContent>
        </CmtCard>
    );
};
