import {
    Box,
    CardContent,
    Grid,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
} from "@mui/material";
import CmtCard from "Components/CmtCard/CmtCard";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { TileTitle, IconView } from "Components/CmtProfileContainer/sc.ProfileContainer";
import moment from "moment";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { CmtDatePicker } from "Components/CmtDatePicker/CmtDatePicker";
import EventIcon from "@mui/icons-material/Event";

export const CreateProjectInfos = ({
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    projectType,
}) => {
    return (
        <Box p={2}>
            <CmtCard>
                <TileTitle>Informations</TileTitle>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <FormInput
                                name="name"
                                label="Nom"
                                id="projectName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.name}
                                margin="none"
                                error={touched.name && errors.name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="labelprojectType">Type de projet</InputLabel>
                                <Select
                                    labelId="labelprojectType"
                                    id={"projectTypesSelect"}
                                    value={values.projectType}
                                    name="projectType"
                                    onChange={handleChange}
                                    error={touched.projectType && Boolean(errors.projectType)}
                                    onBlur={handleBlur}
                                >
                                    {projectType?.map((item, index) => (
                                        <MenuItem
                                            value={item.id}
                                            key={`projectType ${index}`}
                                            id={`projectTypeItem-${index}`}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>
                                    {touched.projectType && errors.projectType}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <EventIcon />
                                </IconView>
                                <Box ml={5} width="100%">
                                    <Box component="span" fontSize={12} color="primary">
                                        Date de début estimée
                                    </Box>
                                    <CmtDatePicker
                                        fullWidth
                                        value={values.startDate}
                                        disablePast
                                        onTouched={setFieldTouched}
                                        name="startDate"
                                        id="infosStartDateInput"
                                        setValue={(value) => {
                                            setFieldValue(
                                                "startDate",
                                                moment(value).format("YYYY-MM-DD")
                                            );
                                        }}
                                        error={touched.startDate && errors.startDate}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <EventIcon />
                                </IconView>
                                <Box ml={5} width="100%">
                                    <Box component="span" fontSize={12} color="primary">
                                        Date de fin estimée
                                    </Box>
                                    <CmtDatePicker
                                        fullWidth
                                        value={values.endDate}
                                        disablePast
                                        id="infosEndDateInput"
                                        onTouched={setFieldTouched}
                                        name="endDate"
                                        setValue={(value) =>
                                            setFieldValue(
                                                "endDate",
                                                moment(value).format("YYYY-MM-DD")
                                            )
                                        }
                                        error={touched.endDate && errors.endDate}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
                                <IconView>
                                    <ConfirmationNumberIcon />
                                </IconView>
                                <Box ml={5} width="100%">
                                    <Box component="span" fontSize={12} color="primary">
                                        Nombre de représentation prévues
                                    </Box>
                                    <FormInput
                                        type="text"
                                        name="performancesNb"
                                        id="performancesNbInput"
                                        value={values.performancesNb}
                                        margin="none"
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;

                                            if (e.target.value === "" || re.test(e.target.value)) {
                                                setFieldValue("performancesNb", e.target.value);
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </CmtCard>
        </Box>
    );
};
