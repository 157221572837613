import { useTheme } from "@emotion/react";
import { Box, CardContent, Grid } from "@mui/material";
import { PresentationBox, PublicTypo } from "../sc.Public";

export const PriceBlockElem = ({ title, subtitle, list, children, ...rest }) => {
    const theme = useTheme();

    return (
        <Grid item px={{ xs: 5, md: 13 }} xs={12} md={6} lg={4} py={10} {...rest}>
            <PresentationBox {...rest} sx={{ py: 15, display: "flex", flexDirection: "column" }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <PublicTypo
                        variant="h1"
                        family={theme.palette.publicTypo.hFamily}
                        color="primary"
                    >
                        {title}
                    </PublicTypo>
                    <PublicTypo
                        variant="h1"
                        family={theme.palette.publicTypo.hFamily}
                        color="primary"
                    >
                        {subtitle}
                    </PublicTypo>
                </Box>

                <CardContent>
                    <Box component="ul">
                        {list?.map((item, index) => (
                            <PublicTypo
                                component="li"
                                family={theme.palette.publicTypo.pFamily}
                                pt={3}
                                color="primary"
                                key={index}
                            >
                                {item}
                            </PublicTypo>
                        ))}
                    </Box>
                </CardContent>
                {children}
            </PresentationBox>
        </Grid>
    );
};
