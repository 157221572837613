import { Box, Tooltip, Popover, Typography, Chip } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useState } from "react";
import {
    NotificationDescription,
    NotificationElement,
    PopoverIconButton,
    NotificationCircle,
} from "Apps/ConnectedContainer/sc.ConnectedContainer";
import moment from "moment";
import history from "services/History/history";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getNotificationsAction } from "redux/actions/profileActions";
import AddIcon from "@mui/icons-material/Add";

export default function Notifications({ profile, markAsRead }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [historyLoading, setHistoryLoading] = useState(profile.notificationLoading);

    const dispatch = useDispatch();

    const onOpenPopOver = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onClosePopOver = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        setHistoryLoading(profile.notificationLoading);
    }, [profile]);

    const dateMessage = (date) => {
        const now = moment();

        switch (true) {
            case moment(date).isAfter(moment().subtract(2, "minute")):
                return "Maintenant";
            case moment(date).isAfter(moment().subtract(1, "hour")):
                return `Il y a ${moment.duration(now.diff(date)).minutes()} minutes`;
            case moment(date).isAfter(moment().subtract(1, "day")):
                return moment(date).format("HH : mm");
            default:
                return moment(date).format("DD/MM/YYYY");
        }
    };

    const loadMore = () => {
        if (profile?.canLoadNotifications && !historyLoading) {
            setHistoryLoading(true);

            dispatch(
                getNotificationsAction({
                    lastId: profile?.profile?.notifications.at(-1).id,
                    limit: profile?.notificationLimit,
                })
            );
        }
    };

    return (
        <>
            <Tooltip title="Notifications">
                <PopoverIconButton open={open} onClick={onOpenPopOver} color="primary" size="large">
                    <NotificationsIcon />
                    {profile?.profile?.notifications?.filter((item) => !item?.isRead).length >
                        0 && <NotificationCircle opened={open} />}
                </PopoverIconButton>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClosePopOver}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box maxHeight="70vh" width="300px">
                    <Typography component="p" m={3} fontWeight={600}>
                        Notifications
                    </Typography>
                    {profile?.profile?.notifications?.map((item, index) => (
                        <NotificationElement
                            px={3}
                            py={2}
                            key={index}
                            readed={item?.isRead}
                            onClick={() => {
                                markAsRead(item?.id);
                                history.push(item?.link);
                            }}
                        >
                            <Typography
                                component="p"
                                fontSize={13}
                                fontWeight={!item.isRead && "bold"}
                                color="primary"
                                lineHeight="20px"
                                height="20px"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nobreak"
                            >
                                {item?.title}
                            </Typography>

                            <NotificationDescription component="p" color="textSecondary">
                                {item?.description}
                            </NotificationDescription>

                            <Box display="flex" justifyContent="flex-end" width="100%" pr={3}>
                                <Typography component="p" fontSize={10}>
                                    {dateMessage(item?.createdAt)}
                                </Typography>
                            </Box>
                        </NotificationElement>
                    ))}
                    {profile?.canLoadNotifications && (
                        <Box display="flex" justifyContent="center" py={3}>
                            <Chip
                                variant="default"
                                icon={<AddIcon />}
                                label="Voir la suite"
                                size="small"
                                onClick={loadMore}
                            />
                        </Box>
                    )}
                </Box>
            </Popover>
        </>
    );
}
