import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { ShapeImg } from "Components/Public/ShapeImg/ShapeImg";
import { PriceBlockElem } from "../Partial/PriceBlockElem";

export const PriceBlock = () => {
    return (
        <Box py={20} backgroundColor="#f8bc0c">
            <Container maxWidth="lg">
                <Grid container width="100%" px={10}>
                    <PriceBlockElem
                        title="- 0€ -"
                        subtitle="1ère scène"
                        list={[
                            "Ullit, vidit quidebit que ima nam volor as.",
                            "Ullit, vidit quidebit que ima nam volor as.",
                            "Ullit, vidit quidebit que ima nam volor as.",
                        ]}
                    >
                        <ShapeImg
                            src="/images/Public/Shape/Shape8.png"
                            rotate="180deg"
                            top="10px"
                            left="10px"
                        />

                        <ShapeImg
                            src="/images/Public/Shape/Shape14.png"
                            rotate="90deg"
                            bottom="-50px"
                            right="-50px"
                        />
                    </PriceBlockElem>

                    <PriceBlockElem
                        title="- X€ -"
                        subtitle="Bercy"
                        list={[
                            "Ullit, vidit quidebit que ima nam volor as.",
                            "Ullit, vidit quidebit que ima nam volor as.",
                            "Ullit, vidit quidebit que ima nam volor as.",
                        ]}
                    >
                        <ShapeImg
                            src="/images/Public/Shape/Shape18.png"
                            rotate="180deg"
                            top="10px"
                            left="10px"
                        />

                        <ShapeImg
                            src="/images/Public/Shape/Shape15.png"
                            rotate="90deg"
                            bottom="-50px"
                            right="-50px"
                        />
                    </PriceBlockElem>

                    <PriceBlockElem
                        title="- XX€ -"
                        subtitle="Stade de FR"
                        list={[
                            "Ullit, vidit quidebit que ima nam volor as.",
                            "Ullit, vidit quidebit que ima nam volor as.",
                            "Ullit, vidit quidebit que ima nam volor as.",
                        ]}
                    >
                        <ShapeImg src="/images/Public/Shape/Shape17.png" top="10px" left="10px" />

                        <ShapeImg
                            src="/images/Public/Shape/Shape16.png"
                            rotate="90deg"
                            bottom="-50px"
                            right="-50px"
                        />
                    </PriceBlockElem>
                </Grid>
            </Container>
        </Box>
    );
};
