import { useState } from "react";
import DisplayProjectDescriptionCard from "./DisplayProjectDescriptionCard";
import EditionProjectDescriptionCard from "./EditionProjectDescriptionCard";

export const ProjectDescriptionCard = ({ projectData, updateProject }) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (
            <EditionProjectDescriptionCard
                projectData={projectData}
                setEditionMode={setEditionMode}
                submit={updateProject}
            />
        );
    } else {
        return (
            <DisplayProjectDescriptionCard
                projectData={projectData}
                setEditionMode={setEditionMode}
            />
        );
    }
};
