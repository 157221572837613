import { Button, Slider, Switch, Typography, Accordion } from "@mui/material";
import { styled } from "@mui/system";
import CmtCard from "Components/CmtCard/CmtCard";
import { Swiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

export const PublicTypo = styled(Typography)`
    font-family: ${(props) => props.family};
    font-size: ${(props) => props.size};
    text-overflow: "-";
`;

export const PublicButton = styled(Button)`
    color: ${(props) => props.textColor || "rgba(255, 255, 255, 1)"};
    text-transform: none;
    font-weight: 600;
    font-size: 1.1em;
    font-family: ${(props) => props.family || "Helvetica"};
`;

export const PresentationBox = styled(CmtCard)`
    position: relative;
    padding-block: 2rem;
    height: 100%;
    overflow: visible;
    background-color: ${(props) => props.theme.palette.background.paper};
`;

export const SwiperWrapper = styled(Swiper)`
    padding-inline: 20px;
    padding-block: 50px;
    display: flex;
    justify-content: center;
    & .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        ${(props) => props.theme.breakpoints.down("md")} {
            width: 100%;
        }
    }

    & .swiper-button-next,
    .swiper-button-prev {
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

export const PrettoSlider = styled(Slider)`
    color: ${(props) => props.theme.palette.primary.publicMain};
    & .MuiSlider-rail {
        border: ${(props) => `3px solid ${props.theme.palette.primary.main}`};
    }
    height: 15px;
    border-radius: 30px;

    & .MuiSlider-track {
        border: "none";
        margin-left: 2px;
        background-color: ${(props) => props.theme.palette.primary.light};
    }

    & .MuiSlider-thumb {
        height: 25px;
        width: 25px;
        background-color: ${(props) => props.theme.palette.primary.main};

        &:focus,
        &:hover,
        & .Mui-active,
        & .Mui-focusvisible {
            boxshadow: none;
        }

        before {
            display: "none";
        }
    }
    & .muislider-valuelabel {
        lineheight: 1.2;
        fontsize: 12;
        background: "unset";
        padding: 0;
        width: 32;
        height: 32;
        borderradius: "50% 50% 50% 0";
        backgroundcolor: "#52af77";
        transformorigin: "bottom left";
        transform: "translate(50%; -100%) rotate(-45deg) scale(0)";
        "&:before": {
            display: "none";
        }
        "&.muislider-valuelabelopen": {
            transform: "translate(50%; -100%) rotate(-45deg) scale(1)";
        }
        "& > *": {
            transform: "rotate(45deg)";
        }
    }
`;

export const PaymentSwitch = styled(Switch)`
    width: 80px;
    align-items: center;

    & .MuiSwitch-track,
    .MuiSwitch-track {
        background-color: ${(props) => props.theme.palette.primary.main};
        opacity: 0.5;
        flex-shrink: 0;
        height: 20px;
        border-radius: 40px;
    }
    & .MuiSwitch-thumb {
        background-color: ${(props) => props.theme.palette.primary.main};
    }

    & .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(100%);
    }
`;

export const AccordionElem = styled(Accordion)`
    margin-bottom: 0 !important;
    border-top: ${(props) => `1px dashed ${props.theme.palette.primary.main}`};

    &:before {
        display: none;
    }
`;
