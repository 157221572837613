import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

export default function DisplayModeProjectInfos ({project, setEditionMode}) {
    const [hover, setHover] = useState(false);

    return (
        <Box
            display='flex'
            alignItems='center'
            pr={10}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Typography
                component="p"
                variant="h1"
                color='white'
                onClick={() => setEditionMode(true)}
            >
                {project?.name} {hover && 
                    <Box position='absolute' right={0} ml={5} component='span' height='100%' color='white' className='pointer'>
                        <CreateOutlinedIcon />
                    </Box>
                }
            </Typography>
        </Box>
    );
};