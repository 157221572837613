import { useState } from "react";
import DisplayInfosCard from "./DisplayInfosCard";
import EditionInfosCard from './EditionInfosCard';

export const ProfileInfosCard = ({profile, positionType, update}) => {
    const [editionMode, setEditionMode] = useState(false);

    if (editionMode) {
        return (<EditionInfosCard profile={profile} setEditionMode={setEditionMode} positionType={positionType} submit={update} />);
    } else {
        return (<DisplayInfosCard profile={profile} setEditionMode={setEditionMode} />);
    }


};