import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { DEFAULT_COMPAGNY_SIZE_LIST, REDIRECTION_TIME } from "Constant";
import { Formik } from "formik";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import auth from "services/Api/auth";
import companies from "services/Api/companies";

const INIT_VALUE = {
    name: "",
    compagniesTypes: [],
    siret: "",
    description: "",
    address1: "",
    address2: "",
    zipCode: "",
    city: "",
    size: "",
};

export const CreateCompanyForm = ({ submit, companiesType, disconnect, onClose, profile }) => {
    const [list, setList] = useState([]);
    const [disabled, setDisabled] = useState(true);

    const registeredCompanies = profile?.profile?.compagnyUserActives?.map(
        (item) => item?.compagny?.siret
    );

    const handleCompanyName = async (value) => {
        if (value.length < 3) {
            setList([]);

            return;
        }

        const check = await auth.checkIsAuth();

        if (!check) {
            disconnect();

            return;
        }

        const result = await companies.getCompaniesList(value);

        if (!result.result) {
            return;
        }

        let tmpList = result.data;
        tmpList.push({
            name: "JE NE TROUVE PAS MA STRUCTURE",
            default: true,
        });

        setList(tmpList);
    };

    const setCompanyValue = (newValue, setFieldValue) => {
        if (!newValue) {
            setFieldValue("name", "");
            setList([]);
            return;
        }

        setDisabled(false);

        if (newValue?.default) {
            return;
        }

        if (registeredCompanies.includes(newValue?.siret)) {
            NotificationManager.error(
                "Vous appartenez déjà à cette structure.",
                "Erreur",
                REDIRECTION_TIME
            );

            setFieldValue("name", "");

            return;
        }

        setFieldValue("name", newValue?.name || "");
        setFieldValue("siret", newValue?.siret || "");
        setFieldValue("address1", newValue?.address1 || "");
        setFieldValue("address2", newValue?.address2 || "");
        setFieldValue("zipCode", newValue?.zipCode || "");
        setFieldValue("city", newValue?.city || "");
    };

    const checkError = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = "Veuillez renseigner le nom de votre structure.";
        }

        if (!values.siret) {
            errors.siret = "Veuillez indiquer votre numéro de siret.";
        } else if (!/^[0-9]/i.test(values.siret)) {
            errors.siret = "Votre numéro de siret ne doit être composé que de chiffres.";
        } else if (values.siret.length < 14) {
            errors.siret = "Votre numéro de siret doit être composé de 14 chiffres.";
        } else if (registeredCompanies.includes(values.siret)) {
            errors.siret = "Vous appartenez déjà à une entreprise ayant ce numéro de siret.";
        }

        if (!values.address1) {
            errors.address1 = "Veuillez renseigner votre adresse.";
        }

        if (!values.zipCode) {
            errors.zipCode = "Veuillez renseigner votre code postal.";
        } else if (!/^[0-9]/i.test(values.zipCode)) {
            errors.zipCode = "Votre code postale est incorrect.";
        }

        if (!values.city) {
            errors.city = "Veuillez renseigner votre ville.";
        }

        if (values.compagniesTypes === "") {
            errors.compagniesTypes = "Veuillez indiquer le type de votre structure.";
        }

        if (!values.size) {
            errors.size = "Veuillez renseigner la taille de votre sctructure.";
        }

        return errors;
    };

    return (
        <Box>
            <Typography component="div" variant="h1">
                Enregistrer une structure
            </Typography>
            <Formik
                initialValues={INIT_VALUE}
                validate={(values) => checkError(values)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const result = await submit(values);

                    if (result?.result) {
                        setList([]);
                        resetForm();
                    }

                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                }) => (
                    <CmtForm onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    freeSolo
                                    filterOptions={(options) => options}
                                    id="nameAutoComplete"
                                    inputValue={values.name}
                                    onChange={(event, newValue) => {
                                        setCompanyValue(newValue, (name, val) =>
                                            setFieldValue(name, val)
                                        );
                                    }}
                                    options={list}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            mb={1}
                                            {...props}
                                            id={`autoComplete-${option.name.replaceAll(" ", "-")}`}
                                            display="flex"
                                            flexDirection="column"
                                            width="100%"
                                        >
                                            <Box my={0} width="100%">
                                                <Typography component="p" variant="h4">
                                                    {option.name}
                                                </Typography>
                                            </Box>
                                            <Box my={0} width="100%">
                                                <Typography component="p" variant="h5">
                                                    {option.siret}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <FormInput
                                            {...params}
                                            onChange={(e) => {
                                                setFieldValue("name", e.target.value);
                                                handleCompanyName(e.target.value);
                                            }}
                                            label="Nom de la structure"
                                            onBlur={handleBlur}
                                            error={touched.name && errors.name}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    type="text"
                                    name="siret"
                                    label="Siret"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.siret}
                                    error={touched.siret && errors.siret}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    type="text"
                                    name="address1"
                                    label="Adresse 1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address1}
                                    error={touched.address1 && errors.address1}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormInput
                                    type="text"
                                    name="address2"
                                    label="Adresse 2"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address2}
                                    error={touched.address2 && errors.address2}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <FormInput
                                    type="text"
                                    name="zipCode"
                                    label="Code Postal"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.zipCode}
                                    error={touched.zipCode && errors.zipCode}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} md={8} lg={9}>
                                <FormInput
                                    type="text"
                                    name="city"
                                    label="Ville"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.city}
                                    error={touched.city && errors.city}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id="LabelCompaniesType">
                                        Secteur d'activité
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="LabelPositionType"
                                        variant="standard"
                                        value={values.compagniesTypes}
                                        name="compagniesTypes"
                                        onChange={handleChange}
                                        error={
                                            touched.compagniesTypes &&
                                            Boolean(errors.compagniesTypes)
                                        }
                                        onBlur={handleBlur}
                                        renderValue={() => {
                                            const result = values?.compagniesTypes
                                                ?.map((element) => element?.name)
                                                .join(", ");

                                            return result;
                                        }}
                                        id="roleSelect"
                                        multiple
                                        disabled={disabled}
                                    >
                                        {companiesType?.map((item, index) => [
                                            <ListSubheader
                                                color="primary"
                                                key={`positionGroup ${index}`}
                                            >
                                                {item.name}
                                            </ListSubheader>,
                                            item.values.map((it, ind) => (
                                                <MenuItem
                                                    value={it}
                                                    key={`positionType ${ind}`}
                                                    id={`positionType${ind}`}
                                                >
                                                    <Checkbox
                                                        checked={Boolean(
                                                            values?.compagniesTypes?.find(
                                                                (element) => element.id === it.id
                                                            )
                                                        )}
                                                    />
                                                    {it.name}
                                                </MenuItem>
                                            )),
                                        ])}
                                    </Select>
                                    <FormHelperText error>
                                        {touched.compagniesTypes && errors.compagniesTypes}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id="LabelCompanySize">
                                        Taille de la structure
                                    </InputLabel>
                                    <Select
                                        labelId="LabelCompanySize"
                                        id={"compagnySizeSelect"}
                                        value={values.size}
                                        name="size"
                                        onChange={handleChange}
                                        error={touched.size && Boolean(errors.size)}
                                        onBlur={handleBlur}
                                        disabled={disabled}
                                    >
                                        {DEFAULT_COMPAGNY_SIZE_LIST?.map((item, index) => (
                                            <MenuItem
                                                value={item.id}
                                                key={index}
                                                id={`CompanySizeItem-${index}`}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {touched.compagniesTypes && errors.compagniesTypes}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInput
                                    id="standard-multiline-flexible"
                                    multiline
                                    fullWidth
                                    maxRows={4}
                                    type="text"
                                    name="description"
                                    label="Description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    disabled={disabled}
                                    inputProps={{ maxLength: 500 }}
                                />
                                <FormHelperText
                                    style={{ float: "right" }}
                                    id="descriptionHelperText"
                                >
                                    {values.description.length} / 500
                                </FormHelperText>
                            </Grid>

                            <Box width="100%" display="flex" mt={2} justifyContent="space-between">
                                <Button
                                    id="cancelCompanyButton"
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={onClose}
                                >
                                    <Typography>Annuler</Typography>
                                </Button>
                                <Button
                                    id="createCompanyButton"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled || isSubmitting}
                                >
                                    <Typography>Enregistrer</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </CmtForm>
                )}
            </Formik>
        </Box>
    );
};
