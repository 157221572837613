import {
    Box,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import history from "services/History/history";
import { ANNOUNCEMENTS_PATH, DEFAULT_PRICE_LIST, EDIT_PATH, MY_ANNOUNCEMENTS_PATH } from "Constant";
import CmtImage from "Components/CmtImage/CmtImage";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    RowTableContainer,
    TranslatedIcon,
    HiddenIcon,
    SkeletonTableContainer,
} from "Components/CmtTableElements/sc.TableElements";
import { useState } from "react";
import { RestrictedModale } from "Components/RestrictedModale/RestrictedModale";

export const AnnouncementsRow = ({
    item,
    basicPath,
    changeFavorite,
    deleteAnnouncement,
    path,
    profile,
    openRestrictedModale,
}) => {
    const isMyAnnouncement = path === MY_ANNOUNCEMENTS_PATH ? true : false;

    return (
        <RowTableContainer
            onClick={() => {
                if (!profile?.profile.isCompleted) {
                    openRestrictedModale();
                } else {
                    history.push(`${basicPath}/${item?.id}`);
                }
            }}
        >
            <TableCell>
                <CmtImage
                    src={item?.announcementPhotos && item?.announcementPhotos[0]?.photoFileName}
                    size={100}
                    objectFit="cover"
                />
            </TableCell>
            <TableCell>
                <Typography component="p" variant="h4">
                    {item?.title}
                </Typography>
                <Typography component="p" variant="h5">
                    {item?.createdBy?.firstName} {item?.createdBy?.lastName}{" "}
                </Typography>
            </TableCell>
            <TableCell> {item?.announcementType?.name} </TableCell>
            <TableCell>
                {DEFAULT_PRICE_LIST?.find((elem) => elem.id === item?.priceRange)?.name}{" "}
            </TableCell>
            <TableCell>
                <Typography component="p"> {moment(item?.fromDate).format("LL")} </Typography>
            </TableCell>
            <TableCell>
                <Typography component="p"> {item?.fullAddress} </Typography>
            </TableCell>
            <TableCell>
                <Box display="flex" onClick={(e) => e.stopPropagation()}>
                    {isMyAnnouncement ? (
                        <>
                            <TranslatedIcon
                                className="action-option icons"
                                onClick={() =>
                                    history.push(`${ANNOUNCEMENTS_PATH}/${item?.id}${EDIT_PATH}`)
                                }
                            >
                                <EditIcon fontSize="medium" color="primary" />
                            </TranslatedIcon>

                            <HiddenIcon
                                className="action-option icons"
                                onClick={() =>
                                    deleteAnnouncement({ id: item?.id, basicPath: basicPath })
                                }
                            >
                                <DeleteIcon fontSize="medium" color="primary" />
                            </HiddenIcon>
                        </>
                    ) : item?.isFavorite ? (
                        <IconButton className="icons" onClick={() => changeFavorite(item?.id)}>
                            <FavoriteIcon fontSize="medium" color="primary" />{" "}
                        </IconButton>
                    ) : (
                        <IconButton className="icons" onClick={() => changeFavorite(item?.id)}>
                            <FavoriteBorderIcon fontSize="medium" color="primary" />{" "}
                        </IconButton>
                    )}
                </Box>
            </TableCell>
        </RowTableContainer>
    );
};

const TableSkeletonLine = () => {
    return (
        <SkeletonTableContainer>
            <TableCell>
                <Skeleton variant="rectangular" width={100} height={100} />
            </TableCell>

            <TableCell>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="50%" />
            </TableCell>

            <TableCell>
                <Skeleton variant="text" width="80%" />
            </TableCell>

            <TableCell>
                <Skeleton variant="text" width="90%" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" width="90%" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" width="100%" />
            </TableCell>
            <TableCell>
                <Skeleton variant="circular" width={40} height={40} />
            </TableCell>
        </SkeletonTableContainer>
    );
};

export const AnnouncementsTable = ({
    list,
    basicPath,
    filters,
    changeFilters,
    profileId,
    handleClickFavorite,
    deleteAnnouncement,
    path,
    loading,
    profile,
}) => {
    const field = filters.sorter ? filters.sorter.split(" ")[0] : "";
    const order = filters.sorter ? filters.sorter.split(" ")[1] : "";

    const [restrictedModale, setResctrictedModale] = useState(false);

    const handleSortClick = (newField) => {
        let newOrder = "";

        if (field === newField) {
            newOrder = order === "ASC" ? "DESC" : "ASC";
        } else {
            newOrder = order;
        }

        changeFilters({ ...filters, sorter: `${newField} ${newOrder}` });
    };

    return (
        <>
            <TableContainer sx={{ overflowX: "hidden" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Photo</TableCell>

                            <TableCell align="justify">
                                <TableSortLabel
                                    active={field === "name"}
                                    direction={order.toLowerCase()}
                                    onClick={() => handleSortClick("name")}
                                >
                                    Titre
                                </TableSortLabel>
                            </TableCell>

                            <TableCell align="justify">
                                <TableSortLabel
                                    active={field === "category"}
                                    direction={order.toLowerCase()}
                                    onClick={() => handleSortClick("category")}
                                >
                                    Catégorie
                                </TableSortLabel>
                            </TableCell>

                            <TableCell align="justify">
                                <TableSortLabel
                                    active={field === "price"}
                                    direction={order.toLowerCase()}
                                    onClick={() => handleSortClick("price")}
                                >
                                    Fourchette de prix
                                </TableSortLabel>
                            </TableCell>

                            <TableCell align="justify">
                                <TableSortLabel
                                    active={field === "publicationDate"}
                                    direction={order.toLowerCase()}
                                    onClick={() => handleSortClick("publicationDate")}
                                >
                                    Date
                                </TableSortLabel>
                            </TableCell>

                            <TableCell align="justify">
                                <TableSortLabel
                                    active={field === "distance"}
                                    direction={order.toLowerCase()}
                                    onClick={() => handleSortClick("distance")}
                                >
                                    Lieu
                                </TableSortLabel>
                            </TableCell>

                            <TableCell align="justify" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((item, index) => (
                            <AnnouncementsRow
                                item={item}
                                basicPath={basicPath}
                                profileId={profileId}
                                changeFavorite={handleClickFavorite}
                                deleteAnnouncement={deleteAnnouncement}
                                path={path}
                                key={index}
                                openRestrictedModale={() => setResctrictedModale(true)}
                                profile={profile}
                            />
                        ))}
                        {loading &&
                            [...Array(3)].map((_, index) => <TableSkeletonLine key={index} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            {restrictedModale && <RestrictedModale close={() => setResctrictedModale(false)} />}
        </>
    );
};
