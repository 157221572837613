import {
    Checkbox,
    Chip,
    ClickAwayListener,
    FormControl,
    FormHelperText,
    Grid,
    Hidden,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CmtAppHeader from "Components/CmtAppHeader/CmtAppHeader";
import { CmtButtonDatePicker } from "Components/CmtDatePicker/CmtButtonDatePicker";
import { CmtButtonTimePicker } from "Components/CmtDatePicker/CmtButtonTimePicker";
import { CmtButtonDateTimePicker } from "Components/CmtDatePicker/CmtButtonDateTimePicker";
import CmtEditor from "Components/CmtEditor/CmtEditor";
import CmtForm from "Components/CmtForm/CmtForm";
import FormInput from "Components/CmtFormInput/CmtFormInput";
import { PLANNING_PATH, REDIRECTION_TIME } from "Constant";
import { Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { CirclePicker } from "react-color";
import { NotificationManager } from "react-notifications";
import { CalendarEventEditorFormControl, SidebarContainer } from "../sc.Planning";
import { Button } from "@mui/material";
import history from "services/History/history";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MAPBOX_TOKEN } from "Constant";
import { useEffect } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import MenuIcon from "@mui/icons-material/Menu";

const initMapInput = (setLocation) => {
    var geocoder = new MapboxGeocoder({
        accessToken: MAPBOX_TOKEN,
        countries: "fr",
        placeholder: "Ajouter une adresse",
    });

    geocoder.addTo("#geocoder-container");

    geocoder.on("result", function (e) {
        setLocation(e);
    });
};

export const CreateEventForm = ({ planningData, projectList, submit }) => {
    const [isCollapse, setIsCollapse] = useState(true);

    const [location, setLocation] = useState("");

    const [startDateAnchor, setStartDateAnchor] = useState(null);
    const [startHourAnchor, setStartHourAnchor] = useState(null);
    const [endDateAnchor, setEndDateAnchor] = useState(null);
    const [endHourAnchor, setEndHourAnchor] = useState(null);

    const [reminderAnchor, setReminderAnchor] = useState(null);

    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        if (isInit) {
            return;
        }

        setIsInit(true);

        initMapInput(setLocation);
    }, [isInit]);

    const checkError = (values) => {
        let errors = {};

        if (!values.title) {
            errors.title = "Veuillez renseigner un titre pour votre calendrier";
        }

        if (!values.color) {
            errors.color = "Veuillez choisir une couleur pour votre calendrier";
        }

        if (!values.startDate) {
            errors.startDate = "Veuillez renseigner une date de début";
        }

        if (!values.endDate) {
            errors.endDate = "Veuillez renseigner une date de fin";
        }

        if (!values.startHour && !values.allDay) {
            errors.startHour = "Veuillez renseigner une heure de début";
        }

        if (!values.endHour && !values.allDay) {
            errors.endHour = "Veuillez renseigner une heure de fin";
        }

        return errors;
    };

    const handleSubmit = async (values) => {
        let data = values;

        if (data.allDay) {
            data.startHour = null;
            data.endHour = null;
        }

        data.fullAddress = location;

        submit(data).then((result) => {
            if (result?.result) {
                NotificationManager.success(
                    "Votre évènement à été crée correctement",
                    "Succès",
                    REDIRECTION_TIME
                );

                history.push(PLANNING_PATH);
            } else {
                NotificationManager.error(result?.error?.message, "Erreur", REDIRECTION_TIME);
            }
        });
    };

    return (
        <Formik
            initialValues={{
                calendarId: "",
                title: "",
                description: "",
                startDate: null,
                endDate: null,
                startHour: null,
                endHour: null,
                fullAddress: "",
                color: "",
                reminder: null,
                allDay: false,
                projectsShared: [],
            }}
            validate={(values) => checkError(values)}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldTouched,
                handleBlur,
                setFieldValue,
            }) => (
                <CmtForm onSubmit={handleSubmit} className="fullHeight">
                    <Box width="100%" height="100%" display="flex">
                        <Box
                            flexGrow={1}
                            p={5}
                            maxWidth="100%"
                            sx={{ overflowY: "auto", overflowX: "hidden" }}
                        >
                            <Box display="flex" justifyContent="space-between">
                                <CmtAppHeader title="Créer un évènement" />
                                <Hidden smUp>
                                    <MenuIcon
                                        color="primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsCollapse(false);
                                        }}
                                        className="pointer"
                                    />
                                </Hidden>
                            </Box>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} display="flex" alignItems="flex-end">
                                    <FormInput
                                        name="title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                        label="Titre"
                                        margin="normal"
                                        error={touched?.title && errors?.title}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} display="flex" alignItems="flex-end">
                                    <FormControl variant="standard" fullWidth margin="dense">
                                        <InputLabel id="calendarId">
                                            Selectionner un calendrier
                                        </InputLabel>
                                        <Select
                                            value={values.calendarId}
                                            onChange={(e) => {
                                                if (!values?.color) {
                                                    setFieldValue(
                                                        "color",
                                                        planningData?.calendarList?.owner?.find(
                                                            (it) => it.id === e.target.value
                                                        ).color
                                                    );
                                                }

                                                setFieldValue("calendarId", e.target.value);
                                            }}
                                            name="calendarId"
                                            labelId="calendarId"
                                        >
                                            {planningData?.calendarList?.owner?.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Box display="flex" alignItems="center">
                                                        <CircleIcon
                                                            sx={{ ml: 3, color: item?.color }}
                                                        />
                                                        <ListItemText
                                                            primary={item.name}
                                                            sx={{ ml: 3 }}
                                                        />
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>
                                            {touched.calendarId && errors.calendarId}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Box display="flex" mt={5} alignItems="center" flexWrap="wrap">
                                <Chip
                                    variant={values.startDate ? "filled" : "outlined"}
                                    size="medium"
                                    sx={{ my: 2 }}
                                    label={values.startDate || "Date de début"}
                                    color={touched.startDate && errors.startDate && "error"}
                                    onClick={(e) => setStartDateAnchor(e.currentTarget)}
                                />

                                <CmtButtonDatePicker
                                    value={values.startDate}
                                    setValue={(value) =>
                                        setFieldValue(
                                            "startDate",
                                            moment(value).format("YYYY-MM-DD")
                                        )
                                    }
                                    disablePast
                                    onTouched={setFieldTouched}
                                    label="Date de début"
                                    error={touched.startDate && errors.startDate}
                                    open={startDateAnchor}
                                    setOpen={setStartDateAnchor}
                                />

                                {!values.allDay && (
                                    <Chip
                                        variant={values.startHour ? "filled" : "outlined"}
                                        sx={{ my: 2, ml: 3 }}
                                        size="medium"
                                        color={touched.startHour && errors.startHour && "error"}
                                        label={values.startHour || "Heure de début"}
                                        onClick={(e) => setStartHourAnchor(e.currentTarget)}
                                    />
                                )}

                                <CmtButtonTimePicker
                                    value={values.startHour}
                                    setValue={(value) =>
                                        setFieldValue("startHour", moment(value).format("HH:mm"))
                                    }
                                    disablePast
                                    onTouched={setFieldTouched}
                                    error={touched.startHour && errors.startHour}
                                    open={startHourAnchor}
                                    setOpen={setStartHourAnchor}
                                />

                                <Typography component="p" variant="h1" mx={3}>
                                    -
                                </Typography>

                                {!values.allDay && (
                                    <Chip
                                        variant={values.endHour ? "filled" : "outlined"}
                                        sx={{ my: 2, mr: 3 }}
                                        size="medium"
                                        color={touched.endHour && errors.endHour && "error"}
                                        label={values.endHour || "Heure de fin"}
                                        onClick={(e) => setEndHourAnchor(e.currentTarget)}
                                    />
                                )}

                                <CmtButtonTimePicker
                                    value={values.endHour}
                                    setValue={(value) =>
                                        setFieldValue("endHour", moment(value).format("HH:mm"))
                                    }
                                    disablePast
                                    onTouched={setFieldTouched}
                                    error={touched.endHour && errors.endHour}
                                    open={endHourAnchor}
                                    setOpen={setEndHourAnchor}
                                />

                                <Chip
                                    variant={values.endDate ? "filled" : "outlined"}
                                    size="medium"
                                    color={touched.endDate && errors.endDate && "error"}
                                    sx={{ my: 2, mr: "auto" }}
                                    label={values.endDate || "Date de fin"}
                                    onClick={(e) => setEndDateAnchor(e.currentTarget)}
                                />

                                <CmtButtonDatePicker
                                    value={values.endDate}
                                    setValue={(value) =>
                                        setFieldValue(
                                            "endDate",
                                            value ? moment(value).format("YYYY-MM-DD") : ""
                                        )
                                    }
                                    disablePast
                                    onTouched={setFieldTouched}
                                    error={touched.endDate && errors.endDate}
                                    open={endDateAnchor}
                                    setOpen={setEndDateAnchor}
                                />

                                <Chip
                                    icon={<AddAlertIcon />}
                                    variant={values.reminder ? "filled" : "outlined"}
                                    sx={{ my: 2 }}
                                    size="medium"
                                    label={values.reminder || "Ajouter un rappel"}
                                    onClick={(e) => setReminderAnchor(e.currentTarget)}
                                />

                                <CmtButtonDateTimePicker
                                    value={values.reminder}
                                    setValue={(value) =>
                                        setFieldValue(
                                            "reminder",
                                            moment(value).format("YYYY-MM-DD HH:mm")
                                        )
                                    }
                                    disablePast
                                    onTouched={setFieldTouched}
                                    open={reminderAnchor}
                                    setOpen={setReminderAnchor}
                                />
                            </Box>

                            <Box display="flex" mt={4} alignItems="center">
                                <Checkbox
                                    checked={values.allDay}
                                    onClick={(e) => setFieldValue("allDay", e.target.checked)}
                                />
                                <Typography component="p">Toute la journée</Typography>
                            </Box>

                            <Box mt={4}>
                                <Box pb={2}>
                                    <Typography component="p" variant="h6" color="gray" my={2}>
                                        Couleur de l'évènement
                                    </Typography>
                                </Box>
                                <CirclePicker
                                    width="100%"
                                    onChange={(newValue) => setFieldValue("color", newValue.hex)}
                                    value={values.color}
                                    name="color"
                                    circleSpacing={8}
                                    onBlur={handleBlur}
                                />
                                <FormHelperText error>
                                    {touched.color && errors.color}
                                </FormHelperText>
                            </Box>

                            <Box mt={4}>
                                <InputLabel id="description">Description</InputLabel>
                                <CalendarEventEditorFormControl>
                                    <CmtEditor
                                        labelId="description"
                                        value={values.description}
                                        onBlur={() => setFieldTouched("description", true, false)}
                                        onChange={(val) => {
                                            setFieldValue("description", val);
                                        }}
                                    />
                                    <FormHelperText error>
                                        {touched.description && errors.description}
                                    </FormHelperText>
                                </CalendarEventEditorFormControl>
                            </Box>

                            <Box pb={5} pt={4} id="geocoder-container"></Box>

                            <Box mt={4} display="flex" width="100%" justifyContent="space-between">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => history.push(PLANNING_PATH)}
                                >
                                    Annuler
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    Créer
                                </Button>
                            </Box>
                        </Box>

                        <ClickAwayListener onClickAway={() => setIsCollapse(true)}>
                            <SidebarContainer p={5} isCollapse={isCollapse}>
                                <Typography component="p" variant="subtitle1" mb={10}>
                                    Partager
                                </Typography>

                                <Typography component="p" variant="subtitle2" my={5}>
                                    {" "}
                                    Mes projets{" "}
                                </Typography>
                                {projectList?.map((item, index) => (
                                    <Box display="flex" alignItems="center" key={index}>
                                        <Checkbox
                                            checked={values.projectsShared.includes(item.id)}
                                            onClick={(e) =>
                                                setFieldValue(
                                                    "projectsShared",
                                                    e.target.checked
                                                        ? values.projectsShared.concat(item.id)
                                                        : values.projectsShared.filter(
                                                              (it) => it !== item.id
                                                          )
                                                )
                                            }
                                            color="primary"
                                        />
                                        <Typography component="p" ml={2} variant="h5">
                                            {" "}
                                            {item?.name}{" "}
                                        </Typography>
                                    </Box>
                                ))}
                            </SidebarContainer>
                        </ClickAwayListener>
                    </Box>
                </CmtForm>
            )}
        </Formik>
    );
};
