import { Box, Button, Typography } from "@mui/material";
import { FieldArray } from "formik";
import { Droppable } from "react-beautiful-dnd";
import { NoteCheckElem } from "./NoteCheckElem";
import PostAddIcon from '@mui/icons-material/PostAdd';


export const NoteCheckList = ({
    values,
    handleValidateCheckbox,
    setFieldValue,
    handleDeleteCheck,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleCreateCheck,
    handleUpdateCheck,
}) => {
    return (
        <FieldArray name='todoList'>
            {({ remove, push }) => (
                <>
                    <Typography component='p' variant='h5'  color='textSecondary' fontSize={12}> Tâches </Typography>
                    <Droppable droppableId='checkList'>
                        {(provided) => (
                            <Box {...provided.droppableProps} ref={provided.innerRef}>
                                {values?.todoList?.map((item, index) => (
                                    <NoteCheckElem
                                        key={index}
                                        item={item}
                                        index={index}
                                        handleValidateCheckbox={handleValidateCheckbox}
                                        setFieldValue={setFieldValue}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        handleDeleteCheck={handleDeleteCheck}
                                        remove={remove}
                                        handleCreateCheck={handleCreateCheck}
                                        handleUpdateCheck={handleUpdateCheck}
                                    />
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                    <Box pt={2} pl={4}>
                        <Button size='small' color='primary' onClick={() => {push({title: '', checked: false})}}>
                            <PostAddIcon/>
                            <Typography component='p' variant='h6'> Ajouter une tâche</Typography>
                        </Button>
                    </Box>
                </>
            )}
        </FieldArray>
    );
};