import { Box, IconButton, alpha } from "@mui/material";
import { styled } from "@mui/system";
import FormInput from "Components/CmtFormInput/CmtFormInput";

export const DescriptionInput = styled(FormInput)`
    & .MuiInputBase-input {
        font-size: 13px;
    }

    & .MuiFormLabel-root {
        font-size: 13px;
    }
`;

export const TitleInput = styled(FormInput)`
    & .MuiInputBase-input {
        font-size: 15px;
        font-weight: 500;
    }

    & .MuiFormLabel-root {
        font-size: 15px;
        font-weight: 500;
    }
`;

export const CancelIconButton = styled(IconButton)`
    transition: 1s;
    background-color: white;
    &:hover {
        background-color: ${(props) => props.theme.palette.error.main};
        color: white;
    }
`;

export const ValidateIconButton = styled(IconButton)`
    transition: 1s;
    background-color: white;
    &:hover {
        background-color: ${(props) => props.theme.palette.primary.main};
        color: white;
    }
`;

export const AddIconButton = styled(IconButton)`
    transition: 1s;
    background-color: ${(props) => props.theme.palette.primary.main};
    color: white;
    &:hover {
        background-color: white;
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

export const TaskItemBox = styled(Box)`
    width: 100%;
    display: flex;
    padding-inline: 20px;
    padding-block: 10px;
    align-items: center;
    transition: 1s;

    &:hover {
        background-color: ${(props) => alpha(props.theme.palette.primary.main, 0.08)};
        transform: translateY(-4px);
        box-shadow: ${(props) => `0 3px 10px 0 ${alpha(props.theme.palette.common.dark, 0.2)}`};
    }
`;
