import { InputBase } from "@mui/material";
import { Box, styled } from "@mui/system";

export const SearchContainer = styled(Box)`
    position: relative;
    width: 100%;
    margin-left: ${props => props.align === 'right' ? 'auto': '0'};
    display: flex;
    align-items: center;
    & .inputRoot {
        width: 100%;
    }

    & .inputInput {
        padding-left: 40px;
        width: 100%
    };
`;

export const IconSearchContainer = styled(Box)`
    position: relative;
    margin-left: ${props => props.align === 'right' ? 'auto': '0'};
    border-radius: 30px;
    &:hover {
        background-color: ${props => props.theme.palette.primary.light};
    };
    display: flex;
    align-items: center;
`;

export const IconInput = styled(InputBase)`
    & .inputRoot {
        color: inherit;
        width: 100%;
    };

    & .inputInput {
        width: ${props => !props.value ? '48px' : '235px'};
        height: 48px;
        
        &:focus {
            width: 235px;
        };

        padding: ${props => props.theme.spacing(2, 2, 2, 0)};
        padding-left: ${props => `calc(1em + ${props.theme.spacing(6)}px)`};
        transition: ${props => props.theme.transitions.create('width')};
        box-sizing: border-box;
    };
`;

export const IconSearch = styled(Box)`
    height: 48px;
    width: 48px;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;
